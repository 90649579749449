import {getRequest, URL_SERVER} from './../requests'


export default async function getApplication(token, appId, byRound){
    let query = ""
    if(byRound){
        query += "?byRoundId=" + "1"
    }
    try {
        return await getRequest(URL_SERVER + "application/" + appId + query, token)
    } catch (error) {
        console.error(error);
    }

}