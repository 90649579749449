import {postRequest, URL_SERVER} from './../requests'


export default async function addApplication(token, hole_id, round_id, worker_id, date, products){
    const data = {
        "hole_id": hole_id,
        "round_id": round_id,
        "worker_id": worker_id,
        "date": date,
        "products": products,
        }
    try {
        return await postRequest(URL_SERVER + 'application', data, token)
    } catch (error) {
        console.error(error);
    }

}