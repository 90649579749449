import {postRequest, URL_SERVER} from './../requests'


export default async function addContractDetail(token, hole_id,property_id,program_id){
    const data = {
        "hole_id": hole_id,
        "property_id": property_id,
        "program_id": program_id
        }
    try {

        return await postRequest(URL_SERVER + 'contractDetail', data, token)
    } catch (error) {
        console.error(error);
    }

}