import {getRequest, URL_SERVER} from './../requests'


export default async function listNotes(token, propertyId, lawnManagementCompanyId, skip, limit){
    try {
        let query = "?"
        if(propertyId != ""){
            query += "propertyId=" + propertyId
        } else {
            query += "lawnManagementCompanyId=" + lawnManagementCompanyId
        }

        if(limit){
            query += "&limit=" + limit
        }

        return await getRequest(URL_SERVER + "notes" + query, token)
    } catch (error) {
        console.error(error);
    }

}