import React, { useEffect, useState } from 'react';
import { TextField, Chip, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ValidationInput from 'components/ValidationInput/ValidationInput';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    FormFeedback,
    Button
  } from "reactstrap";
import {VALID_REGEX} from "assets/validation/errors"
  
export default function Tags({validationType, getTags}) {
    const [items, setItems] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [validTagsInput, setValidTagsInput] = useState(true)
    const [disabledBtn,setDisabledBtn] = useState(true)
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && inputValue.trim() && validTagsInput) {
        setItems([...items, inputValue.trim()]);
        setInputValue('');
        getTags([...items, inputValue.trim()])
        setDisabledBtn(true)
      }
    };

    const pushedAddButton = (inputValue) =>{
      if(inputValue && validationType && !disabledBtn){
          setItems([...items, inputValue]);
          setInputValue('');
          getTags([...items, inputValue])
        }
        setDisabledBtn(true)
    }
  
    const handleRemoveItem = (indexToRemove) => {
      setItems(items.filter((_, index) => index !== indexToRemove));
        getTags(items.filter((_, index) => index !== indexToRemove))
    };

    useEffect(()=>{
      if(validationType && inputValue){
        var valid_state = VALID_REGEX[validationType].test(inputValue)
        if(valid_state){
          setDisabledBtn(false)
        }else{
          setDisabledBtn(true)
        }
      }
    },[inputValue])

    return (
    <div>
      <div style={{ display: "flex"}}>
        <div style={{ flex: 1 }}> {/* Ensure the input takes available space */}
            <ValidationInput
                value={inputValue}
                setter={setInputValue}
                onKeyDown={handleKeyDown}
                validationType={validationType}
                label="Enter items"
                placeholder="Type something and press Enter"
                variant="outlined"
                getValidState={setValidTagsInput}
                fullWidth
            />
        </div>
        <Button
            style={{background:"white",
                    border: "1px solid #d0d4d4", 
                    padding:"0px 17px",
                    borderRadius:"5px",
                    cursor: disabledBtn ? "not-allowed" : "pointer",
                  }}
            onClick={()=>pushedAddButton(inputValue)}
            disabled={disabledBtn}
        >+</Button>
      </div>
      <div style={{ marginTop: '10px' }}>
        {items.map((item, index) => (
          <Chip
            key={index}
            label={item}
            onDelete={() => handleRemoveItem(index)}
            deleteIcon={
              <IconButton size="small">
                <CloseIcon />
              </IconButton>
            }
            style={{ margin: '5px' }}
          />
        ))}
      </div>
    </div>
    );
}
