import {postRequest, URL_SERVER} from './../requests'


export default async function forgotPassword(email){
    const data = {
        "email": email,
        }
    try {
        return await postRequest(URL_SERVER + '/forgot/password', data)
    } catch (error) {
        console.error(error);
    }
}