import { useState, useEffect } from "react";
import { useTokenContext } from "App"
import {
  Card,
  Row,
  Col,
  CardBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import getGrowthSummary from "api/growthTables/getGrowthSummary";
import generateGrowthSummary from "api/growthTables/generateGrowthSummary";
import DropdownList from "components/DropdownList/DropdownList";
import InfoBox from "components/InfoBox/InfoBox";
import { LineChart } from "@mui/x-charts";

function PropertyDetailGrowthTablePane({ holeId, holesNames, selectedHole, setSelectedHole, year, setYear, years, setReload }) {
  const { token, setToken } = useTokenContext();

  const [growthSummaryResponse, setGrowthSummaryResponse] = useState(null);
  const [generateGrowthSummaryResponse, setGenerateGrowthSummaryResponse] = useState(null);

  const doGetGrowthSummary = async (year) => {
    try {
      let growthSummaryResponse = await getGrowthSummary(token, holeId, year)
      setGrowthSummaryResponse(growthSummaryResponse)
    } catch (error) {
      console.log("Error on Get Growth Summary:", error);
    }
  }

  const doGenerateGrowthSummary = async () => {
    try {
      let generateGrowthSummaryRsp = await generateGrowthSummary(token, holeId)
      setGenerateGrowthSummaryResponse(generateGrowthSummaryRsp)
    } catch (error) {
      console.log("Error on Generate Growth Summary:", error);
    }
  }

  useEffect(() => {
    doGetGrowthSummary(year);
    if (generateGrowthSummaryResponse) {
      setReload(generateGrowthSummaryResponse)
    }
  }, [holeId, generateGrowthSummaryResponse])

  useEffect(() => {
    if (growthSummaryResponse) {
      if (growthSummaryResponse.success == false) {
        doGenerateGrowthSummary();
      }
    }
  }, [growthSummaryResponse])



  return (
    <>
      <Card className=" bg-secondary shadow" style={{ borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardBody> 
          <div className="d-flex  align-items-center">
            {holesNames && <span className="d-flex align-items-center">
                <h6 className="heading-small text-muted mr-3">
                  Zone
                </h6>
                <DropdownList header={selectedHole} items={Object.keys(holesNames)} setter={setSelectedHole} />
                <h6 className="heading-small text-muted mr-3 ml-3">
                  Year
                </h6>
                {years && year && <DropdownList header={year} items={years} setter={setYear} />}
              </span>
            }
          </div>

          <Col lg="12">
            <Card className="p-4 mt-3">
              <Row className="px-5 pb-1">
                {"Nutrient demand graph for current location"}
              </Row>
              {growthSummaryResponse && growthSummaryResponse.data &&
                <LineChart
                  axisHighlight={{ x: "line" }}
                  xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], scaleType: 'point', valueFormatter: ((date) => new Date(0, date - 1).toLocaleString('default', { month: "short" })) }]}
                  series={[
                    {
                      data: growthSummaryResponse["data"]["growth_tables"].slice().reverse().map((growth_table) => { return growth_table.growth_potential })
                    }
                  ]}
                  height={400}
                  margin={{ left: 40, right: 30, top: 30, bottom: 30 }}
                  grid={{ vertical: true, horizontal: true }}
                  slotProps={{
                    legend: {
                      position: {
                        vertical: 'top',
                        horizontal: 'middle',
                      },
                      itemMarkWidth: 20,
                      itemMarkHeight: 5,
                      markGap: 5,
                      itemGap: 8,
                    }
                  }}
                />
              }
            </Card>
          </Col>

          <div className="d-flex modal-header pt-1 pb-2">
            <div className="d-flex pt-1 pb-2 align-items-center">
              <h6 className="heading-small text-muted  mr-3">
                Nutrient demand
              </h6>
              <i className="fa-regular fa-circle-question pb-2" id="unit"></i>
              <UncontrolledTooltip
                target="unit"
              >
                A Growth table serves as a reference report detailing the quantities of substances needed for the current hole location.
              </UncontrolledTooltip>
            </div>
           
          </div>
          <Row>
            <Col lg="12">
              <div className="pl-lg-3">

                <Table className="align-items-center table-light table-sm" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "1vw" }}>Month</th>
                      {/* <th scope="col" >Avg temp(F)</th> */}
                      {/* <th scope="col">% Fertilizer <br/> demand <br/> forecast </th> */}
                      <th scope="col">N</th>
                      <th scope="col">P</th>
                      <th scope="col">K</th>
                      <th scope="col">Ca</th>
                      <th scope="col">Mg</th>
                      <th scope="col">S</th>
                      <th scope="col">Fe</th>
                      <th scope="col">Mn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {growthSummaryResponse && growthSummaryResponse.success &&
                      growthSummaryResponse["data"]["growth_tables"].slice().reverse().map((growth_table, idx) => (
                        <tr id={growth_table["id"]} key={idx}>
                          <th scope="row" style={{ maxWidth: "25vw", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {growth_table["month"]}
                          </th>
                          {/* <td>
                        {growth_table["avg_temp"].toFixed(2)}
                        </td> */}
                          {/* <td>
                        {growth_table["growth_potential"].toFixed(2)}
                        </td> */}
                          <td>
                            {growth_table["n"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["p"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["k"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["ca"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["mg"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["s"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["fe"].toFixed(2)}
                          </td>
                          <td>
                            {growth_table["mn"].toFixed(2)}
                          </td>
                        </tr>

                      ))}
                    {/* {growthSummaryResponse && growthSummaryResponse.success && 
                  <>
                   <tr id={"total"}  style={{backgroundColor:"#d9e0d5"}}>
                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        TOTAL
                        </th>
                        <td>
                        </td>
                        <td> 
                        </td> 
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["N"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["P"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["K"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["Ca"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["Mg"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["S"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["Fe"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["total"]["Mn"].toFixed(2)}
                        </td>
                        </tr>
                        <tr id={"rmv"} style={{backgroundColor:"#d9e0d5"}}>
                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        Removed (ppm)
                        </th>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                        {"N/A"}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["P"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["K"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["Ca"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["Mg"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["S"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["Fe"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["removed"]["Mn"].toFixed(2)}
                        </td>
                        </tr>
                        <tr id={"rmvplsn"} style={{backgroundColor:"#d9e0d5"}}>
                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        Removed <br/>Plus MLSN ppm
                        </th>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                        {"N/A"}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["P"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["K"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["Ca"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["Mg"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["S"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["Fe"].toFixed(2)}
                        </td>
                        <td>
                        {growthSummaryResponse["data"]["growth_summary"]["rmv_plus_mlsn"]["Mn"].toFixed(2)}
                        </td>
                        </tr>
                        </>
                  } */}
                  </tbody>
                </Table>


                <div className="mt-3">
                  <InfoBox text={["The unit of measurement for all elements is lbs/1000 sq ft."]} />
                </div>

              </div>
            </Col>

          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default PropertyDetailGrowthTablePane;


