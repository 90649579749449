export const VALID_REGEX = {
    FREETEXT: /^[\.\d+\p{Pd}\p{L}\s]{1,500}$/u,
    FREETEXT_ZERO: /^[\.\d+\p{Pd}\p{L}\s]{0,500}$/u,
    PERSONNAME: /^(?:[a-zA-Z]+(?: [a-zA-Z]+)*){2,100}?$/u,
    USERNAME: /^[0-9a-zA-Z._@-]{3,100}$/u,
    EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/u,
    MOBILENUMBER: /^\+[0-9]{6,15}$/u,
    LETTERSANDNUMBERS: /^[0-9a-zA-Z]{1,250}$/u,
    PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/u,
    NUMERIC: /^[0-9.]{1,250}$/u,
    NUMERIC_NULL: /^[0-9.,]{0,250}$/u,
    CUSTOM_FREETEXT: /^[\.\d+\p{Pd}\p{L}\s%"(),]{1,500}$/u,
}