import {getRequest, URL_SERVER} from './../requests'


export default async function getCompany(token, taxId, name, id, lawnManagementCompanyId){
    try {

        var params;
        if(taxId !== ""){
            params = "?taxId=" + taxId;
        }
        if(name != ""){
            params = "?name=" + name;
        }
        if(id != ""){
            params = "?id=" + id;
        }

        return await getRequest(URL_SERVER + 'company/'+lawnManagementCompanyId + "/get" + params, token)
    } catch (error) {
        console.error(error);
    }

}