import { useState, useEffect, createContext, useContext } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import { LineChart } from '@mui/x-charts/LineChart';
import { getFormattedTime } from "utils/utils";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Table,
    CardBody,
} from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import getApplication from "api/property/getApplication";
import Alert from "components/Alerts/Alert";
import listWorkers from "api/provider/listWorkers";
import ValidationInput from "components/ValidationInput/ValidationInput";
import DropdownList from "components/DropdownList/DropdownList";
import getRound from "api/rounds/getRound";
import getHole from "api/property/getHole";
import getProduct from "api/products/getProduct";
import listProducts from "api/products/listProducts";
import { updateApplication } from "api/property/updateApplication"
import listExcesses from "api/property/listExcesses";
import deleteProduct2Application from "api/products/deleteProduct2Application";
import addProduct2application from "api/products/addProduct2Application";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import getProperty from "api/property/getProperty";
import listNotes from "api/property/listNotes";
import { getLCMID } from "api/company/getLCMID";
import { DatePicker } from "reactstrap-date-picker";
import getAnnualNPK from "api/property/getAnnualNPK";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { isDifferenceMoreThanTenDays } from "utils/utils";
import addFeedback from "api/property/addFeedback";
import updateProduct2Application from "api/products/updateProduct2Application";

const rating_labels = {
    0: 'Dead grass',
    1: 'Poor',
    2: 'Average',
    3: 'Acceptable',
    4: 'Good',
    5: 'Excellent condition',
};

const ViewApplication = () => {

    const { token, setToken } = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState("success")
    const [round, setRound] = useState(null);
    const [worker, setWorker] = useState(null)
    const [workers, setWorkers] = useState(null);
    const [workersNames, setWorkersNames] = useState(null);
    const [hole, setHole] = useState(null);
    const [applicationResponse, setApplicationResponse] = useState(null);
    const [application, setApplication] = useState(null);
    const [products, setProducts] = useState(null);
    const [productsNames, setProductsNames] = useState(null);
    const [inverseProductsNames, setInverseProductsNames] = useState(null);
    const [productsDict, setProductsDicts] = useState(null)
    const [updateApp, setUpdateApp] = useState(null)
    const [excesses, setExcesses] = useState(null);
    const [updateVisible, setUpdateVisible] = useState(false)
    const [addProductVisibleRow, setAddProductVisibleRow] = useState(false)
    const [newProductName, setNewProductName] = useState("Select...")
    const [newProductQuantity, setNewProductQuantity] = useState("");
    const [newProductQuantityValid, setNewProductQuantityValid] = useState(false);
    const [product2ApplicationResponse, setProduct2ApplicationResponse] = useState(null)
    const [property, setProperty] = useState(null);
    const [notes, setNotes] = useState(null);
    const [datePicker, setDatePicker] = useState(new Date().toISOString())
    const [annualNPK, setAnnualNPK] = useState(null)
    const [annualNPKData, setAnnualNPKData] = useState(null)
    const [rating, setRating] = useState(5)
    const [addFeedbackRsp, setAddFeedbackRsp] = useState(null)
    const [editProductIdx, setEditProductIdx] = useState(-1)
    const [editProductCarrierVolume, setEditProductCarrerVolume] = useState(null)
    const [newProductCarrierVolume, setNewProductCarrierVolume] = useState(null)
    const [editProductCarrierVolumeValid, setEditProductCarrierVolumeValid] = useState(false)
    const [newProductCarrierVolumeValid, setNewProductCarrierVolumeValid] = useState(false)
    const [updateProduct2ApplicationRsp, setUpdateProduct2ApplicationRsp] = useState(null)


    function onEditCancelProdiuctClicked() {
        setEditProductIdx(-1)
        setEditProductCarrerVolume(null)
        setNewProductCarrierVolume(null)
        setEditProductCarrierVolumeValid(false)
        setNewProductCarrierVolumeValid(false)
        setAddProductVisibleRow(false)
    }
    function onClickSetStatusDone(status) {
        doUpdateApplication("", status)
    }

    function onUpdateWorker() {

        doUpdateApplication(Object.keys(workersNames).find(id => workersNames[id] === worker) || "", "", parseInt(new Date(datePicker).getTime() / 1000))
    }

    function handleDateChange(value, formattedValue) {
        setDatePicker(value)
    }

    function onRemoveProductFromApplication(prod) {
        doDeleteProduct2Application(prod["id"])
    }

    function onAddProduct2Application(prod) {
        doAddProduct2Application()
    }

    function onAddFeedbackClick() {
        doAddFeedback()
    }

    const doAddFeedback = async () => {
        try {
            let rsp = await addFeedback(token, id, rating ? parseInt(rating + 1) : 1);
            setAddFeedbackRsp(rsp);
        } catch (error) {
            console.log("Error on Add Feedback:", error);
        }
    }

    const doDeleteProduct2Application = async (product2ApplicationId) => {
        try {
            let rsp = await deleteProduct2Application(token, product2ApplicationId);
            setUpdateApp(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    };

    const doAddProduct2Application = async () => {
        try {
            let rsp = await addProduct2application(token, id, Object.keys(productsNames).find(id => productsNames[id] === newProductName) || "", newProductQuantity, parseFloat(newProductCarrierVolume));
            setUpdateApp(rsp);
            setProduct2ApplicationResponse(rsp)
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    };

    const doUpdateApplication = async (worker_id, status, date) => {
        try {
            let rsp = await updateApplication(token, id, status, worker_id, date);
            setUpdateApp(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    };

    const doGetListExcesses = async () => {
        try {
            let rsp = await listExcesses(token, id);
            setExcesses(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }

    }


    const doGetProducts = async () => {
        try {
            let rsp = await listProducts(token, true, lawnManagementCompanyId);
            setProducts(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    };

    const doGetApplication = async () => {
        try {
            let rsp = await getApplication(token, id);
            setApplicationResponse(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    }

    const doGetListWorkers = async () => {
        try {
            let rsp = await listWorkers(token, lawnManagementCompanyId);
            setWorkers(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    }

    const doGetRound = async (round_id) => {
        try {
            let roundResp = await getRound(token, round_id);
            setRound(roundResp);
        } catch (error) {
            console.log("Error on Get Round:", error);
        }
    }

    const doGetHole = async () => {
        try {
            let rsp = await getHole(token, round["data"]["hole_id"]);
            setHole(rsp);
        } catch (error) {
            console.log("Error on Get Round:", error);
        }
    }

    const doGetProperty = async () => {
        try {
            let rsp = await getProperty(token, round["data"]["property_id"]);
            setProperty(rsp);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }

    const doGetPropertyNotes = async () => {
        try {
            let rsp = await listNotes(token, round["data"]["property_id"], "", 4);
            setNotes(rsp);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }

    const doGetAnnualNPK = async () => {
        try {
            let rsp = await getAnnualNPK(token, round["data"]["hole_id"], new Date().getFullYear().toString());
            setAnnualNPK(rsp);
        } catch (error) {
            console.log("Error on Get Annual NPL:", error);
        }
    }

    const doUpdateProduct2Application = async (prodId) => {
        try {
            let rsp = await updateProduct2Application(token, prodId, application["nutrients_application"]["id"], 0, parseFloat(editProductCarrierVolume))
            setUpdateApp(rsp)
            setUpdateProduct2ApplicationRsp(rsp)

        } catch (error) {
            console.log("Error on add product 2 program round: ", error)
        }
    }

    useEffect(() => {
        if (updateProduct2ApplicationRsp && updateProduct2ApplicationRsp.success) {
            onEditCancelProdiuctClicked()
        }
    }, [updateProduct2ApplicationRsp])


    useEffect(() => {
        if (application) {
            doGetProducts();
        }
    }, [application])

    async function getLawnManagementId() {
        setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
        if (lawnManagementCompanyId) {
            doGetApplication();
            doGetListWorkers();
            doGetListExcesses();
        }
    }, [lawnManagementCompanyId, updateApp])

    useEffect(() => {
        if (token) {
            getLawnManagementId()
        }
    }, [id, token, updateApp])



    useEffect(() => {
        if (applicationResponse) {
            setApplication(applicationResponse.data)
            setDatePicker(new Date(applicationResponse["data"]["nutrients_application"]["date"] * 1000).toISOString())
            doGetRound(applicationResponse.data["nutrients_application"]["round_id"]);
        }
    }, [applicationResponse])

    useEffect(() => {
        if (workers && workers.success && workers["data"] != null) {
            setWorkersNames(workers["data"].reduce((acc, item) => {
                acc[item.operator["id"]] = item.operator["name"] + " (" + item.associate["email"] + " )"
                return acc;
            }, {}))
        }
    }, [workers]);

    useEffect(() => {
        if (round) {
            if (round.success) {
                doGetHole();
                doGetProperty();
                doGetPropertyNotes();
                doGetAnnualNPK();
            }
        }
    }, [round])

    useEffect(() => {
        if (products && products.success && products["data"] != null) {
            setProductsNames(products["data"].reduce((acc, item) => {
                acc[item["id"]] = item["name"]
                return acc

            }, {}))

            setProductsDicts(products["data"].reduce((acc, item) => {
                acc[item["id"]] = item
                return acc

            }, {}))

            setInverseProductsNames(products["data"].reduce((acc, item) => {
                acc[item["name"]] = item["id"]
                return acc

            }, {}))

        }
    }, [products])

    function navigateBack() {
        if (hole) {
            const propsToPass = {
                pane: 3
            };
            navigate(-1);
        }
    }

    useEffect(() => {
        if (application && workersNames) {
            setWorker(workersNames[application["nutrients_application"]["worker_id"]])
        }
    }, [application, workersNames])

    useEffect(() => {
        if (updateApp) {
            setAlert(updateApp.message)
        }
    }, [updateApp])

    useEffect(() => {
        if (product2ApplicationResponse) {
            setAddProductVisibleRow(false);
            setNewProductCarrierVolume(null)
            setNewProductName("")
            setNewProductCarrierVolumeValid(false)
            setNewProductQuantityValid(false)
            setNewProductQuantity(0)
        }
    }, [product2ApplicationResponse])

    useEffect(() => {
        if (annualNPK && annualNPK.success && annualNPK.data) {


            const calculateReverseCumulativeSum = (data) => {
                let cumulativeSum = 0;
                return data.map((value, index, arr) => cumulativeSum += arr[index]);
            };

            const cumulativeN = calculateReverseCumulativeSum(annualNPK.data.N.slice().reverse());
            const cumulativeP = calculateReverseCumulativeSum(annualNPK.data.P.slice().reverse());
            const cumulativeK = calculateReverseCumulativeSum(annualNPK.data.K.slice().reverse());

            // Pregătirea datelor pentru grafic
            setAnnualNPKData(annualNPK.data.appl_dates.slice().reverse().map((date, index) => ({
                date: date,
                N: cumulativeN[index],
                P: cumulativeP[index],
                K: cumulativeK[index]
            })))
        }
    }, [annualNPK])

    useEffect(() => {
        if (addFeedbackRsp) {
            if (addFeedbackRsp.success) {
                setAlertColor("success")
                doGetApplication()
            } else {
                setAlertColor("danger")
            }
            setAlert(addFeedbackRsp.message)

        }
    }, [addFeedbackRsp])

    useEffect(() => {
        if (newProductName && inverseProductsNames && inverseProductsNames[newProductName]) {
            let carrierVol = productsDict[inverseProductsNames[newProductName]]["carrier_volume"]
            if (carrierVol && carrierVol > 0)
                setNewProductCarrierVolume(carrierVol)
            setNewProductCarrierVolumeValid(true)
        }
    }, [newProductName])


    return (
        <>
            <PageHeader />
            {/* Page content */}
            <Container className={alert ? "mt--8" : "mt--7"} fluid>

                <div className="col">
                    {alert && <Alert color={alertColor} text={alert} setter={setAlert} ></Alert>}
                    <Row>
                        <Col lg="12" className="mb-2">
                            {hole && hole.success && hole.data && property && property.data &&
                                <BreadcrumbNavigation active="Application details" past={[{ "name": "Customers", "link": "/admin/customers" }, { "name": "Details", "link": "/admin/customer/detail/" + hole["data"]["hole"]["customer_id"] },
                                { "name": property["data"]["property"]["address"], "link": "/admin/property/detail/" + property["data"]["property"]["id"] }]} />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card className="bg-secondary shadow">

                                <CardHeader className="border-0 d-flex modal-header">
                                    <div className="d-flex">
                                        <Button
                                            color="transparent"
                                            onClick={(e) => { e.preventDefault(); navigateBack() }}
                                            size="sm"
                                        >
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </Button>
                                        <h3 className="mb-0">Application details</h3>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-2">
                                        <Row className="pb-4">
                                            {hole && hole.success &&
                                                <>
                                                    <Col lg="5" >
                                                        <Card className="py-3 px-4">
                                                            <CardHeader className="m-0 p-0 pb-1">
                                                                <label className="form-control-label text-muted">Property</label>
                                                                <h5>  {property && property.success && property["data"]["property"]["address"] + " " + property["data"]["property"]["zip_code"] + " - " + property["data"]["customerName"] + " - " + hole["data"]["hole"]["hole_name"]}
                                                                </h5>
                                                            </CardHeader>
                                                            <Row className="mt-2">
                                                                <Col lg="8">
                                                                    <label className="form-control-label text-muted">
                                                                        Turf Type
                                                                    </label>
                                                                    <h5>
                                                                        {hole["data"]["turf_type"]["turf_type_name"]}
                                                                    </h5>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <label className="form-control-label text-muted">
                                                                        Area <small>[sq ft]</small>
                                                                    </label>
                                                                    <h5>
                                                                        {hole["data"]["hole"]["sq_ft"]}
                                                                    </h5>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                        {application["feedback"] ?
                                                            <Card className="px-3 py-2 mt-2" >
                                                                <Row className="pl-4 pt-2" >
                                                                    <div className="d-block" style={{ width: "100%" }}>
                                                                        <label
                                                                            className="form-control-label mr-3"
                                                                            htmlFor="input-mailing-city"
                                                                        >
                                                                           How's the grass looking post-application?
                                                                        </label>
                                                                        <div className="d-flex align-items-center py-2">
                                                                            <Rating
                                                                                name="text-feedback"
                                                                                value={application["feedback"]["rating"] - 1}
                                                                                readOnly
                                                                                precision={1}
                                                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                                            />
                                                                            <div className="text-muted ml-3" style={{ fontSize: "0.85rem" }}>
                                                                                {rating_labels[application["feedback"]["rating"] - 1]}
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </Row>
                                                            </Card>
                                                            :
                                                            <>
                                                                {application["nutrients_application"]["status"]["StatusValue"] === "Done" &&
                                                                    <Card className="px-3 py-2 mt-2">
                                                                        <Row className="pl-4 pt-2" >
                                                                            {isDifferenceMoreThanTenDays(application["nutrients_application"]["status"]["UpdatedAt"]) ?
                                                                                <div className="d-block" style={{ width: "100%" }}>
                                                                                    <div className="d-flex">
                                                                                        <label
                                                                                            className="form-control-label mr-3"
                                                                                            htmlFor="input-mailing-city"
                                                                                        >
                                                                                            hhHow's the grass looking post-application?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between py-2">
                                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                                            <Rating
                                                                                                name="text-feedback"
                                                                                                value={rating}
                                                                                                onChange={(event, newValue) => {
                                                                                                    setRating(newValue);
                                                                                                }}

                                                                                                precision={1}
                                                                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                                                            />
                                                                                            <div className="text-muted ml-3" style={{ fontSize: "0.85rem" }}>
                                                                                                {rating ? rating_labels[rating] : rating_labels[0]}
                                                                                            </div>
                                                                                        </div>
                                                                                        <Button color="success" className="justify-content-end mr-4" size="sm" onClick={() => onAddFeedbackClick()}>
                                                                                            Submit
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>

                                                                                :
                                                                                <>
                                                                                    <div className="d-flex align-items-center py-1">
                                                                                        <label
                                                                                            className="form-control-label mr-3"
                                                                                            htmlFor="input-mailing-city"
                                                                                        >
                                                                                            Feedback:
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="d-block text-muted py-1 pb-3" style={{ fontSize: "0.85rem" }}>
                                                                                        You need to wait 10 days from the time of application before you can provide feedback.
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </Row>
                                                                    </Card>
                                                                }
                                                            </>
                                                        }


                                                    </Col>
                                                    <Col lg="7">
                                                        <Card className="p-4">
                                                            <Row className="px-5">
                                                                Annual NPK totals
                                                            </Row>
                                                            {annualNPK && annualNPK["success"] && annualNPK["data"] && annualNPKData &&

                                                                <LineChart
                                                                    axisHighlight={{ x: "line" }}
                                                                    xAxis={[{ data: annualNPKData.map((en) => { return en["date"] }), valueFormatter: (date) => getFormattedTime(date), scaleType: 'point' }]}
                                                                    series={[
                                                                        {
                                                                            label: 'N',
                                                                            data: annualNPKData.map((en) => { return en["N"] }),
                                                                        },
                                                                        {
                                                                            label: 'P',
                                                                            data: annualNPKData.map((en) => { return en["P"] }),
                                                                        },
                                                                        {
                                                                            label: 'K',
                                                                            data: annualNPKData.map((en) => { return en["K"] }),
                                                                        }
                                                                    ]}
                                                                    height={200}
                                                                    margin={{ left: 40, right: 30, top: 30, bottom: 30 }}
                                                                    grid={{ vertical: true, horizontal: true }}
                                                                    slotProps={{
                                                                        legend: {
                                                                            position: {
                                                                                vertical: 'top',
                                                                                horizontal: 'middle',
                                                                            },
                                                                            itemMarkWidth: 20,
                                                                            itemMarkHeight: 5,
                                                                            markGap: 5,
                                                                            itemGap: 8,
                                                                        }
                                                                    }}
                                                                />
                                                            }

                                                            {/* <CardHeader className="m-0 p-0 pb-1">
                                                                <h4>Recent notes</h4>
                                                                </CardHeader>
                                                            <div className="mt-2">
                                                            {notes && notes.success && notes.data &&
                                                            
                                                            notes.data.slice(0,2).map((note,idx) => (
                                                                <Card id={"note-" + idx} className="p-2 my-1 shadow bg-secondary">
                                                                    <small>{note.content}</small>
                                                                </Card>
                                                            ))
                                                            
                                                            }
                                                            </div> */}
                                                        </Card>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </div>
                                    {round && round.success &&
                                        <>
                                            {/* <h6 className="heading-small text-muted mb-4">
                                                Round recommandations
                                            </h6>
                                            <div className="pl-lg-4">                        
                                            <Row>
                                            <Col lg="12">
                                                    <Table className="align-items-center table-light table-sm" responsive>
                                                    <thead className="thead-dark">
                                                        <tr>
                                                        <th scope="col">Day</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">N</th>
                                                        <th scope="col">P</th>
                                                        <th scope="col">K</th>
                                                        <th scope="col">Ca</th>
                                                        <th scope="col">Mg</th>
                                                        <th scope="col">S</th>
                                                        <th scope="col">Fe</th>
                                                        <th scope="col">Mn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <td>
                                                        {round["data"]["application_day"]}
                                                        </td>
                                                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                                                        {getFormattedTime(round["data"]["application_date"])}
                                                        </th>
                                                        <td>
                                                        {round["data"]["n_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["p_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["k_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["ca_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["mg_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["s_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["fe_q"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                        {round["data"]["mn_q"].toFixed(2)}
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            
                                            </Col>
                                            </Row>                         
                                            </div> */}

                                            {/* <hr className="my-2 mt-4" />
                                            
                                            <div className="d-flex modal-header pl-0 pb-0">
                                                <h6 className="heading-small text-muted mr-5">
                                                    Application
                                                </h6>                            
                                            </div> */}
                                            <div className="pl-lg-2">
                                                <Row className="pb-4 align-items-center">
                                                    <Col lg="5">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <label
                                                                    className="form-control-label mr-3 mb-2 mb-md-0"
                                                                    htmlFor="input-mailing-city"
                                                                >
                                                                    Assigned Worker
                                                                    <span>
                                                                        <small className="text-danger">
                                                                            &nbsp;*
                                                                        </small>
                                                                    </span>
                                                            </label>
                                                            {workersNames && application && 
                                                                <DropdownList 
                                                                    className="mb-2 mb-md-0"
                                                                    header={worker} 
                                                                    items={Object.values(workersNames)} 
                                                                    setter={(w) => { setWorker(w); setUpdateVisible(true) }}
                                                                    size="sm"
                                                                />
                                                            }
                                                            {updateVisible &&
                                                                <Button 
                                                                    className="mt-md-0 mt-1"
                                                                    color="light" size="sm" 
                                                                    onClick={() => { onUpdateWorker(); setUpdateVisible(false) }}>
                                                                    <i className="fa-solid fa-pen"></i>
                                                                    &nbsp;Update
                                                                </Button>
                                                            }
                                                       
                                                        </div>
                                                    </Col>

                                                    <Col lg="3" className="d-flex flex-wrap" >
                                                        <div className="d-flex align-items-center justify-content-start ">
                                                            <label
                                                                className="form-control-label mr-3"
                                                                htmlFor="input-mailing-city"
                                                            >
                                                                Application Date
                                                                <span>
                                                                </span>
                                                            </label>
                                                            <div>
                                                                <DatePicker id="example-datepicker"
                                                                    className=""
                                                                    dateFormat="MM/DD/YYYY"
                                                                    value={datePicker}
                                                                    onChange={(v, f) => { handleDateChange(v, f); setUpdateVisible(true) }}
                                                                    style={{}}

                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4"> 
                                                        <Row className="d-flex align-items-center flex-wrap">
                                                            <label
                                                                className="form-control-label mr-2"
                                                                htmlFor="input-mailing-city"
                                                            >
                                                                Status
                                                            </label>

                                                            {application &&
                                                               <>
                                                                <Col>
                                                                    <Card className="p-3">
                                                                        <div className="d-block mr-4 flex-wrap">
                                                                            <strong className="d-block">{application["nutrients_application"]["status"]["StatusValue"]}</strong>
                                                                            <small className="d-block">
                                                                                {application["nutrients_application"]["status"]["StatusReason"]}
                                                                            </small>
                                                                            <small className="d-block">
                                                                                {getFormattedTime(application["nutrients_application"]["status"]["UpdatedAt"])}
                                                                            </small>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                                
                                                                {application["nutrients_application"]["status"]["StatusValue"] == "In Progress" && (new Date().setHours(0, 0, 0, 0) >= new Date(application["nutrients_application"]["date"] * 1000).setHours(0, 0, 0, 0)) &&
                                                                    <Col style={{margin:'0px'}}>
                                                                    
                                                                        <Card className="p-3 ml-0">
                                                                            <div className="d-flex flex-column align-items-start">
                                                                                <strong className="d-block">Set status to</strong>
                                                                                <Button color="success" onClick={() => onClickSetStatusDone("Done")}>
                                                                                    <i className="fa-solid fa-circle-check"></i>
                                                                                    &nbsp;Done
                                                                                </Button>
                                                                            </div>
                                                                        </Card>
                                                                    </Col>
                                                                }
                                                               </>
                                                            }
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Table className="align-items-center table-light table-sm" responsive>
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col" style={{ width: "13vw" }}></th>
                                                            <th scope="col">N</th>
                                                            <th scope="col">P</th>
                                                            <th scope="col">K</th>
                                                            <th scope="col">Ca</th>
                                                            <th scope="col">Mg</th>
                                                            <th scope="col">S</th>
                                                            <th scope="col">Fe</th>
                                                            <th scope="col">Mn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {application &&
                                                            <tr id="total" >
                                                                <th scope="row" style={{ maxWidth: "25vw", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                    quantities scheduled
                                                                </th>
                                                                <td>
                                                                    {application["nutrients_application"]["total_n"] ? application["nutrients_application"]["total_n"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_p"] ? application["nutrients_application"]["total_p"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_k"] ? application["nutrients_application"]["total_k"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_ca"] ? application["nutrients_application"]["total_ca"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_mg"] ? application["nutrients_application"]["total_mg"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_s"] ? application["nutrients_application"]["total_s"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_fe"] ? application["nutrients_application"]["total_fe"].toFixed(2) : 0.0000}
                                                                </td>
                                                                <td>
                                                                    {application["nutrients_application"]["total_mn"] ? application["nutrients_application"]["total_mn"].toFixed(2) : 0.0000}
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                    {round && round.success &&
                                                        <tr style={{ backgroundColor: "#f7f7f7" }} id={"recommended-round"} className="text-primary font-italic">
                                                            <th scope="row" style={{ maxWidth: "25vw", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                <small className="d-block">(recommended round)</small>
                                                                {getFormattedTime(round["data"]["application_date"])}
                                                            </th>
                                                            <td>
                                                                {round["data"]["n_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["p_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["k_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["ca_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["mg_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["s_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["fe_q"].toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {round["data"]["mn_q"].toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    }
                                                </Table>
                                            </div>

                                        </>


                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12" lg="12" md="12">
                            <Card className="bg-secondary shadow mt-4" >
                                <CardHeader className="border-0 d-flex modal-header" >
                                    <h6 className="heading-small text-muted mr-5">
                                        Products applied
                                    </h6>
                                    {application && application["nutrients_application"]["status"]["StatusValue"] == "In Progress" &&
                                        <Button color="primary" size="sm" onClick={() => setAddProductVisibleRow(true)} >
                                            + Add
                                        </Button>
                                    }
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-2">
                                        <Table className="align-items-center table-light table-sm" responsive>
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col" className="justify-content-start text-left">Product name</th>
                                                    <th scope="col" className="">Quantity<br /><small>TOTAL lbs or oz</small></th>
                                                    <th scope="col" className="">Carrier Volume<br /><small>[gal/1000sqft]</small></th>
                                                    <th scope="col" className="">Dillution rate<br /><small>[lbs or oz / gal]</small></th>
                                                    <th scope="col" className="justify-content-end text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {application && productsNames && application["products"] && application["products"].map((prod, idx) => (
                                                    <tr id="total" key={idx}>
                                                        <th>
                                                            {productsNames[prod["product_id"]]}
                                                        </th>
                                                        <td >
                                                            {prod["quantity"] && prod["quantity"].toFixed(2)}
                                                        </td>
                                                        <td>
                                                            {editProductIdx == idx ?
                                                                <>
                                                                    <ValidationInput type="text" placeholder="carrier vol" value={editProductCarrierVolume} setter={setEditProductCarrerVolume} validationType="NUMERIC" message={"Invalid volume"} getValidState={setEditProductCarrierVolumeValid} />
                                                                </>
                                                                :
                                                                <>
                                                                    {prod["carrier_volume"] && prod["carrier_volume"].toFixed(2)}
                                                                </>
                                                            }

                                                        </td>
                                                        <td>
                                                            {hole && hole.data && hole.data.hole &&
                                                                <>
                                                                    {editProductIdx == idx && editProductCarrierVolumeValid && editProductCarrierVolume ?
                                                                        <>
                                                                            {((1000 * prod["quantity"] / hole["data"]["hole"]["sq_ft"]) / editProductCarrierVolume).toFixed(2)}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {prod["carrier_volume"] && ((1000 * prod["quantity"] / hole["data"]["hole"]["sq_ft"]) / prod["carrier_volume"]).toFixed(2)}
                                                                        </>
                                                                    }

                                                                </>
                                                            }
                                                        </td>
                                                        <td className="justify-content-end text-right">
                                                            {application && application["nutrients_application"]["status"]["StatusValue"] == "In Progress" &&
                                                                <>
                                                                    {editProductIdx == idx ?
                                                                        <>
                                                                            <Button
                                                                                color="success"
                                                                                className=""
                                                                                type="button"
                                                                                size="sm"
                                                                                disabled={!(editProductCarrierVolumeValid)}
                                                                                onClick={() => doUpdateProduct2Application(prod["product_id"])}

                                                                            >
                                                                                <i className="fa-solid fa-check"></i>
                                                                            </Button>
                                                                            <Button
                                                                                color="light"

                                                                                type="button"
                                                                                size="sm"
                                                                                onClick={onEditCancelProdiuctClicked}

                                                                            >
                                                                                <i className="fa-solid fa-xmark"></i>
                                                                            </Button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {productsDict && productsDict[prod["product_id"]] && productsDict[prod["product_id"]]["type"] !== "granular" &&
                                                                                <Button color="secondary" size="sm" onClick={() => { setEditProductIdx(idx); setEditProductCarrerVolume(prod["carrier_volume"]) }}>
                                                                                    <i className="fa-solid fa-pen"></i>
                                                                                </Button>
                                                                            }

                                                                            <Button color="danger" size="sm" onClick={() => onRemoveProductFromApplication(prod)}>
                                                                                <i className="fa-solid fa-trash"></i>
                                                                            </Button>
                                                                        </>
                                                                    }

                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))

                                                }
                                                {addProductVisibleRow && productsNames &&
                                                    <tr id="new-prod" style={{ backgroundColor: "#f7f7f7" }}>
                                                        <th>
                                                            <DropdownList header={newProductName} items={Object.values(productsNames)} setter={setNewProductName} />
                                                        </th>
                                                        <td >
                                                            <ValidationInput type="text" placeholder="Quantity" value={newProductQuantity} setter={setNewProductQuantity} validationType="NUMERIC" message={"Invalid quantity"} getValidState={setNewProductQuantityValid} />
                                                        </td>
                                                        <td>
                                                            {newProductName && inverseProductsNames[newProductName] && productsDict[inverseProductsNames[newProductName]]["type"] != "granular" &&
                                                                <ValidationInput type="text" placeholder="carrier vol" value={newProductCarrierVolume} setter={setNewProductCarrierVolume} validationType="NUMERIC" message={"Invalid carrier vol"} getValidState={setNewProductCarrierVolumeValid} />
                                                            }
                                                        </td>
                                                        <td>
                                                            {newProductName && inverseProductsNames[newProductName] && newProductCarrierVolume && newProductCarrierVolumeValid && newProductQuantity && newProductQuantityValid && hole && hole.data &&
                                                                ((1000 * newProductQuantity / hole["data"]["hole"]["sq_ft"]) / newProductCarrierVolume).toFixed(2)}
                                                        </td>
                                                        <td className="justify-content-end text-right">
                                                            <Button color="success" size="sm" disabled={!(newProductName != "" && newProductQuantityValid && newProductQuantity > 0)} onClick={onAddProduct2Application}>
                                                                <i className="fa-solid fa-check"></i>
                                                            </Button>
                                                            <Button color="danger" size="sm" onClick={() => { setAddProductVisibleRow(false); setNewProductName("Select..."); setNewProductQuantity(""); setNewProductQuantityValid(true) }}>
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>

                                                }

                                            </tbody>
                                        </Table>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="12" lg="12" md="12">
                            <Card className="bg-secondary shadow mt-4" >
                                <CardHeader className="border-0 d-flex modal-header" >
                                    <h6 className="heading-small text-muted mr-5">
                                        Excesses
                                    </h6>
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-2">
                                        {application && application["nutrients_application"]["status"]["StatusValue"] == "Done" ?
                                            <div className="d-block">
                                                <Table className="align-items-center table-light table-sm" responsive>
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col" >N</th>
                                                            <th scope="col">P</th>
                                                            <th scope="col" >K</th>
                                                            <th scope="col" >Ca</th>
                                                            <th scope="col" >Mg</th>
                                                            <th scope="col" >S</th>
                                                            <th scope="col" >Fe</th>
                                                            <th scope="col" className="justify-content-end text-right">Mn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {excesses && excesses["data"] && excesses["data"].map((ex) => (
                                                            <tr id="total">
                                                                <td>
                                                                    {ex["n_q"].toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    {ex["p_q"].toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    {ex["k_q"].toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    {ex["ca_q"].toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    {ex["mg_q"].toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    {ex["s_q"].toFixed(2)}
                                                                </td>
                                                                <td>
                                                                    {ex["fe_q"].toFixed(2)}
                                                                </td>
                                                                <td className="justify-content-end text-right">
                                                                    {ex["mn_q"].toFixed(2)}
                                                                </td>
                                                            </tr>
                                                        ))

                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            :
                                            <small>
                                                <i className="fa-solid fa-circle-info"></i>&nbsp;

                                                Excesses are generated when the application status changes to "done".
                                            </small>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>

            </Container>
        </>
    );
};

export default ViewApplication;


