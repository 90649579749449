import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Button,
  } from "reactstrap";
  // core components
  import PageHeader from "components/Headers/PageHeader.js";
  import listProviders from "api/provider/listProviders"
  import ModalDialog from "components/ModalDialog/ModalDialog";
  import AddProvider from "./AddProvider";
  import Alert from "components/Alerts/Alert";
import { getLCMID } from "api/company/getLCMID";

  
  const Providers = () => {
    const [providers, setProviders] = useState([]);
    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [modalProvidersState, setModalProvidersState] = useState(false);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])

    const doGetListProviders = async () => {
        try{
            let currentProviders = await listProviders(token, lawnManagementCompanyId, 0, 100);
            setProviders(currentProviders);
        } catch (error) {
            console.log("Error on List Providers:", error);
        }
    }

    function onAddProviderSuccess(message){
      setModalProvidersState(false);
      setAlert(message);

      //reload
      doGetListProviders();
    }


    function onProviderDetailsClick(ProviderId){

      const propsToPass = {
        initial_property_id: ProviderId,
      };
      navigate('/admin/provider/detail/' + ProviderId, { state: propsToPass });
    }

    function onAddProviderClick(){
      setModalProvidersState(true);
    }

    function getProviderName(provider){
      return provider["provider"]["name"]
    }

    function getProviderEmail(provider){
      if(provider["provider"]["provider_type"] === "company"){
        return provider["associate"]["company_email"];
      } else {
        return provider["associate"]["email"];
      }
    }

    async function getLawnManagementId () {
      setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
    if(lawnManagementCompanyId){
      doGetListProviders();            
    }
    }, [lawnManagementCompanyId])

    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          {alert&&
            <Alert color="success" text={alert} setter={setAlert}></Alert>
          }
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex modal-header">
                  <h3 className="mb-0">My Team</h3>
                  <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddProviderClick();}}
                        size="sm"
                        
                        >
                       + Add
                        </Button>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Function</th>
                      {/* <th scope="col" /> */}
                    </tr>
                  </thead>
                  <tbody>
                    {providers && providers["data"] && providers["data"].slice(startIndex, endIndex).map((provider,idx) => (
                        <tr id={provider["provider"]["id"]} key={idx}>
                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        {getProviderName(provider)}
                        </th>
                        <td>
                          {provider["provider"]["provider_type"]=="natural"? "Employee": "Externalized company"}
                        </td>
                        <td>
                         {getProviderEmail(provider)}
                        </td>
                        <td>
                         {provider["provider"]["mobile"]}
                        </td>
                        <td>
                          {provider["provider"]["provider_function"]}
                        </td>
                        {/* <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""  
                              onClick={() => onProviderDetailsClick(provider["provider"]["id"])}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {providers && providers.success && providers["data"]&&
                  <div className="mt-3">
                      <PaginationComponent data={providers["data"]} itemsPerPage={constants["ROWS_PER_PAGE"]} getStart={setStartIndex} getEnd={setEndIndex}/>
                  </div>
                }
              </Card>
            </div>
          </Row>
        </Container>
        {modalProvidersState &&
            <ModalDialog 
                size="lg"
                status={modalProvidersState} 
                setter={setModalProvidersState}
                title={"Add Team Member"}
                content={<AddProvider setSuccess={(message) => onAddProviderSuccess(message)}/>}
            />
        }
        </>
    );
  };
  
  export default Providers;


    