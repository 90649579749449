import {postRequest, URL_SERVER} from './../requests'


export default async function addHole(token, hole_name, property_id, turf_id, gps, sq_ft, number_of_applications, irrigation, clippings){
    const data = {
        "hole_name": hole_name,
        "property_id": property_id,
        "turf_type_id": turf_id,
        "gps": gps,
        "sq_ft": sq_ft,
        "irrigation": irrigation,
        "clippings": clippings,
        }
    try {

        return await postRequest(URL_SERVER + 'hole', data, token)
    } catch (error) {
        console.error(error);
    }

}