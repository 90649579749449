import {getRequest, URL_SERVER} from './../requests'


export default async function listProgramBuilders(token, lawnManagementCompanyId, turfTypeId, configuredOnly){
    let query = ""
    if(turfTypeId){
        query += "?turfTypeId=" + turfTypeId
    }
    if(configuredOnly){
        query += "?configuredOnly=1"
    }
    try {
        return await getRequest(URL_SERVER + "programBuilders/"+lawnManagementCompanyId + query, token)
    } catch (error) {
        console.error(error);
    }
}