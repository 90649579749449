import {postRequest, URL_SERVER} from './../requests'


export default async function addContact(customerId, email, firstName, lastName, mobile, token){
    const data = {
        "customer_id": customerId,
        "email": email,
        "mobile": mobile,
        "create_account": 2
        }
    if(firstName!=""){
        data["first_name"] = firstName;
        data["last_name"] = lastName;
    }
    try {

        return await postRequest(URL_SERVER + 'contact', data, token)
    } catch (error) {
        console.error(error);
    }

}