import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

function DropdownListWithSearch({ header, items, setter, disabled }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function onItemClick(item) {
    console.log("here")
    setter(item);
    setDropdownOpen(true); // Close dropdown on selection
  }

  useEffect(() => {
    // Filter items based on search term
    if (searchTerm !== '') {
      const newItems = items.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(newItems);
    } else {
      setFilteredItems(items)
    }

  }, [searchTerm, items]);

  useEffect(() => {
    if (dropdownOpen) {
      const handleKeyDown = (event) => {
        if (event.key.length === 1) { // Record character input
          setSearchTerm((prev) => prev + event.key);
        } else if (event.key === "Backspace") { // Handle backspace
          setSearchTerm((prev) => prev.slice(0, -1));
        }
        else if (event.key === "Escape") { // Close dropdown on Escape key
          setDropdownOpen(false);
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!dropdownOpen) {
      setSearchTerm(''); // Reset search term when dropdown closes
    }
  }, [dropdownOpen]);

  return (
    <div style={{ width: "100%" }}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ width: "100%" }} disabled={disabled}>
        <DropdownToggle caret style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", backgroundColor: "White" }}>
          {header}
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: "16vh", overflowY: "scroll", overflowX: "hidden", maxWidth: "25vw", width: "100%" }}>
          <div style={{ padding: '5px 15px', fontSize: '14px' }}>
            {searchTerm ? searchTerm : 'Type to search...'}
          </div>
          <hr className="my-1" />
          {filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <DropdownItem id={index} key={index} style={{ textOverflowX: "ellipsis" }} onClick={() => onItemClick(item)}>
                {item}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem disabled>No items found</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default DropdownListWithSearch;