import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import DatePicker from "react-multi-date-picker";


function RadioButtonGroup({labels, setter, datePicker, setDatePicker, colors, icons, outline, size}) {
  const [rSelected, setRSelected] = useState(1);

  return (
    <div>
       <ButtonGroup>
      {labels.map((label,idx) => (
         <Button
          id={"button radio " + label}
          size={size? size: "sm"}
          color={colors? rSelected===idx+1 ? colors[idx] : "light": "primary"}
          outline
          onClick={() => {setRSelected(idx+1); setter(idx+1)}}
          active={!outline && rSelected === idx+1}
          style={outline ? {border:0}: {}}
          key={idx}
       >
        <div>
        {icons && icons[idx]}
        </div>
        <div>
        {label}
        </div>
       </Button>

      ))}
      {datePicker &&
       <DatePicker  range format="MM/DD" dateSeparator="-" className="green" value={datePicker} onChange={setDatePicker} render={
        <Button
        id={"button picker"}
        size="sm"
        color={rSelected=== labels.length +1? "primary": "light"}
        onClick={() => {setRSelected(labels.length+1); setter(labels.length+1);}}
        outline
        active={rSelected === labels.length+1}
        style={{borderTopLeftRadius:0, borderBottomLeftRadius:0, borderLeftWidth:0}}
       >{datePicker.length ==2 ? datePicker[0].day+" "+datePicker[0].month.name + " " + " - " +datePicker[1].day+" "+datePicker[1].month.name: datePicker.length ==1?datePicker[0].day+" "+datePicker[0].month.name:"Select range" }</Button>

       }/>

      }
      </ButtonGroup>
    </div>
  );
}

export default RadioButtonGroup;