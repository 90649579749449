import {postRequest, URL_SERVER} from './../requests'


export default async function addSoilTest(token, hole_id, growth_summary_id, P, K, Ca, Mg, S, Fe, Mn, pH, salinity, date){
    const data = {
        "hole_id": hole_id,
        "growth_summary_id": growth_summary_id,
        "P": P,
        "K": K,
        "Ca": Ca,
        "Mg": Mg,
        "S": S,
        "Fe": Fe,
        "Mn": Mn,
        "PH": pH,
        "salinity": salinity,
        
        "test_timestamp": date,
        }
    try {

        return await postRequest(URL_SERVER + 'soilTest', data, token)
    } catch (error) {
        console.error(error);
    }

}