import {getRequest, URL_SERVER} from './../requests'


export default async function listRounds(token, holeId, lawnManagementCompanyId, status, fromTimestamp, toTimestamp, programId){
    try {
        let query = "?"
        if(holeId){
            query += "holeId=" + holeId
        } else {
            if(lawnManagementCompanyId){
            query += "lawnManagementCompanyId=" + lawnManagementCompanyId
            } else {
                query += "programId=" + programId
            }
        }
        if(status){
            query += "&status=" + status
        }
        if(fromTimestamp){
            query += "&fromTime=" + fromTimestamp
        }
        if(toTimestamp){
            query += "&toTime=" + toTimestamp
        }


        return await getRequest(URL_SERVER + "rounds/list" + query , token)
    } catch (error) {
        console.error(error);
    }

}