import { useState, useEffect } from "react";
import { Input } from "reactstrap";

function Filter({ elements, setFilteredElements, filterBy="" }) {
  const [filterText, setFilterText] = useState("");

  const filterElements = () => {
    if(filterBy){
      const filtered = elements && elements.filter(item =>
        item[filterBy]?.toLowerCase().includes(filterText.toLowerCase())
      );
      setFilteredElements(filtered);
    }else{
      setFilteredElements(elements)
    }
  };

  useEffect(() => {
    filterElements();
  }, [filterText, elements]);

  const handleClear = () => {
    setFilterText("");
  };

  return (
    <div 
      className="d-flex" 
      style={{ 
        width: "200px",
      }}
    >
      <Input
        id="search"
        type="text"
        placeholder="Search..."
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
        style={{height:"30px"}}
      />
      <button
        onClick={handleClear}
        style={{
          backgroundColor: "white",
          padding: "2px 8px",
          border: "1px solid #cad1d7",
          borderRadius: "5px",
          marginLeft:"1px",
          color: "#c1c6d1"
        }}
      >
        <i className="fa fa-times" aria-hidden="true" />
      </button>
    </div>
  );
}

export default Filter;
