import {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"

import {
    Card,
    CardHeader,
    Container,
    Row,
    CardBody,
    Button,
  } from "reactstrap";
  // core components
  import PageHeader from "components/Headers/PageHeader.js";
  import ModalDialog from "components/ModalDialog/ModalDialog";
  import AddProduct from "./AddProduct";
  import Alert from "components/Alerts/Alert";

import TabPaneComponent from "components/TabPane/TabPaneComponent";
import Products from "./Feriliser";
import ProductsWeedControl from "./WeedControl";
import PestControl from "./PestControl";
import Jobs from "./Jobs";
import AddJob from "./AddJob";
  
  const ProductsWrapper = () => {
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState("success")
    const [activePane, setActivePane] = useState(0)
    const [modalProductsState, setModalProductsState] = useState(false);
    const [modalAddJobState, setModalAddJobState] = useState(false);

    function onAddProductSuccess(message){
        setModalProductsState(false);
        setAlert(message);
        setAlertColor("success")
      }

    function onAddJobSuccess(message){
      setModalAddJobState(false);
      setAlert(message);
      setAlertColor("success")
    }

    function onAddProductClick(){
        setModalProductsState(true);
      }

    function onAddJobClick(){
      setModalAddJobState(true)
    }

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          {alert&&
            <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
          }
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex modal-header">
                  <h3 className="mb-0">Products & Services</h3>
                  {activePane == 3?
                    <Button
                    color="primary"
                    onClick={(e) =>{e.preventDefault(); onAddJobClick();}}
                    size="sm"
                    
                    >
                    + Add job
                    </Button>
                    :
                    <Button
                    color="primary"
                    onClick={(e) =>{e.preventDefault(); onAddProductClick();}}
                    size="sm"
                    
                    >
                  + Add product
                    </Button>
                    }

                </CardHeader>
                <CardBody>
                <TabPaneComponent 
                            activeTab={activePane}
                            setActiveTab={setActivePane}
                            names={["Fertilizer", "Weed control", "Pest control", "Jobs"]} 
                            contents={[<Products reloadProducts={{alert}}/>,  <ProductsWeedControl reloadProducts={{alert}}/>,
                            <PestControl reloadProducts={{alert}}/>, <Jobs reloadJobs={{alert}}/>
                  ]} />
                </CardBody>
                </Card>
                </div>
                </Row>
                </Container>
                {modalProductsState &&
                <ModalDialog 
                    size="lg"
                    status={modalProductsState} 
                    setter={setModalProductsState}
                    title={"Add Product"}
                    content={<AddProduct setSuccess={(message) => onAddProductSuccess(message)}/>}
                />
                }
                {modalAddJobState &&
                <ModalDialog 
                    size="lg"
                    status={modalAddJobState} 
                    setter={setModalAddJobState}
                    title={"Add Job"}
                    content={<AddJob setSuccess={(message) => onAddJobSuccess(message)}/>}
                />
                }
        </>
    );
  };
  
  export default ProductsWrapper;


    