import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';

function MultiMonthPicker({getSelectedMonths}) {
  const [selectedMonths, setSelectedMonths] = useState([]);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleMonthChange = (event) => {
    const month = event.target.value;
    let updatedSelectedMonths;

    if (selectedMonths.includes(month)) {
      updatedSelectedMonths = selectedMonths.filter(m => m !== month);
    } else {
      updatedSelectedMonths = [...selectedMonths, month];
    }

    setSelectedMonths(updatedSelectedMonths);

    // Map selected months to their indices (1 to 12) and pass to getSelectedMonths
    const selectedMonthIndices = updatedSelectedMonths.map(m => months.indexOf(m) + 1);
    getSelectedMonths(selectedMonthIndices);
  };
  

  return (
    <div className='ml-3'>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)',gridAutoFlow: 'row'}}>
        {months.map((month, index) => (
          <div style={{display:"flex"}}>
            <Label id={month} className='pt-0 pb-0 mt-0 mb-0' />
            <Input
              type="checkbox"
              value={month}
              checked={selectedMonths.includes(month)}
              onChange={handleMonthChange}
            />
              <h5>
                {month}
              </h5>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MultiMonthPicker;