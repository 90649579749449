
import {useState} from "react";
import { UncontrolledAlert } from "reactstrap";

function Alert({color, text, setter}){

  function onValueChange(){    
    setter(false);
  }
  
  return (
    <>
    <UncontrolledAlert color={color} fade={true} onClick={onValueChange}>
        {color=="success" ?
          <strong className="text-white" >
            <i className="fa-solid fa-circle-check"></i>&nbsp;&nbsp;
            {text} 
          </strong>
          :
          <strong className="text-white" >
            <i className="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;
          {text} 
        </strong>
        }
        </UncontrolledAlert>
    </>
    );
};

export default Alert;

