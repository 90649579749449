import {getRequest, URL_SERVER} from './../requests'


export default async function getUserByUsername(token, username){
    try {

        return await getRequest(URL_SERVER + 'user/'+username, token)
    } catch (error) {
        console.error(error);
    }

}