
export function convert_timestamp_to_date(timestamp){
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return {"year": year, "month": month, "day": day, "hours": hours, "min": minutes, "sec": seconds}
}

export function compare_timestamp(timestamp){
    if (timestamp == null){
        return false
    }
    // Calculate the difference in milliseconds
  const differenceS = timestamp - Math.floor(Date.now() / 1000);

  // Convert milliseconds to hours
  const differenceHours = differenceS / (60 * 60);

  // Return true if the difference is less than 1 hour, false otherwise
  return differenceHours < 1;
}

export function getFormattedTime(timestamp){
    var date = convert_timestamp_to_date(timestamp);
    return date["month"] + "/" + date["day"] + "/" + date["year"]
  }

export function isDifferenceMoreThanTenDays(timestamp1) {
  // Convert 10 days to milliseconds
  const tenDaysInMillis = 0.5 * 24 * 60 * 60;
  const timestamp2 = Math.floor(Date.now())/1000;
  // Calculate the absolute difference between the two timestamps
  const difference = Math.abs(timestamp1 - timestamp2);

  // Check if the difference is greater than 10 days
  return difference > tenDaysInMillis;
}

export function computeDifferenceInDays(timestamp1) {
  // Convert 10 days to milliseconds
  const tenDaysInMillis = 10 * 24 * 60 * 60;
  timestamp1 = timestamp1 + tenDaysInMillis
  const timestamp2 = Math.floor(Date.now())/1000
  // Calculate the absolute difference between the two timestamps
  const difference = Math.abs(timestamp1 -timestamp2);

  // Check if the difference is greater than 10 days
  console.log(timestamp1)

  console.log(timestamp2)
  console.log(difference)
  return parseInt(difference/(24*60*60))
}