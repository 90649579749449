import {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import TabPaneComponent from "components/TabPane/TabPaneComponent"
import PropertyDetailGeneralPane from "./PropertyDetailGeneralPane";
import PropertyDetailGrowthTablePane from "./PropertyDetailGrowthTablePane"
import PropertyDetailLogBook from "./PropertyDetailLogBook";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    CardBody,
  } from "reactstrap";

import PageHeader from "components/Headers/PageHeader.js";
import Alert from "components/Alerts/Alert";
import getProperty from "api/property/getProperty";
import listHoles from "api/property/listHoles";
import PropertyDetailSoilTestsPane from "./PropertyDetailSoilTestsPane";
import PropertyDetailRoundsPane from "./PropertyDetailRoundsPane";
import PropertyDetailNotes from "./PropertyDetailNotes";
import getHole from "api/property/getHole";
import listTurfTypes from "api/property/listTurfTypes";
import { getLCMID } from "api/company/getLCMID";
import listProgramBuilders from "api/programBuilder/listProgramBuilders";

  const PropertyDetail = () => {
    const {token, setToken} = useTokenContext();
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const { id } = useParams();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null)
    const [property, setProperty] = useState(null);
    const [holesNames, setHolesNames] = useState(null);
    const [holes, setHoles] = useState(null);
    const [selectedHole, setSelectedHole] = useState("No holes");
    const [holeResponse, setHoleResponse] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear())
    const [turfTypes, setTurfTypes] = useState(null);
    const [turfTypesNames, setTurfTypesNames] = useState(null);
    const [reload, setReload] = useState(null)
    const [programs, setPrograms] = useState(null)
    const [activePane, setActivePane] = useState(0);

    const location = useLocation();
    const { state } = location;
    const { message, pane } = state || {};

    const doGetPrograms = async () => {
        try {
            let rsp = await listProgramBuilders(token, lawnManagementCompanyId, null, true)
            setPrograms(rsp)
        } catch (error) {
            console.log("Error on get programs by turf type:", error)
        }
    }

    const doGetProperty = async () => {
        try{
            var getPropertyResponse = await getProperty(token, id);
            setProperty(getPropertyResponse);
        } catch (error) {
            console.log("Error on Get Property", error);
        }
    }

    const doListHoles = async () => {
        try{
            var listHolesResponse = await listHoles(token, id);
            setHoles(listHolesResponse);
        } catch (error) {
            console.log("Error on List Holes", error);
        }
    }

    const doListTurfTypes = async () => {
        try{
            var rsp = await listTurfTypes(token, id);
            setTurfTypes(rsp);
        } catch (error) {
            console.log("Error on List Turf Types", error);
        }
    }

    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
      }

    useEffect(() => {
        if(lawnManagementCompanyId){
            doGetProperty();
            doListHoles();
            doListTurfTypes();
            doGetPrograms();
        }
        }, [lawnManagementCompanyId])
    
    useEffect(() => {
        if(id && token){
            getLawnManagementId()
        }
    }, [id,token])

    useEffect(() => {
        if(holes){
            if(holes['data'] && holes['data']['holes'] && holes['data']['holes'].length > 0){
                if(selectedHole == "No holes"){
                    setSelectedHole(holes["data"]["holes"][0]["hole_name"])
                }   
                setHolesNames(holes["data"]["holes"].reduce((acc,item) => {
                    acc[item["hole_name"]] = item["id"]
                    return acc
                }, {}))
            }
        }
    }, [holes])

    useEffect(() => {
        if(turfTypes){
            setTurfTypesNames(turfTypes["data"].reduce((acc,item) => {
                acc[item["id"]] = item["turf_type_name"]
                return acc
            }, {}))
        }
    }, [turfTypes])

    useEffect(() => {
        const savedTab = localStorage.getItem('activeTab');
        if (savedTab) {
            setActivePane(parseInt(savedTab));
        }
    }, []);

    const handleTabChange = (tab) => {
        setActivePane(parseInt(tab));
        localStorage.setItem('activeTab', parseInt(tab));
    };

    useEffect(() => {
        if(message){
            setAlert(message);
            navigate(".", { replace: true });
        }
        if(pane){
            setActivePane(pane);
        }
    }, [message, location, state, pane])

    const doGetHole = async () => {
        try{
            let rsp = await getHole(token, holesNames[selectedHole])
            setHoleResponse(rsp)
        } catch (error) {
            console.log("Error on Get Growth Summary:", error);
        }
    }

    useEffect(() => {
        if(holesNames){
            doGetHole();
        }
    }, [selectedHole,holesNames, reload])


    return (
      <>
        <PageHeader />
        <Container className={alert ? "mt--8": "mt--7"} fluid>
            <div className="col">
            {alert&&
            <Alert color="success" text={alert} setter={setAlert}></Alert>
          }
            <Row>
            <Col lg="12" className="mb-2">
            {property && property.data && <BreadcrumbNavigation active="Property" past={[{"name":"Customers", "link":"/admin/customers"}, {"name":"Detail", "link":"/admin/customer/detail/" + property["data"]["property"]["customer_id"]}]}/>}
            </Col>
            </Row>
                <Card className="shadow">

                    <CardHeader className="border-0 d-flex modal-header align-items-center">
                        <div className="d-flex">
                            <Button
                            color="transparent"
                            onClick={(e) =>{e.preventDefault(); navigate(-1)}}
                            size="sm"
                            >
                                <i className="fa-solid fa-arrow-left"></i>
                            </Button>
                            {property && <h3 className="mb-0 mr-4">&nbsp;{property["data"]["property"]["address"]}</h3>}
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                        {property && holesNames && holeResponse && turfTypesNames &&  programs&&
                            <TabPaneComponent 
                                activeTab={activePane}
                                setActiveTab={handleTabChange}
                                names={["General", "Soil tests", "Program", "Turf logbook", "Notes","Nutrient demand"]} 
                                contents={[
                                <PropertyDetailGeneralPane property={property["data"]["property"]} holes={holes["data"]} customerName={property["data"]["customerName"]} turfTypesNames={turfTypesNames} reloadHoles={doListHoles} contract={property["data"]["contract"]} programs={programs}/>, 
                                <PropertyDetailSoilTestsPane holeId={holesNames[selectedHole] } holesNames={holesNames} selectedHole={selectedHole} setSelectedHole={setSelectedHole} holes={holes["data"]}/>,
                                <PropertyDetailRoundsPane property={property["data"]} holeId={holesNames[selectedHole]} holesNames={holesNames} selectedHole={selectedHole} setSelectedHole={setSelectedHole} reloadHoles={doListHoles}  year={year} setYear={setYear} years={holeResponse["data"]["years"]} lawnManagementCompanyId={lawnManagementCompanyId} holes={holes["data"]} hole={holeResponse} programs={programs}/>,
                                <PropertyDetailLogBook holeId={holesNames[selectedHole]} holesNames={holesNames} selectedHole={selectedHole} setSelectedHole={setSelectedHole}  year={year} setYear={setYear} years={holeResponse["data"]["years"]} setReload={setReload} reloadHoles={doListHoles}/>, 
                                <PropertyDetailNotes  property={property["data"]["property"]}/>,
                                <PropertyDetailGrowthTablePane holeId={holesNames[selectedHole]} holesNames={holesNames} selectedHole={selectedHole}
                                        setSelectedHole={setSelectedHole} year={year} setYear={setYear} years={holeResponse["data"]["years"]} setReload={setReload}/>
                            
                            ]}
                                
                            />
                        }
                        {property && holes && holes['data'] && holes['data'] && holes['data'].length == 0 &&
                            <TabPaneComponent 
                                activeTab={activePane}
                                setActiveTab={setActivePane}
                                names={["General", "Notes"]} 
                                contents={[<PropertyDetailGeneralPane property={property["data"]["property"]} holes={holes["data"]} customerName={property["data"]["customerName"]} turfTypesNames={turfTypesNames} reloadHoles={doListHoles} contract={property["data"]["contract"]}/>,
                                <PropertyDetailNotes  property={property["data"]["property"]}/> 
                            
                            ]}
                            />
                        }
                    </CardBody>
                </Card>
            </div>
        </Container>
        </>
    );
  };
  
  export default PropertyDetail;


    