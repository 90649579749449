import {getRequest, URL_SERVER} from './../requests'


export default async function listProperties(token, lawnManagementCompanyId, customerId,  skip, limit){
    try {

        var uri = 'properties?'

        if(lawnManagementCompanyId != null){
            uri += "lawnCompanyId=" + lawnManagementCompanyId
        } else {
            uri += "customerId=" + customerId
        }

        return await getRequest(URL_SERVER + uri, token)
    } catch (error) {
        console.error(error);
    }

}