import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {compare_timestamp} from 'utils/utils'
export const URL_SERVER = 'http://dev.kotystech.com/api/'

export async function getRequest(url, token=null){  
  //check if expiry date is ok? do a verify token to get the refresh token and refreshToekn api call
  //update to local storage token and expiry date
  const expiryDate = localStorage.getItem("expiryDate")
  if (token==null){
    token = localStorage.getItem("token")    
  }
  if (compare_timestamp(expiryDate)){
    console.log(compare_timestamp(expiryDate))
    //refresh token
    const headers = {
      'Accept-Language': 'en-GB',             
      'RequestId': uuidv4(),
    }
    if(token){
      headers['Authorization'] = "Bearer " + token
    }

    try {
      axios.get(URL_SERVER+"token/verify", {
          headers: headers
      }).then((responseVerify) => {

        if(responseVerify.data.success){
          const token = responseVerify.data.data.token
          const refreshToken = responseVerify.data.data.refresh_token
          const headers = {
            'Access-Control-Allow-Origin': "*",
            'Content-Type': 'application/json',
            'Accept-Language': 'en-GB',
            'RequestId': uuidv4(),
            }      
          headers['Authorization'] = "Bearer " + token    
          try {
            const body = {
              'refresh_token':refreshToken
            }
            axios.post(URL_SERVER+"token/refresh", body, {
                headers: headers
            }).then((responseRefresh) => {
              if (responseRefresh.data.success){
                localStorage.set("token",responseRefresh.data.data.token)
                localStorage.set("expiryDate",responseRefresh.data.data.expire_at)
              }
            })
          }
          catch(error) {
              if (error.response) {
                return error.response.data;
              } else if (error.request) {
                console.error('Axios Error request:', error.request);
              } else {
                console.error('Axios Unknown Error', error.message);
              }
          }
        }
    }).catch((error) => {
      //navigate to login and print error
      console.log(error)
    })
      
    }
    catch(error) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.error('Axios Error request:', error.request);
      } else {
        console.error('Axios Unknown Error', error.message);
      }
    }
  }
    const headers = {
              'Accept-Language': 'en-GB',             
              'RequestId': uuidv4(),
              }
    token = localStorage.getItem("token")
    if(token){
        headers['Authorization'] = "Bearer " + token
    }
    try {
         const response = await axios.get(url, {
            headers: headers
         })
         return response.data
    }
    catch(error) {
          if (error.response) {
            return error.response.data;
          } else if (error.request) {
            console.error('Axios Error request:', error.request);
          } else {
            console.error('Axios Unknown Error', error.message);
          }
    }
}

export async function postRequest(url, data, token=null){
  if (token==null){
    token = localStorage.getItem("token")
  }
  //check if expiry date is ok? do a verify token to get the refresh token and refreshToekn api call
  //update to local storage token and expiry date
    const headers = {
              'Access-Control-Allow-Origin': "*",
              'Content-Type': 'application/json',
              'Accept-Language': 'en-GB',
              'RequestId': uuidv4(),
              }
    if(token){
        headers['Authorization'] = "Bearer " + token
    }
    console.log('Sending POST request with headers:', headers);
    try {
         const response = await axios.post(url, data, {
            headers: headers
         })
         return response.data
    }
    catch(error) {
          if (error.response) {
            return error.response.data;
          } else if (error.request) {
            console.error('Axios Error request:', error.request);
          } else {
            console.error('Axios Unknown Error', error.message);
          }
    }
}

export async function putRequest(url, data, token=null){
  if (token==null){
    token = localStorage.getItem("token")
  }
  const headers = {
            'Access-Control-Allow-Origin': "*",
            'Content-Type': 'application/json',
            'Accept-Language': 'en-GB',
            'RequestId': uuidv4(),
            }
  if(token){
      headers['Authorization'] = "Bearer " + token
  }
  try {
       const response = await axios.put(url, data, {
          headers: headers
       })
       return response.data
  }
  catch(error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          console.error('Axios Error request:', error.request);
        } else {
          console.error('Axios Unknown Error', error.message);
        }
  }
}

export async function deleteRequest(url, data, token=null){
  if (token==null){
    token = localStorage.getItem("token")
  }
  const headers = {
            'Access-Control-Allow-Origin': "*",
            'Accept-Language': 'en-GB',
            'RequestId': uuidv4(),
            }
  if(token){
      headers['Authorization'] = "Bearer " + token
  }
  try {
       const response = await axios.delete(url, {
          headers: headers
       })
       return response.data
  }
  catch(error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          console.error('Axios Error request:', error.request);
        } else {
          console.error('Axios Unknown Error', error.message);
        }
  }
}


export async function getPhotoSource(photoId, token){
  if (token==null){
    token = localStorage.getItem("token")
  }
  const headers = {
            'Accept-Language': 'en-GB',
            'RequestId': uuidv4(),
            }
  if(token){
      headers['Authorization'] = "Bearer " + token
  }
  try {
    const response = fetch(URL_SERVER + '/file/download/' + photoId, {
      method: 'GET',
      headers: headers
    })  
    return response;
  } catch (error) {
    console.log(error)
  }
}
