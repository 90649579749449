import {postRequest, URL_SERVER} from './../requests'


export default async function addJob(token, lawnManagementCompanyId,name,avg_time,price){
    const data = {
        "lawn_management_company_id": lawnManagementCompanyId,
        "name": name,
        "avg_time": avg_time,
        "price": price
        }    
    try {
        return await postRequest(URL_SERVER + 'job', data, token)
    } catch (error) {
        console.error(error);
    }

}