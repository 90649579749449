
// reactstrap components
import {useState, useEffect} from "react"
import {
  Button,
  Card,
  CardBody,
  Form,
  Col,
} from "reactstrap";
import ValidationInput from "components/ValidationInput/ValidationInput";
import Alert from "components/Alerts/Alert"
import resetPassword from "api/resetPassword/resetPassword"
import { useNavigate,useParams} from "react-router-dom";
import { useNotification } from 'components/NotificationContext/NotificationContext';

const ResetPassword = () => {
  const { id } = useParams();
  const { notification, setNotification } = useNotification();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState(null);
  const [wrongPwd, setWrongPwd] = useState(null);
  const [rpResponse, setResponse] = useState(null);

  const [validPassword, setValidPassword] = useState(true);
  const [validConfirmPassword, setValidConfirmPassword] = useState(true);

  const navigate = useNavigate();

  function setPasswordFunction(usr){
    setPassword(usr)
  }
  function setConfirmPasswordFunction(usr){
    setConfirmPassword(usr)
  }
  function ResetPasswordClick() {    
    onResetPasswordClick()
  }
  const onResetPasswordClick = async () => {
    //(password != "") && 
    if ((confirmPassword == password)){
        try {
            var response = await resetPassword(id,password)
            setResponse(response)      
        } catch (error) {
        console.error('Reset password request error:', error);
        }
    }else{
        setWrongPwd(true)
    }
  }

  useEffect(() => {
    if (rpResponse){
      if (rpResponse.success){
        setNotification('Your password has been changed succesfully!');
        navigate("/auth/login")
      }else{
        setAlert(true)
      }
    }
  },[rpResponse]);

  function LoginClick(){
    navigate("/auth/login")
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-2">
                  <strong className="" color="primary">Create new password</strong>
                </div>
                {notification && 
                  <Alert color="success" text={notification} setter={setNotification}></Alert>
                }
                {wrongPwd && 
                  <Alert color="danger" text={"Confirm Password does not match Password"} setter={setWrongPwd}></Alert>
                }
                {!notification &&   
                  <div className="text-center text-muted mb-4">
                    <small>We are here to help you regain access to your account</small>
                  </div>
                }
                <div>
                    {alert&&
                        <Alert color="danger" text="Invalid code!" setter={setAlert}></Alert>
                    }
                </div>   
            <Form role="form"> 
            <div className="mb-3">                             
              <ValidationInput placeholder="Password" type="password" name="password" validationType="PASSWORD" setter={setPasswordFunction} icon={<i className="ni ni-lock-circle-open" />} message="Invalid password" mandatory={true} getValidState={setValidPassword}/>
              </div>    
              <ValidationInput placeholder="Confirm Password" type="password" name="password" validationType="PASSWORD" setter={setConfirmPasswordFunction} icon={<i className="ni ni-lock-circle-open" />} message="Invalid password" mandatory={true} getValidState={setValidConfirmPassword}/>
              <div className="text-center">
                <Button className="my-3" color="primary" type="button" onClick={ResetPasswordClick} block disabled={!(validConfirmPassword&validPassword)}>
                  Reset password
                </Button>                              
              </div> 
            </Form>
          </CardBody>
        </Card>
        <div className="text-center justify-content-center text-align-center">

          <div className="text-center ">
          <Button className="my-2" color="light" type="button" onClick={LoginClick} outline style={{backgroundColor:"rgba(24,29,39,0.5)"}}>
            Login
          </Button>    
          </div>            
        </div>
      </Col>
    </>
  );
};

export default ResetPassword;
