import {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import HoleViewOnMap from "./HoleViewOnMap";
// core components
import { getFormattedTime } from "utils/utils";
import ModalDialog from "components/ModalDialog/ModalDialog";
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";
import {
    Card,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CardBody,
    Input,
    Table,
    UncontrolledTooltip,
  } from "reactstrap";
import deleteHole from "api/property/deleteHole";
import Alert from "components/Alerts/Alert";

  function PropertyDetailGeneralPane({property,holes, customerName, turfTypesNames, reloadHoles, contract, programs}){
    const {token, setToken} = useTokenContext();
    const [modalView, setModalView] = useState(null);
    const [holeSelected, setHoleSelected] = useState(null);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const { id } = useParams();
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])
    const [deleteHoleRsp, setDeleteHoleRsp] = useState(null)
    const [alertColor, setAlertColor] = useState(alert)
    
    function onViewMapClicked(hole){
        setHoleSelected(hole);
        setModalView(true);
    }

    function getProgramNameByHoleId(holeId){
           if(holes && holes["contract_detail"]){
               let currentContractDet = holes["contract_detail"].filter((det) => (det.hole_id == holeId))
               if(currentContractDet && currentContractDet.length ==1){
                   return programs.data.filter((program) => program.id == currentContractDet[0].program_id)[0].program_name
               } else {
                   return "-"
               }
           }
           return 0
       }

    function onDeleteZoneClicked(holeId){
        if(holes['holes'].length == 1){
            setAlert("Your property needs to have at least 1 zone associated!")
            setAlertColor("danger")
        } else {
            doDeleteHole(holeId)
        }
    }

    const doDeleteHole = async (holeId) => {
        try{
            let rsp = await deleteHole(token,holeId)
            setDeleteHoleRsp(rsp);
        } catch (error){
            console.log("Error on delete hole:", error)
        }
    }

    useEffect(() => {
        if(deleteHoleRsp){
            if(deleteHoleRsp.success){
                setAlertColor("success")
                reloadHoles(deleteHoleRsp.success)
            } else {
                setAlertColor("danger")
            }
            setAlert(deleteHoleRsp.message)
            
        }
    },[deleteHoleRsp])

    return (
      <>
        <Card className=" bg-secondary shadow" style={{borderTop:0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardBody>
             {alert&&<Alert color={alertColor} text={alert} setter={setAlert}></Alert>}
                <Form>
                    {property && holes &&
                    <>
                    <h6 className="heading-small text-muted mb-4">
                        Property
                    </h6>
                    <div className="pl-lg-4">
                        <Row className="align-items-center">
                        <Col lg="4">
                            <FormGroup className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-email"
                            >
                                Customer
                            </label>
                            <Link to={"/admin/customer/detail/" + property["customer_id"]} className="font-weight-bold ml-1 d-block">
                                {customerName}
                            </Link>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Address
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={property["address"]}
                                id="input-email"
                                placeholder="email"
                                type="email"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="2">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Zip Code
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={property["zip_code"]}
                                id="input-email"
                                placeholder="email"
                                type="email"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Contract Start Date
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={getFormattedTime(contract["contract"]["start_date"])}
                                id="input-start"
                                placeholder="email"
                                type="email"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Contract End date
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={getFormattedTime(contract["contract"]["end_date"])}
                                id="input-end"
                                placeholder="email"
                                type="email"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>

                    <hr className="my-4" />
                    <div className="d-flex modal-header pl-0">
                    <div className="d-flex">
                    <h6 className="heading-small text-muted mb-2 mr-2">
                        Lawn information
                        
                    </h6>
                    <div>
                    <i className="fa-regular fa-circle-question pb-2" id="unit"></i>
                    <UncontrolledTooltip
                        target="unit"
                    >
                        A zone represents a section of a property. If you have a large property, you might need multiple zones to represent different areas or sections within it. Each zone will have an associated growth table to track its specific needs and progress.
                    </UncontrolledTooltip>
                    </div>
                </div>
                    <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); navigate("/admin/hole/new/" + property["id"])}}
                        size="sm"
                        
                        >
                       + Add zone
                        </Button>
                    </div>
                    </>
                    }
                    <div className="pl-lg-3">

                <Table className="align-items-center table-light" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Zone name</th>
                      <th scope="col">Turf Type</th>
                      <th scope="col">Area&nbsp;<small>[sq ft]</small></th>
                      <th scope="col">Program assigned</th>
                      <th scope="col">Irrigation</th>
                      <th scope="col">Clippings</th>
                      <th scope="col">Location</th>
                      <th scope="col" className="justify-content-end text-right"></th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    {holes && holes["holes"] && holes["holes"].slice(startIndex, endIndex).map((hole, idx) => (
                        <tr id={hole["id"]} key={idx}>
                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        {hole["hole_name"]}
                        </th>
                        <td>
                        {turfTypesNames[hole["turf_type_id"]]}
                        </td>
                        <td>
                        {hole["sq_ft"]}
                        </td>
                        <td>
                        {programs && programs.data && getProgramNameByHoleId(hole["id"])}
                        </td>
                        <td>
                        {hole["irrigation"]==1? "yes" : "no"}
                        </td>
                        <td>
                        {hole["clippings"]==1? "keep" : "remove"}
                        </td>
                        <td >
                        <Button
                            className="text-primary"
                            type="button"
                            size="md"
                            color=""  
                            onClick={() => onViewMapClicked(hole)}
                            
                        >
                            <i className="fa-solid fa-map-location-dot fa-lg"></i>
                        </Button>
                        </td>
                        <td className="text-right">
                        <Button
                            type="button"
                            size="sm"
                            color="danger"  
                            onClick={() => onDeleteZoneClicked(hole["id"])}
                            
                        >
                            <i className="fa-solid fa-trash fa-lg"></i>
                        </Button>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                    {holes && holes["holes"] &&
                        <div className="mt-3">
                            <PaginationComponent data={holes["holes"]} itemsPerPage={constants["ROWS_PER_PAGE"]} getStart={setStartIndex} getEnd={setEndIndex}/>
                        </div>
                    }
                    </div>
                </Form>
            </CardBody>
        </Card>
        {modalView &&
        <ModalDialog 
            size="lg"
            status={modalView} 
            setter={setModalView} 
            title={"View zone " + holeSelected["hole_name"] + " on map"}
            content={<HoleViewOnMap lat={holeSelected["gps"][0]} lng={holeSelected["gps"][1]} />}                              
        />
        
        }
        </>
    );
  };
  
  export default PropertyDetailGeneralPane;


    