import {postRequest, URL_SERVER} from './../requests'


export default async function addNote(token, property_id, content){
    const data = {
        "property_id": property_id,
        "content": content,
        }
    try {

        return await postRequest(URL_SERVER + 'note', data, token)
    } catch (error) {
        console.error(error);
    }

}