
import {useState, useEffect} from "react";

import {
  Input,
  Row,
  Col,
  FormGroup,
  Button,
  Card
} from "reactstrap";
import Alert from "components/Alerts/Alert"

import getCompany from "api/company/getCompany";
import getUserByUsername from "api/users/getUser";
import addProduct from "api/products/addProduct";

import DropdownList from "components/DropdownList/DropdownList"
import {STATES} from "assets/texts/states"
import { useTokenContext } from "App";
import ValidationInput from "components/ValidationInput/ValidationInput";
import { getLCMID } from "api/company/getLCMID";
import addJob from "api/jobs/addJob";

function AddJob({setSuccess}){
  const {token, setToken} = useTokenContext();
  const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);

  //fields
  const [jobType, setJobType] = useState("");
  const [price, setPrice] = useState("");
  const [avgTime, setAvgTime] = useState("");
  
  //fields validations
  const [jobTypeValid, setJobTypeValid] = useState(true);
  const [priceValid, setPriceValid] = useState(true);
  const [avgTimeValid, setAvgTimeValid] = useState(true);

  const [addJobResponse, setAddJobResponse] = useState(false);
  const [alert, setAlert] = useState(null);

  function onAddJobClicked(){
    if(jobType == ""||price===""||avgTime===""){
        setAlert("Complete all mandatory fields (*)")
    } else {
        doAddJob();
    }           
  }

  const doAddJob = async () => {
    try{
        let resp = await addJob(token,lawnManagementCompanyId,jobType, parseInt(avgTime), parseFloat(price));
        setAddJobResponse(resp);
    } catch (error) {
        console.log("Error on Add Product:", error);
    }
  }

  useEffect(() => {
    if(addJobResponse){
        if(addJobResponse.success){
            setSuccess(addJobResponse.message);
        } else {
            setAlert(addJobResponse.message)
        }
    }

  }, [addJobResponse])

  async function getLawnManagementId () {
    setLawnManagementCompanyId(await getLCMID(token))
  }

  
    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])

  
  return (
    <>
        {alert&&<Alert color="danger" text={alert} setter={setAlert}></Alert>}
        <div className="pl-lg-2 pr-lg-2">                
                <>
                <div>
                <Row className="pt-lg-4">
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-company-name"
                        >
                            Job Type
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                         <ValidationInput type="text" placeholder="Product Name" value={jobType} setter={setJobType} validationType="FREETEXT" message={"Invalid name"} mandatory getValidState={setJobTypeValid}/>
                    </Col>
                </Row>
                <Row className="pt-lg-4">
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-company-name"
                        >
                            Avg. time/1000 sqft <small>[mins]</small>
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                         <ValidationInput type="text" placeholder="minutes" value={avgTime} setter={setAvgTime} validationType="NUMERIC" message={"Invalid number"} mandatory getValidState={setAvgTimeValid}/>
                    </Col>
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-company-name"
                        >
                            Price/1000 sqft <small>[$]</small>
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                         <ValidationInput type="text" placeholder="Price" value={price} setter={setPrice} validationType="NUMERIC" message={"Invalid price"} mandatory getValidState={setPriceValid}/>
                    </Col>
                </Row>
                    </div>                         
                </>            
        </div>
        <div className="pt-3 pl-2">
        <Button 
        color="primary" 
        type="button"
        disabled={!(jobTypeValid&&avgTimeValid&&priceValid)}
        onClick={(e) => {e.preventDefault(); onAddJobClicked();}}>
            Add Job
        </Button>
        </div>
    </>
    );
};

export default AddJob;
