import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const steps = [
  {
    label: 'Create your lawn care company',
    description: `Kickstart your lawn care journey by creating your own company profile.`,
    button:'Go to add company',
    link:'/admin/user-profile'
  },
  {
    label: 'Add your customers',
    description:
      'Build your customer base by adding new clients to your account. ',
      button:'Go to customers',
      link:'/admin/customers'
    
  },
  {
    label: 'Add your team',
    description: `Grow your business by adding team members to your roster. Assign roles, manage schedules, and collaborate seamlessly`,
    button:'Go to My Team',
    link:'/admin/providers'
  },
  {
    label: 'Add your products',
    description: `Showcase your lawn care products by adding them to your inventory.`,
    button:'Go to products',
    link:'/admin/products'
  },
  ,
  {
    label: 'Add your programs',
    description: `
    Create a customized program for a specific area and turf type using selected products with the Program Builder, a template configurator that allows you to tailor settings and parameters to meet your needs.`,
    button:'Go to Program Builder',
    link:'/admin/programBuilder'
  },
  {
    label: "Completed!",
    description:'',
      // button:'Go to properties',
      // link:'/admin/properties'
  },
];


export default function VerticalLinearStepper({activeStep}) {
  const navigate = useNavigate();
  console.log("ACTIVE STEP:", activeStep)
  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical" style={{ position: 'relative', paddingTop: '20px' }}  >
        {steps.map((step, index) => (
          <Step key={step.label} sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                                color: "#517d3a"
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                                color: "#517d3a"
                            },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                fill: "#ffffff",
                            }
                        }}>
            <StepLabel
              className={"text-muted"}
              optional={
                index === 4? (
                  <small className="text-muted">Last step</small>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <small>{step.description}</small>
                {
                  index !== 6 ? (<div>
                    <Button
                      color="primary"
                      onClick={() => navigate(step.link)}
                    >
                      {step.button}
                    </Button>
                  </div>) : null                   
                }
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length&& (
        <div>
          <h6 className="text-muted">All steps completed!</h6>
        </div>
      )}
    </div>
  );
}