import { deleteRequest } from 'api/requests';
import {getRequest, URL_SERVER} from './../requests'


export default async function deleteCustomer(token, customerId, skip, limit){
    try {

        return await deleteRequest(URL_SERVER + 'customer/'+customerId, token)
    } catch (error) {
        console.error(error);
    }

}