
import {useState, useEffect} from "react";

import {
  Row,
  Col,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Alert from "components/Alerts/Alert"

import addProduct from "api/products/addProduct";

import DropdownList from "components/DropdownList/DropdownList"
import { useTokenContext } from "App";
import ValidationInput from "components/ValidationInput/ValidationInput";
import { getLCMID } from "api/company/getLCMID";
import Tags from "components/Tags/Tags"

function AddProduct({setSuccess}){
  const {token, setToken} = useTokenContext();
  const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);

  //fields
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [qty, setQty] = useState("");
  const [type, setType] = useState("granular");
  const [weightGallon, setWeightGallon] = useState("");
  const [n, setN] = useState("");
  const [p, setP] = useState("");
  const [k, setK] = useState("");
  const [ca, setCa] = useState("");
  const [mg, setMg] = useState("");
  const [s, setS] = useState("");
  const [fe, setFe] = useState("");
  const [mn, setMn] = useState("");
  const [quantityType, setQuantityType] = useState("gal")
  const [epaReg, setEpaReg] = useState("")
  const [rate, setRate] = useState("")
  const [carrierVolume, setCarrierVolume] = useState("")
  const [activeIngredients, setActiveIngredients] = useState("")
  //fields validations
  const [productNameValid, setProductNameValid] = useState(true);
  const [priceValid, setPriceValid] = useState(true);
  const [qtyValid, setQtyValid] = useState(true);
  const [typeValid, setTypeValid] = useState(true);
  const [category, setCategory] = useState("fertilizer")
  const [weightGallonValid, setWeightGallonValid] = useState(true);
  const [nValid, setNValid] = useState(true);
  const [pValid, setPValid] = useState(true);
  const [kValid, setKValid] = useState(true);
  const [caValid, setCaValid] = useState(true);
  const [mgValid, setMgValid] = useState(true);
  const [sValid, setSValid] = useState(true);
  const [feValid, setFeValid] = useState(true);
  const [mnValid, setMnValid] = useState(true);
  const [rateValid, setRateValid] = useState(true);
  const [carrierVolumeValid, setCarrierVolumeValid] = useState(true)

  const rateAreaOptions=["1000sqft","acre"]
  const [rateArea,setRateArea] = useState(rateAreaOptions[0])
  
  const [epaRegValid, setEpaRegValid] = useState(true);

  const [addProductResponse, setAddProductResponse] = useState(false);
  const [alert, setAlert] = useState(null);

  function onAddProductClick() {
    if ((type === "liquid" && category === "fertilizer" && weightGallon === "") || 
        (type !== "granular" && carrierVolume === "") || 
        (category !== "fertilizer" && rate === "") ||
        (category !== "fertilizer" && (activeIngredients.length <= 0 || activeIngredients === "")) ||
        (category !== "fertilizer" &&  epaReg === "")) {
            setAlert("Complete all mandatory fields (*)");
    } else {
        if(productName === "" || type === "" || price === "" || qty === "" || 
          (category === "fertilizer" && (n === "" || p === "" || k === ""))) {
            setAlert("Complete all mandatory fields (*)");
        } else {
            doAddProduct();
        }  
    }            
  }

  const doAddProduct = async () => {
    let newCategoryFertilizer=category;
    try{
        var n_proc, p_proc, k_proc, ca_proc, mg_proc, s_proc, fe_proc, mn_proc
        if(n!=""){
            n_proc=parseFloat(n/100)
        }
        if(p!=""){
            p_proc=parseFloat(p/100)
        }
        if(k!=""){
            k_proc=parseFloat(k/100)
        }
        if(ca!=""){
            ca_proc=parseFloat(ca/100)
        }
        if(mg!=""){
            mg_proc=parseFloat(mg/100)
        }
        if(s!=""){
            s_proc=parseFloat(s/100)
        }
        if(fe!=""){
            fe_proc=parseFloat(fe/100)
        }
        if(mn!=""){
            mn_proc=parseFloat(mn/100)
        }
        let quantityTransformed = qty
        if(type == "liquid" && quantityType=="oz"){
            quantityTransformed = qty/128
        }
        if(category==="fertilizer"){
            newCategoryFertilizer="fertiliser"
        }
        let newRate= rate
        if(category !== "fertilizer" && type==="liquid" && rate !== ""){ //if product is weed/pest control and liquid
            if(rateArea === rateAreaOptions[1]){ //if quantity area type will be in acre
                newRate=(rate*1000)/43560 //transform from acre to /1000sqft
            }
        }
        let resp = await addProduct(token,productName, price, quantityTransformed, type,weightGallon, n_proc, p_proc ,k_proc,ca_proc,mg_proc,s_proc ,fe_proc, mn_proc,lawnManagementCompanyId, epaReg, newCategoryFertilizer, activeIngredients, parseFloat(newRate), parseFloat(carrierVolume),quantityType);
        setAddProductResponse(resp);
    } catch (error) {
        console.log("Error on Add Product:", error);
    }
  }

  useEffect(() => {
    if(addProductResponse){
        if(addProductResponse.success){
            setSuccess(addProductResponse.message);
        } else {
            setAlert(addProductResponse.message)
        }
    }

  }, [addProductResponse])

  async function getLawnManagementId () {
    setLawnManagementCompanyId(await getLCMID(token))
  }

  
    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])

    function onCategoryChange(c){

        
        setCategory(c)

        if(c == "fertilizer"){
            setActiveIngredients("")
            if (type == "wettable powder"){
                setType("granular")
            }

        } else {

            if(type == "dry soluble"){
                setType("granular")
            }
            setCa("")
            setMg("")
            setFe("")
            setS("")
            setMn("")

            setCaValid(true)
            setMgValid(true)
            setFeValid(true)
            setSValid(true)
            setMnValid(true)
        }
    }


  return (
    <>
        {alert&&<Alert color="danger" text={alert} setter={setAlert}></Alert>}
        <div className="pl-lg-2 pr-lg-2">                
                <>
                <div>
                <Row className="pt-lg-2">
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-company-name"
                        >
                            Product Name
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                         <ValidationInput type="text" placeholder="Product Name" value={productName} setter={setProductName} validationType="CUSTOM_FREETEXT" message={"Invalid name"} mandatory getValidState={setProductNameValid}/>
                    </Col>
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-taxId"
                        >
                            Category
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                        <DropdownList header={category} items={["fertilizer","weed control", "pest control"]} setter={(c) => onCategoryChange(c)}/>                        
                    </Col>
                {/* </Row> */}
                {/* <Row className="pt-lg-2"> */}
                <Col lg="6" className="pt-1">
                        <label
                            className="form-control-label"
                            htmlFor="input-reg-no"
                        >EPA Registration Number
                        {category !== "fertilizer" &&
                           <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        }
                        </label>
                        <ValidationInput type="text" placeholder="EPA Reg. No" value={epaReg} setter={setEpaReg} validationType="FREETEXT_ZERO" message={"Invalid registration number"} mandatory getValidState={setEpaRegValid}/>

                </Col>
                    <Col lg="6" className="pt-1">
                        <label
                            className="form-control-label"
                            htmlFor="input-taxId"
                        >
                            Product Type
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                        {category == "fertilizer" ?
                        <DropdownList header={type} items={["granular","liquid", "dry soluble"]} setter={setType}/>      
                        :
                        <DropdownList header={type} items={["granular","liquid", "wettable powder"]} setter={setType}/>      
                        }
                                          
                    </Col>
                    
                {/* </Row> */}
                    {/* <Row className="pt-lg-2"> */}
                    <Col lg="6" className="pt-1">
                        <label
                            className="form-control-label"
                            htmlFor="input-reg-no"
                        >
                           Price
                           <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                        <ValidationInput type="text" placeholder="Price" value={price} setter={setPrice} validationType="NUMERIC" message={"Invalid price"} mandatory getValidState={setPriceValid}/>

                    </Col>
                    
                    <Col lg="3" className="pt-1">
                        <label
                            className="form-control-label"
                            // htmlFor="input-email-cmp"
                        >
                            Quantity&nbsp;
                            {type==="liquid"&&<small>[{quantityType}]</small>}
                            {(type==="granular"||type=="wettable powder"||type == "dry soluble")&&<small>[lbs]</small>}                            
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                        <ValidationInput type="text" placeholder="Quantity" value={qty} setter={setQty} validationType="NUMERIC" message={"Invalid quantity"} mandatory getValidState={setQtyValid}/>
                    </Col>  

                    {type == "liquid"   &&
                    <Col lg="3" className="pt-1">
                        <label
                            className="form-control-label"
                            // htmlFor="input-email-cmp"
                        >
                            Quantity type
                            <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>                   
                        </label>
                        <DropdownList header={quantityType} items={["gal", "oz"]} setter={setQuantityType}/> 
                    </Col> 
                    }     
                    {/* </Row> */}
                    {/* <Row className="mt-1"> */}
                    {type != "granular" &&
                        <Col lg="6" className="pt-1">
                            <label
                                className="form-control-label"
                                // htmlFor="input-email-cmp"
                            >
                                Carrier volume &nbsp;   
                                <span>
                                {<small>[gal/1000sqft]</small>}
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>                
                            </label>
                            <ValidationInput type="text" placeholder="Carrier volume" value={carrierVolume} setter={setCarrierVolume} validationType="NUMERIC" message={"Invalid carrier volume"} mandatory getValidState={setCarrierVolumeValid}/>                        
                    </Col> 
                    }
                    {type==="liquid"&& category == "fertilizer" && 
                        <Col lg="3" className="pt-1">
                            <label
                                className="form-control-label"
                                htmlFor="input-taxId"
                            >
                                 <span>
                                <i className="fa-regular fa-circle-question fa-lg" id="edit-button"></i>
                                <UncontrolledTooltip
                                    target="edit-button"
                                >
                               You can find this information on the label of the product. It has no correlation with ratio per acre.
                                    </UncontrolledTooltip>
                                </span>&nbsp;
                                Weight/Gallon
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>

                                </span>

                            </label>
                            <ValidationInput type="text" placeholder="Weight/Gallon" value={weightGallon} setter={setWeightGallon} validationType="NUMERIC" message={"Invalid weight/gallon"} mandatory getValidState={setWeightGallonValid}/>                        
                        </Col>  
                     }
                        
                    {category != "fertilizer" && 
                        <Col lg="3" className="pt-1">
                            <label
                                className="form-control-label"
                                htmlFor="input-taxId"
                            >
                                Rate
                                
                                <span>
                                <small>{type == "liquid" ? " [oz":" [lbs"} /{category!=="fertilzier" && type==="liquid"? rateArea : "1000sqft"}]</small>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <ValidationInput type="text" placeholder="Rate" value={rate} setter={setRate} validationType="NUMERIC" message={"Invalid rate"} mandatory getValidState={setRateValid}/>                        
                        </Col>
                    }    
                    {category !== "fertilizer" && type==="liquid" &&
                        <Col lg="3" className="pt-1">
                            <label
                                className="form-control-label"
                                // htmlFor="input-email-cmp"
                            >
                                Rate area unit
                                <span>
                                    <small style={{ color: "red", borderColor: "red" }}>
                                        &nbsp;*
                                    </small>
                                </span>            
                            </label>
                            <DropdownList header={rateArea} items={rateAreaOptions} setter={setRateArea}/> 
                        </Col> 
                    } 
                    </Row>
                    </div>
                    <hr className="mt-4 mb-2" />
                    {/* Address */}
                    <Row>
                        <Col lg="12">
                        <h6 className="heading-small text-muted mb-2">
                            Attributes
                        </h6>
                        <div>
                            <Row >
                                {(category == "fertilizer" || (category != "fertilizer" && type != "liquid")) &&
                                <>
                                <Col lg={category == "fertilizer" ? 3: 4}>
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-website"
                                    >
                                        <small>%</small>N
                                        {category == "fertilizer" && 
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                        }
                                    </label>
                                    <ValidationInput type="text" placeholder="N" value={n} setter={setN} validationType={category == "fertilizer" ? "NUMERIC" : "NUMERIC_NULL"} message={"Invalid N"} mandatory getValidState={setNValid}/>
                                    </FormGroup>
                                </Col>
                                <Col lg={category == "fertilizer" ? 3: 4}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                    >
                                        <small>%</small>P
                                        {category == "fertilizer" && 
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                        }
                                    </label>
                                    <ValidationInput type="text" placeholder="P" value={p} setter={setP}  validationType={category == "fertilizer" ? "NUMERIC" : "NUMERIC_NULL"} message={"Invalid P"} mandatory getValidState={setPValid}/>
                                </Col>
                                <Col lg={category == "fertilizer" ? 3: 4}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-city"
                                    >
                                        <small>%</small>K
                                        {category == "fertilizer" && 
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                        }
                                    </label>
                                    <ValidationInput type="text" placeholder="K" value={k} setter={setK}  validationType={category == "fertilizer" ? "NUMERIC" : "NUMERIC_NULL"} message={"Invalid K"} mandatory getValidState={setKValid}/>
                                </Col>
                                </>
                                }
                                {category == "fertilizer" && 
                                <Col lg="3">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-billing-state"
                                    >
                                        <small>%</small>Ca
                                        <span>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Ca" value={ca} setter={setCa} validationType="NUMERIC" message={"Invalid Ca"} mandatory getValidState={setCaValid}/>
                                </Col>
                                }
                            </Row>
                            {category == "fertilizer" ? 
                            <Row className="pt-lg-2">
                                
                                <Col lg="3">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                    >
                                        <small>%</small>Mg
                                        <span>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Mg" value={mg} setter={setMg} validationType="NUMERIC" message={"Invalid Mg"} mandatory getValidState={setMgValid}/>
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                    >
                                        <small>%</small>S
                                        <span>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="S" value={s} setter={setS} validationType="NUMERIC" message={"Invalid S"} mandatory getValidState={setSValid}/>
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                    >
                                        <small>%</small>Fe
                                        <span>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Fe" value={fe} setter={setFe} validationType="NUMERIC" message={"Invalid Fe"} mandatory getValidState={setFeValid}/>
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                    >
                                        <small>%</small>Mn
                                    </label>
                                    <ValidationInput type="text" placeholder="Mn" value={mn} setter={setMn} validationType="NUMERIC" message={"Invalid Mn"} mandatory getValidState={setMnValid}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            :
                            <Row className="pt-lg-2">
                                <Col  lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-ds222"
                                        >
                                            Active ingredients 
                                            <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                            </span>
                                        </label>
                                        <Tags validationType="CUSTOM_FREETEXT" getTags={setActiveIngredients}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            }
                        </div>
                        </Col>                   
                    </Row>                            
                </>            
        </div>
        <div className="pt-2 pl-2">
        <Button 
        color="primary" 
        type="button"
        disabled={!(productNameValid&&priceValid&&qtyValid&&typeValid&&epaRegValid&&nValid&&pValid&&kValid&&caValid&&mgValid&&sValid&&feValid&&mnValid&&rateValid&&carrierVolumeValid)}
        onClick={(e) => {e.preventDefault(); onAddProductClick();}}>
            Add Product
        </Button>
        </div>
    </>
    );
};

export default AddProduct;
