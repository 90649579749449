import {useState, useEffect} from "react";
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import {
Button,
Col,
Table,
UncontrolledTooltip
} from "reactstrap";
import ValidationInput from "components/ValidationInput/ValidationInput";
import DropdownList from "components/DropdownList/DropdownList";
import listProducts from "api/products/listProducts"
import { getLCMID } from "api/company/getLCMID";
import {Chip} from '@mui/material';
  
const AddApplicationProducts = ({recommandation, adjustCarrier, round, setAlert, setCurrentProductsParent, productsType, title, hideQuantity, currentProductsParent, area}) => {

const {token, setToken} = useTokenContext();
const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
const { id } = useParams();
const [products, setProducts] = useState(null);
const [currentProducts, setCurrentProducts] = useState([]);
const [currentProductsList, setCurrentProductsList] = useState([])
const [addProduct, setAddProduct] = useState(false);
const [productsNames, setProductsNames] = useState(null);
const [newProductName, setNewProductName] = useState("Select...");
const [newProductQuantity, setNewProductQuantity] = useState("");
const [newProductCarrierVolume, setNewProductCarrierVolume] = useState("");
const [editProduct, setEditProduct] = useState(-1);
const [editProductQuantity, setEditProductQuantity] = useState("");
const [editProductCarrierVolume, setEditProductCarrierVolume] = useState("");
const [editProductQuantityValid ,setEditProductQuantityValid] = useState(false)

const [newProd, setNewProd] = useState({"N": 0, "P":0, "K":0, "Ca":0, "Mg":0, "S":0, "Fe":0, "Mn":0})
const [newProductQuantityValid, setNewProductQuantityValid] = useState(false);
const [newProductCarrierVolumeValid, setNewProductCarrierVolumeValid] = useState(false);
const [editProductCarrierVolumeValid, setEditProductCarrierVolumeValid] = useState(false);
const [isEdit,setIsEdit]=useState(false)

function onConfirmAddProductClicked(){
    setCurrentProducts([...currentProducts, {"product_id": productsNames[newProductName]["id"], "quantity": newProductQuantity, "carrier_volume": newProductCarrierVolume}])
    setCurrentProductsParent([...currentProducts, {"product_id": productsNames[newProductName]["id"], "quantity": newProductQuantity, "carrier_volume": newProductCarrierVolume}])
    setCurrentProductsList([...currentProductsList, 
        {"name": newProductName, "quantity": newProductQuantity,"type": productsNames[newProductName]["type"],
         "active_ingredients": productsNames[newProductName]["active_ingredients"],"carrier_volume": newProductCarrierVolume, 
         "recommended": false, ...newProd
        }])
    onCancelAddProductClicked();
}

function onCancelAddProductClicked(){
    setNewProd({"N": 0, "P": 0, "K":0, "Ca":0, "Mg":0, "S":0, "Fe":0, "Mn":0})
    setNewProductName("Select...")
    setNewProductQuantity("")
    setNewProductQuantityValid(false)
    setAddProduct(false)
}

function onEditAddProductClicked(name){
    let updatedCurrentProducts = currentProducts.map(product => {
        if (product.product_id === productsNames[name].id) {
            let current_carrier_volume
            if(adjustCarrier){
                current_carrier_volume = editProductCarrierVolume
            } else {
                current_carrier_volume = productsNames[name]["carrier_volume"]
            }
            return {
                ...product,
                quantity: editProductQuantity,
                carrier_volume: current_carrier_volume
            };
        }
        return product;
    });
    setCurrentProducts(updatedCurrentProducts)
    setCurrentProductsParent(updatedCurrentProducts)

    let prod = productsNames[name]
    let factor;
    if(prod["type"] == "granular"||prod["type"] == "wettable powder"||prod["type"] == "dry soluble"){
        factor = editProductQuantity
    } else {
        if(prod["category"] == "fertiliser"){
            factor = editProductQuantity / 128 * prod["weight_per_gallon"]
        } else {
            factor = 0
        }
    }
    let new_nutrients = {
        "N": prod["n_pc"] *  factor,
        "P":  prod["p_pc"] *  factor,
        "K": prod["k_pc"] *  factor,
        "Ca": prod["ca_pc"] *  factor,
        "Mg": prod["mg_pc"] *  factor,
        "S": prod["s_pc"] *  factor,
        "Fe":  prod["fe_pc"] *  factor,
        "Mn": prod["mn_pc"] *  factor,
    }

    const updatedProductsList = currentProductsList.map(product => {
        if (product.name === name) {
            let current_carrier_volume
            if(adjustCarrier){
                current_carrier_volume = editProductCarrierVolume
            } else {
                current_carrier_volume = productsNames[name]["carrier_volume"]
            }
            return {
                ...product,
                quantity: editProductQuantity,
                carrier_volume: current_carrier_volume,
                N: new_nutrients.N,
                P: new_nutrients.P,
                K: new_nutrients.K,
                Ca: new_nutrients.Ca,
                Mg: new_nutrients.Mg,
                S: new_nutrients.S,
                Fe: new_nutrients.Fe,
                Mn: new_nutrients.Mn,
                active_ingredients: productsNames[name]["active_ingredients"]             
            };
        }
        return product;
    });
    setCurrentProductsList(updatedProductsList);
    onEditCancelProdiuctClicked()
    }

    function onEditCancelProdiuctClicked(){
        setEditProduct(-1);
        setEditProductQuantity("");
        setEditProductCarrierVolume("");
        setEditProductQuantityValid(false);
        setEditProductCarrierVolumeValid(false);
        setIsEdit(false)
    }

    function onEditProductClicked(idx, quantity, carrier_volume){
        setEditProduct(idx);
        setEditProductQuantity(quantity);
        setEditProductCarrierVolume(carrier_volume);
        setEditProductCarrierVolumeValid(true);
        setEditProductQuantityValid(true);
        setIsEdit(true)
    }

    function onDeleteProductClicked(idx){
        setCurrentProductsList(currentProductsList.filter((_,index) => index!==idx))
        setCurrentProducts(currentProducts.filter((_,index) => index!==idx))
        setCurrentProductsParent(currentProducts.filter((_,index) => index!==idx))
    }

    const doGetListProducts = async () => {
        try{
            let rsp = await listProducts(token, "true", lawnManagementCompanyId, productsType);
            setProducts(rsp);
        } catch (error) {
            console.log("Error on List Turf Types:", error);
        }
    }

    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
        }

        useEffect(() => {
        if(lawnManagementCompanyId && productsType){
        doGetListProducts();
        }
        }, [lawnManagementCompanyId, productsType])

        useEffect(() => {
            if(id, token){
                getLawnManagementId()
            }
        }, [id,token])

    useEffect(() => {
        if(products && products.success && products["data"]!=null){
            setProductsNames(products["data"].reduce((acc,item) => {
                acc[item["name"]] = item
                return acc
                
            }, {}))
        }
    },[products])


    useEffect(() => {   
        if(newProductName!=="Select..." && newProductQuantity!==0 && newProductQuantity!=="" && newProductQuantityValid){
            let q_float = parseFloat(newProductQuantity)
            let q_carrier_volume = parseFloat(newProductCarrierVolume)
            if(!isNaN(q_float)){
                setNewProductQuantity(q_float)
            }
            // if(!isNaN(q_carrier_volume) && adjustCarrier){
            //     setNewProductCarrierVolume(q_carrier_volume)
            // } else {
            //     setNewProductCarrierVolume(productsNames[newProductName]["carrier_volume"])
            // }
            
            let prod = productsNames[newProductName]
            let factor;
            if (prod["category"] == "fertiliser"){
                if(prod["type"] == "granular"||prod["type"] == "wettable powder"||prod["type"] == "dry soluble"){
                    factor = newProductQuantity
                } else {
                    factor = newProductQuantity / 128 * prod["weight_per_gallon"]
                } 
            }  else {  
            //this below didn't let for weed/pest control to add a new product and edit the rate(was putting the same value over and over,without letitng to edit)
            //     if(prod["rate"]){
            //         setNewProductQuantity(prod["rate"])
            //     }
                factor = 0
            }
            setNewProd({
                "N": prod["n_pc"] *  factor,
                "P":  prod["p_pc"] *  factor,
                "K": prod["k_pc"] *  factor,
                "Ca": prod["ca_pc"] *  factor,
                "Mg": prod["mg_pc"] *  factor,
                "S": prod["s_pc"] *  factor,
                "Fe":  prod["fe_pc"] *  factor,
                "Mn": prod["mn_pc"] *  factor,
            })
        }
    }, [newProductQuantity,newProductQuantityValid])

    useEffect(() => {
        
        if(productsNames && recommandation && recommandation["Product:"] && (!currentProductsParent || currentProductsParent.length==0)){
            let currentProd = recommandation["Product:"].map(product => {
                let quantity = recommandation["Q"]
                if(product["type"] == "liquid"){
                    quantity = recommandation["Q"]/product['weight_per_gallon'] * 128
                }

                return {
                    "product_id":product["id"],
                    "quantity": quantity *1000/area,
                    "carrier_volume": product["carrier_volume"]

                }
            })
            let currentProdList = recommandation["Product:"].map(product => {
                let prod = productsNames[product["name"]]
                let factor;
                let quantity = recommandation["Q"]

                if(prod["type"] == "granular" || prod["type"] == "wettable powder"  || prod["type"] == "dry soluble" ){
                    factor = recommandation["Q"]* 1000/area
                } else {
                    if(prod["category"] == "fertiliser"){
                        quantity = recommandation["Q"]/prod['weight_per_gallon'] * 128 
                        factor = recommandation["Q"] * 1000/area
                    } else {
                        if (prod["rate"]) {
                            quantity = prod["rate"]
                        }
                        factor = 0
                    }

                }
                let new_prod = {
                    "N": prod["n_pc"] *  factor,
                    "P":  prod["p_pc"] *  factor,
                    "K": prod["k_pc"] *  factor,
                    "Ca": prod["ca_pc"] *  factor,
                    "Mg": prod["mg_pc"] *  factor,
                    "S": prod["s_pc"] *  factor,
                    "Fe":  prod["fe_pc"] *  factor,
                    "Mn": prod["mn_pc"] *  factor,
                }
                
                return {    
                    "quantity": quantity * 1000/area,
                    "name": product["name"],
                    "type": product["type"],
                    "carrier_volume": product["carrier_volume"],
                    "recommended": true,
                    "active_ingredients": product["active_ingredients"],
                    ...new_prod
                }
            })

            setCurrentProducts([...currentProducts, ...currentProd])
            setCurrentProductsParent([...currentProducts, ...currentProd])
            setCurrentProductsList([...currentProductsList, ...currentProdList])
        }
    }, [recommandation, productsNames, currentProductsParent])

    const total = currentProductsList ? 
    currentProductsList.reduce((accumulator, product) => {
        return {
            "N": accumulator.N + (product["N"] || 0),
            "P": accumulator.P + (product["P"] || 0),
            "K": accumulator.K + (product["K"] || 0),
            "Ca": accumulator.Ca + (product["Ca"] || 0),
            "Mg": accumulator.Mg + (product["Mg"] || 0),
            "S": accumulator.S + (product["S"] || 0),
            "Fe": accumulator.Fe + (product["Fe"] || 0),
            "Mn": accumulator.Mn + (product["Mn"] || 0),
        };
    }, {
        "N": 0.0,
        "P": 0.0,
        "K": 0.0,
        "Ca": 0.0,
        "Mg": 0.0,
        "S": 0.0,
        "Fe": 0.0,
        "Mn": 0.0,
    }) 
    : {
        "N": 0.0,
        "P": 0.0,
        "K": 0.0,
        "Ca": 0.0,
        "Mg": 0.0,
        "S": 0.0,
        "Fe": 0.0,
        "Mn": 0.0,
    };

    useEffect(() => {
        if(newProductName!="Select..."){
            let currProd = productsNames[newProductName]
            let recommendedQuantity = 0
            if(productsType == "fertiliser"){
                let necessaryN = (round["n_q"]*1000/area) - total["N"]

                let n_pc = currProd.n_pc*100
                if(currProd.type == "granular"||currProd.type == "wettable powder"||currProd.type == "dry soluble"){
                    recommendedQuantity = 100*necessaryN/n_pc
                } else {
                    recommendedQuantity = 100*necessaryN/n_pc/currProd['weight_per_gallon'] * 128
                }
            } else {
                if (currProd["rate"]) {
                    recommendedQuantity = currProd["rate"]
                }
            }
            if(recommendedQuantity < 0) {
                recommendedQuantity = 0;
            }
            setNewProductQuantity(recommendedQuantity)
            setNewProductQuantityValid(true)
            if(currProd["carrier_volume"]){
                setNewProductCarrierVolume(currProd["carrier_volume"])
            } else {
                setNewProductCarrierVolume("")
            }
            setNewProductCarrierVolumeValid(true)
        }
    }, [newProductName])

    useEffect(() => {
        if(productsNames && currentProductsParent && currentProductsParent.length > 0){
            let currentProd = currentProductsParent.map(product => {
                return {
                    "product_id":product.product["id"],
                    "quantity": product.rate,
                    "carrier_volume":product.carrier_volume

                }
            })
            let currentProdList = currentProductsParent.map(product => {
                let prod = productsNames[product.product["name"]]
                let factor;
                let quantity = product.rate

                if(prod["type"] == "granular"||prod["type"] == "wettable powder"||prod["type"] == "dry soluble"){
                    factor = quantity
                } else {
                    if(prod["category"] == "fertiliser"){
                        factor = quantity / 128 * prod["weight_per_gallon"]
                    } else {
                        factor = 0
                    }
                }

                let new_prod = {
                    "N": prod["n_pc"] *  factor,
                    "P":  prod["p_pc"] *  factor,
                    "K": prod["k_pc"] *  factor,
                    "Ca": prod["ca_pc"] *  factor,
                    "Mg": prod["mg_pc"] *  factor,
                    "S": prod["s_pc"] *  factor,
                    "Fe":  prod["fe_pc"] *  factor,
                    "Mn": prod["mn_pc"] *  factor,
                }
                return {    
                    "quantity": quantity,
                    "name": product.product["name"],
                    "type": product.product["type"],
                    "carrier_volume": product["carrier_volume"],
                    "recommended": false,
                    "active_ingredients":product.product["active_ingredients"],
                    ...new_prod
                }
            })

            setCurrentProducts([...currentProducts, ...currentProd])
            setCurrentProductsParent([...currentProducts, ...currentProd])
            setCurrentProductsList([...currentProductsList, ...currentProdList])
        }
    },[currentProductsParent, productsNames])

    // State for live nutrient values
    const [editNutrientValues, setEditNutrientValues] = useState({
        N: 0,
        P: 0,
        K: 0,
        Ca: 0,
        Mg: 0,
        S: 0,
        Fe: 0,
        Mn: 0
    });

    //live changes on npk etc when chages are performed on editProductQuantity and a product is in edit mode
    useEffect(() => {
        if (editProduct !== -1 && editProductQuantityValid && isEdit) {
            const productName = currentProductsList[editProduct].name;
            const prod = productsNames[productName];

            let factor;
            if (prod["type"] === "granular" || prod["type"] === "wettable powder" || prod["type"] === "dry soluble") {
                factor = editProductQuantity;
            } else if (prod["category"] === "fertiliser") {
                factor = editProductQuantity / 128 * prod["weight_per_gallon"];
            } else {
                factor = 0;
            }

            setEditNutrientValues({
                N: prod["n_pc"] * factor,
                P: prod["p_pc"] * factor,
                K: prod["k_pc"] * factor,
                Ca: prod["ca_pc"] * factor,
                Mg : prod["mg_pc"] *  factor,
                S: prod["s_pc"] *  factor,
                Fe:  prod["fe_pc"] *  factor,
                Mn: prod["mn_pc"] *  factor,
            });
        } else {
            setEditNutrientValues({
                N: 0,
                P: 0,
                K: 0,
                Ca: 0,
                Mg: 0,
                S: 0,
                Fe: 0,
                Mn: 0
            });
        }
    }, [editProductQuantity,isEdit]);

    //live changes of n,p,k when product is pest/weed control and product quantity changes
    useEffect(()=>{
        if(addProduct && newProductQuantity && productsType !== "fertiliser"){
            let prod=productsNames[newProductName]
            let factor=newProductQuantity
            setNewProd({
                N: prod["n_pc"] * factor,
                P: prod["p_pc"] * factor,
                K: prod["k_pc"] * factor,
            })
        }
    },[addProduct,newProductCarrierVolume,newProductQuantity])

    return (
    <>
        <div className="d-flex  mb-2">
        <h3 className="">
            {title}
        </h3>
            <Col className="justify-content-end d-flex">
                <Button
                    color="primary"
                    onClick={(e) =>{e.preventDefault(); setAddProduct(true); !productsNames && setAlert("You haven't added any products yet.")}}
                    size="sm"
                    >
                    + Add Product
                </Button>
            </Col>
        </div>
        <Table className="align-items-center table-light table-sm" responsive>
            <thead className="thead-dark">
                <tr>
                <th scope="col">Product Name</th>
                <th scope="col">Rate/1000sqft</th>
                <th scope="col">Product Type</th>
                <th scope="col">Carrier volume<small className="d-block">gal/1000sqft</small></th>
                <th scope="col">Dillution rate</th>
               
                <th scope="col">N</th>
                <th scope="col">P</th>
                <th scope="col">K</th>
                {productsType == "fertiliser" &&
                <>
                <th scope="col">Ca</th>
                <th scope="col">Mg</th>
                <th scope="col">S</th>
                <th scope="col">Fe</th>
                <th scope="col">Mn</th>
                </>
                }
                {productsType !== "fertiliser" &&
                <th scope="col">Active Ingredients</th>
                }
                <th scope="col" className="justify-content-end text-right">
                <div>
                    <i className="fa-regular fa-circle-question fa-lg" id="edit-button"></i>
                    <UncontrolledTooltip
                        target="edit-button"
                    >
                    Once a product is added, only the quantity and the carrier volume can be edited. To select a different product, please delete this row and add a new one.
                        </UncontrolledTooltip>
                    </div>
                </th>
                </tr>
            </thead>
            <tbody>
                {currentProductsList && currentProductsList.map((product, idx) => (
                        <tr id={product["name"] + idx} key={idx}>
                        <td style={{maxWidth:"12vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                    {product["recommended"]? 
                        <div className="d-block" style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                        <small className="text-align-center font-italic d-block mb-2 text-primary">
                        (recommended product)
                        </small>
                        {product["name"]} 
                        </div>
                    :
                        product["name"]}
                        </td>
                        <td className="d-flex align-items-center">
                        {editProduct!== idx ? (product["quantity"] && parseFloat(product["quantity"]).toFixed(2)) : <ValidationInput type="text" placeholder="Quantity" value={editProductQuantity} setter={setEditProductQuantity} validationType="NUMERIC" message={"Invalid quantity"} getValidState={setEditProductQuantityValid}/>}
                        &nbsp;<small>{product["type"] === "liquid" ? " oz" : " lbs"}</small>
                        </td>
                        <td>
                        {product["type"]}
                        </td>
                        <td>
                            {adjustCarrier && editProduct === idx && product["type"] != "granular" ? 
                            <ValidationInput type="text" placeholder="carrier vol" value={editProductCarrierVolume} setter={setEditProductCarrierVolume} validationType="NUMERIC" message={"Invalid volume"} getValidState={setEditProductCarrierVolumeValid}/>
                            :
                            product["carrier_volume"]}
                        </td>
                        <td>
                        {product["carrier_volume"]? 
                        <>{
                            
                        editProduct=== idx ? (editProductQuantity/editProductCarrierVolume).toFixed(2) : (product["quantity"]/product["carrier_volume"]).toFixed(2)}
                        &nbsp;<small>{product["type"] === "liquid" ? " oz/gal" : " lbs/gal"}</small>
                        </>:
                        <></>}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.N.toFixed(2) : (product["N"] && product["N"] !== "N/A" && product["N"].toFixed(2))}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.P.toFixed(2) : (product["P"] && product["P"]!="N/A" && product["P"].toFixed(2))}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.K.toFixed(2) : (product["K"] && product["K"] != "N/A" && product["K"].toFixed(2))}
                        </td>
                        {productsType == "fertiliser" &&
                        <>
                        <td>
                        {editProduct === idx ? editNutrientValues.Ca.toFixed(2) : (product["Ca"] && product["Ca"] != "N/A" && product["Ca"].toFixed(2))}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.Mg.toFixed(2) : (product["Mg"] && product["Mg"] != "N/A" && product["Mg"].toFixed(2))}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.S.toFixed(2) : (product["S"] && product["S"] != "N/A" && product["S"].toFixed(2))}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.Fe.toFixed(2) : (product["Fe"] && product["Fe"] != "N/A" && product["Fe"].toFixed(2))}
                        </td>
                        <td>
                        {editProduct === idx ? editNutrientValues.Mn.toFixed(2) : (product["Mn"] && product["Mn"] != "N/A" && product["Mn"].toFixed(2))}
                        </td>
                        </>
                        }
                        {productsType !== "fertiliser" &&
                        
                        <td>
                            {product && Array.isArray(product["active_ingredients"]) && product["active_ingredients"].length > 0 ?
                                product["active_ingredients"].map((ingredient, idx) => (
                                    <Chip key={idx} label={ingredient} />
                            ))
                            : "N/A"
                            }
                        </td>
                        }
                        <td className="justify-content-end text-right"
                            style={{position: "sticky",
                                right: "0",
                                zIndex: "1000",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius:"5px",
                                maxWidth:"100px"
                            }}
                        >
                    {editProduct!==idx?
                    <>

                    <Button
                        
                        color="secondary"
                        type="button"
                        size="sm"
                        disabled={!(newProductQuantityValid||newProductCarrierVolumeValid|| newProductName==="Select...")}
                        onClick={()=>onEditProductClicked(idx, product["quantity"], product["carrier_volume"])}
                        
                    >
                        <i className="fa-solid fa-pen"></i>
                    </Button>

                        <Button
                        color="danger"
                        
                        type="button"
                        size="sm"
                        onClick={()=>onDeleteProductClicked(idx)}
                        
                    >
                        <i className="fa-solid fa-trash"></i>
                    </Button>

                    </>
                    :
                    <>
                    <Button
                        color="success"
                        className=""
                        type="button"
                        size="sm"
                        disabled={!(editProductQuantityValid)}
                        onClick={() => onEditAddProductClicked(product["name"])}
                        
                    >
                        <i className="fa-solid fa-check"></i>
                    </Button>
                        <Button
                        color="light"
                        
                        type="button"
                        size="sm"
                        onClick={onEditCancelProdiuctClicked}
                        
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                    </>
                    }
                        
                        </td>
                        </tr>
                ))}
            
            {productsNames &&
                <>
                {addProduct &&
                <tr id={"new-prod"} style={{backgroundColor:"#f7f7f7"}}>
                        <td style={{maxWidth:"12vw"}}>
                        <DropdownList header={newProductName} items={Object.keys(productsNames).filter(key => { let product= productsNames[key]; return !currentProducts.some(currProduct => currProduct.product_id === product.id)}).sort()} setter={setNewProductName}/>
                        </td>
                        <td style={{maxWidth:"7vw"}}>
                        <ValidationInput type="text" placeholder="Qty" value={newProductQuantity} setter={setNewProductQuantity} validationType="NUMERIC" message={"Invalid quantity"} getValidState={setNewProductQuantityValid}/>
                        
                        </td>
                        <td>
                        {newProductName!="Select..." ? productsNames[newProductName]["type"] : "N/A"}
                        </td>
                        <td>
                        {newProductName!="Select..." && productsNames[newProductName]["type"] !== "granular" &&
                        <>
                        {adjustCarrier ?
                        <ValidationInput type="text" placeholder="carrier vol" value={newProductCarrierVolume} setter={setNewProductCarrierVolume} validationType="NUMERIC" message={"Invalid vol"} getValidState={setNewProductCarrierVolumeValid}/>
                        :
                        productsNames[newProductName]["carrier_volume"]
                        }
                        </>
                        }
                        </td>
                        <td>
                        {newProductName!="Select..." &&
                            <>
                            {adjustCarrier ?
                                <>
                                {newProductCarrierVolume && newProductCarrierVolumeValid && newProductQuantity && newProductQuantityValid ?
                                    (newProductQuantity/newProductCarrierVolume).toFixed(2)
                                    :
                                    (newProductQuantity/productsNames[newProductName]["carrier_volume"]).toFixed(2)}
                                    &nbsp;<small>{productsNames[newProductName]["type"] === "liquid" ? " oz/gal" : " lbs/gal"}</small>
                                                        
                                </>
                            :
                            productsNames[newProductName]["carrier_volume"] && 
                                <>
                                {(newProductQuantity/productsNames[newProductName]["carrier_volume"]).toFixed(2)}
                                &nbsp;<small>{productsNames[newProductName]["type"] === "liquid" ? " oz/gal" : " lbs/gal"}</small>
                                </>
                            }
                            </>
                        }
                        </td>
                        <td>
                        {newProd["N"]!=="N/A"? newProd["N"].toFixed(2) : "N/A"}
                        </td>
                        <td>
                        {newProd["P"]!=="N/A"? newProd["P"].toFixed(2) : "N/A"}
                        </td>
                        <td>
                        {newProd["K"]!=="N/A"? newProd["K"].toFixed(2) : "N/A"}
                        </td>
                        {productsType == "fertiliser" &&
                        <>
                        <td>
                        {newProd["Ca"]!=="N/A"? newProd["Ca"].toFixed(2) : "N/A"}
                        </td>
                        <td>
                        {newProd["Mg"]!=="N/A"? newProd["Mg"].toFixed(2) : "N/A"}
                        </td>
                        <td>
                        {newProd["S"]!=="N/A"? newProd["S"].toFixed(2) : "N/A"}
                        </td>
                        <td>
                        {newProd["Fe"]!=="N/A"? newProd["Fe"].toFixed(2) : "N/A"}
                        </td>
                        <td>
                        {newProd["Mn"]!=="N/A"? newProd["Mn"].toFixed(2) : "N/A"}
                        </td>
                        </>
                        }
                        {productsType !== "fertiliser" &&
                        <td>
                            {productsNames && productsNames[newProductName] && productsNames[newProductName]["active_ingredients"] && productsNames[newProductName]["active_ingredients"].length > 0 ?
                                productsNames[newProductName]["active_ingredients"].map((ingredient, idx) => (
                                    <Chip key={idx} label={ingredient} />
                            ))
                            : "N/A"
                            }
                        </td>
                        }
                        <td className="justify-content-end text-right" 
                            style={{position: "sticky",
                                right: "0",
                                zIndex: "1000",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius:"5px"
                            }}
                        >
                        <Button
                        color="success"
                        className=""
                        type="button"
                        size="sm"
                        disabled={!(newProductQuantityValid || newProductName!=="Select...")}
                        onClick={onConfirmAddProductClicked}
                    >
                        <i className="fa-solid fa-check"></i>
                    </Button>
                        <Button
                        color="danger"
                        
                        type="button"
                        size="sm"
                        onClick={onCancelAddProductClicked}
                        
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                        </td>
                    
                </tr>
                }
                </>
            }
            
                <tr id={"total"} style={{backgroundColor:"#f7f7f7"}}>
                        <th >
                        TOTAL
                        </th>
                        <td style={{maxWidth:"7vw"}}>

                        </td>
                        <td>
                        </td>
                        <td>
                            
                        </td>
                        <td></td>
                        <td>
                        {total["N"].toFixed(2)}
                        </td>
                        <td>
                        {total["P"].toFixed(2)}
                        </td>
                        <td>
                        {total["K"].toFixed(2)}
                        </td>
                        {productsType == "fertiliser" &&
                        <>
                        <td>
                        {total["Ca"].toFixed(2)}
                        </td>
                        <td>
                        {total["Mg"].toFixed(2)}
                        </td>
                        <td>
                        {total["S"].toFixed(2)}
                        </td>
                        <td>
                        {total["Fe"].toFixed(2)}
                        </td>
                        <td>
                        {total["Mn"].toFixed(2)}
                        </td>
                        </>
                        }
                       <td className="justify-content-end text-right"></td>
                       {productsType !== "fertiliser" &&
                        <td className="justify-content-end text-right"></td>
                       }
                </tr>

                {round && productsType=="fertiliser" &&
                    < tr id={"recommended-round"} style={{backgroundColor:"#f7f7f7"}} className="text-primary font-italic">
                        <th >
                    <div className="d-block">
                    <small className="d-block">(recommended round)</small>
                        TOTAL
                        </div>
                        </th>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td></td>
                        <td></td>
                        <td>
                    {(round["n_q"]*1000/area).toFixed(2)}
                    </td>
                    <td>
                    {(round["p_q"]*1000/area).toFixed(2)}
                    </td>
                    {productsType == "fertiliser" &&
                    <>
                    <td>
                    {(round["k_q"]*1000/area).toFixed(2)}
                    </td>
                    <td>
                    {(round["ca_q"]*1000/area).toFixed(2)}
                    </td>
                    <td>
                    {(round["mg_q"]*1000/area).toFixed(2)}
                    </td>
                    <td>
                    {(round["s_q"]*1000/area).toFixed(2)}
                    </td>
                    <td>
                    {(round["fe_q"]*1000/area).toFixed(2)}
                    </td>
                    <td>
                    {(round["mn_q"]*1000/area).toFixed(2)}
                    </td>
                    </>
                    }
                        <td className="justify-content-end text-right">
                        
                        </td>
                    
                </tr>
            }
            </tbody>
        </Table>
        </>
    );
};

export default AddApplicationProducts;


