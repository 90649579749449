
import {
    Row,
    Col,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,

  } from "reactstrap";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

  function TabPaneComponent({activeTab, setActiveTab, names, contents}){

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
   
    return (
        <div>
        {/* <Tabs value={activeTab} onChange={(ev, newVal) => setActiveTab(newVal)} aria-label="basic tabs example"  indicatorColor="primary"  textColor="primary" >
        {names && names.map((name, idx) => (
          <Tab label={name} {...a11yProps(idx)} />
        ))}
        </Tabs> */}

        <Nav tabs>

            {names && names.map((name, idx) => (
                <NavItem id={"tab-" + idx} key={idx}>
                    <NavLink
                   
                    className={`${activeTab === idx ? 'active' : ''}`}
                    onClick={() => setActiveTab(idx)}
                    >
                    <Button color="transparent" style={{
                        margin: '0',
                        boxShadow: 'none',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }} >{name}</Button>
                    </NavLink>
                </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row>
              <Col sm="12">
                {contents[activeTab]}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  };
  
  export default TabPaneComponent;


    