import {getRequest, URL_SERVER} from './../requests'


export default async function listExcesses(token, applicationId, holeId, skip, limit){
    try {
        let query="?"
        if(applicationId){
            query+= "applicationId=" + applicationId
        } else {
            query += "holeId=" + holeId
        }
        return await getRequest(URL_SERVER + "excesses" + query, token)
    } catch (error) {
        console.error(error);
    }

}