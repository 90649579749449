import {getRequest, URL_SERVER} from './../requests'


export default async function listTurfTypes(token,skip,limit){
    try {

        return await getRequest(URL_SERVER + 'turfTypes', token)
    } catch (error) {
        console.error(error);
    }

}