import {getRequest, URL_SERVER} from './../requests'


export default async function getTotalArea(token, lawnManagementCompanyId){
    try {
        return await getRequest(URL_SERVER + "totalArea/" + lawnManagementCompanyId, token)
    } catch (error) {
        console.error(error);
    }

}