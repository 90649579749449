import {useState, useEffect} from "react";
import { useTokenContext } from "App"
import PaginationComponent from "components/Pagination/Pagination";

import {
    Table,
    Row,
  } from "reactstrap";
  // core components
import listProducts from "api/products/listProducts"
import Alert from "components/Alerts/Alert";
import { getLCMID } from "api/company/getLCMID";
import { Chip } from "@mui/material";
import Filter from "components/Filter/Filter.js";
  
const PestControl = ({reloadProducts}) => {
  const [products, setProducts] = useState([]);
  const {token, setToken} = useTokenContext();
  const [alert, setAlert] = useState(null);
  const [alertColor, setAlertColor] = useState("success")
  const [startIndex, setStartIndex] = useState(1)
  const [endIndex, setEndIndex] = useState(6)
  const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);


  const doGetListProducts = async () => {
      try{
          let currentProducts = await listProducts(token, true,lawnManagementCompanyId, "pest_control");
          setProducts(currentProducts);
      } catch (error) {
          console.log("Error on List Products:", error);
      }
  }

  async function getLawnManagementId () {
    setLawnManagementCompanyId(await getLCMID(token))
  }

  useEffect(() => {
  if(lawnManagementCompanyId){
      doGetListProducts();
  }
  }, [lawnManagementCompanyId, reloadProducts])

  useEffect(() => {
      if(token){
          getLawnManagementId()
      }
  }, [token])

  const [newProducts,setNewProducts]=useState([])

  return (
    <>
        {/* Table */}
        {alert&&
          <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
        }
        <div style={{ margin:"5px 0px 5px 0px"}}>
            <Filter elements={products.data} setFilteredElements={setNewProducts} filterBy={"name"}/>
        </div>
        <Row>
          <div className="col">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">EPA</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Type</th>
                    <th scope="col">lbs/gal</th>
                    <th scope="col">Active ingredients</th>
                    <th scope="col">N</th>
                    <th scope="col">P</th>
                    <th scope="col">K</th>                  
                  </tr>
                </thead>
                <tbody>
                  {newProducts && newProducts
                    .sort((a, b) => {
                      if (a["name"] < b["name"]) return -1;
                      if (a["name"] > b["name"]) return 1;
                      return 0;
                      })
                    .slice(startIndex, endIndex)
                    .map((product,idx) => (
                      <tr id={product["id"]} key={idx}>
                      <th scope="row" style={{maxWidth:"15vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                      {product["name"]}
                      </th>
                      <th scope="row" style={{maxWidth:"15vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                      {product["epa_registration_number"] && product["epa_registration_number"]}
                      </th>
                      <td>
                        {product["price"]+"$"}
                      </td>
                      <td>
                        {product["type"] === "liquid" ?
                            product["quantity_type"] && product["quantity_type"]==="oz"?
                              product["quantity"]*128 +"oz"
                              :
                              product["quantity"]+"gal"
                            : product["quantity"]+"lbs"
                        }
                      </td>
                      <td>
                          {product["type"]}
                      </td>
                      <td>
                          {product["weight_per_gallon"]}
                      </td>
                      <td>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap', // Permite împachetarea pe rânduri noi
                          maxWidth: '30rem', // Setează lățimea maximă dorită
                          margin: '0 auto', // Centerizează containerul dacă este necesar
                          overflow: 'hidden', // Asigură că overflow-ul este ascuns
                        }}
                      >
                        {product["active_ingredients"] && Array.isArray(product["active_ingredients"]) &&
                          product["active_ingredients"].map((ingr, index) => (
                            <Chip
                              key={index}
                              label={ingr}
                              style={{ margin: '5px' }}
                            />
                          ))
                        }
                       </div>
                      </td>
                      <td>
                        {product["n_pc"] ? parseInt(product["n_pc"] * 100) + "%" : "0%"}
                      </td>
                      <td>
                      {product["p_pc"] ? parseInt(product["p_pc"] * 100) + "%": "0%"}
                      </td>
                      <td>
                      {product["k_pc"] ? parseInt(product["k_pc"] * 100) + "%" : "0%"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {products && products.success && products["data"] && newProducts &&
                <div className="mt-3">
                  <PaginationComponent data={newProducts} itemsPerPage={6} getStart={setStartIndex} getEnd={setEndIndex}/>
                </div>
              }
          </div>
        </Row>

      </>
  );
};


export default PestControl;