import {postRequest, URL_SERVER} from './../requests'


export default async function addFeedback(token, application_id, rating, rating_reason){
    const data = {
        "application_id": application_id,
        "rating": rating,
        "rating_reasom": rating_reason
        }
    try {

        return await postRequest(URL_SERVER + 'applicationFeedback', data, token)
    } catch (error) {
        console.error(error);
    }

}