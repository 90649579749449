import {useState, useEffect} from "react";
import { useTokenContext } from "App"
import DropdownList from "components/DropdownList/DropdownList";
import {
    Card,
    Button,
    CardBody,
    Table,
    UncontrolledTooltip,
  } from "reactstrap";
  // core components
import { getFormattedTime } from "utils/utils";
import ModalDialog from "components/ModalDialog/ModalDialog";
import listSoilTests from "api/growthTables/listSoilTests";
import listSeasonDemands from "api/growthTables/listSeasonDemands";
import AddSoilTest from "./AddSoilTest";
import Alert from "components/Alerts/Alert"
import getGrowthSummary from "api/growthTables/getGrowthSummary";
import generateGrowthSummary from "api/growthTables/generateGrowthSummary";
import InfoBox from "components/InfoBox/InfoBox";
import generateRounds from "api/rounds/generateRounds";
  
  function PropertyDetailSoilTestsPane({holeId, holesNames, selectedHole, setSelectedHole,holes}){
    const {token, setToken} = useTokenContext();
    const [alert, setAlert] = useState(null);

    const date = new Date(Date.now());
    const year = date.getFullYear();

    const [soilTests, setSoilTests] = useState(null);
    const [seasonDemands, setSeasonDemands] = useState(null);
    const [modalSoilTestAddState, setModalSoilTestAddState] = useState(false);
    const [generateGrowthSummaryResponse, setGenerateGrowthSummaryResponse] = useState(null);
    const [growthSummaryResponse, setGrowthSummaryResponse] = useState(null);

    const doGenerateGrowthSummary = async () => {
        try{
            let generateGrowthSummaryRsp = await generateGrowthSummary(token, holeId)
            setGenerateGrowthSummaryResponse(generateGrowthSummaryRsp)
        } catch (error) {
            console.log("Error on Generate Growth Summary:", error);
        }
    }
    const doGetGrowthSummary = async (year) => {
        try{
            let growthSummaryResponse = await getGrowthSummary(token, holeId, year)
            setGrowthSummaryResponse(growthSummaryResponse)
        } catch (error) {
            console.log("Error on Get Growth Summary:", error);
        }
    }

    useEffect(() => {
        doGetGrowthSummary(year);
    }, [holeId, generateGrowthSummaryResponse])

    useEffect(() => {
        if(growthSummaryResponse){
            if(growthSummaryResponse.success == false){
                doGenerateGrowthSummary();
            }
        }
    },[growthSummaryResponse])

    const doGetListSoilTests = async () => {
        try {
            let soilTestResp = await listSoilTests(token, holeId);
            setSoilTests(soilTestResp);
        } catch (error) {
            console.log("Error on list soil test:", error)
        }
    }

    const doGetListSeasonDemands = async () => {
        try {
            let seasonDemResp = await listSeasonDemands(token, holeId);
            setSeasonDemands(seasonDemResp);
        } catch (error) {
            console.log("Error on list season demands:", error)
        }
    }

    function onAddSoilTestSuccess(message){
        setAlert(message);
        setModalSoilTestAddState(false);

        doGetListSoilTests();
        doGetListSeasonDemands();

        if(holes && holes['contract_detail']){
            let currentHole = holes['contract_detail'].filter((ctrDet) => (ctrDet.hole_id == holesNames[selectedHole]))
            if(currentHole.length == 1){
                doGenerateRounds()
            }
        }
    }

    useEffect(() => {
        if(holeId){
            doGetListSoilTests();
            doGetListSeasonDemands();
        }
    }, [holeId])

    const doGenerateRounds = async () => {
        try{
            await generateRounds(token, holeId)
            // setGenerateRoundsResponse(rsp)
        } catch (error) {
            console.log("Error on Generate Growth Summary:", error);
        }
    }

    return (
      <>
        <Card className=" bg-secondary shadow" style={{borderTop:0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardBody>
            {alert&&
            <Alert color="success" text={alert} setter={setAlert}></Alert>
          }
            <div className="d-flex modal-header pt-1 pb-2">
                    <div className="d-flex align-items-center">
                    <h6 className="heading-small text-muted  mr-3">
                        Soil Tests
                    </h6>
                    <i className="fa-regular fa-circle-question pb-2" id="unit-soil"></i>
                        <UncontrolledTooltip
                            target="unit-soil"
                        >
                        A soil test analyses nutrient levels in ppm to guide fertilization and soil management for optimum turf grow.
                        </UncontrolledTooltip>
                    
                    <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); setModalSoilTestAddState(true)}}
                        size="sm"
                        className="ml-5"
                        >
                       + Add
                        </Button>
                        </div>
                        {holesNames && <span className="d-flex align-items-center">
                        <h6 className="heading-small text-muted mr-3">
                            Zone
                        </h6>
                          <DropdownList header={selectedHole} items={Object.keys(holesNames)} setter={setSelectedHole}/>
                          </span>
                          }
                    </div>
                <div className="pl-lg-3">
                <Table className="align-items-center table-light table-sm" responsive>
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">P</th>
                        <th scope="col">K</th>
                        <th scope="col">Ca</th>
                        <th scope="col">Mg</th>
                        <th scope="col">S</th>
                        <th scope="col">Fe</th>
                        <th scope="col">Mn</th>
                        <th scope="col">pH</th>
                        <th scope="col">Salinity</th>
                        <th scope="col" className="justify-content-end text-right">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {soilTests && soilTests.success && soilTests["data"].map((soilTest,idx) => (
                            <tr id={soilTest["id"]} key={idx}>
                                <td>
                                {soilTest["p"]!=="" ? parseFloat(soilTest["p"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["k"]!=="" ? parseFloat(soilTest["k"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["ca"]!=="" ? parseFloat(soilTest["ca"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["mg"]!=="" ? parseFloat(soilTest["mg"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["s"]!=="" ? parseFloat(soilTest["s"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["fe"]!=="" ? parseFloat(soilTest["fe"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["mn"]!=="" ? parseFloat(soilTest["mn"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["PH"]!=="" ? parseFloat(soilTest["PH"]).toFixed(2) : ""}
                                </td>
                                <td>
                                {soilTest["salinity"]!=="" ? parseFloat(soilTest["salinity"]).toFixed(2) : ""}
                                </td>
                                <td className="justify-content-end text-right">
                                {getFormattedTime(soilTest["dateTime"])}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="mt-4">
                    <InfoBox text={["Values are in ppm"]} />
                </div>

                </div>
                <hr className="my-4" />
                <div className="d-flex modal-header pt-1 pb-2">
                    <div className="d-flex align-items-center">
                    <h6 className="heading-small text-muted  mr-3">
                        Yearly fertilizer rate forecast
                    </h6>
                    <i className="fa-regular fa-circle-question pb-2" id="unit-season"></i>
                        <UncontrolledTooltip
                            target="unit-season"
                        >
                        Season demand is a forecast of nutrient demand through the year, expressed in lbs/1000sqft
                        </UncontrolledTooltip>
                    </div>
                </div>
                <div className="pl-lg-3">
                <Table className="align-items-center table-light table-sm" responsive>
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">N</th>
                        <th scope="col">P</th>
                        <th scope="col">K</th>
                        <th scope="col">Ca</th>
                        <th scope="col">Mg</th>
                        <th scope="col">S</th>
                        <th scope="col">Fe</th>
                        <th scope="col">Mn</th>
                        <th scope="col" className="justify-content-end text-right">Generated At</th>
                        </tr>
                    </thead>
                    <tbody>
                    {seasonDemands && seasonDemands.success && seasonDemands["data"].map((seasonDemand,idx) => (
                            <tr id={seasonDemand["id"]} key={idx}>
                                <td>
                                {seasonDemand["total_N"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_P"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_K"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_Ca"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_Mg"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_S"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_Fe"].toFixed(2)}
                                </td>
                                <td>
                                {seasonDemand["total_Mn"].toFixed(2)}
                                </td>
                                <td className="justify-content-end text-right">
                                {getFormattedTime(seasonDemand["created_at"])}
                                </td>
                            </tr>
                    ))}
                    </tbody>
                    </Table>
                    <div className="mt-4">
                        <InfoBox text={["The unit of measurement for all elements is lbs/1000 sq ft."]} />
                    </div>
                    </div>
            </CardBody>
            
        </Card>
        {modalSoilTestAddState && holeId && 
            <ModalDialog 
                status={modalSoilTestAddState} 
                setter={setModalSoilTestAddState} 
                title={"Add Soil Test"} 
                content={<AddSoilTest holeId={holeId} setSuccess={onAddSoilTestSuccess} />}
            />
        }
        </>
    );
  };
  
  export default PropertyDetailSoilTestsPane;


    