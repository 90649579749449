import {getRequest, URL_SERVER} from './../requests'


export default async function listApplications(token, workerId, holeId, lawnManagementCompanyId, status, skip, limit, fromTimeStamp, toTimestamp){
    try {
        let query="?"
        if(workerId){
            query+= "workerId=" + workerId
        }
        if(holeId){
            query += "holeId=" + holeId
        }
        if(lawnManagementCompanyId){
            query += "lawnManagementCompanyId=" + lawnManagementCompanyId
        }
        if(status){
            query += "&status=" + status
        }
        if(skip){
            query += "&skip=" + skip
        }
        if(limit){
            query += "&limit=" + limit
        }
        if(fromTimeStamp){
            query += "&fromTime=" + fromTimeStamp
        }
        if(toTimestamp){
            query += "&toTime=" + toTimestamp
        }
        return await getRequest(URL_SERVER + "applications" + query, token)
    } catch (error) {
        console.error(error);
    }

}