import GoogleMapComponent from "components/GoogleMaps/GoogleMapComponent";
  
  function HoleViewOnMap({lat, lng}){

    return (
      <div style={{height:"50vh", width:"100%"}}>
      <GoogleMapComponent markerSet={{lat: lat, lng: lng}}  draggable={false}/>
    </div>
    );
  };
  
  export default HoleViewOnMap;


    