import {putRequest, URL_SERVER} from './../requests'

export async function updateApplication(token, application_id, status, worker_id,date, qt_check){
    
    var data = {}
    data["nutrients_application_id"] = application_id;
    if(worker_id!=""){
        data["worker_id"] = worker_id;
    }
    if(status!=""){
        data["status"] = status;
    }
    console.log(date)
    if(date){
        data["date"] = date;
    }
    if(qt_check){
        data["qt_check"] = qt_check;
    }


    try {

        return await putRequest(URL_SERVER + 'application', data, token)
    } catch (error) {
        console.error(error);
    }

}