import {putRequest, URL_SERVER} from './../requests'

export async function updateCustomer(token, customer_id, delivery_address, 
    delivery_city, delivery_state, delivery_country, delivery_zip_code, mobile, name){
    
    var data = {}
    data["customer_id"] = customer_id;
    data["delivery_address"] = delivery_address;
    data["delivery_city"] = delivery_city;
    data["delivery_country"] = delivery_country;
    data["delivery_state"] = delivery_state;
    data["delivery_zip_code"] = delivery_zip_code;
    if(mobile){
        data["mobile"] = mobile
    }

    if(name){
        data["name"] = name
    }

    try {

        return await putRequest(URL_SERVER + 'customer', data, token)
    } catch (error) {
        console.error(error);
    }

}