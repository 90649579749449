import {useState, useEffect} from "react"
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import ValidationInput from "components/ValidationInput/ValidationInput";
import Alert from "components/Alerts/Alert"
import forgotPassword from "api/forgotPassword/forgotPassword"
import { useNavigate } from "react-router-dom";
import { useNotification } from 'components/NotificationContext/NotificationContext';

const ForgotPassword = () => {
  const { notification, setNotification } = useNotification();
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState(null);
  const [fpResponse, setResponse] = useState(null);

  const navigate = useNavigate();

  function setUsernameFunction(usr){
    setEmail(usr)
  }

  function ForgotPasswordClick() {    
    onForgotPasswordClick()
  }
  function LoginClick(e){
    e.preventDefault();
    navigate("/auth/login")
  }
  function RegisterClick(){
    navigate("/auth/register")
  }
  const onForgotPasswordClick = async () => {
    try {
      var response = await forgotPassword(email)
      setResponse(response)
    } catch (error) {
      console.error('Forgot password request error:', error);
    }
  }

  useEffect(() => {
    if (fpResponse){
      if (fpResponse.success){
        setNotification('Check your email inbox for reset password link!');
        navigate("/auth/login")
      }else{
        setAlert(true)
      }
    }
  },[fpResponse]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-2">
                  <strong className="" color="primary">Forgot Password</strong>
                </div>
                {notification && 
                  <Alert color="success" text={notification} setter={setNotification}></Alert>
                }
                {!notification &&   
                  <div className="text-center text-muted mb-4">
                    <small>Would you like to reset your password?</small>
                  </div>
                }
                <div>
                {alert&&
                <Alert color="danger" text="This email is not registered!" setter={setAlert}></Alert>
                }
              </div>   
            <Form role="form">
              <FormGroup className="mb-3">              
                <ValidationInput placeholder="Email" type="email" name="email" validationType="EMAIL" icon={<i className="ni ni-email-83" />} setter={setUsernameFunction} message={"Invalid Email"}/>
              </FormGroup>              
             
              <div className="text-center">
                <Button className="my-3" color="primary" type="button" onClick={ForgotPasswordClick} block>
                  Email me
                </Button>                              
              </div>
              <Row className="mt-2" >
                <Col xs="6">
                  <a href="" onClick={(e)=>{LoginClick(e)}}>
                    <small>&larr; Sign in</small>
                  </a>
                </Col> 
              </Row>   
            </Form>
          </CardBody>
        </Card>
        <div className="text-center">
          <div className="text-center text-light mt-2">
              <small>Join our community!</small>
          </div>
          <div className="text-center ">
          <Button className="my-2" color="light" type="button" onClick={RegisterClick} outline style={{backgroundColor:"rgba(24,29,39,0.5)"}}>
            Register
          </Button>    
          </div>            
        </div>

      </Col>
    </>
  );
};

export default ForgotPassword;
