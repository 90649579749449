
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Customers from "views/examples/customer/Customers"
import CustomersDetails from "views/examples/customer/CustomersDetails"
import AddProperty from "views/examples/property/AddProperty"
import AddHole from "views/examples/property/AddHole"
import PropertyDetail from "views/examples/property/PropertyDetail"
import AddApplication from "views/examples/property/AddApplication"
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import ForgotPassword from "views/examples/ForgotPassword";
import VerifyEmail from "views/examples/VerifyEmail";
import ResetPassword from "views/examples/ResetPassword";
import Providers from "views/examples/provider/Providers";
import ProvidersDetails from "views/examples/provider/ProvidersDetails";
import AddProduct from "views/examples/product/AddProduct";
import ViewApplication from "views/examples/property/ViewApplication";
import ProgramBuilder from "views/examples/prorgamBuilder/ProgramBuilder";
import AddTemplate from "views/examples/prorgamBuilder/AddTemplate";
import TemplateDetails from "views/examples/prorgamBuilder/TemplateDetails";
import ProductsWrapper from "views/examples/product/ProductsWrapper";
import ConfigureRound from "views/examples/prorgamBuilder/ConfigureRound";
import ConfigureUnscheduledApplication from "views/examples/property/ConfigureUnscheduledApplication";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-red", 
    component: <Index />,
    layout: "/admin",
    show: true
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "fa-solid fa-building-user text-blue",
    component: <Customers />,
    layout: "/admin",
    show: true
  },
  {
    path: "/products",
    name: "Products & Services",
    icon: "fa-solid fa-jug-detergent text-green",
    component: <ProductsWrapper />,
    layout: "/admin",
    show: true
  },
  {
    path: "/programBuilder",
    name: "Program Builder",
    icon: "fa-solid fa-wrench text-black",
    component: <ProgramBuilder />,
    layout: "/admin",
    show: true
  },
  {
    path: "/providers",
    name: "My Team",
    icon: "fa-solid fa-chalkboard-user text-purple",
    component: <Providers />,
    layout: "/admin",
    show: true
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
    show: false
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: <Maps />,
    layout: "/admin",
    show: false
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-orange",
    component: <Profile />,
    layout: "/admin",
    show: true
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
    show: false
  },

  {
    path: "/customer/detail/:id",
    name: "CustomerDetail",
    icon: "ni ni-bullet-list-67 text-red",
    component: <CustomersDetails />,
    layout: "/admin",
    show: false
  },
  {
    path: "/programBuilder/new",
    name: "New Program Builder",
    icon: "fa-solid fa-wrench text-black",
    component: <AddTemplate />,
    layout: "/admin",
    show: false
  },
  {
    path: "/programBuilder/details/:id",
    name: "Template details",
    icon: "fa-solid fa-wrench text-black",
    component: <TemplateDetails />,
    layout: "/admin",
    show: false
  },
  {
    path: "/programRound/details/:id",
    name: "Configure Rounds",
    icon: "fa-solid fa-wrench text-black",
    component: <ConfigureRound />,
    layout: "/admin",
    show: false
  },
  {
    path: "/round/configure/:id",
    name: "Configure Rounds",
    icon: "fa-solid fa-wrench text-black",
    component: <ConfigureUnscheduledApplication />,
    layout: "/admin",
    show: false
  },
  // {
  //   path: "/provider/detail/:id",
  //   name: "ProviderDetail",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <ProvidersDetails />,
  //   layout: "/admin",
  //   show: false
  // },
  // {
  //   path: "/properties",
  //   name: "Properties",
  //   icon: "fa-solid fa-seedling text-primary",
  //   component: <Properties />,
  //   layout: "/admin",
  //   show: false
  // },
  {
    path: "/property/new/:id",
    name: "AddProperty",
    icon: "ni ni-bullet-list-67 text-red",
    component: <AddProperty />,
    layout: "/admin",
    show: false
  },

  {
    path: "/product/new",
    name: "AddProduct",
    icon: "ni ni-bullet-list-67 text-red",
    component: <AddProduct />,
    layout: "/admin",
    show: false
  },
  {
    path: "/property/detail/:id",
    name: "PropertyDetail",
    icon: "ni ni-bullet-list-67 text-red",
    component: <PropertyDetail />,
    layout: "/admin",
    show: false
  },
  {
    path: "/hole/new/:id",
    name: "AddHole",
    icon: "ni ni-bullet-list-67 text-red",
    component: <AddHole />,
    layout: "/admin",
    show: false
  },
  {
    path: "/application/new/:id",
    name: "AddApplication",
    icon: "ni ni-bullet-list-67 text-red",
    component: <AddApplication />,
    layout: "/admin",
    show: false
  },
  {
    path: "/application/details/:id",
    name: "ViewApplication",
    icon: "ni ni-bullet-list-67 text-red",
    component: <ViewApplication />,
    layout: "/admin",
    show: false
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    show: false
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
    show: false
  },
  {
    path: "/forgot/password",
    name: "Forgot Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ForgotPassword />,
    layout: "/auth",
    show: false
  },
  {
    path: "/verify/:id",
    name: "Verify Email",
    icon: "ni ni-circle-08 text-pink",
    component: <VerifyEmail />,
    layout: "/auth",
    show: false
  },
  {
    path: "/reset/password/:id",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ResetPassword/>,
    layout: "/auth",
    show: false
  },
];
export default routes;
