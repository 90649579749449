import {useState, useEffect, createContext, useContext} from "react";
import { Link } from "react-router-dom";
import {BrowserRouter as Router, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import TabPaneComponent from "components/TabPane/TabPaneComponent"
import HoleViewOnMap from "./HoleViewOnMap";
import GoogleMapComponent from "components/GoogleMaps/GoogleMapComponent";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CardBody,
    Input,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle
  } from "reactstrap";
  // core components
  import { getFormattedTime } from "utils/utils";
import ModalDialog from "components/ModalDialog/ModalDialog";
import listApplications from "api/property/listApplications";
import listWorkers from "api/provider/listWorkers";
import Alert from "components/Alerts/Alert"
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";
import { getLCMID } from "api/company/getLCMID";
import DropdownList from "components/DropdownList/DropdownList";

  function PropertyDetailLogBook({holeId, holesNames, selectedHole, years, year, setSelectedHole, setYear}){
    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [applicationResponse, setApplicationResponse] = useState(null);
    const [applications, setApplications] = useState(null);
    const [alert, setAlert] = useState(null);
    const [workers, setWorkers] = useState(null);
    const [workersNames, setWorkersNames] = useState(null);
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])
    const navigate = useNavigate();
    
    function onSeeDetailsApplicationClicked(application){
      navigate("/admin/application/details/"  + application["id"]);
  }

  const doGetListWorkers = async () => {
    try{
        let rsp = await listWorkers(token, lawnManagementCompanyId);
        setWorkers(rsp);
    } catch (error) {
        console.log("Error on List Workers:", error);
    }
}

useEffect(() => {
  if(workers && workers.success && workers["data"]!=null){
      setWorkersNames(workers["data"].reduce((acc, item) => {
          acc[item.operator["id"]]=item.operator["name"] + " (" + item.associate["email"] + " )"         
          return acc;
      }, {}))
  }
}, [workers]);


    const doListApplications = async () => {
      try{
        let rsp = await listApplications(token, "", holeId)
        setApplicationResponse(rsp)
      } catch (error) {
          console.log("Error on List Rounds:", error);
      }
  }

    useEffect(() => {
      if(applicationResponse){
          if(applicationResponse.data != null){
              setApplications(applicationResponse.data)
          }
      }
  },[applicationResponse])

    async function getLawnManagementId () {
      setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
    if(lawnManagementCompanyId){
      doGetListWorkers()
      doListApplications()
    }
    }, [lawnManagementCompanyId])

    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])

    useEffect(() => {
      if(holeId){
        doListApplications()
      }
    },[holeId, year])
    
    return (
      <>

        <Card className=" bg-secondary shadow" style={{borderTop:0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardBody>
            {alert&&<Alert color="success" text={alert} setter={setAlert}></Alert>}
              <div className="d-flex modal-header pt-1 pb-2">
                    <div className="d-flex align-items-center">
                      <h6 className="heading-small text-muted  mr-3">
                          Applications
                      </h6>
                      <i className="fa-regular fa-circle-question pb-2" id="unit-soil"></i>
                      <UncontrolledTooltip
                            target="unit-soil"
                        >
                      Applications represent the planned product applications on properties. Each application needs to be assigned to a worker.
                        </UncontrolledTooltip>
                        </div>
                        {holesNames && <span className="d-flex align-items-center">
                        <h6 className="heading-small text-muted mr-3">
                            Zone
                        </h6>
                          <DropdownList header={selectedHole} items={Object.keys(holesNames)} setter={setSelectedHole}/>
                          <h6 className="heading-small text-muted mr-3 ml-3">
                            Year
                        </h6>
                          {years && year && <DropdownList header={year} items={years} setter={setYear}/>}
                          </span>
                          
                          
                          }
                   
              </div>
              <div className="pl-lg-3">
              <Table className="align-items-center table-light table-sm" responsive>
                  <thead className="thead-dark">
                    <tr>                    
                      <th scope="col">Date</th>
                      <th scope="col">Worker</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col">Round</th> */}
                      <th scope="col" className="justify-content-end text-right">See Details</th>
                    </tr>
                  </thead>
                  <tbody>
                  {applications && workersNames && 
                        applications.slice().slice(startIndex, endIndex).map((application,idx) => (                        
                        <tr id={application["application"]["id"]} key={idx} onClick={(e) => {e.preventDefault(); }}>                        
                          <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                            {getFormattedTime(application["application"]["date"])}
                          </th>
                          <td>
                          {workersNames[application["application"]["worker_id"]]}
                          </td>
                          <td>
                          {application["application"]["status"]["StatusValue"]}
                          </td>
                          {/* <td>
                          {application["round_id"]}
                          </td>                         */}
                          <td className="justify-content-end text-right">
                            <Button
                                className="text-primary"
                                type="button"
                                size="md"
                                color=""  
                                onClick={(e) => {e.preventDefault(); onSeeDetailsApplicationClicked(application["application"])}}                           
                            >
                               <i className="fas fa-ellipsis-v" />
                            </Button>
                          </td>
                        </tr>

                  ))}
                    </tbody>
                  </Table>
              </div>
              {applications && 
                        <div className="mt-3">
                            <PaginationComponent data={applications} itemsPerPage={constants["ROWS_PER_PAGE"]} getStart={setStartIndex} getEnd={setEndIndex}/>
                        </div>
                    }
            </CardBody>
        </Card>
        </>
    );
  };
  
  export default PropertyDetailLogBook;


    