import {useState, useEffect} from "react";
import { useTokenContext } from "App"
import PaginationComponent from "components/Pagination/Pagination";

import {
    Table,
    Row,

  } from "reactstrap";
  // core components

import Alert from "components/Alerts/Alert";
import { getLCMID } from "api/company/getLCMID";
import listJobs from "api/jobs/listJobs";
  
  const Jobs = ({reloadJobs}) => {
    const [jobs, setJobs] = useState(null);
    const {token, setToken} = useTokenContext();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState("success")
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(6)
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);


    const doGetListJobs = async () => {
      try{
        let rsp  = await listJobs(token, lawnManagementCompanyId)
        setJobs(rsp)
      } catch (error) {
        console.log("Error on List jobs:", error)
      }
    }

    async function getLawnManagementId () {
      setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
      if(lawnManagementCompanyId){
          doGetListJobs();
      }
    }, [lawnManagementCompanyId, reloadJobs])

    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])


    return (
      <>
          {/* Table */}
          {alert&&
            <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
          }
          <Row>
            <div className="col">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Avg. time/1000 sqft</th>
                      <th scope="col">Price/1000 sqft</th>                
                    </tr>
                  </thead>
                  <tbody>
                    {jobs && jobs.data && jobs.data.slice(startIndex, endIndex).map((job,idx) => (
                      <tr id={job.id} key={idx}>
                      <td>
                        {job.name}
                      </td>
                      <td>
                      {job.avg_time}
                      </td>
                      <td>
                      {job.price}
                      </td>
                      </tr>
                    ))}
                   
                  </tbody>
                </Table>
                {jobs && jobs.success && jobs["data"] && 
                        <div className="mt-3">
                            <PaginationComponent data={jobs["data"]} itemsPerPage={6} getStart={setStartIndex} getEnd={setEndIndex}/>
                        </div>
                    }
            </div>
          </Row>

        </>
    );
  };
  
  
  export default Jobs;


    