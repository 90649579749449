import React from 'react';
import Particles from "@tsparticles/react";

const ParticleEffect = () => {
  return (
    <>
    <Particles options={{
                "background": {
                    "color": "#282c34",
                    "opacity":"0"
                },
                "interactivity": {
                    "events": {
                        "onClick": {
                            "enable": true,
                            "mode": "repulse"
                        },
                        "onHover": {
                            "enable": true,
                            "mode": "bubble",
                            "parallax": {
                                enable:false,
                                force:2,
                                smooth:10
                            }                    
                        },
                        "resize": {
                            "delay":0.3,
                            "enable":true
                        }
                    },
                    "modes": {
                        "bubble": {
                            "distance": 250,
                            "duration":2,
                            "mix":false,
                            "opacity":0,
                            "size":0                                        
                        },
                        "repulse": {
                            "distance": 400,
                            "duration": 0.5,
                            "factor":100,
                            "speed":1,
                            "maxSpeed":50,
                            "easing":"ease-out-quad"
                        }
                    }
                },                
                "particles": {
                    "bounce":{
                        "horizontal":{
                            "value":1
                        },
                        "vertical":{
                            "value":1
                        }
                    },
                    "color": {
                        "animation": {
                            "h": {
                                "count":0,
                                "enable":false,
                                "sync":true,
                                "speed":1
                            },
                            "s":{
                                "count":0,
                                "enable":false,
                                "sync":true,
                                "speed":1
                            },
                            "l":{
                                "count":0,
                                "enable":false,
                                "sync":true,
                                "speed":1
                            }
                        },
                        "value": "#ffff"
                    },
                    "links": {
                        "color": "#ffffff",
                        "distance": 150,
                        "enable": false,
                        "opacity": 0.8,
                        "width": 1
                    },
                    "collisions": {
                        "absorb": {
                            "speed":2
                        },
                        "overlap":{
                            "enable":true,
                            "retries":0
                        }
                    },
                    "move": {
                        "angle":{
                            "offset":0,
                            "value":90
                        },
                        "attract":{
                            "distance":200,
                            "enable":false,
                            "rotate":{
                                "x":3000,
                                "y":3000
                            }
                        },
                        "center":{
                            "x":50,
                            "y":50,
                            "mode":"percent",
                            "radius":0
                        },
                        "decay":0,
                        "direction": "none",
                        "enable": true,
                        "outModes": {
                            "default": "out"
                        },
                        "random": false,
                        "speed": {
                            "min":0.1,
                            "max":4
                        },
                        "straight": false
                    },
                    "number": {
                        "density": {
                            "enable": true
                        },
                        "limit": {
                            "mode":"delete",
                            "value":0,
                        },
                        "value":200
                    },
                    "opacity": {
                        "value": {
                            "min":0.1,
                            "max":0.9
                        },
                        "animation":{
                            "count":0,
                            "enable":true,
                            "speed":1,
                            "decay":0,
                            "delay":0,
                            "sync":false,
                            "mode":"auto",
                            "startValue":"random",
                            "destroy":"none"                    
                        }
                    },
                    "reduceDuplicates":false,
                    "shape": {
                        "type": "circle",
                        "close":true,
                        "fill":true
                    },
                    "size": {
                        "animation": {
                            "enable":false
                        },
                        "value": {
                            "max":4,
                            "min":1
                        }
                    },
                    "effect":{
                        "close":true,
                        "fill":true,
                    }
                },
                "motion":{
                    "disable":true,
                    "reduce":{
                        "factor":4,
                        "value":true
                    }
                }
            }}/>
    </>
  );
};

export default ParticleEffect;
