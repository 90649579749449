/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
import "react-multi-date-picker/styles/colors/green.css"
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import DatePicker from "react-multi-date-picker";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "components/Headers/Header.js";
import listCustomers from "api/customer/listCustomers"
import listProviders from "api/provider/listProviders"
import listProperties from "api/property/listProperties";
import listRounds from "api/rounds/listRounds";
import getMyCompanies from "api/me/getMyCompanies"
import { useTokenContext } from "App";
import { getFormattedTime } from "utils/utils";
import DropdownList from "components/DropdownList/DropdownList";
import VerticalLinearStepper from "components/VerticalLinearStepper/VerticalLinearStepper";
import GoogleMapComponent from "components/GoogleMaps/GoogleMapComponent";
import listApplications from "api/property/listApplications";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import InfoBox from "components/InfoBox/InfoBox"
import listProducts from "api/products/listProducts";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";
import { updateApplication } from "api/property/updateApplication";
import Alert from "components/Alerts/Alert";
import ModalDialog from "components/ModalDialog/ModalDialog";
import HoleViewOnMap from "./examples/property/HoleViewOnMap";
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";
import addApplication from "api/rounds/addApplication";
import getTotalArea from "api/dashboard/totalArea";
import getTotalSpent from "api/dashboard/totalSpent";
import listProgramBuilders from "api/programBuilder/listProgramBuilders";
import getProduct from "api/products/getProduct";
import { isDifferenceMoreThanTenDays } from "utils/utils";
import ReportIcon from '@mui/icons-material/Report';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { computeDifferenceInDays } from "utils/utils";
import addFeedback from "api/property/addFeedback";
import { QTCHECK_color } from "assets/texts/states";
import { QTCHECK } from "assets/texts/states";
import ValidationInput from "components/ValidationInput/ValidationInput";
import updateProduct2Application from "api/products/updateProduct2Application";
import PageHeader from "components/Headers/PageHeader.js";

const markerPins = ['http://maps.google.com/mapfiles/ms/icons/red-dot.png', 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png', 'http://maps.google.com/mapfiles/ms/icons/green-dot.png']

const Index = (props) => {
  const navigate = useNavigate();

  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const {token, setToken} = useTokenContext();
  const [myCompanies, setMyCompanies] = useState(null);
  const [myCompaniesResponse, setMyCompaniesResp] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [alert, setAlert] = useState(null);
  const [alertColor, setAlertColor] = useState("success");
  const [providers, setProviders] = useState(null);
  const [properties, setProperties] = useState(null);
  const [stepIndex, setStepIndex] = useState(0)
  const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState("")
  const [showJourney, setShowJourney] = useState(false)
  const [roundsResponse, setRoundsResponse] = useState(null);
  const [propIdsMap, setPropIdsMap] = useState(null)
  const [propertiesMap, setPropertiesMap] = useState(null)
  const [products, setProducts]  = useState(null)
  const [inProgressApplications, setInProgressApplications] = useState(1)
  const [openMapModal, setOpenMapModal] = useState(false);
  const [viewMapApplication, setViewMapApplication] = useState(null);
  const [timePeriod, setTimePeriod] = useState(1);
  const [periodValue, setPeriodValue] = useState([]);
  const [viewApplications, setViewApplications] = useState(1)
  const [productsNames, setProductsNames] = useState(null);
  const [productsDicts, setProductsDicts] = useState(null);
  const [completedApplications, setCompletedApplications] = useState(null)
  const [addApplicationResponse, setAddApplicationResponse] = useState(null)
  const [programBuilders, setProgramBuilders] = useState(null)
  const [programBuildersNames, setProgramBuildersNames] = useState(null)
  const [updateApp, setUpdateApp] = useState(null);

  const [startIndexRounds, setStartIndexRounds] = useState(1)
  const [endIndexRounds, setEndIndexRounds] = useState(5)

  const [startIndexApp, setStartIndexApp] = useState(1)
  const [endIndexApp, setEndIndexApp] = useState(5)

  const [startIndexCompleted, setStartIndexCompleted] = useState(1)
  const [endIndexCompleted, setEndIndexCompleted] = useState(5)

  const [roundProgram, setroundProgram] = useState({})
  const [roundWorker, setRoundWorker] = useState({})
  const [workersNames, setWorkersNames] = useState(null);
  const [totalArea, setTotalArea] = useState(null)
  const [totalSpent, setTotalSpent] = useState(null)
  const [applicationFeedback, setApplicationFeedback]  = useState({})

  const [roundsResponseOrdered, setRoundsResponseOrdered] = useState(null)
  const [selectedProgramsProducts, setselectedProgramsProducts] = useState(null)
  const [addFeedbackRsp, setAddFeedbackRsp] = useState(null)
  const [editProductIdx, setEditProductIdx] = useState([])
  const [editProductCarrierVolume, setEditProductCarrerVolume] = useState(null)
  const [editProductCarrierVolumeValid, setEditProductCarrierVolumeValid] = useState(true)
  const [updateProduct2ApplicationRsp, setUpdateProduct2ApplicationRsp] = useState(null)

  function onAddApplicationClicked(roundId){
    navigate("/admin/round/configure/"  + roundId);
  }

  function onAddFeedbackClicked(applicationId){
    doAddFeedback(applicationId, applicationFeedback[applicationId]? parseInt(applicationFeedback[applicationId]+1) : 1)
  }

  function onViewApplicationClicked(applicationId) {
    navigate("/admin/application/details/"  + applicationId);
  }

  function onClickSetStatusDone(id, status){
    doUpdateApplication(id, status, "")
  }

  function onOpenMapModal(gps){
    setOpenMapModal(true)
    setViewMapApplication(gps)
  }

  // function handleSetroundProgram(round, programName){
  //   let roundId = round.id
  //   setroundProgram(prevroundProgram => ({...prevroundProgram, [roundId]:programName}));
  //   let selectedIdx =  programBuilders["data"]["programBuilders"].findIndex((pb, idx) => pb.program_name == programName);
  //   let granularOnce = false
  //   let quantities = [];
  //     programBuilders.data.products2ProgramBuilders[selectedIdx].forEach((prod) => {
  //       if (prod.type === "granular" && !granularOnce) {
  //         granularOnce = true;
          
  //         quantities = [...quantities,round["n_q"]/prod["n_pc"]]
  //       }else{
  //         quantities = [...quantities, 0]
  //       }
  //     });

  //   programBuilders["data"]["programBuilders"].map((pb, idx) => (
  //     pb.program_name == programName && 
  //     setselectedProgramsProducts(selProgr => ({...selProgr, [roundId]:{"product": programBuilders.data.products2ProgramBuilders[idx], "quantity": quantities}})) 
  //   ))
  // }

  function handleSetRoundWOrker(roundId, worker){
    setRoundWorker(prevRoundWorker => ({...prevRoundWorker, [roundId]:worker}));
  }

  function onScheduleApplicationClicked(round){
    let workerId = Object.entries(workersNames).filter(([key, value]) => value == roundWorker[round["id"]])
    if(!workerId || workerId.length == 0){
      setAlert("Select a worker before trying to schedule an application.")
      setAlertColor("danger")
      document.scrollingElement.scrollTop = 0;
    } else {
      workerId = workerId[0][0]
      doUpdateApplication(round["id"],"In Progress",workerId);
      }
  }

  function getProductObjectById(productId){
    let current_product = products["data"].filter((prod) => (prod.id == productId))
    if(current_product.length>0){
      return current_product[0]
    }
    return null
  }

  const doAddFeedback = async (applicationId, rating) => {
    try {
        let rsp = await addFeedback(token, applicationId, rating? parseInt(rating+1) : 1);
        setAddFeedbackRsp(rsp);
    } catch (error) {
        console.log("Error on Add Feedback:", error);
    }
}

  function getProductPrice(productId, quantityProd){
    let product = getProductObjectById(productId)
    if(product == null||!quantityProd) {
      return 0
    }
    return product.price/product.quantity *quantityProd
  }

  function getProductsPrices(application_products){
    let sum = 0;
    application_products.map((prod) => {
      sum += getProductPrice(prod.product_id, prod.quantity ? prod.quantity : 0)
    })

    return sum.toFixed(2)
  }


  const doAddApplication = async (round, worker, pr) => {
    try{
        let rsp = await addApplication(token, round["hole_id"], round["id"], worker, round["application_date"], pr);
        setAddApplicationResponse(rsp);
    } catch (error) {
        console.log("Error on Add Application:", error);
    }
  }
  const doGetProgramBuilders = async () => {
    try{
        let rsp = await listProgramBuilders(token, lawnManagementCompanyId);
        setProgramBuilders(rsp);
    } catch (error) {
        console.log("Error on List Program Builders:", error);
    }
  }

  const doUpdateProduct2Application = async (prodId, appId) => {
    try {
       let rsp = await updateProduct2Application(token, prodId, appId, 0, parseFloat(editProductCarrierVolume))
       setUpdateApp(rsp)
       setUpdateProduct2ApplicationRsp(rsp)
       
    } catch (error) {
        console.log("Error on add product 2 program round: ", error)
    }
  }

  function onEditCancelProdiuctClicked(){
    setEditProductIdx(-1)
    setEditProductCarrerVolume(null)
    setEditProductCarrierVolumeValid(false)
}

  useEffect(() => {
    if( updateProduct2ApplicationRsp && updateProduct2ApplicationRsp.success){
        onEditCancelProdiuctClicked()
      }
  },[updateProduct2ApplicationRsp])


  useEffect(()=>{
    // console.log("customers:",customers)
    if (lawnManagementCompanyId){
      getListCustomers()
      getListApplications()
      getListCompletedApplications()
      // doGetTotalArea()     
      // doGetTotalSpent()
    }
  },[lawnManagementCompanyId])

  // const doGetTotalArea = async () => {
  //   try{
  //     let rsp = await getTotalArea(token,lawnManagementCompanyId);
  //     setTotalArea(rsp);
  //   } catch (error) {
  //     console.log("Error on Get Total Area:", error);
  //   }
  // }
  

  // const doGetTotalSpent = async () => {
  //   try{
  //     let rsp = await getTotalSpent(token,lawnManagementCompanyId);
  //     setTotalSpent(rsp);
  //   } catch (error) {
  //     console.log("Error on Get Total Spent:", error);
  //   }
  // }


  const doUpdateApplication = async (id,status, workerId) => {
    try{
        let rsp = await updateApplication(token, id, status, workerId);
        setUpdateApp(rsp);
    } catch (error) {
        console.log("Error on List Workers:", error);
    } 
  };  

  const getListRounds = async () => {
    try{
      let fromTime;
      let toTime;
      let currentDate = new Date();
      fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
      if(timePeriod ==1){
        
        toTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);
      } else{
        if(timePeriod == 2){
          fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()- currentDate.getDay()+1,0,0,0,0);
          toTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() + 7, 23, 59, 59, 999);
        } else {
          if(timePeriod == 3) {
            fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
            toTime = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
          }//TODO
          else {
            if(periodValue.length == 2){
              fromTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 0, 0, 0, 0);
              toTime = new Date(periodValue[1].year, periodValue[1].monthIndex, periodValue[1].day, 23, 59, 59, 999);
            } else {
              if(periodValue.length == 1){
                fromTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 0, 0, 0, 0);
                toTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 23, 59, 59, 999);
              }
            }
          }
        }
        } 
      let fromTimestamp = Math.floor(fromTime.getTime() / 1000);
      let toTimestamp = Math.floor(toTime.getTime() / 1000);
        let rsp = await listApplications(token, "", "", lawnManagementCompanyId, "Unscheduled", null, null, fromTimestamp, toTimestamp)
        setRoundsResponse(rsp)
    } catch (error) {
        console.log("Error on List Rounds:", error);
    }
}

  const getListProviders = async () => {
    try{
        let currentProviders = await listProviders(token, lawnManagementCompanyId, 0, 100);
        setProviders(currentProviders);
    } catch (error) {
        console.log("Error on List Providers:", error);
    }
}

  const getListCustomers = async () => {
    try{
        let currentCustomers = await listCustomers(token, lawnManagementCompanyId, 0, 100);
        setCustomers(currentCustomers.data);
    } catch (error) {
        console.log("Error on List Customers:", error);
    }
}

  const getListProperties = async () => {
    try{
        let currentproperties = await listProperties(token, lawnManagementCompanyId, null, 0, 100);
        setProperties(currentproperties);
    } catch (error) {
        console.log("Error on List properties:", error);
    }
  }

  const getListApplications = async () => {
    try{
      let fromTime;
      let toTime;
      let currentDate = new Date();
       fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
      if(timePeriod ==1){
        
        toTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);
      } else{
        if(timePeriod == 2){
          fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()- currentDate.getDay()+1,0,0,0,0);
          toTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() + 7, 23, 59, 59, 999);
        } else {
          if(timePeriod == 3) {
            fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
            toTime = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
          }//TODO
          else {
            if(periodValue.length == 2){
              fromTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 0, 0, 0, 0);
              toTime = new Date(periodValue[1].year, periodValue[1].monthIndex, periodValue[1].day, 23, 59, 59, 999);
            } else {
              if(periodValue.length == 1){
                fromTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 0, 0, 0, 0);
                toTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 23, 59, 59, 999);
              }
            }
          }
        }
        } 
      let fromTimestamp = Math.floor(fromTime.getTime() / 1000);
      let toTimestamp = Math.floor(toTime.getTime() / 1000);

      let rsp = await listApplications(token, "", "", lawnManagementCompanyId, "In Progress", null, null, fromTimestamp, toTimestamp)
      setInProgressApplications(rsp)
    } catch (error) {
      console.log("Error on List Applications:", error);
    }
  }

  const getListCompletedApplications = async () => {
    try{
      let fromTime;
      let toTime;
      let currentDate = new Date();
      fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
      if(timePeriod ==1){
        
        toTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);
      } else{
        if(timePeriod == 2){
          fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()- currentDate.getDay()+1,0,0,0,0);
          toTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() + 7, 23, 59, 59, 999);
        } else {
          if(timePeriod == 3) {
            fromTime= new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
            toTime = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
          }//TODO
          else {
            if(periodValue.length == 2){
              fromTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 0, 0, 0, 0);
              toTime = new Date(periodValue[1].year, periodValue[1].monthIndex, periodValue[1].day, 23, 59, 59, 999);
            } else {
              if(periodValue.length == 1){
                fromTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 0, 0, 0, 0);
                toTime = new Date(periodValue[0].year, periodValue[0].monthIndex, periodValue[0].day, 23, 59, 59, 999);
              }
            }
          }
        }
        } 
      let fromTimestamp = Math.floor(fromTime.getTime() / 1000);
      let toTimestamp = Math.floor(toTime.getTime() / 1000);

      let rsp = await listApplications(token, "", "", lawnManagementCompanyId, "Done", null, null, fromTimestamp, toTimestamp)
      setCompletedApplications(rsp)
    } catch (error) {
      console.log("Error on List Applications:", error);
    }
  }

  const getListProducts = async () => {
    try{
      let rsp = await listProducts(token, "false", lawnManagementCompanyId)
      setProducts(rsp)
    } catch (error) {
      console.log("Error on List Applications:", error);
    }
  }
  

  useEffect(()=>{
    // console.log("customers:",customers)
    if (customers){
      if (customers.length>0){
        setStepIndex(2)
        getListProviders()
      }else{
        setShowJourney(true)
      }
    }
  },[customers])

  useEffect(()=>{
    // console.log("customers:",customers)
    if (providers){
      if (providers.data){
        if (providers.data.length>0){
          setStepIndex(3)    
          getListProducts()    
        }else{
          setShowJourney(true)
        }
      }
    }
  },[providers])

  useEffect(() => {
    if(providers){
        setWorkersNames(providers["data"].filter((item) => item.provider.provider_function=="worker").reduce((acc, item) => {
            acc[item.provider["id"]] = item.provider["name"] + " (" + item.associate["email"] + " )"
            return acc;
        }, {}))
      }
    }, [providers]);

  useEffect(()=>{
    if (properties){
      // console.log("properties:",properties)
      if (properties.data){
          if (properties.data.properties &&properties.data.properties.length>0){
            // setStepIndex(5)  
            getListRounds()
          }else{
            // setShowJourney(true)
          }
        
      }
    }
  },[properties])

  useEffect(()=>{
    if (products){
      if (products.data && products.data.length>0){
        setStepIndex(4)  
        getListProperties()
        doGetProgramBuilders()
        setProductsNames(products["data"].reduce((acc,item) => {
          acc[item["id"]] = item["name"]
          return acc
          
        }, {}))

        setProductsDicts(products["data"].reduce((acc,item) => {
          acc[item["id"]] = item
          return acc
          
        }, {}))
      }else{
        setShowJourney(true)
      }
        
    }
  },[products])



  const getMyCompaniesFunction = async()=>{
    try {
      let response = await getMyCompanies(token);      
      setMyCompaniesResp(response)            
      // console.log(response)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(addApplicationResponse){
      if(addApplicationResponse.success){
        setAlertColor("success")
        getListApplications()
        getListRounds()
      } else {
        setAlertColor("danger")
      }
      document.scrollingElement.scrollTop = 0;
      setAlert(addApplicationResponse.message)
        
    }
  },[addApplicationResponse])

  useEffect(()=>{
    if (myCompaniesResponse){
      if (myCompaniesResponse.success){
        setMyCompanies(myCompaniesResponse.data)
        if (myCompaniesResponse.data.companies_roles.length>0){
          // console.log("local storage added lcmid",localStorage.getItem("LCMId"))
          if (localStorage.getItem("LCMId")==null){
            // console.log("local storage",localStorage.getItem("LCMId"))
            localStorage.setItem("LCMId",myCompaniesResponse.data.companies_roles[0]["company_id"])
          }
          setLawnManagementCompanyId(localStorage.getItem("LCMId"))          
          setStepIndex(1)
        }else{
          setShowJourney(true)
        }         
      }
    }
  },[myCompaniesResponse]);

  useEffect(() => {
    getMyCompaniesFunction()  
  }, [token]);

  useEffect(() => {
    if(updateApp){
      if(updateApp.success == true){
        getListRounds()
        getListApplications()
        // doGetTotalSpent()
        getListCompletedApplications()
        setAlert(updateApp.message)
        setAlertColor("success")
      } else {
        setAlert(updateApp.message)
        setAlertColor("danger")
      }
    }
  }, [updateApp]);

  useEffect(() => {
    if(timePeriod && lawnManagementCompanyId){
        getListApplications()
        getListCompletedApplications()
        getListRounds()
        setPropertiesMap(null)
        setPropIdsMap(null)
    }
  }, [timePeriod, periodValue]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  useEffect(() => {
    if(roundsResponse && roundsResponse.data ){
      // setPropIdsMap(roundsResponse["data"].reduce((acc, item) => {
      //   acc[item.application.id] = item.round.property_id;
      //   return acc;
      // }, {}))

      // setPropertiesMap(properties["data"].properties.reduce((acc, item, index) => {
      //   acc[item.id] = index
      //   return acc;
      // }, {}))

      setRoundsResponseOrdered(roundsResponse.data.sort((a,b) => {return b.application.date - a.application.date}).reverse())
     
    }
  },[roundsResponse])

  useEffect(() => {
    if(programBuilders){
      if(programBuilders.success && programBuilders.data && programBuilders["data"]){
          setShowJourney(false)
          setStepIndex(5)
        setProgramBuildersNames(programBuilders["data"].reduce((acc,item) => {
          acc[item["program_name"]] = item["id"]
          return acc
          
      }, {}))
      }

      if(programBuilders.success && programBuilders.data === null){
        setShowJourney(true)

      }
    }
  },[programBuilders])

  useEffect(() => {
    if(addFeedbackRsp){
        if(addFeedbackRsp.success){
            setAlertColor("success")
            getListCompletedApplications()
        } else {
            setAlertColor("danger")
        }
        setAlert(addFeedbackRsp.message)

    }
}, [addFeedbackRsp])

  useEffect(() => {
    console.log(stepIndex)
  },[stepIndex])


  return (
    <>
      {/* <Header /> */}
      <PageHeader />
      
      {/* Page content */}
      <Container className="mt--7" fluid>
        {alert&&
            <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
          }
        {/* <Row>
          <Col lg="12" className="mb-2">
          <BreadcrumbNavigation active="Dashboard"/>
          </Col>
        </Row> */}
        <Row>     
          {/* <Col className="mb-3 mb-xl-0 px-3" xl="4">
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col  d-flex align-items-center">
                        <CardTitle className="text-uppercase text-muted mb-0 mr-4" style={{fontSize:"0.9rem"}}>
                          Properties in our care
                        </CardTitle>
                        {properties&&properties.data&&properties.data.properties?
                        <span className="h2 font-weight-bold mb-0">{properties.data.properties.length}</span>
                        :
                        <span className="h2 font-weight-bold mb-0">{0}</span>
                        }
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa-solid fa-seedling text-secondary" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>                              
          </Col> */}
          {/* <Col className="mb-3 mb-xl-0 px-3" xl="4">
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col d-flex align-items-center">
                        <CardTitle className="text-uppercase text-muted mb-0 d-flex mr-4" style={{fontSize:"0.9rem"}}>
                          Total area managed
                        </CardTitle>
                        {totalArea && totalArea["data"]  ?                        
                        <span className="h2 font-weight-bold mb-0 d-flex">{totalArea["data"].toFixed(2)}&nbsp;</span>
                        :
                        <span className="h2 font-weight-bold mb-0">{0}</span>
                        }<small>[sq ft]</small>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                        <i className="fa-solid fa-chart-area"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>             
            </Col> */}
            {/* <Col className="mb-3 mb-xl-0 px-3" xl="4">
              <Card className="card-stats mb-4 mb-lg-0">
                <CardBody>
                  <Row>
                    <div className="col d-flex align-items-center">
                      <CardTitle className="text-uppercase text-muted mb-0 mr-4" style={{fontSize:"0.9rem"}}>
                        Total spent
                      </CardTitle>
                      {totalSpent && totalSpent["data"]  ? 
                        <span className="h2 font-weight-bold mb-0">{totalSpent["data"].toFixed(2) + "$"}</span>
                        :
                        <span className="h2 font-weight-bold mb-0">{0}</span>
                      }
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                      <i className="fa-solid fa-sack-dollar"></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>               
            </Col> */}
             
          {
            showJourney===true&&stepIndex!==5&&
            <>
            <Col className="mb-5 mb-xl-0 px-3 pt-4 mt-4" xl="4">
              <Card className="shadow p-2">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h5 className="text-uppercase text-muted ls-1 mb-1">
                        Journey
                      </h5>
                    </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                  <VerticalLinearStepper activeStep={stepIndex}/>
                  </CardBody>
              </Card>
            </Col>
            <Col className="mb-5 mb-xl-0 px-3 pt-4 mt-4" xl="8">
              <Card className="shadow p-2">

                  <CardBody>
                  <small>
                    <i className="fa-solid fa-circle-info"></i>&nbsp;
                                
                    Complete your journey to unlock a comprehensive view of all available statistics.
                  </small>
                  </CardBody>
              </Card>
            </Col>
            </>                       
          }          
        </Row>

        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
          
            {showJourney===false&&stepIndex===5&&properties&&properties.data&&properties.data.properties&&properties.data.properties.length>0&&
            <Card className="shadow">
              <>
                <CardHeader className="border-0 pb-1">
                  <h3 className="m-0">Dashboard</h3>
                </CardHeader>
                
                <CardBody>
                <div className="d-flex modal-header py-0 mb-2 align-items-center">
                      <div className="d-flex">
                          <RadioButtonGroup labels={["Unscheduled","Scheduled", "Completed"]} colors={["danger", "warning", "success"]} icons={[<ReportIcon />, <EngineeringIcon />, <TaskAltIcon />]} size="sm" outline={true} setter={(val) => setViewApplications(val)}/>                   
                      </div>  
                      <div className="d-flex  align-items-center">
                        <i className="fa-solid fa-clock"></i>
                        <strong className="mx-3" style={{fontSize:"0.75rem"}}>Time period</strong>
                        <RadioButtonGroup labels={["Today","This week", "This month"]} setter={(val) => setTimePeriod(val)} colors={["primary", "primary", "primary"]}  datePicker={periodValue} setDatePicker={setPeriodValue}/>
                      </div>
                  </div>
                <Row>
                <Col xl="12" lg="12">
                {viewApplications == 1 ?
                  <>
                  
                  {roundsResponse && roundsResponse.success && roundsResponse["data"] &&
                 <div className="pl-lg-3">
                 <Row>
                   <Col lg="12" md="12" className="mt-md-5 mt-lg-0" style={{minWidth:"50%", minHeight:"40vh"}}>
                         { <GoogleMapComponent  draggable={false} markers={roundsResponse.data ? roundsResponse.data.map((item,idx) => {return {"type":"round","gps":item.hole_gps, "customer_name":item.customer_name, "address":item.property_address, "date":item['application'].date, "id": item['application'].round_id, "icon": markerPins[viewApplications-1]}}) : null} onClickSetStatusDone={onClickSetStatusDone} onViewRoundClicked={onAddApplicationClicked}/>}
                        </Col>  
                 </Row>
               </div>

                  }
                  </>
                  :
                  <>
                  {viewApplications == 2 ?
                  <>
                  {inProgressApplications && inProgressApplications.success && inProgressApplications.data &&
                    <div className="pl-lg-3">
                      <Row>
                      <Col lg="12" md="12" className="mt-md-5 mt-lg-0" style={{minWidth:"50%", minHeight:"40vh"}}>
                       <GoogleMapComponent  draggable={false} markers={inProgressApplications.data ? inProgressApplications.data.map((item,idx) => {return {"type":"scheduled","gps":item.hole_gps, "customer_name":item.customer_name, "address":item.property_address , "date":item.application.date, "worker": item.worker_name, "id": item.application.id, "icon": markerPins[viewApplications-1]}}) : null} onClickSetStatusDone={onClickSetStatusDone} onViewApplicationClicked={onViewApplicationClicked}/>
                    </Col>  
                      
                      </Row>
                    </div>
                  }
                  </>
                  :
                  <>
                  {completedApplications && completedApplications.success && completedApplications.data &&
                    <div className="pl-lg-3">
                      <Row>
                      <Col lg="12" md="12" className="mt-md-5 mt-lg-0" style={{minWidth:"50%", minHeight:"40vh"}}>
                       <GoogleMapComponent  draggable={false} markers={completedApplications.data ? completedApplications.data.map((item,idx) => {return {"type":"completed","gps":item.hole_gps, "customer_name":item.customer_name, "address":item.property_address , "date":item.application.date, "worker": item.worker_name, "id": item.application.id, "icon": markerPins[viewApplications-1]}}) : null} onClickSetStatusDone={onClickSetStatusDone} onViewApplicationClicked={onViewApplicationClicked}/>
                    </Col>  
                      
                      </Row>
                    </div>
                  }
                  </>
                  }
                  </>
                }
          </Col>
          <Col  xl="12" lg="12">
              <div className="pl-lg-3 pt-5">
              <Row>  
              <Col lg="12">
              <Table className="align-items-center  table-sm" responsive>
                {viewApplications == 1 ?
                <>
                  <thead className="thead-dark">
                  <tr >
                  <th scope="col">Round</th>   
                    <th scope="col">Suggested <br/> date</th>     
                    <th scope="col">Last <br/>appointment</th>   
                    <th scope="col">Customer</th>    
                    <th scope="col">Program</th>
                    <th scope="col">QT check</th>     
                    <th scope="col">Worker</th>   
                    <th scope="col" className="text-right"  style={{width:"1vw", overflow:"hidden", textOverflow:"ellipsis"}}></th>
                  </tr>
                </thead>
                <tbody>
                {roundsResponseOrdered &&
                      roundsResponseOrdered.slice(startIndexRounds, endIndexRounds).map((round, idx) => (
                      <tr id={round["application"]["id"]} tabIndex="0" key={idx}>
                      <td scope="row">{"Round "+round["round_number"]}</td>
                      <th scope="row" style={{maxWidth:"5vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                      {getFormattedTime(round["application"]["date"])}
                      </th>
                      <td>
                      {round["last_appl_completed"] !== 0 ? getFormattedTime(round["last_appl_completed"]) : "-"}
                      </td>
                      <td style={{maxWidth:"10vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                      <div>
                      {round["customer_name"]}
                      <div className="d-block">
                      {round["property_address"]}
                      </div>
                      </div>      
                      </td>

                      {/* <td>
                        <div>
                          <strong className="text-primary">N -&nbsp;</strong>
                        {round["round"]["n_q"] && round["round"]["n_q"].toFixed(2)}
                        </div>
                        <div>
                        <strong className="text-primary">P -&nbsp;</strong>
                        {round["round"]["p_q"] && round["round"]["p_q"].toFixed(2)}
                        </div>
                        <strong className="text-primary">K -&nbsp;</strong>
                        {round["round"]["k_q"] && round["round"]["k_q"].toFixed(2)}
                      </td> */}
                      <td style={{maxWidth:"12vw"}}>
                     {/* {programBuildersNames ?
                      <DropdownList header={roundProgram[round["round"].id] ? roundProgram[round["round"].id] : "Select..."} items={Object.keys(programBuildersNames)}  setter={(programName) => handleSetroundProgram(round["round"], programName)}></DropdownList>
                      :
                      <div className="mt-4 mb-4 ml-2">
                      <InfoBox text={["No program registered.", "Go to products to add your programs."]}/>
                      </div>
                      } */}
                      {round.program_name}
                      </td>
                      {/* <td style={{maxWidth:"10vw"}}>
                        <div className="d-block">
                          {selectedProgramsProducts && selectedProgramsProducts[round["round"].id] && selectedProgramsProducts[round["round"].id]["product"].map((product,_) => (
                            <strong className="d-block" id={product.id} style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                           { product.name}
                            </strong>
                          ))
                          }
                        </div>
                      </td> */}
                      {/* <td> {selectedProgramsProducts && selectedProgramsProducts[round["round"].id] && selectedProgramsProducts[round["round"].id]["quantity"].map((quantity,idx) => (
                            <strong className="d-block" id={'q-'+idx} style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                           { quantity && (1000* quantity/round["area"]).toFixed(2)}
                            </strong>
                          ))
                          }</td>
                      <td>
                        {round["area"]}
                      </td>
                      <td> {selectedProgramsProducts && selectedProgramsProducts[round["round"].id] && selectedProgramsProducts[round["round"].id]["quantity"].map((quantity,idx) => (
                            <div className="d-block" id={'q-'+idx} style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                           { quantity && quantity.toFixed(2)}
                            </div>
                          ))
                          }</td> */}
                      {/* <td style={{maxWidth:"10vw"}}>
                        <div className="d-block">
                          {selectedProgramsProducts && selectedProgramsProducts[round["round"].id] && selectedProgramsProducts[round["round"].id]["product"].map((product,idx) => (
                            <div className="d-block" id={product.id} style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                           { (product.price/product.quantity * selectedProgramsProducts[round["round"].id]["quantity"][idx]).toFixed(2) + "$"}
                            </div>
                          ))
                          }
                        </div>
                      </td> */}
                        {/* <th>
                        {selectedProgramsProducts && selectedProgramsProducts[round["round"].id] && selectedProgramsProducts[round["round"].id]["product"].reduce((total, product, idx) => {
                            return total + (product.price / product.quantity * selectedProgramsProducts[round["round"].id]["quantity"][idx]);
                          }, 0).toFixed(2) + "$"}
                        </th> */}
                      <th scope="col" className={"text-"+QTCHECK_color[round["application"]["qt_check"]]}>{QTCHECK[round["application"]["qt_check"]]}</th>
                      <td style={{maxWidth:"12vw"}}>
                      {workersNames && <DropdownList  header={roundWorker[round["application"].id] ? roundWorker[round["application"].id] : "Select..."} items={Object.values(workersNames)}  setter={(worker) => handleSetRoundWOrker(round["application"].id, worker)}></DropdownList>}
                      </td>
                      <td className="text-right mr-0 pr-0">
                      <Button
                          className="text-grey"
                          type="button"
                          size="sm"
                          color=""  
                          onClick={() => onAddApplicationClicked(round["application"]["round_id"])}
                          
                      >
                          <i className="fa-solid fa-eye fa-lg"></i>
                      </Button>
                      <Button
                          type="button"
                          size="sm"
                          color="primary"  
                          onClick={() => onScheduleApplicationClicked(round["application"])}
                          
                      >
                        <small>schedule</small>
                         
                      </Button>
 
                      </td>
                      </tr>

                ))}
                {roundsResponse && roundsResponse.success && roundsResponse["data"] && roundsResponseOrdered && roundsResponseOrdered.length !==0 &&
                  <div className="mt-3">
                      <PaginationComponent data={roundsResponseOrdered} itemsPerPage={5} getStart={setStartIndexRounds} getEnd={setEndIndexRounds}/>
                  </div>
                }
                {!roundsResponseOrdered || roundsResponseOrdered.length === 0 &&
                  <tr>
                    <td colSpan="100%" style={{padding:'0px',margin:'0px',fontSize:'15px'}}>
                      <div className="mt-4 mb-4 ml-2">
                        <InfoBox text={["You are up to date with your unscheduled applications."]}/>
                      </div>
                    </td>
                  </tr>

                }
                </tbody>
                </>
                :
                <>
                {viewApplications ==2 ?
                  <>
                  <thead className="thead-dark">
                        <tr>
                          <th scope="col">Round</th>   
                          <th scope="col">Date</th>   
                          <th scope="col" style={{ position: 'sticky', left: '0'}}>Customer</th>     
                          <th scope="col">Program<br/>Products<br/>Jobs</th>    
                          <th scope="col">Rate&nbsp;<small>/1000sqft</small></th>    
                          <th scope="col" style={{width:"8rem"}}>Carrier volume<br/><small>gal/1000sqft</small></th>    
                          <th scope="col">Dillution rate<br/><small>lbs or oz/gal</small></th>    
                          <th scope="col">Area <br/><small>[sqft]</small></th> 
                          <th scope="col">Total <br/> product <br/> quantity <small>[lbs/oz]</small>&nbsp;</th>
                          <th scope="col">Worker</th>   
                          <th scope="col" className="text-right"  style={{width:"1vw", overflow:"hidden", textOverflow:"ellipsis"}}></th>
                        </tr>
                      </thead>
                      <tbody>
                  {inProgressApplications.data.slice(startIndexApp, endIndexApp).map((application, idx) => (
                    <tr id={application["application"]["id"]} tabIndex="0" key={idx}>
                        <td scope="row">{"Round "+application["round_number"]}</td>
                      <th>{getFormattedTime(application["application"]["date"])}</th>
                      <td style={{ position: 'sticky', left: '0', background: 'white'}}>
                        <div >
                          {application["customer_name"]}
                        <div className="d-block">
                        {application["property_address"]}
                        </div>
                        </div>
                      </td>

                      {/* <td>
                          <div>
                            <strong className="text-primary">N -&nbsp;</strong>
                          {application["application"]["total_n"] && application["application"]["total_n"].toFixed(2)}
                          </div>
                          <div>
                          <strong className="text-primary">P -&nbsp;</strong>
                          {application["application"]["total_p"] && application["application"]["total_p"].toFixed(2)}
                          </div>
                          <strong className="text-primary">K -&nbsp;</strong>
                          {application["application"]["total_k"] && application["application"]["total_k"].toFixed(2)}
                        </td>
                        <td>-</td> */}
                        <td style={{maxWidth:"12vw"}}>
                        <div>
                          <strong>{application["program_name"]}</strong>
                        {productsNames && application["products"] && application["products"].map((product, idx) => (
                          <span id={product.id} style={{overflow:"hidden", textOverflow:"ellipsis"}} className="d-block">
                            {productsNames[product.product_id]}
                            
                          </span>
                        ))}
                        </div>
                        </td>
                        <td>
                        <div>&nbsp;</div>
                        {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {product.quantity ? (1000*product.quantity/application["area"]).toFixed(2): 0.00}
                          </div>
                        ))}
                        
                        </td>
                        <td style={{minWidth:"8rem"}}>
                        <div>&nbsp;</div>
                        {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {productsDicts[product.product_id] && productsDicts[product.product_id]["type"] != "granular" ?
                              <>
                              {application["application"]["id"] == editProductIdx[0] && idx == editProductIdx[1] ?
                              <div className="d-flex align-items-center">
                                <div style={{minWidth:"70px"}}>
                              <ValidationInput type="text" placeholder="carrier vol" value={editProductCarrierVolume} setter={setEditProductCarrerVolume} validationType="NUMERIC" message={"Invalid volume"} getValidState={setEditProductCarrierVolumeValid}/>
                              </div>
                              <div className="ml-1">
                              <Button
                                  color="success"
                                  className=""
                                  type="button"
                                  size="sm"
                                  disabled={!(editProductCarrierVolumeValid)}
                                  onClick={() => doUpdateProduct2Application(product["product_id"], application["application"]["id"])}
                                  
                              >
                                  <i className="fa-solid fa-check"></i>
                              </Button>
                                  <Button
                                  color="light"
                                  
                                  type="button"
                                  size="sm"
                                  onClick={onEditCancelProdiuctClicked}
                                  
                              >
                                  <i className="fa-solid fa-xmark"></i>
                              </Button>
                              </div>
                              </div>
                              :
                              <div>
                              {product.carrier_volume ? product.carrier_volume: "-"}
                              &nbsp;&nbsp;&nbsp;
                                <i className="fa-solid fa-pen" onClick={() => {setEditProductIdx([application["application"]["id"], idx]); setEditProductCarrerVolume(product["carrier_volume"])}} style={{cursor: "pointer"}}></i>
                              </div>
                              }

                              </>
                              :
                              "-"
                            }
                            
                          </div>
                        ))}
                        
                        </td>
                        <td>
                        <div>&nbsp;</div>
                        {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {application["application"]["id"] == editProductIdx[0] && idx == editProductIdx[1] ?
                            <>
                              {editProductCarrierVolumeValid && editProductCarrierVolume? (product.quantity/editProductCarrierVolume).toFixed(2) : "-"}
                              </>
                            :
                            <>
                              {product.carrier_volume  && product.quantity? ((1000*product.quantity/application["area"])/product.carrier_volume).toFixed(2) : "-"}
                              </>
                            }
                          </div>
                        ))}
                        
                        </td>
                        <td>
                        {application["area"]}
                      </td>
                      <td>
                      <div>&nbsp;</div>
                      {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {product.quantity ? product.quantity.toFixed(2): 0.00}
                          </div>
                        ))}
                      </td>
                      {/* <td>
                      {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {getProductPrice(product.product_id, product.quantity).toFixed(2) + "$"}
                          </div>
                        ))}
                        </td> */}
                        {/* <th>
                        {application["products"] && getProductsPrices(application["products"]) + "$"}
                        </th> */}
                      <td style={{maxWidth:"12vw",overflow:"hidden", textOverflow:"ellipsis"}}>{application["worker_name"]}</td>
                      <td className="text-right mr-0 pr-0">
                        <Button
                          className="text-grey"
                          type="button"
                          size="sm"
                          color=""  
                          onClick={() => onViewApplicationClicked(application["application"]["id"])}
                          
                          >
                          <i className="fa-solid fa-eye fa-lg"></i>
                        </Button>
                         
                        {/* changes here */}
                        {/* the application date has passed? then i can mark it as done (dates are converted to compare only the days,months,years) */}
                        {new Date().setHours(0, 0, 0, 0) >= new Date(application["application"]["date"] * 1000).setHours(0, 0, 0, 0) ? (
                        <Button style={{marginRight:'5px'}} className="btn-sm" color="success" onClick={() => onClickSetStatusDone(application["application"]["id"], "Done")}>
                          <i className="fa-solid fa-circle-check"></i>
                              &nbsp;Done
                        </Button>
                        )
                        :
                        <Button style={{ visibility: 'hidden', marginRight: '5px' }} className="btn-sm">
                            <i className="fa-solid fa-circle-check"></i>
                            &nbsp;Done
                        </Button>
                        }
                      </td>
                    </tr>
                  ))}

                  {inProgressApplications && inProgressApplications.success && inProgressApplications["data"] && inProgressApplications["data"].length > 0 &&
                    <div className="mt-3">
                        <PaginationComponent data={inProgressApplications["data"] } itemsPerPage={5} getStart={setStartIndexApp} getEnd={setEndIndexApp}/>
                    </div>
                  }
                  {inProgressApplications.data.length == 0 &&
                    <tr>
                      <td colSpan="100%" style={{padding:'0px',margin:'0px',fontSize:'15px'}}>
                        <div className="mt-4 mb-4 ml-2">
                          <InfoBox text={["You are up to date with your applications."]}/>
                        </div>
                      </td>
                    </tr>
                  }
                    </tbody>
                  </>

                :
                <>
                <thead className="thead-dark">
                      <tr >
                      <th scope="col">Round</th>   
                      <th scope="col">Date</th>   
                        <th scope="col">Customer</th>     
                        <th scope="col">Program</th>    
                        <th scope="col">Worker <br/>Time</th>      
                        <th scope="col">Total Cost</th>    
                        <th scope="col">Revenue</th>    
                        <th scope="col">Feedback</th>   
                        <th scope="col" className="text-right"  style={{width:"1vw", overflow:"hidden", textOverflow:"ellipsis"}}></th>
                      </tr>
                    </thead>
                    <tbody>
                {completedApplications.data.slice(startIndexCompleted, endIndexCompleted).map((application, idx) => (
                    <tr id={application["application"]["id"]} tabIndex="0">
                    <td scope="row">{"Round "+application["round_number"]}</td>
                    <th>
                      <div>
                        
                      {/* {application["feedback"] == null && isDifferenceMoreThanTenDays(application["application"]["status"]["UpdatedAt"]) &&
                    <div style={{color:"#fa860f"}} className="d-flex align-items-center mt-0">
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      <small style={{fontWeight:"bold"}}>&nbsp;add feedback</small>
                    </div>
                    } */}
                    {getFormattedTime(application["application"]["date"])}
                        </div></th>
                    <td>
                      <div>
                        {application["customer_name"]}
                      <div className="d-block">
                      {application["property_address"]}
                      </div>
                      </div>
                    </td>

                    {/* <td>
                        <div>
                          <strong className="text-primary">N -&nbsp;</strong>
                        {application["application"]["total_n"] && application["application"]["total_n"].toFixed(2)}
                        </div>
                        <div>
                        <strong className="text-primary">P -&nbsp;</strong>
                        {application["application"]["total_p"] && application["application"]["total_p"].toFixed(2)}
                        </div>
                        <strong className="text-primary">K -&nbsp;</strong>
                        {application["application"]["total_k"] && application["application"]["total_k"].toFixed(2)}
                      </td> */}
                      <td><strong>{application["program_name"]}</strong></td>
                      {/* <td style={{maxWidth:"12vw"}}>
                        
                        {productsNames && application["products"] && application["products"].map((product, idx) => (
                          <strong id={product.id} style={{overflow:"hidden", textOverflow:"ellipsis"}}  className="d-block">
                            {productsNames[product.product_id]}
                            
                          </strong>
                        ))}
                        </td>
                        <td>
                        {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {product.quantity ? (1000*product.quantity/application["area"]).toFixed(2): 0.00}
                          </div>
                        ))}
                        </td> */}
                        {/* <td>
                        {application["area"]}
                      </td>
                      <td>
                      {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {product.quantity?product.quantity.toFixed(2):0.00}
                          </div>
                        ))}
                      </td> */}
                      {/* <td>
                      {application["products"] && application["products"].map((product, idx) => (
                          <div id={product.id}>
                            {getProductPrice(product.product_id, product.quantity).toFixed(2)+"$"}
                          </div>
                        ))}
                        </td> */}
                        <td>{application["worker_name"]}</td>
                        <th>
                        {application["products"] && getProductsPrices(application["products"])+"$"}
                        </th>
                        <td>-</td>
                        <td>
                        {isDifferenceMoreThanTenDays(application["application"]["status"]["UpdatedAt"]) ?
                        <>
                        {application["feedback"] ?
                          <div className="d-flex align-items-center">
                          <Rating
                          size="small"
                          name="text-feedback"
                          value={application["feedback"]["rating"]-1}
                          readOnly
                          precision={1}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                          />
                          
                          <small>&nbsp;Done</small>
                          </div>

                        :
                        <Rating
                        size="small"
                        name="text-feedback"
                        value={applicationFeedback[application["application"]["id"]]}
                        onChange={(event, newValue) => {
                          setApplicationFeedback(prevState => ({...prevState, [application["application"]["id"]]:newValue}));
                          }}
                      
                        precision={1}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        }
                        </>
                        
                            :
                            <>
                            <strong className="text-warning">
                            {"Feedback"}<br/>{ " in "+
                            computeDifferenceInDays(application["application"]["status"]["UpdatedAt"])
                            + " days"}
                            </strong>
                           </>
                          }
                        </td>
                    <td  className="text-right mr-0 pr-0">
                      <div className="d-flex text-right justify-content-end">
                        {!application["feedback"] && isDifferenceMoreThanTenDays(application["application"]["status"]["UpdatedAt"])  &&
                      <Button
                      className="btn-sm"
                      type="button"
                      size="sm"
                      color="success"  
                      onClick={() => onAddFeedbackClicked(application["application"]["id"])}
                      
                      >
                     <i className="fa-solid fa-check"></i>
                    </Button>
                        }
                    <Button
                      className="text-grey"
                      type="button"
                      size="sm"
                      color=""  
                      onClick={() => onViewApplicationClicked(application["application"]["id"])}
                      
                      >
                      <i className="fa-solid fa-eye fa-lg"></i>
                    </Button>

                    </div>
                    </td>
                  </tr>
                      ))

                  }
                    {completedApplications && completedApplications.success && completedApplications["data"] && completedApplications["data"].length > 0 &&
                      <div className="mt-3">
                          <PaginationComponent data={completedApplications["data"] } itemsPerPage={5} getStart={setStartIndexCompleted} getEnd={setEndIndexCompleted}/>
                      </div>
                      }
                {completedApplications.data.length == 0 &&
                  <tr>
                    <td colSpan="100%" style={{padding:'0px',margin:'0px',fontSize:'15px'}}>
                      <div className="mt-4 mb-4 ml-2">
                        <InfoBox text={["You have no completed applications in the selected time interval."]}/>
                      </div>
                    </td>
                  </tr>
                }
                </tbody>
                </>
                }
                
                </>
                }
                  </Table>
                  </Col>
                  

          </Row>
          </div>
          </Col>
          </Row>
            </CardBody>
            </>  
        </Card>
            }    
   
          </Col>   
            
        </Row>
      </Container>
      {openMapModal &&
        <ModalDialog 
            size="lg"
            status={openMapModal} 
            setter={setOpenMapModal} 
            title={"Upcoming application for " + viewMapApplication["customer_name"]}
            content={  <HoleViewOnMap lat={viewMapApplication["hole_gps"][0]} lng={viewMapApplication["hole_gps"][1]} />}                              
        />
        
        }
    </>
  );
};

export default Index;
