import {getRequest, URL_SERVER} from './../requests'


export default async function listCustomers(token, lawnManagementCompanyId, skip, limit){
    try {

        return await getRequest(URL_SERVER + 'customers/'+lawnManagementCompanyId, token)
    } catch (error) {
        console.error(error);
    }

}