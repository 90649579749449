/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components

import Sidebar from "components/Sidebar/Sidebar.js";
import { useNotification } from 'components/NotificationContext/NotificationContext';
import { useTokenContext } from "App"
import routes from "routes.js";
import {URL_SERVER} from './../api/requests'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation(); 

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const {token, setToken} = useTokenContext();
  const navigate = useNavigate();
  const { setNotification } = useNotification();

  const checkToken = async () => {
    let tkn = ""
    if (!token) {
      tkn = localStorage.getItem("token")
    }else{
      tkn = token
    }
    const headers = {
      'Accept-Language': 'en-GB',             
      'RequestId': uuidv4(),
      }

    if(!tkn){
      setNotification('Unauthorized access! Please Login!');
      navigate('/auth/login');
    } else {
    if(tkn){
      headers['Authorization'] = "Bearer " + tkn
    }  
    try{
      axios.get(URL_SERVER+"token/verify", {
          headers: headers
      }).then((response) => {
        if(response.data.success){
          setToken(tkn)
        } else {
          setNotification('Unauthorized access! Please Login!');
          navigate('/auth/login');
        }
      })  
     
    }catch(error){
      setNotification('Unauthorized access! Please Login!');
      navigate('/auth/login');
    }
    }
  }

  checkToken()
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const [hideSidebar,setHideSidebar]=useState(false)
  const [isMobile, setIsMobile]=useState(false)

  //check if the device user is using is mobile
  //if he's on mobile, the back button won't appear
  //if he's on mobile, the sidebar will be handled by react expand="md" in sidebar
  //this way, give the user to hide/show the sidebar if he's on desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setHideSidebar(false)
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
     {!hideSidebar && 
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/quantum-turf-logo.png"),
          imgAlt: "...",
        }}
      />
    }
      <div className="main-content" ref={mainContent}>
        {!isMobile && 
          <div 
            style={{
              position:'absolute', 
              left: '3px', 
              top: '5px',
              zIndex: '1000',
              backdropFilter: 'blur(5px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button 
              style={{
                padding:'5px 12px',
                borderRadius:'10px',
                border: '0px',
                backgroundColor: '#ffffff',
                opacity: '0.75'
              }}
              onClick={()=>setHideSidebar(!hideSidebar)}
            >
              {!hideSidebar ?
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              :
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              }
            </button>
          </div>
        }

        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
      </div>
    </>
  );
};

export default Admin;
