import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    CardBody,
  } from "reactstrap";
  // core components
import PageHeader from "components/Headers/PageHeader.js";
import Alert from "components/Alerts/Alert";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import { useParams } from 'react-router-dom';
import { getLCMID } from "api/company/getLCMID";
import getRoundProgram from "api/rounds/getRoundProgram";
import AddApplicationProducts from "../property/AddApplicationProducts";
import generateRecomendation from "api/products/generateRecomendation";
import addProduct2ProgramBuilder from "api/products/addProduct2ProgramBuilder";
import deleteProduct2ProgramBuilder from "api/products/deleteProduct2ProgramBuilder";
import updateProduct2ProgramBuilder from "api/products/updateProduct2ProgramBuilder";

  const ConfigureRound = () => {

    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const {token, setToken} = useTokenContext();
    const navigate = useNavigate();
    const { id } = useParams();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState(null);
    const [round, setRound] = useState(null)
    const [recommandation, setRecommendation] = useState(null)
    const [currentProductsFertiliser, setCurrentProductsFertiliser] = useState([]);
    const [currentProductsWeed, setCurrentProductsWeed] = useState([]);
    const [currentProductsPest, setCurrentProductsPest] = useState([]);
    const [nrOfProductsAdded, setNrOfProductsAdded] = useState(0)
    const [nrOfProductsDeleted, setNrOfProductsDeleted] = useState(0)
    const [nrOfProductsUpdated, setNrOfProductsUpdated] = useState(0)
    const [fertiliserExistent, setFertilizerExistent] = useState([])
    const [weedExistent, setWeedExistent] = useState([])
    const [pestExistent, setPestExistent] = useState([])
    const [addProduct2BuilderRsp, setAddProduct2BuilderRsp] = useState(null)
    const [deleteProduct2BuilderRsp, setDeleteProduct2BuilderRsp] = useState(null)
    const [updateProduct2BuilderRsp, setUpdateProduct2BuilderRsp] = useState(null)

    async function getLawnManagementId () {
      setLawnManagementCompanyId(await getLCMID(token))
    }

    const doGetRecommendation = async () => {
        try{
            let rsp = await generateRecomendation(token, id);
            setRecommendation(rsp.data);
        } catch (error) {
            console.log("Error get recommendation:", error);
        }
    }  

    const doGetRound = async () => {
        try {
            let rsp = await getRoundProgram(token, id)
            setRound(rsp)
        } catch (error) {
            console.log("Error on get round:", error)
        }
    }

    const doAddProduct2ProgramRound = async (prodId, quantity) => {
        try {
           let rsp = await addProduct2ProgramBuilder(token, round.data.round.hole_id, prodId, lawnManagementCompanyId, id, quantity)
           setAddProduct2BuilderRsp(rsp)
           
        } catch (error) {
            console.log("Error on add product 2 program round: ", error)
        }
    }

    const doDeleteProduct2ProgramRound = async (prodId) => {
        try {
           let rsp = await deleteProduct2ProgramBuilder(token, round.data.round.id, prodId)
           setDeleteProduct2BuilderRsp(rsp)
           
        } catch (error) {
            console.log("Error on add product 2 program round: ", error)
        }
    }

    const doUpdateProduct2ProgramRound = async (prodId, quantity) => {
        try {
           let rsp = await updateProduct2ProgramBuilder(token, prodId, round.data.round.id, parseFloat(quantity))
           setUpdateProduct2BuilderRsp(rsp)
           
        } catch (error) {
            console.log("Error on add product 2 program round: ", error)
        }
    }

    async function processAddProductsSequentially(products) {
        if (products.length === 0) {
            // Dacă nu mai sunt produse de procesat, ieșim din funcție recursivă
            return;
        }
        const entry = products[0];
        await doAddProduct2ProgramRound(entry.product_id, entry.quantity);
    
        await processAddProductsSequentially(products.slice(1));
    }


    async function processDeleteProductsSequentially(products) {
        if (products.length === 0) {
            // Dacă nu mai sunt produse de procesat, ieșim din funcție recursivă
            return;
        }
        const entry = products[0];
        await doDeleteProduct2ProgramRound(entry.id);
    
        await processDeleteProductsSequentially(products.slice(1));
    }


    async function updateProductsSequentially(products) {
        if (products.length === 0) {
            // Dacă nu mai sunt produse de procesat, ieșim din funcție recursivă
            return;
        }
    
        const entry = products[0];
        console.log(entry)
        await doUpdateProduct2ProgramRound(entry.product_id, entry.quantity);
    
        await updateProductsSequentially(products.slice(1));
    }

    function onSaveRoundClicked(){
        let combinedList = [...currentProductsFertiliser, ...currentProductsWeed, ...currentProductsPest]
        let existentList = [...fertiliserExistent, ...weedExistent, ...pestExistent]

       
        const productsAdded = combinedList.filter(product2 => 
            !existentList.some(product1 => product1.product.id === product2.product_id)
        );

        const productsUpdated = combinedList.filter(product2 => 
            existentList.some(product1 => product1.product.id === product2.product_id && product1.rate !== product2.quantity)
        );

        const productsRemoved = existentList.map((prod) => prod.product).filter(product1 => 
            !combinedList.some(product2 => product2.product_id === product1.id)
        );

        setNrOfProductsAdded(productsAdded.length)
        setNrOfProductsDeleted(productsRemoved.length)   
        setNrOfProductsUpdated(productsUpdated.length)       


        processAddProductsSequentially(productsAdded) .then(() => {
            console.log("Toate produsele au fost adăugate în program secvențial.");
        })
        .catch(error => {
            console.error("Eroare în procesul de adăugare a produselor secvențial:", error);
        });
        processDeleteProductsSequentially(productsRemoved) .then(() => {
            console.log("Toate produsele au fost sterse în program secvențial.");
        })
        .catch(error => {
            console.error("Eroare în procesul de adăugare a produselor secvențial:", error);
        });

        updateProductsSequentially(productsUpdated) .then(() => {
            console.log("Toate produsele au fost updatate în program secvențial.");
        })
        .catch(error => {
            console.error("Eroare în procesul de adăugare a produselor secvențial:", error);
        });

        if(productsAdded.length == 0 && productsRemoved.length == 0 && productsUpdated.length == 0){
            navigate(-1)
        }

    }


    useEffect(() => {
        if(lawnManagementCompanyId){
            doGetRound()
            doGetRecommendation()
        }
    }, [lawnManagementCompanyId])

    useEffect(() => {
        if(id && token){
            getLawnManagementId()
        }
    }, [id, token])

    useEffect(() => {
        if(round){
            if(round.data.products){
                round.data.products.forEach((product, idx) => {
                    if (product.category === "fertiliser") {
                        setFertilizerExistent(prevState => [...prevState, {"product": product, "rate": round.data.rates[idx]}]);
                    } else if (product.category === "weed_control") {
                        setWeedExistent(prevState => [...prevState,  {"product": product, "rate": round.data.rates[idx]}]);
                    } else if (product.category === "pest_control") {
                        setPestExistent(prevState => [...prevState,  {"product": product, "rate": round.data.rates[idx]}]);
                    }
                });
            }
        }
    },[round])

    useEffect(() => {
        if(addProduct2BuilderRsp){
            if(addProduct2BuilderRsp.success){
                setNrOfProductsAdded(prevState => prevState-1)
            } else {
                setAlert(addProduct2BuilderRsp.message)
                setAlertColor("danger")
            }
        }
    },[addProduct2BuilderRsp])

    useEffect(() => {
        if(deleteProduct2BuilderRsp){
            if(deleteProduct2BuilderRsp.success){
                setNrOfProductsDeleted(prevState => prevState-1)
            } else {
                setAlert(deleteProduct2BuilderRsp.message)
                setAlertColor("danger")
            }
        }
    },[deleteProduct2BuilderRsp])

    useEffect(() => {
        if(updateProduct2BuilderRsp){
            if(updateProduct2BuilderRsp.success){
                setNrOfProductsUpdated(prevState => prevState-1)
            } else {
                setAlert(updateProduct2BuilderRsp.message)
                setAlertColor("danger")
            }
        }
    },[updateProduct2BuilderRsp])

    useEffect(() => {
        if(nrOfProductsAdded == 0 && nrOfProductsDeleted == 0 && nrOfProductsUpdated ==0 &&(addProduct2BuilderRsp || deleteProduct2BuilderRsp || updateProduct2BuilderRsp)){
            setAlert("Saved changes!")
            setAlertColor("success")
            navigate(-1)
        }
    },[nrOfProductsAdded, nrOfProductsDeleted, nrOfProductsUpdated,addProduct2BuilderRsp,deleteProduct2BuilderRsp, updateProduct2BuilderRsp])

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className={alert ? "mt--8": "mt--7"} fluid>
        {alert&&
                <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
            }
            <div className="col">
                <Row>
                <Col lg="12" className="mb-2">
                {round && round.data && <BreadcrumbNavigation active={"Round " + round.data.round.round_number} past={[{"name":"ProgramBuilder", "link":"/admin/programBuilder"}]}/>}
                </Col>
                </Row>
                <Card className="bg-secondary shadow">

                    <CardHeader className="border-0 d-flex modal-header">
                        <div className="d-flex align-items-center">
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); navigate(-1)}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                        <h3>
                        {round && round.data && "Configure round " + round.data.round.round_number}
                        </h3>

                        </div>
                    </CardHeader>
                {round && round.data &&
                   <CardBody>
                    {round.data.round.tags && round.data.round.tags.includes("fertiliser") &&
                    <Row className="mb-5">
                    <Col lg="12">
                    <AddApplicationProducts recommandation={recommandation} round={round["data"].round} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsFertiliser} productsType="fertiliser" title="Fertilization plan" hideQuantity={true} currentProductsParent={fertiliserExistent} area={1000}/>
                    </Col>
                    </Row>
                    }
                    {round.data.round.tags && round.data.round.tags.includes("weed") &&
                   <Row className="mb-5">
                    <Col lg="12">
                    <AddApplicationProducts round={round["data"].round} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsWeed} productsType="weed_control" title="Weed control plan" hideQuantity={true} currentProductsParent={weedExistent} area={1000}/>
                    </Col>
                    </Row>
                    }
                    {round.data.round.tags && round.data.round.tags.includes("pest") &&
                    <Row className="">
                    <Col lg="12">
                    <AddApplicationProducts round={round["data"].round} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsPest} productsType="pest_control" title="Pest control plan" hideQuantity={true} currentProductsParent={pestExistent} area={1000}/>
                    </Col>
                    </Row>
                    }
                    <Row className="justify-content-center">
                        <Col lg="12" >
                    <div className=" mt-5 ">
                    <Button
                            color="primary"
                            onClick={(e) =>{e.preventDefault(); onSaveRoundClicked()}}
                            size="md"
                            >
                        Save round
                            </Button>
                    </div>
                    </Col>
                    </Row>
                   </CardBody>
                 }
                </Card>
                
            </div>
        </Container>
        </>
    );
  };
  
  export default ConfigureRound;


    