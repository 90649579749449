import React, { useState, useEffect } from 'react';
import {Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = ({ data, itemsPerPage, getStart, getEnd }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    let start = (currentPage-1)*itemsPerPage
    let end = start + itemsPerPage
    getStart(start)
    getEnd(end)
  },[currentPage,itemsPerPage,data])

  return (
      <Pagination style={{ justifyContent: 'flex-end' }}>
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink previous onClick={() => handleClick(currentPage - 1)} />
        </PaginationItem>

        {[...Array(totalPages)].map((_, i) => (
          
          (currentPage == i +1|| currentPage == i || currentPage==i +2) &&
          <PaginationItem active={i + 1 === currentPage} key={i}>
            <PaginationLink onClick={() => handleClick(i + 1)}>
              {i+1}
            </PaginationLink>
          </PaginationItem>
          
        ))}

        <PaginationItem disabled={currentPage >= totalPages}>
          <PaginationLink next onClick={() => handleClick(currentPage + 1)} />
        </PaginationItem>
      </Pagination>
  );
};

export default PaginationComponent;
