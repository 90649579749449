import {useState, useEffect} from "react";
import { useTokenContext } from "App"
import {
    Card,
    Button,
    CardBody,
    Table,
    UncontrolledTooltip,
  } from "reactstrap";
import { getFormattedTime } from "utils/utils";
import Alert from "components/Alerts/Alert"
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";
import listNotes from "api/property/listNotes";
import ValidationInput from "components/ValidationInput/ValidationInput";
import addNote from "api/property/addNote";

  function PropertyDetailNotes({property}){
    const {token, setToken} = useTokenContext();
    const [notes, setNotes] = useState(null)
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState(null);
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])
    const [addNoteClicked, setAddNoteClicked] = useState(false)
    const [newNote, setNewNote] = useState("")
    const [newNoteValid, setNewNoteValid] = useState(true);
    const [addNoteRsp, setAddNoteRsp] = useState(null)

    function onAddNoteClicked(){
        setAddNoteClicked(true)
    }

    function onAddNoteAction(){
      if(newNote == ""){
        setAlertColor("danger")
        setAlert("You can not add an empty note!")
      } else {
        doAddNote();
      }
    }

    const doListNotes = async () => {
      try{
        let rsp = await listNotes(token, property["id"], "")
        setNotes(rsp)
      } catch (error) {
          console.log("Error on List Rounds:", error);
      }
  }

    const doAddNote = async () => {
      try {
        let rsp = await addNote(token,  property["id"], newNote)
        setAddNoteRsp(rsp)
      } catch (error) {
        console.log("Error on Add Rounds:", error);
      }
    }

    useEffect(() => {
      if(addNoteRsp){
        if(addNoteRsp.success){
          doListNotes();
          setAlert(addNoteRsp.message)
          setAlertColor("success")
          setNewNote(null)
          setNewNoteValid(true)
          setAddNoteClicked(false)
        } else {
          setAlert(addNoteRsp.message)
          setAlertColor("danger")
        }
      }
    },[addNoteRsp])


    useEffect(()=>{
      doListNotes()
    },[])
    
    return (
      <>

        <Card className=" bg-secondary shadow" style={{borderTop:0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardBody>
            {alert&&<Alert color={alertColor} text={alert} setter={setAlert}></Alert>}
              <div className="d-flex modal-header pt-1 pb-2">
                    <div className="d-flex align-items-center">
                      <h6 className="heading-small text-muted  mr-3">
                          Notes
                      </h6>
                      <i className="fa-regular fa-circle-question pb-2" id="unit-soil"></i>
                      <UncontrolledTooltip
                            target="unit-soil"
                        >
                      Additional information about the property
                        </UncontrolledTooltip>
                    </div>
                    <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddNoteClicked();}}
                        size="sm"
                        
                        >
                       + Add
                        </Button>

              </div>
              <div className="pl-lg-3">
              <Table className="align-items-center table-light table-sm" responsive>
                  <thead className="thead-dark">
                    <tr>                    
                      <th scope="col">Date</th>
                      <th scope="col">Notes</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                  { notes && notes.data &&
                        notes.data.slice(startIndex, endIndex).map((note, idx) => (                        
                        <tr id={"note-" + idx} key={idx}> 
                          <th>
                            {getFormattedTime(note.created_at)}
                          </th>                       
                          <td scope="row" style={{maxWidth:"40vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                              <Card className="p-2 my-1 shadow bg-secondary">
                                <div style={{ display: 'block', whiteSpace: 'pre-wrap' }}>{note.content}</div>
                              </Card>
                          </td>
                          <th scope="col"></th>
                        </tr>

                  ))}
                  {addNoteClicked &&
                    <tr style={{backgroundColor:"#f7f7f7"}}>
                    <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                      New note
                    </th>
                    <td style={{height:"20vh"}}>
                    <ValidationInput type="textarea" placeholder="Write something about the property..."  value={newNote} setter={setNewNote} validationType="FREETEXT" message={"Invalid characters in note"} getValidState={setNewNoteValid}/>
                    </td>
                    <td className="justify-content-end text-right">
                      <Button
                        color="success"
                        className=""
                        type="button"
                        size="sm"
                        disabled={!(newNoteValid)}
                        onClick={onAddNoteAction}
                    >
                        <i className="fa-solid fa-check"></i>
                    </Button>
                    </td>
                    </tr>
                    }
                    </tbody>
                  </Table>
              </div>
                {notes && notes.data &&
                  <div className="mt-3">
                      <PaginationComponent data={notes.data} itemsPerPage={constants["ROWS_PER_PAGE"]} getStart={setStartIndex} getEnd={setEndIndex}/>
                  </div>
                }
            </CardBody>
            
        </Card>

        </>
    );
  };
  
  export default PropertyDetailNotes;


    