// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Row,
  Col,
} from "reactstrap";
import Alert from "components/Alerts/Alert"
import {React,useState, useEffect} from "react"
import ValidationInput from "components/ValidationInput/ValidationInput";
import ModalDialog from "components/ModalDialog/ModalDialog";
import register from "api/register/register";
import getLegal from "api/legal/getLegal";
import { useNavigate } from "react-router-dom";
import { useNotification } from 'components/NotificationContext/NotificationContext';

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [legal_type1, setLegalType1] = useState("");
  const [legal_type2, setLegalType2] = useState("");
  const [legal_version1, setLegalVersion1] = useState("");
  const [legal_version2, setLegalVersion2] = useState("");
  const [legal_content1, setLegalContent1] = useState("");
  const [legal_content2, setLegalContent2] = useState("");
  const [modal_legal1, setModalLegal1] = useState(false);
  const [modal_legal2, setModalLegal2] = useState(false);
  const [legal1_checked, setLegal1_checked] = useState(false);
  const [legal2_checked, setLegal2_checked] = useState(false);
  const [alert, setAlert] = useState(null);
  const [registerResponse, setRegisterResponse] = useState("");

  const [validEmail, setValidEmail] = useState(true);
  const [validFirst, setValidFirst] = useState(true);
  const [validLast, setValidLast] = useState(true);
  const [validPassword, setValidPassword] = useState(true);

  const navigate = useNavigate();
  const { setNotification } = useNotification();

  //once at the beggining
  //get pp and t&c
  useEffect(()=>{
    getLegalFunc()
  },[]);

  const getLegalFunc = async() => {
    try{
      var legalResponse = await getLegal()
      console.log(legalResponse)
      setLegalType1(legalResponse.data[0].type)
      setLegalType2(legalResponse.data[1].type)
      setLegalVersion1(legalResponse.data[0].version)
      setLegalVersion2(legalResponse.data[1].version)     
      setLegalContent1(legalResponse.data[0].content)
      setLegalContent2(legalResponse.data[1].content)
    }catch (error) {
      console.error('Register request error:', error);
    }
  }
  function setUsernameFunction(usr){
    setEmail(usr)
  }
  function setPasswordFunction(pwd){    
    setPassword(pwd)
  }
  function setFirstnameFunction(usr){
    setFirstname(usr)
  }
  function setLastnameFunction(usr){    
    setLastname(usr)
  }
  function RegisterClick() {
    if(email=="" || first_name == "" || last_name == ""  || password == ""){
      setAlert(["Complete all mandatory fields (*)"])
    } else {
      if(!legal1_checked || !legal2_checked){
        setAlert(["You have to accept all our legal policies & conditions."])
      } else {
        onRegisterClick();
      }
    }
  }
  function LoginClick(){
    navigate("/auth/login")
  }
  function openModal1(e){
    e.preventDefault();
    setModalLegal1(true)
  }
  function openModal2(e){
    e.preventDefault();
    setModalLegal2(true)
  }

  const onRegisterClick = async () => {
    try {
      var registerResponse = await register(email, first_name, last_name, password, legal_type1, legal_version1, legal_type2, legal_version2)
      console.log(registerResponse)
      setRegisterResponse(registerResponse) 
    } catch (error) {
      console.error('Register request error:', error);
    }
  }
  useEffect(() => {
    if (registerResponse){
      if (registerResponse.success){
        //send legal approve        
        setNotification('Registration successful! Check your email inbox for confirmation!');
        navigate("/auth/login")
      }else{
        var messages;
        if(!registerResponse["errors"]){
          messages= [registerResponse.message]
        } else {
          messages = registerResponse.errors.map(error => error.error_message)
        }
        setAlert(messages)
      }
    }
  },[registerResponse]);

  const parseHtmlString = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const body = doc.body;
    const reactElements = Array.from(body.childNodes).map((node, index) => {
      return <div key={index} dangerouslySetInnerHTML={{ __html: node.outerHTML }} />;
    });

    return reactElements;
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">          
          <CardBody className="px-lg-5 py-lg-4">
                <div className="text-center text-muted mb-2">
                  <strong className="text-muted">Join Our Community!</strong>
                </div>  
                <Card className="text-center text-muted p-2 mb-2">
                  <small style={{ fontStyle: '', fontSize: '0.65em', }}>The registration process on this website is presently open to companies participating in our alpha testing. To join the whitelist, please fill out the "Get Early Access" form at 
                  
                  <a
                  className="font-weight-bold ml-1"
                  href="https://quantumturf.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  quantumturf.com.
                  </a>
                  </small>
                </Card>  
                
                {alert&&
                  alert.map((text,index)=>(
                    <Alert key={index} color="danger" text={text} setter={setAlert}></Alert>
                  ))
                }

            <Form role="form">
                <Row className="px-3 pb-3">
                <ValidationInput placeholder="Email" type="email" name="email" icon={<i className="ni ni-email-83" />} validationType="EMAIL" setter={setUsernameFunction} message="Invalid email" mandatory={true} getValidState={setValidEmail}/>
                </Row>
                <Row className="px-3 pb-3">
                  <ValidationInput placeholder="First Name" type="first_name" name="first_name" validationType="PERSONNAME" setter={setFirstnameFunction} icon={<i className="fa-solid fa-user"></i>} message="Invalid first name" mandatory={true} getValidState={setValidFirst}/>
                  </Row>
                <Row className="px-3 pb-3">
                  <ValidationInput placeholder="Last Name" type="last_name" name="last_name" validationType="PERSONNAME" setter={setLastnameFunction} icon={<i className="fa-solid fa-user"></i>} message="Invalid last name" mandatory={true} getValidState={setValidLast}/>
                  </Row>
                  <Row className="px-3 pb-3">
                  <ValidationInput placeholder="Password" type="password" name="password" validationType="PASSWORD" setter={setPasswordFunction} icon={<i className="ni ni-lock-circle-open" />} message="Invalid password" mandatory={true} getValidState={setValidPassword}/>                             
                  </Row>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      onClick={(e) => setLegal1_checked(!legal2_checked)}
                    />
                    
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >                      
                      <span className="text-muted">
                      <small style={{color:"red"}}>
                          *&nbsp;
                        </small>
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => {openModal1(e); setLegal1_checked(!legal1_checked)}}>
                          Privacy Policy
                        </a>
                      </span>
                      {modal_legal1 &&
                          <ModalDialog 
                              size="lg"
                              status={modal_legal1} 
                              setter={setModalLegal1} 
                              title={""}
                              content={ parseHtmlString(legal_content1)}                              
                          />
                      }
                    </label>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="custom2CheckRegister"
                      type="checkbox"
                      onClick={(e) => setLegal2_checked(!legal2_checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="custom2CheckRegister"
                    >
                      <span className="text-muted">
                      <small style={{color:"red"}}>
                          *&nbsp;
                        </small>
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => {openModal2(e); setLegal2_checked(!legal2_checked)}}>
                          Terms & Conditions
                        </a>
                      </span>
                      {modal_legal2 &&
                          <ModalDialog 
                              size="lg"
                              status={modal_legal2} 
                              setter={setModalLegal2} 
                              title={""}
                              content={parseHtmlString(legal_content2)}                              
                          />
                      }
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button 
                className="mt-0" 
                color="primary" 
                type="button" 
                onClick={RegisterClick} 
                block
                disabled={!(validEmail&&validFirst&&validLast&validPassword)}
                >
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <div className="text-center justify-content-center text-align-center">
          <div className="text-center text-light mt-2">
              <small>Already have an account? </small>
          </div>
          <div className="text-center ">
          <Button className="my-2" color="light" type="button" onClick={LoginClick} outline style={{backgroundColor:"rgba(24,29,39,0.5)"}}>
            Login
          </Button>    
          </div>            
        </div>
      </Col>   
    </>
  );
};

export default Register;
