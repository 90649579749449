import {useState, useEffect} from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Table,
    Badge,
  } from "reactstrap";
  import Alert from "components/Alerts/Alert"
  import { useTokenContext } from "App"
  import PageHeader from "components/Headers/PageHeader.js";
  import getProvider from "api/provider/getProvider"
  import ModalDialog from "components/ModalDialog/ModalDialog"
  import AddEmployee from "./AddEmployee";
  import { useNavigate } from 'react-router-dom';
  import { useParams } from 'react-router-dom';
  import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";

//   import {updateProvider} from "api/provider/updateProvider"
import ValidationInput from "components/ValidationInput/ValidationInput";
  
  function ProvidersDetails(){
    const [provider, setProvider] = useState(null);
    const [modalEmployeesState, setModalEmployeesState] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const {token, setToken} = useTokenContext();
    const { id } = useParams();
    const [providerId, setProviderId] = useState(null);

    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [mailingState, setMailingState] = useState(null);
    const [country, setCountry] = useState(null);

    const [addressValid, setAddressValid] = useState(true);
    const [cityValid, setCityValid] = useState(true);
    const [countryValid, setCountryValid] = useState(true);

    // const [updateProviderResponse, setUpdateProviderResponse] = useState(null);
    const [alert, setAlert] = useState(null);
    const navigate = useNavigate();

    const doGetProvider = async (id) => {
        try{
            let currentProvider = await getProvider(token, id, 0, 100);
            setProvider(currentProvider);
        } catch (error) {
            console.log("Error on Get Provider:", error);
        }
    }

    // const doUpdateProvider = async (id) => {
    //     try{
    //         let resp = await updateProvider(token, id, address, city, mailingState, country);
    //         setUpdateProviderResponse(resp);
    //     } catch (error) {
    //         console.log("Error on Get Provider:", error);
    //     }
    // }


    function getProviderName(provider){
      return provider["lawn_provider"]["name"]
    }

    function onAddEmployeesClick(){
        setModalEmployeesState(true);
    }

    function onAddEmployeeSuccess(alertMessage){
        document.scrollingElement.scrollTop = 0;
        setModalEmployeesState(false);
        //reload
        doGetProvider(providerId);
        setAlert(alertMessage);
    }

    function onEditMailingClicked(){
        setEditClicked(true);
    }

    // function onUpdateClick(){
    //     doUpdateProvider(providerId);
    // }

    function onCancelEditClicked(){
        setAddress(provider["data"]["lawn_provider"]["delivery_address"])
        setCity(provider["data"]["lawn_provider"]["delivery_city"])
        setMailingState(provider["data"]["lawn_provider"]["delivery_state"])
        setCountry(provider["data"]["lawn_provider"]["delivery_country"])
        setEditClicked(false);
    }


    useEffect(() => {
        if(id){
            doGetProvider(id);
        }
    }, [id])

    useEffect(() => {
        if(provider){
            setProviderId(provider["data"]["lawn_provider"]["id"])
            setAddress(provider["data"]["lawn_provider"]["delivery_address"])
            setCity(provider["data"]["lawn_provider"]["delivery_city"])
            setMailingState(provider["data"]["lawn_provider"]["delivery_state"])
            setCountry(provider["data"]["lawn_provider"]["delivery_country"])
        }
    }, [provider]);
    
    // useEffect(() => {
    //     if(updateProviderResponse){
    //         document.scrollingElement.scrollTop = 0;
    //         setAlert(updateProviderResponse.message)
    //         setEditClicked(false);
    //         doGetProvider(providerId);
    //     }
    // }, [updateProviderResponse])

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className={alert ? "mt--8": "mt--7"}  fluid>
        {alert&&
            <Alert color="success" text={alert} setter={setAlert}></Alert>
        }
        <Row>
          <Col lg="12" className="mb-2">
          <BreadcrumbNavigation active="Details" past={[{"name":"Providers", "link":"/admin/providers"}]}/>
          </Col>
        </Row>
            <Row>
            <Col className="order-xl-1" xl="6">
                <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="d-flex">
                    <Col xs="1">
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); navigate(-1)}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                    </Col>
                    <Col xs="8">
                        {provider && provider["data"] &&
                            <h3 className="mb-0">{(getProviderName(provider["data"]))}</h3>
                        }
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                {provider && provider["data"] &&
                    <Form>
                    {provider["data"]["lawn_provider"]["provider_type"] == "natural" ?
                    <>
                    <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Email
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["email"]}
                                id="input-email"
                                placeholder="email"
                                type="email"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mobile"
                            >
                                Mobile number
                            </label>
                            <Input
                                className="form-control-alternative"mobile
                                defaultValue={provider["data"]["lawn_provider"]["mobile"]}
                                id="input-mobile"
                                placeholder="First name"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>
                    </>
                    :
                    <>
                    <h6 className="heading-small text-muted mb-4">
                        Company information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>

                        <Col lg="12">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-taxId"
                            >
                                TAX ID
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-taxId"
                                defaultValue={provider["data"]["associate"]["company_tax_id"]}
                                placeholder="Email"
                                type="email"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        {/* <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-reg-no"
                            >
                                Registration Number
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_registration_number"]}
                                id="input-reg-no"
                                placeholder="Registration Number"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col> */}
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email-cmp"
                            >
                                Email
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_email"]}
                                id="input-email-cmp"
                                placeholder="Email"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-website"
                            >
                                Website
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_website"]}
                                id="input-website"
                                placeholder="Last name"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                        Mailing information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                        <Col lg="8">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-address"
                            >
                                Address
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_address"]}
                                id="input-address"
                                placeholder="Mailing Address"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-city"
                            >
                                City
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_city"]}
                                id="input-city"
                                placeholder="City"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-billing-state"
                            >
                                State
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_state"]}
                                id="input-billing-state"
                                placeholder="State"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-country"
                            >
                                Zip Code
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_zip_code"]}
                                id="input-country"
                                placeholder="Country"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>  
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-country"
                            >
                                Country
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={provider["data"]["associate"]["company_country"]}
                                id="input-country"
                                placeholder="Country"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col>                        
                        </Row>
                    </div>
                    </>
                    }                 
                    </Form>
                }
                </CardBody>
                </Card>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="6">
                <Card className="card-profile shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">Employees</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                        <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddEmployeesClick();}}
                        size="sm"
                        
                        >
                       + Add
                        </Button>
                    </Col>
                    </Row>
                    <Row>
                        <small></small>
                    </Row>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                    <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {provider && provider["data"] && provider["data"]['Employees'].map((employee) => (
                            <tr id={employee["id"]}>
                            <th scope="row">
                            {employee["user"]["first_name"] +" "+ employee["user"]["last_name"]}
                            </th>
                            <td>
                            {employee["user"]["email"]}
                            </td>
                            <td>
                            {employee["employee"]["mobile"]}
                            </td>
                            <td>
                            <Badge color="danger" pill>
                                Inactive
                            </Badge>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </Table>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </Container>
        {modalEmployeesState &&
            <ModalDialog 
                status={modalEmployeesState} 
                setter={setModalEmployeesState} 
                title={"Add Employee"} 
                content={<AddEmployee customerId={provider["data"]["lawn_provider"]["id"]} setSuccess={onAddEmployeeSuccess} />}
            />
        }
      </>
    );
  };
  
  export default ProvidersDetails;
  