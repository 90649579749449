import {getRequest, URL_SERVER} from './../requests'


export default async function listHoles(token, propertyId, skip, limit){
    try {

        return await getRequest(URL_SERVER + "holes/" + propertyId, token)
    } catch (error) {
        console.error(error);
    }

}