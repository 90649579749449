import {getRequest, URL_SERVER} from './../requests'


export default async function getGrowthSummary(token, holeId, year){
    try {
        return await getRequest(URL_SERVER + "growthSummary/" + holeId + "/" + year, token)
    } catch (error) {
        console.error(error);
    }

}