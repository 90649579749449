import {postRequest, URL_SERVER} from './../requests'


export default async function addCompany(name, tax_id,address,city,country,state,zip_code,email,website, token){
    const data = {
        "company_name": name,
        "company_tax_id": tax_id,
        "company_address": address,
        "company_city": city,
        "company_country": country,
        "company_state": state,
        "company_zip_code": zip_code,
        "company_email": email,
        "company_website": website
        }    
    try {
        return await postRequest(URL_SERVER + 'company', data, token)
    } catch (error) {
        console.error(error);
    }

}