import {Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect, createContext, useContext } from "react";


import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { NotificationProvider } from './components/NotificationContext/NotificationContext';

const TokenContext = createContext();

const App = () => {
    const [token, setToken] = useState(undefined);
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(undefined);

    return (
    <TokenContext.Provider value={{token, setToken, lawnManagementCompanyId, setLawnManagementCompanyId}}>
        <NotificationProvider>
            <Routes>
                <Route path="/auth/*" element={<AuthLayout />} />
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
        </NotificationProvider>
    </TokenContext.Provider>
    );
};

export default App;

export const useTokenContext = () => {
    return useContext(TokenContext);
};