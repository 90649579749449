
// reactstrap components
import {useState, useEffect} from "react"
import {
  Button,
  Card,
  CardBody,
  Form,
  Col,
} from "reactstrap";
import { useParams } from 'react-router-dom';
import Alert from "components/Alerts/Alert"
import forgotPassword from "api/forgotPassword/forgotPassword"
import { useNavigate } from "react-router-dom";
import { useNotification } from 'components/NotificationContext/NotificationContext';
import confirmEmail from "api/login/confirmEmail";

const VerifyEmail = () => {
  const { notification, setNotification } = useNotification();
  const [alert, setAlert] = useState(null);
  const [status, setStatus] = useState("success")
  const [verifyEmail, setVerifyEmail] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  function LoginClick(e){
    e.preventDefault();
    navigate("/auth/login")
  }
  function RegisterClick(){
    navigate("/auth/register")
  }
  const doVerifyEmail = async () => {
    try{
        let rsp = await confirmEmail(id)
        setVerifyEmail(rsp)
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    if(id){
        doVerifyEmail()
    }
  },[id]);

  useEffect(() => {
    if(verifyEmail){
        if(verifyEmail.success){
            setNotification(verifyEmail.message)
            setStatus("success")
        }
        else{
            setNotification(verifyEmail.message)
            setStatus("danger")
        }
    }
  },[verifyEmail])

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-2">
                  <strong className="" color="primary">Email confirmation</strong>
                </div>
                {notification && 
                  <Alert color={status} text={notification} setter={setNotification}></Alert>
                }
                {!verifyEmail && 
                  <div className="text-center text-muted mb-4">
                    <small>Confirming your account...</small>
                  </div>
                }
                {verifyEmail && verifyEmail.success &&
                <>
                <div className="text-center">
                <Button className="my-3" color="primary" type="button" onClick={(e) => LoginClick(e)} block>
                    Login
                </Button>                              
                </div>
                </>
                }
                <div>
                {alert&&
                <Alert color="danger" text="This email is not registered!" setter={setAlert}></Alert>
                }
              </div>   
            <Form role="form">          
            

            </Form>
          </CardBody>
        </Card>
        <div className="text-center">
          <div className="text-center text-light mt-2">
              <small>Join our community!</small>
          </div>
          <div className="text-center ">
          <Button className="my-2" color="light" type="button" onClick={RegisterClick} outline style={{backgroundColor:"rgba(24,29,39,0.5)"}}>
            Register
          </Button>    
          </div>            
        </div>

      </Col>
    </>
  );
};

export default VerifyEmail;
