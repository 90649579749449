import { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Button,
  FormGroup,
  CardBody,
  Input,
  Label,
} from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Alert from "components/Alerts/Alert";
import ValidationInput from "components/ValidationInput/ValidationInput";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import { useParams } from 'react-router-dom';
import { getLCMID } from "api/company/getLCMID";
import getProgramBuilder from "api/programBuilder/getProgramBuilder";
import getProgramGrowthSummary from "api/programBuilder/getProgramGrowthSummary";
import listTurfTypes from "api/property/listTurfTypes";
import { LineChart } from "@mui/x-charts";
import listRounds from "api/rounds/listRounds";
import generateProgramRounds from "api/programBuilder/generateProgramRounds";
import { monthNames } from "assets/constants/constants";
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import InfoBox from "components/InfoBox/InfoBox";
// import { DatePicker } from "reactstrap-date-picker";
import { Checkbox } from "@mui/material";
import YardIcon from '@mui/icons-material/Yard';
import MultiMonthPicker from "components/MultiMonthPicker/MultiMonthPicker";
import GrassIcon from '@mui/icons-material/Grass';
import PestControlIcon from '@mui/icons-material/PestControl';
import AddIcon from '@mui/icons-material/Add';
import { Add, AddCircle } from "@mui/icons-material";
import addExtraRound from "api/rounds/addExtraRound";
import DatePicker from "react-multi-date-picker";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";

const TemplateDetails = () => {

  const tagIcon = { "fertiliser": <YardIcon />, "weed": <GrassIcon />, "pest": <PestControlIcon /> }

  const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
  const { token, setToken } = useTokenContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [alert, setAlert] = useState(null);
  const [alertColor, setAlertColor] = useState(null);
  const [programBuilder, setProgramBuilder] = useState(null)
  const [growthSummary, setGrowthSummary] = useState(null)
  const [turfTypes, setTurfTypes] = useState(null);
  const [turfTypesNames, setTurfTypesNames] = useState(null);
  const [rounds, setRounds] = useState(null)
  const [nrApp, setNrApp] = useState("")
  const [nrAppValid, setNrAppValid] = useState(true)
  const [recommendedNrApp, setRecommendedNrApp] = useState(null)
  const [generateRoundsRsp, setGenerateRoundsRsp] = useState(null)
  const [datePicker, setDatePicker] = useState(new Date().toISOString())

  const [fertilisationCheck, setFertilisationCheck] = useState(false)
  const [weedCheck, setWeedCheck] = useState(false)
  const [pestCheck, setPestCheck] = useState(false)

  const [weedSelectedMonths, setWeedSelectedMonths] = useState([])
  const [pestSelectedMonths, setPestSelectedMonths] = useState([])
  const [addExtraRoundVisible, setAddExtraRoundVisible] = useState(false)

  const [datePickerExtraRound, setDatePickerExtraRound] = useState(new Date().toISOString())
  const [tagsExtraRound, setTagsExtraRound] = useState({})
  const [addExtraRoundRsp, setAddExtraRoundRsp] = useState(null)
  const [viewOpt, setViewOpt] = useState(1)

  async function doAddExtraRound() {
    try {
      const activeTags = Object.entries(tagsExtraRound)
        .filter(([tag, variant]) => variant === 'filled')
        .map(([tag]) => tag);
      let rsp = await addExtraRound(token, id, parseInt(new Date(datePickerExtraRound).getTime() / 1000), rounds.data.length + 1, 2, activeTags)
      setAddExtraRoundRsp(rsp)
    } catch (error) {
      console.log("error on adding extra round:", error)
    }

  }

  function cancelAddingExtraRound() {
    setDatePickerExtraRound(new Date().toISOString())
    setTagsExtraRound({})
    setAddExtraRoundVisible(false)
  }

  function handleDateChange(value, formattedValue) {
    setDatePicker(value)
  }



  function handleDateChangeExtraRound(value, formattedValue) {
    setDatePickerExtraRound(value)
  }

  function handleGenerateRoundsClicked() {
    if (!fertilisationCheck && !weedSelectedMonths && !pestSelectedMonths) {
      setAlert("Select at least one category of products to include in your program.")
      setAlertColor("danger")
    } else {
      if (weedCheck && weedSelectedMonths.length == 0) {
        setAlert("Select at least one month to apply weed control products or exclude them from your program.")
        setAlertColor("danger")
      } else {
        if (pestCheck && pestSelectedMonths.length == 0) {
          setAlert("Select at least one month to apply pest control products or exclude them from your program.")
          setAlertColor("danger")
        } else {
          doGenerateRoundsProgram()
        }
      }
    }

  }

  function onConfigureRoundClicked(roundId) {
    navigate("/admin/programRound/details/" + roundId)
  }


  async function getLawnManagementId() {
    setLawnManagementCompanyId(await getLCMID(token))
  }


  const doGetProgramBuilder = async () => {
    try {
      let rsp = await getProgramBuilder(token, id)
      setProgramBuilder(rsp)
    } catch (error) {
      console.error("Error on progrma builder get", error)
    }
  }

  const doGetGrowthSummary = async () => {
    try {
      let rsp = await getProgramGrowthSummary(token, id, new Date().getFullYear())
      setGrowthSummary(rsp)
    } catch (error) {
      console.error("Error on progrma builder get", error)
    }
  }

  const doGetRounds = async () => {
    try {
      let rsp = await listRounds(token, null, null, null, null, null, id)
      setRounds(rsp)
    } catch (error) {
      console.error("Error on progrma builder get", error)
    }
  }

  const doGenerateRoundsProgram = async () => {
    try {
      let nrOfAppToSend = 0
      let startDateToSend = 0

      let monthsWeedToSend = []
      let monthsPestToSend = []

      if (fertilisationCheck) {
        nrOfAppToSend = nrApp
        startDateToSend = parseInt(new Date(datePicker).getTime() / 1000)
      }

      if (weedCheck) {
        monthsWeedToSend = weedSelectedMonths
      }

      if (pestCheck) {
        monthsPestToSend = pestSelectedMonths
      }

      let rsp = await generateProgramRounds(token, id, parseInt(nrOfAppToSend), startDateToSend, monthsWeedToSend, monthsPestToSend)
      setGenerateRoundsRsp(rsp)
    } catch (error) {
      console.error("Error on progrma builder get", error)
    }
  }

  const doGetListTurfTypes = async () => {
    try {
      let currentTurfTypes = await listTurfTypes(token, 0, 100);
      setTurfTypes(currentTurfTypes);
    } catch (error) {
      console.log("Error on List Turf Types:", error);
    }
  }

  useEffect(() => {
    if (turfTypes) {
      setTurfTypesNames(turfTypes["data"].reduce((acc, item) => {
        acc[item["id"]] = item["turf_type_name"]
        return acc

      }, {}))
    }
  }, [turfTypes]);


  useEffect(() => {
    if (lawnManagementCompanyId) {
      doGetProgramBuilder()
      doGetGrowthSummary()
      doGetListTurfTypes()
      doGetRounds()
    }
  }, [lawnManagementCompanyId])

  useEffect(() => {
    if (id && token) {
      getLawnManagementId()
    }
  }, [id, token])

  useEffect(() => {
    if (growthSummary) {
      if (growthSummary.success == false) {
        setAlert(growthSummary.message)
        setAlertColor("danger")
      } else {
        const ctr_start_date = Math.floor(new Date(new Date().getFullYear(), 0, 1).getTime() / 1000);
        const date = new Date(ctr_start_date * 1000);
        const monthToStart = date.getMonth() + 1;
        if (growthSummary.data && growthSummary.data.growth_tables) {
          let recommendedValue = growthSummary.data.growth_tables.filter((gr_table) => (
            //get months with growth potential > monthToStart
            parseInt(gr_table["month"]) >= monthToStart && gr_table["growth_potential"] > 15

          )).length
          setRecommendedNrApp(recommendedValue)
          setNrApp(recommendedValue)
        }
      }
    }
  }, [growthSummary])

  useEffect(() => {
    if (generateRoundsRsp) {
      if (generateRoundsRsp.success) {
        setAlertColor("success")
        doGetRounds();
        doGetProgramBuilder();
        setFertilisationCheck(false)
        setWeedCheck(false)
        setPestCheck(false)
      } else {
        setAlertColor("danger")
      }
      setAlert(generateRoundsRsp.message)
    }
  }, [generateRoundsRsp])

  useEffect(() => {
    if (addExtraRoundRsp) {
      if (addExtraRoundRsp.message) {
        setAlert(addExtraRoundRsp.message)
      }
      if (addExtraRoundRsp.success) {
        setAlertColor("success")
        cancelAddingExtraRound()
        doGetRounds()
      } else {
        setAlertColor("danger")
      }
    }
  }, [addExtraRoundRsp])

  //useEffect to make the height of the date picker fixed-height/or specifically to make 7 rows always,not depending on the no or days in that month
  useEffect(() => {
    // Function to check and adjust weeks
    const checkAndAdjustWeeks = () => {
      const datepickerElement = document.querySelector('.rmdp-day-picker');

      if (datepickerElement) {
        const weeksContainer = datepickerElement.children[0]; // Adjust based on your structure
        if (weeksContainer) {
          // Get all children with class 'rmdp-week'
          const weeks = weeksContainer.querySelectorAll('.rmdp-week');
          const numberOfWeeks = weeks.length;

          // If there are fewer than 7 weeks, add an empty one
          if (numberOfWeeks < 7) {
            const emptyWeekDiv = document.createElement('div');
            emptyWeekDiv.className = 'rmdp-week'; // Add the class name
            emptyWeekDiv.innerHTML = '<div class="rmdp-day rmdp-day-hidden"></div>'.repeat(7); // Add empty days

            // Append the empty week div to the container
            weeksContainer.appendChild(emptyWeekDiv);
          }
          // If there are more than 7 weeks, remove excess empty weeks
          else if (numberOfWeeks > 7) {
            weeks.forEach(week => {
              if (week.children.length === 7 && [...week.children].every(child => child.classList.contains('rmdp-day-hidden'))) {
                // Remove the week if all its days are hidden
                weeksContainer.removeChild(week);
              }
            });
          } else {
            console.log('No need to add or remove empty weeks.');
          }
        } else {
          console.error('Weeks container not found.');
        }
      } else {
        console.error('Datepicker element not found.');
      }
    };

    // Initialize MutationObserver
    const observer = new MutationObserver(() => {
      checkAndAdjustWeeks();
    });

    // Observe changes in the datepicker container
    const datepickerContainer = document.querySelector('.rmdp-container');
    if (datepickerContainer) {
      observer.observe(datepickerContainer, {
        childList: true,
        subtree: true, // Watch for changes in descendants as well
      });

      // Observe the right button clicks
      const rightButton = document.querySelector('.rmdp-arrow-container');
      if (rightButton) {
        rightButton.addEventListener('click', checkAndAdjustWeeks);
      } else {
        console.error('Right button not found.');
      }
    } else {
      console.error('Datepicker container not found.');
    }

    // Cleanup function to disconnect the observer and remove event listeners
    return () => {
      if (datepickerContainer) {
        observer.disconnect();
      }
      const rightButton = document.querySelector('.rmdp-arrow-container.rmdp-right');
      if (rightButton) {
        rightButton.removeEventListener('click', checkAndAdjustWeeks);
      }
    };
  }, [fertilisationCheck]);


  return (
    <>
      <PageHeader />
      <Container className={alert ? "mt--8" : "mt--7"} fluid>
        {alert &&
          <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
        }
        <div className="col">
          <Row>
            <Col lg="12" className="mb-2">
              {programBuilder && programBuilder.data && programBuilder.data.program && <BreadcrumbNavigation active={programBuilder.data.program.program_name} past={[{ "name": "ProgramBuilder", "link": "/admin/programBuilder" }]} />}
            </Col>
          </Row>
          <Card className="bg-secondary shadow mb-4">

            <CardHeader className="border-0 d-flex modal-header">
              <div className="d-flex align-items-center">
                <Button
                  color="transparent"
                  onClick={(e) => { e.preventDefault(); navigate(-1) }}
                  size="sm"
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </Button>
                {programBuilder && programBuilder.data && programBuilder.data.program &&
                  <div className="d-flex justify-content-center" >
                    <h3 className="m-0">
                      {programBuilder.data.program.program_name}&nbsp;
                    </h3>
                    <h3 className="ml-4">
                      {programBuilder.data.program.zip_code}
                    </h3>
                    <h3 className="ml-4">
                      {turfTypesNames && turfTypesNames[programBuilder.data.program.turf_type_id]}
                    </h3>
                  </div>
                }
              </div>
            </CardHeader>
            <CardBody>
              {programBuilder && programBuilder.data && programBuilder.data.program &&
                <>
                  {!programBuilder.data.program.tags && (rounds && rounds.data && rounds.data.length == 0) ?
                    <>
                      <h5 className="d-flex">
                        What will be included in your program:
                      </h5>
                      <FormGroup className="ml-4 mb-0">
                        <Input
                          id="fertilisationCheck"
                          type="checkbox"
                          value={fertilisationCheck}
                          onChange={() => setFertilisationCheck(!fertilisationCheck)}
                        />
                        <Label check for="fertilisationCheck">
                          <h5>
                            Fertilization
                          </h5>
                        </Label>
                      </FormGroup>
                      <FormGroup className="ml-4 mb-0 ">
                        <Input
                          id="weedCheck"
                          type="checkbox"
                          value={weedCheck}
                          onChange={() => setWeedCheck(!weedCheck)}
                        />
                        <Label check for="weedCheck">
                          <h5>
                            Weed Control
                          </h5>
                        </Label>
                      </FormGroup>
                      <FormGroup className="ml-4 mb-1">
                        <Input
                          id="pestCheck"
                          type="checkbox"
                          value={pestCheck}
                          onChange={() => setPestCheck(!pestCheck)}
                        />
                        <Label check for="pestCheck">
                          <h5>
                            Pest Control
                          </h5>
                        </Label>
                      </FormGroup>
                    </>

                    :
                    <>
                      <h5 className="d-flex">
                        Included in your program:
                      </h5>
                      <FormGroup className="ml-4 mb-0">
                        <Input
                          id="fertilisationCheck"
                          type="checkbox"
                          checked={programBuilder.data.program.tags && programBuilder.data.program.tags.includes("fertiliser")}
                          disabled={true}
                        />
                        <Label check for="fertilisationCheck">
                          <h5>
                            Fertilization
                          </h5>
                        </Label>
                      </FormGroup>
                      <FormGroup className="ml-4 mb-0 ">
                        <Input
                          id="weedCheck"
                          type="checkbox"
                          checked={programBuilder.data.program.tags && programBuilder.data.program.tags.includes("weed")}
                          disabled={true}
                        />
                        <Label check for="weedCheck">
                          <h5>
                            Weed Control
                          </h5>
                        </Label>
                      </FormGroup>
                      <FormGroup className="ml-4 mb-1">
                        <Input
                          id="pestCheck"
                          type="checkbox"
                          checked={programBuilder.data.program.tags && programBuilder.data.program.tags.includes("pest")}
                          disabled={true}
                        />
                        <Label check for="pestCheck">
                          <h5>
                            Pest Control
                          </h5>
                        </Label>
                      </FormGroup>
                    </>

                  }
                </>
              }
              {(fertilisationCheck || (programBuilder && programBuilder.data && programBuilder.data.program && programBuilder.data.program.tags && programBuilder.data.program.tags.includes("fertiliser"))) &&
                <Card className="p-4">
                  <h3>
                    Fertilization demand forecast
                  </h3>

                  <RadioButtonGroup labels={["Chart", "Table"]} colors={["primary", "primary"]} size="sm" outline={true} setter={(val) => setViewOpt(val)} />

                  {viewOpt === 1 ?
                    <>
                      <Row className="px-5">
                        <h4>
                          {"Nutrient demand forecast"}
                        </h4>
                      </Row>
                      {growthSummary && growthSummary.data &&
                        <LineChart
                          axisHighlight={{ x: "line" }}
                          xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], scaleType: 'point', valueFormatter: ((date) => new Date(0, date - 1).toLocaleString('default', { month: "short" })) }]}
                          series={[
                            {
                              data: growthSummary["data"]["growth_tables"].slice().reverse().map((growth_table) => { return growth_table.growth_potential })
                            }
                          ]}
                          height={200}
                          margin={{ left: 40, right: 30, top: 10, bottom: 30 }}
                          grid={{ vertical: true, horizontal: true }}
                          slotProps={{
                            legend: {
                              position: {
                                vertical: 'top',
                                horizontal: 'middle',
                              },
                              itemMarkWidth: 20,
                              itemMarkHeight: 5,
                              markGap: 5,
                              itemGap: 8,
                            }
                          }}
                        />
                      }
                    </>
                    :
                    <Table className="align-items-center table-light table-sm mt-4" responsive>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" style={{ width: "1vw" }}>Month</th>
                          <th scope="col" >Jan</th>
                          <th scope="col">Feb</th>
                          <th scope="col" >March</th>
                          <th scope="col">Apr</th>
                          <th scope="col" >May</th>
                          <th scope="col">June</th>
                          <th scope="col" >July</th>
                          <th scope="col">Aug</th>
                          <th scope="col" >Sept</th>
                          <th scope="col">Oct</th>
                          <th scope="col" >Nov</th>
                          <th scope="col">Dec</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            {"Demand %"}
                          </th>
                          {growthSummary && growthSummary.success &&
                            growthSummary["data"]["growth_tables"].slice().reverse().map((growth_table, idx) => (
                              <td key={idx}>
                                {growth_table["growth_potential"].toFixed(2)}
                              </td>
                            ))}
                        </tr>
                      </tbody>
                    </Table>
                  }

                  <div className="px-0 pt-4">
                    <Row className="d-flex align-items-center">
                      <Col lg="12" md="12">
                        <h3 className="pb-0">
                          {"Yearly fertilizer rate forecast"}
                        </h3>
                        <Table className="align-items-center table-light table-sm" responsive>
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" style={{ width: "1vw" }}>Nutrients</th>
                              <th scope="col" >N</th>
                              <th scope="col">P</th>
                              <th scope="col" >K</th>
                              <th scope="col">Ca</th>
                              <th scope="col" >Mg</th>
                              <th scope="col">S</th>
                              <th scope="col" >Fe</th>
                              <th scope="col">Mn</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>
                                {"lbs/1000sqft"}
                              </th>
                              {growthSummary && growthSummary.success &&
                                <>
                                  <td>
                                    {growthSummary.data.growth_summary.total.N.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.P.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.K.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.Ca.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.Mg.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.S.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.Fe.toFixed(2)}
                                  </td>
                                  <td>
                                    {growthSummary.data.growth_summary.total.Mn.toFixed(2)}
                                  </td>
                                </>
                              }
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                  {rounds && rounds.data && rounds.data.length == 0 &&
                    <>
                      <Row className="px-3 mt-3 d-block mb-0">
                        <h4 className="">
                          {"Recommendations for " + new Date().getFullYear() + ": "}
                        </h4>
                        <h5>
                          {"For best results, QT recommends "}  <strong>{recommendedNrApp}</strong> {" rounds of fertilizer application."}
                          <h5 className="d-block">{"How many rounds of fertilizer do you wish to apply?"}</h5>
                        </h5>
                        <FormGroup>
                          <div className="d-flex">
                            <div className="d-block">
                              <ValidationInput type="text" placeholder="No. of applications" value={nrApp} setter={setNrApp} validationType="NUMERIC" message={"Invalid no. of applications"} getValidState={setNrAppValid} />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="m-0">
                          <h5 className="d-block">{"What is your estimated start date of fertilization?"}</h5>
                          <div className="d-flex">

                            <div className="d-block">
                              <DatePicker id="example-datepicker"
                                className="green"
                                size="md"
                                value={datePicker}
                                onChange={setDatePicker}
                                dateFormat="MM/DD/YYYY"
                                // value   = {datePicker} 
                                // onChange= {(v,f) => handleDateChange(v, f)}
                                style={{}}
                              />

                            </div>
                          </div>
                        </FormGroup>

                      </Row>
                    </>
                  }
                </Card>
              }
              {weedCheck &&
                <Card className="p-4 mt-3">
                  <h3>
                    Weed control plan
                  </h3>
                  <h5>
                    Select the months you wish to apply weed control products:
                  </h5>
                  <MultiMonthPicker getSelectedMonths={setWeedSelectedMonths} />
                </Card>
              }

              {pestCheck &&
                <Card className="p-4 mt-3">
                  <h3>
                    Pest control plan
                  </h3>
                  <h5>
                    Select the months you wish to apply pest control products:
                  </h5>
                  <MultiMonthPicker getSelectedMonths={setPestSelectedMonths} />
                </Card>
              }
              {rounds && rounds.data && rounds.data.length == 0 &&
                <Row className="px-3 d-block mt-0 pt-3">
                  <Button
                    color="primary"
                    disabled={!(nrAppValid)}
                    onClick={(e) => { e.preventDefault(); handleGenerateRoundsClicked() }}
                    size="md"
                    className=""

                  >
                    Generate rounds
                  </Button>
                </Row>
              }


              {rounds && rounds.data && rounds.data.length != 0 &&
                <>
                  <Card className="p-4 mt-2">
                    <div className="d-flex align-items-center justify-content-between modal-header p-1 mt-0 mb-2">
                      <h4 className="mb-0 mt-0">
                        {"Rounds"}
                      </h4>
                      {programBuilder && programBuilder.data && programBuilder.data.configurable &&

                        <Button
                          color="primary"
                          disabled={!(nrAppValid)}
                          onClick={(e) => {
                            e.preventDefault(); setAddExtraRoundVisible(true); setTagsExtraRound(programBuilder.data.program.tags.reduce((acc, tag) => {
                              acc[tag] = "filled";
                              return acc;
                            }, {})
                            )
                          }}
                          size="md"
                          className=""

                        >
                          + Add extra round
                        </Button>
                      }
                    </div>
                    <Table className="align-items-center table table-md" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">No.</th>
                          <th scope="col">Month</th>
                          <th scope="col">N, P, K [lbs/1000sqft]</th>
                          <th scope="col">Products</th>
                          <th scope="col">Tags</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {rounds && rounds.data && rounds.data.slice().reverse().map((round, idx) => (
                          <tr id={round.round.id} tabIndex={0} key={idx}>
                            <th>
                              {round.products ?
                                <CheckIcon color="primary" />
                                :
                                <WarningIcon color="warning" />
                              }
                            </th>
                            <th>
                              {round.round.round_number}
                            </th>
                            <th>
                              {monthNames[(new Date((round.round.application_date) * 1000)).getMonth()]}
                            </th>
                            <th>
                              <Chip label={`${((round?.round?.n_q)).toFixed(3)}`} />
                              <Chip label={`${((round?.round?.p_q)).toFixed(3)}`} />
                              <Chip label={`${((round?.round?.k_q)).toFixed(3)}`} />
                              {/* {((round?.round?.n_q)*100).toFixed(2)}% N - {((round?.round?.p_q)*100).toFixed(2)}%P - {((round?.round?.k_q)*100).toFixed(2)}%K */}
                            </th>
                            <th>
                              {round.products ?
                                <Stack direction="row" spacing={3}>
                                  {round.products
                                    .sort((a, b) => {
                                      const categoryOrder = { fertiliser: 1, weed_control: 2, pest_control: 3 };
                                      return categoryOrder[a.category] - categoryOrder[b.category];
                                    })
                                    .map((product, idx) => (
                                      <Chip label={product.name} key={idx} />
                                    ))}

                                </Stack>
                                :
                                <>
                                  <span className="font-italic text-warning">Configure the round</span>
                                  {/* {"Recommended: "}<small className="font-italic text-primary">Coming soon!</small> */}
                                </>
                              }

                            </th>
                            <td>
                              <Stack direction="row" spacing={3}>
                                {round.round.tags && round.round.tags.map((tag, idx) => (
                                  tag === "fertiliser" ? //change from fertiliser into fertilizer (because from backend comes fertiliser)
                                    <Chip label="fertilizer" key={idx} variant="outlined" icon={tagIcon[tag]} />
                                    :
                                    <Chip label={tag} key={idx} variant="outlined" icon={tagIcon[tag]} />
                                ))}
                              </Stack>
                            </td>
                            <td className="text-right">
                              {/* <Button
                              color="success"
                              
                              onClick={(e) =>{e.preventDefault(); }}
                              size="sm"
                              className=""
                              
                              >
                                <i className="fa-solid fa-check"></i>&nbsp;
                              Accept
                            </Button> */}
                              <Button
                                color="secondary"
                                onClick={(e) => { e.preventDefault(); onConfigureRoundClicked(round.round.id) }}
                                size="sm"
                                className=""

                              >
                                <i className="fa-solid fa-screwdriver-wrench"></i>&nbsp;
                                Configure
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {addExtraRoundVisible &&
                          <tr>
                            <th>
                              <AddCircle color="success" />
                            </th>
                            <th>
                              {rounds.data.length + 1}
                            </th>
                            <th>
                              <div className="d-block">
                                <div>
                                  Estimated date to apply round:
                                </div>
                                <DatePicker id="example-asa"
                                  className="green"
                                  size="md"
                                  value={datePickerExtraRound}
                                  onChange={setDatePickerExtraRound}
                                  dateFormat="MM/DD/YYYY"
                                  style={{}}
                                />
                              </div>
                            </th>
                            <th>
                            </th>
                            <th>
                              <div className="d-block">
                                Include in this round:
                                <Stack direction="row" spacing={3} className="mt-2">
                                  {programBuilder && programBuilder.data && programBuilder.data.program &&
                                    programBuilder.data.program.tags && tagsExtraRound && Object.entries(tagsExtraRound).map(([tag, variant]) => (
                                      <>
                                        {variant == "filled" ?
                                          <Chip
                                            label={tag}
                                            key={tag}
                                            icon={tagIcon[tag]}
                                            color="success"
                                            variant={variant}
                                            onClick={() => setTagsExtraRound(prevTags => ({
                                              ...prevTags,
                                              [tag]: prevTags[tag] === "filled" ? "outlined" : "filled" // Toggle between "blue" and "red"
                                            }))}
                                          />
                                          :
                                          <Chip
                                            label={tag}
                                            key={tag}
                                            icon={tagIcon[tag]}

                                            variant={variant}
                                            onClick={() => setTagsExtraRound(prevTags => ({
                                              ...prevTags,
                                              [tag]: prevTags[tag] === "filled" ? "outlined" : "filled" // Toggle between "blue" and "red"
                                            }))}
                                          />
                                        }
                                      </>

                                    ))
                                  }
                                </Stack>
                              </div>
                            </th>
                            <td className="text-right">
                              <Button
                                color="success"
                                className=""
                                type="button"
                                size="sm"
                                disabled={!Object.values(tagsExtraRound).some(variant => variant === 'filled')}
                                onClick={doAddExtraRound}

                              >
                                <i className="fa-solid fa-check"></i>
                              </Button>
                              <Button
                                color="danger"
                                type="button"
                                size="sm"
                                onClick={cancelAddingExtraRound}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </Button>
                            </td>
                          </tr>

                        }
                      </tbody>
                    </Table>
                  </Card>
                </>
              }

              {rounds && rounds.data && rounds.data.length == 0 &&
                <div className="mt-4">
                  <InfoBox text={["You need to generate and configure all your rounds in order to use this program."]} />
                </div>
              }
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default TemplateDetails;


