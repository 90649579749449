
import React from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  CardTitle
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import UserHeader from "components/Headers/UserHeader.js";
import AddCompany from "./Company.js"
import getMe from "api/me/getMe"
import getMyCompanies from "api/me/getMyCompanies"
import getCompany from "api/company/getCompany"
import getMyLegal from "api/legal/getMyLegal";
import { getFormattedTime } from "utils/utils";
import { useState,useEffect } from "react";
import { useTokenContext } from "App";
import ModalDialog from 'components/ModalDialog/ModalDialog';
import Alert from "components/Alerts/Alert"
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation.js';

const Profile = () => {
  const {token, setToken} = useTokenContext();
  const [me, setMe] = useState(null);
  const [meResp, setMeResp] = useState(null);
  const [activeCompany, setActiveCompany] = useState(null);
  const [companyResponse, setCompanyResp] = useState(null);
  const [myCompanies, setMyCompanies] = useState(null);
  const [myCompaniesResponse, setMyCompaniesResp] = useState(null);
  const [legal, setLegal] = useState(null);
  const [legalResponse, setLegalResp] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [legalTypeContent, setLegalTypeContent] = useState(false)
  const [modalCompanyState, setModalCompanyState] = useState(false);
  const [alert, setAlert] = useState(null);

  function onAddCompanySuccess(response){
    setModalCompanyState(false);
    setAlert(response.message);
    localStorage.setItem("LCMId",response.data.id) 
    getMyCompaniesFunction()
  }

  function onAddCompanyClick(){
    setModalCompanyState(true);
  }

  const getMyCompaniesFunction = async()=>{
    try {
      let response = await getMyCompanies(token);      
      setMyCompaniesResp(response)                  
    } catch (error) {
      
    }
  }
  // const handleChooseCompany = (company) => {
  //   localStorage.setItem("LCMId",company["company_id"])
  //   getCompanyFunction();
  // };

  useEffect(()=>{
    if (myCompaniesResponse){
      if (myCompaniesResponse.success){
        setMyCompanies(myCompaniesResponse.data)    
      }
    }
  },[myCompaniesResponse]);

  const getLegalFunction = async()=>{
    try {
      let response = await getMyLegal(token);      
      setLegalResp(response)            
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    if (legalResponse){
      if (legalResponse.success){
        setLegal(legalResponse.data)        
      }
    }
  },[legalResponse]);

  const getCompanyFunction = async()=>{
    const lawnManagementCompanyId = localStorage.getItem("LCMId")    
    try {
      let response = await getCompany(token,"","",lawnManagementCompanyId,lawnManagementCompanyId);      
      setCompanyResp(response)            
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    if (companyResponse){
      if (companyResponse.success){
        setActiveCompany(companyResponse.data)        
      }
    }
  },[companyResponse]);

  const getMeFunction = async()=>{
    try {
      let response = await getMe(token);      
      setMeResp(response)                  
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    if (meResp){
      if (meResp.success){
        setMe(meResp.data.user)        
      }
    }
  },[meResp]);

  useEffect(()=>{
    if (myCompanies){
      getCompanyFunction()
    }
  },[myCompanies]);
  
  useEffect(() => {
    getMeFunction()
    getMyCompaniesFunction()    
    getLegalFunction()
  }, []);

  return (
    <>
      <UserHeader />     
      <Container className={alert ? "mt--8": "mt--7"} fluid>
      <Row className='mb-3'>
          <Col lg="12">
          <BreadcrumbNavigation active="Profile"/>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1 mb-1 mb-xl-0" xl="6">
          {alert&&
                <Alert color="success" text={alert} setter={setAlert}></Alert>
            }
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="4">
                  <div className="card-profile-image">
                    <a onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/icons/common/profile.jpg")}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardBody className="pt-0 pt-md-4 pb-4">
                <Row>
                  <div className="col mb-3">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                      </div>
                      <div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>                                      
                    {me&&me["first_name"] + " " + me["last_name"]}
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {me&&me["email"]}
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className=' mt-3'>

              <CardBody className="pt-0 pt-md-4">
              <CardTitle>
                Legal documents
              </CardTitle>
                <Table className="align-items-center table-light" responsive>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Version</th>
                          <th scope="col">Publish Date</th>
                          <th scope="col">Status</th>                      
                        </tr>
                      </thead>
                      <tbody>
                        {legal && legal.map((l, idx) => (
                            <tr id={l["id"]} key={idx}>
                              <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                                <Link onClick={()=>{setLegalTypeContent(l["content"]); setModalOpen(true)}} className="font-weight-bold ml-1 d-block">
                                  {l["title"]}
                                </Link>
                              </th>                              
                              <td>
                              {l["version"]}
                              </td>
                              <td>
                              {getFormattedTime(l["created_at"])}
                              </td>
                              <td>
                                <div className='text-success'>
                                  <i className="fa-solid fa-circle-check fa-xl"></i>
                                </div>
                              </td>                            
                            </tr>
                        ))}
                        </tbody>
                  </Table>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-2" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">                    
                    <h2 className="mb-0">{activeCompany&&activeCompany["company_name"]}</h2>
                  </Col>
                  {!activeCompany && <Col xs="4" className="d-flex justify-content-end">
                    <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddCompanyClick();}}
                        size="md"       
                        >
                       + Add Company
                      </Button>
                  </Col>}
                  {/* <Col className="text-right" xs="1">                    
                    <div className="d-flex p-1">
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                        <DropdownToggle color="primary" caret>Choose Company</DropdownToggle>
                        <DropdownMenu>                          
                          {myCompanies&&myCompanies["companies_roles"].map((element,idx) => (
                            <React.Fragment key={idx}>
                              <DropdownItem onClick={() => handleChooseCompany(element)}>{element["company_name"]}</DropdownItem>
                              {/* <DropdownItem divider /> */}
                            {/* </React.Fragment>
                          ))}                          
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col> */}
                </Row>
              </CardHeader>
              {activeCompany?
              <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">
                  Company information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Tax ID
                        </label>
                        <Input
                         className="form-control-alternative"
                          value={activeCompany["company_tax_id"]}
                          id="input-username"
                          placeholder=""
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Registration number
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          value={activeCompany["company_registration_number"]}
                          type="text"
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Website
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany["company_website"]}
                          id="input-first-name"
                          placeholder=""
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Email address
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany["company_email"]}
                          id="input-last-name"
                          placeholder=""
                          type="email" 
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                {/* Address */}
                <h6 className="heading-small text-muted mb-4">
                  Contact information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany&&activeCompany["company_address"]}
                          id="input-address"
                          placeholder="Address"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-city"
                        >
                          City
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany&&activeCompany["company_city"]}
                          id="input-city"
                          placeholder="City"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Country
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany&&activeCompany["company_country"]}
                          id="input-country"
                          placeholder="Country"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          State
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany&&activeCompany["company_state"]}
                          id="input-country"
                          placeholder="Country"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col> 
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Zip Code
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={activeCompany&&activeCompany["company_zip_code"]}
                          id="input-country"
                          placeholder="Country"
                          type="text"
                          readOnly
                        />
                      </FormGroup>
                    </Col>                       
                  </Row>
                </div>
                {/* <hr className="my-4" /> */}
                {/* Description
                <h6 className="heading-small text-muted mb-4">About me</h6>
                <div className="pl-lg-4">
                  <FormGroup>
                    <label>About Me</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="A few words about you ..."
                      rows="4"
                      defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                      Open Source."
                      type="textarea"
                    />
                  </FormGroup>
                </div> */}
              </Form>
            </CardBody>
            :
            <CardBody>
              <h4 className="text-muted">Please add your company!</h4>
            </CardBody>
              }
              
            </Card>
          </Col>
        </Row>
      </Container>     
        {modalOpen &&
          <ModalDialog 
              size="lg"
              status={modalOpen} 
              setter={setModalOpen} 
              title={""}
              content={parseHtmlString(legalTypeContent)}                              
          />
        }
        {modalCompanyState &&
            <ModalDialog 
                size="lg"
                status={modalCompanyState} 
                setter={setModalCompanyState}
                title={"Add Company"}
                content={<AddCompany setSuccess={(response) => onAddCompanySuccess(response)} defaultEmail={me["email"]}/>}
            />
        }
    </>
  );
};


const parseHtmlString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const body = doc.body;
  const reactElements = Array.from(body.childNodes).map((node, index) => {
    return <div key={index} dangerouslySetInnerHTML={{ __html: node.outerHTML }} />;
  });
  // console.log(reactElements)
  return reactElements;
};

Profile.propTypes = {
  direction: PropTypes.string,
};

export default Profile;
