import {getRequest, URL_SERVER} from './../requests'


export default async function getAnnualNPK(token, holeId, year){
    try {
        return await getRequest(URL_SERVER + "annualNPK/" + holeId+"/" + year, token)
    } catch (error) {
        console.error(error);
    }

}