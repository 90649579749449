import {postRequest, URL_SERVER} from './../requests'


export default async function addExtraRound(token, hole_id, date, number, hole_or_program, tags){
    const data = {
        "hole_id": hole_id,
        "date": date,
        "hole_number": number,
        "hole_or_program": hole_or_program
        }
    if(tags){
        data["tags"] = tags
    }
    try {
        return await postRequest(URL_SERVER + 'round', data, token)
    } catch (error) {
        console.error(error);
    }

}