import {putRequest, URL_SERVER} from '../requests'


export default async function updateProduct2Application(token, product_id, application_id, quantity, carrier_volume){
    let data = {
        "product_id": product_id,
        "application_id": application_id,
        "quantity": quantity,
        "carrier_volume":carrier_volume
    }
    try {
        return await putRequest(URL_SERVER + 'application/product', data, token)
    } catch (error) {
        console.error(error);
    }

}
