import {postRequest, URL_SERVER} from '../requests'


export default async function addProgramBuilder(token, name, LCMID, zip_code, turf_type_id, lat, long){
    let data = {
        "program_name": name,
        "lawn_management_company_id": LCMID,
        "zip_code": zip_code,
        "turf_type_id": turf_type_id,
        "gps": [lat, long]
    }
    try {
        return await postRequest(URL_SERVER + 'programBuilder', data, token)
    } catch (error) {
        console.error(error);
    }

}
