import {getRequest, postRequest, URL_SERVER} from '../requests'


export default async function generateProgramRounds(token, programBuilderId, noApplications, startDate, months_weed_control, months_pest_control){
    const data = {
        "program_id": programBuilderId,
        "no_applications_fertiliser": noApplications,
        "start_date": startDate,
        "months_weed_control": months_weed_control,
        "months_pest_control": months_pest_control
        }
    try {
        return await postRequest(URL_SERVER + "program/generateRounds",data, token)
    } catch (error) {
        console.error(error);
    }
}