import {putRequest, URL_SERVER} from '../requests'


export default async function updateProduct2ProgramBuilder(token, product_id, round_id, rate){
    let data = {
        "product_id": product_id,
        "round_id": round_id,
        "quantity": rate,
    }
    try {
        return await putRequest(URL_SERVER + 'product2programBuilder', data, token)
    } catch (error) {
        console.error(error);
    }

}
