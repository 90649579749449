
import { useState, useEffect } from "react";

import {
    Input,
    Row,
    Col,
    FormGroup,
    Button,
    UncontrolledTooltip,
} from "reactstrap";
import { useTokenContext } from "App";
import ValidationInput from "components/ValidationInput/ValidationInput";
import Alert from "components/Alerts/Alert"
import addSoilTest from "api/growthTables/addSoilTest";
import { DatePicker } from "reactstrap-date-picker";
import getGrowthSummary from "api/growthTables/getGrowthSummary";

function AddSoilTest({ holeId, setSuccess }) {
    const { token, setToken } = useTokenContext();
    const [P, setP] = useState("");
    const [K, setK] = useState("");
    const [Ca, setCa] = useState("");
    const [Mg, setMg] = useState("");
    const [S, setS] = useState("");
    const [Fe, setFe] = useState("");
    const [Mn, setMn] = useState("");
    const [ph, setPh] = useState("");
    const [salinity, setSalinity] = useState("");
    const [datePicker, setDatePicker] = useState(new Date().toISOString())

    const [addSoilTestResponse, setAddSoilTestResponse] = useState(false);
    const [alert, setAlert] = useState(null);

    const [growthSummary, setGrowthSummary] = useState(null);

    const [PValid, setPValid] = useState(true);
    const [KValid, setKValid] = useState(true);
    const [CaValid, setCaValid] = useState(true);
    const [MgValid, setMgValid] = useState(true);
    const [SValid, setSValid] = useState(true);
    const [FeValid, setFeValid] = useState(true);
    const [MnValid, setMnValid] = useState(true);
    const [PhValid, setPhValid] = useState(true);
    const [salinityValid, setSalinityValid] = useState(true);


    function handleDateChange(value, formattedValue) {
        setDatePicker(value)
    }

    function onAddClick() {
        doAddSoilTest();
    }

    const doAddSoilTest = async () => {
        try {
            if (growthSummary && growthSummary.success == true) {
                let rsp = await addSoilTest(token, holeId, growthSummary["data"]["growth_summary"]["id"], P, K, Ca, Mg, S, Fe, Mn, parseFloat(ph), salinity, parseInt(new Date(datePicker).getTime() / 1000).toString())
                setAddSoilTestResponse(rsp);
            } else {
                setAlert("No growth summary registered for the sampling year.")
            }

        } catch (error) {
            console.log("Error on Add Soil Test:", error);
        }
    }

    const doGetGrowthSummary = async (year) => {
        try {
            let rsp = await getGrowthSummary(token, holeId, year)
            setGrowthSummary(rsp);
        } catch (error) {
            console.log("Error on Get growth summary:", error);
        }
    }


    useEffect(() => {
        if (addSoilTestResponse) {
            if (addSoilTestResponse.success) {
                setSuccess(addSoilTestResponse.message);
            } else {
                setAlert(addSoilTestResponse.message)
            }
        }

    }, [addSoilTestResponse])

    useEffect(() => {
        //get growth summary id
        const date = new Date(datePicker);
        const year = date.getFullYear();
        doGetGrowthSummary(year);
    }, [datePicker, holeId])

    return (
        <>
            <div className="pl-lg-2">
                {alert &&
                    <Alert color="danger" text={alert} setter={setAlert}></Alert>
                }
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                P&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="P" value={P} setter={setP} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setPValid} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                K&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="K" value={K} setter={setK} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setKValid} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Ca&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="Ca" value={Ca} setter={setCa} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setCaValid} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Mg&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="Mg" value={Mg} setter={setMg} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setMgValid} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                S&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="S" value={S} setter={setS} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setSValid} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Fe&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="Fe" value={Fe} setter={setFe} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setFeValid} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Mn&nbsp;
                                <small>[ppm]</small>
                            </label>
                            <ValidationInput type="text" placeholder="Mn" value={Mn} setter={setMn} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setMnValid} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                pH&nbsp;
                            </label>
                            <ValidationInput type="text" placeholder="pH" value={ph} setter={setPh} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setPhValid} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Salinity&nbsp;
                                <small>[ppt]</small>
                            </label>
                            <ValidationInput type="text" placeholder="Salinity" value={salinity} setter={setSalinity} validationType="NUMERIC_NULL" message={"Invalid value"} getValidState={setSalinityValid} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Sampling date
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small> 
                                &nbsp;
                                <i className="fa-regular fa-circle-question pb-2" id="unit-soil"></i>
                                <UncontrolledTooltip
                                    target="unit-soil"
                                >
                                    Reminder: Get your soil tested this year to ensure your plants have the best conditions for growth. 
                                </UncontrolledTooltip>
                            </label>
                            <DatePicker id="example-datepicker"
                                dateFormat="MM/DD/YYYY"
                                value={datePicker}
                                onChange={(v, f) => handleDateChange(v, f)}
                                // minDate={new Date('2024-01-01')}
                                maxDate={new Date(`${new Date().getFullYear()}-12-31`)}
                            />
                            
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="pl-lg-2 pt-3">
                <Button
                    color="primary"
                    disabled={!(PValid && KValid && CaValid && MgValid && SValid && FeValid && MnValid && PhValid && salinityValid)}
                    type="button"
                    onClick={(e) => { e.preventDefault(); onAddClick() }}>
                    Add
                </Button>
            </div>
        </>
    );
};

export default AddSoilTest;
