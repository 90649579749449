
import {useState} from "react";

import {
  Input,
  InputGroup,
  InputGroupAddon,
  FormFeedback
} from "reactstrap";

import {VALID_REGEX} from "assets/validation/errors"
import { useEffect } from "react"


function ValidationInput({placeholder, type, validationType, setter, icon, message, mandatory, getValidState, disabled, value, resetInvalid, onBlur, onKeyDown}){
  const [invalid, setInvalid] = useState(false);

  function onValueChange(currentValue){    
    setter(currentValue);
    if(validationType){
      var valid_state = VALID_REGEX[validationType].test(currentValue)
      setInvalid(!valid_state)
      if(getValidState){
        getValidState(valid_state)
      }
    }
  }

  useEffect(()=> {
    
    if(resetInvalid==true||resetInvalid==false){
      setInvalid(false);
    }
  }, [resetInvalid]);


  return (
    <>
    <InputGroup style={ type === "textarea" ? { height: "100%" } : {}}>
    {icon &&
    <InputGroupAddon addonType="prepend">
      {invalid ?
      <span className="input-group-text" style={{color: "red", borderColor: "red"}}>
        {mandatory && (
        <small style={{ color: "red", borderColor: "red" }}>
          *&nbsp;
        </small>
      )}
       {icon}
      </span>
      :
      <span className="input-group-text">
      {mandatory && (
        <small style={{ color: "red", borderColor: "red" }}>
          *&nbsp;
        </small>
      )}
      {icon}
    </span>
    }
    </InputGroupAddon>
  }
    <Input 
      type={type}
      invalid={invalid}
      value={value}
      onKeyDown={onKeyDown}
      onChange={(e) => onValueChange(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      
    />
    </InputGroup>
    {invalid && <FormFeedback style={{ display: 'block' }}>
      <small>{message}</small>
      
      </FormFeedback>}
    </>
    );
};

export default ValidationInput;
