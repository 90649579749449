import {postRequest, URL_SERVER} from './../requests'


export default async function resetPassword(code,new_password){
    const data = {
        "code": code,
        "new_password":new_password
        }
    try {
        return await postRequest(URL_SERVER + '/reset/password', data)
    } catch (error) {
        console.error(error);
    }
}