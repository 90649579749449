import {getRequest, URL_SERVER} from '../requests'


export default async function getProvider(token, customerId, skip, limit){
    try {

        return await getRequest(URL_SERVER + 'provider/'+customerId, token)
    } catch (error) {
        console.error(error);
    }

}