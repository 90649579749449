import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CardBody,
    Input
  } from "reactstrap";
import { useParams } from 'react-router-dom';
import PageHeader from "components/Headers/PageHeader.js";
import listTurfTypes from "api/property/listTurfTypes";
import Alert from "components/Alerts/Alert";
import ValidationInput from "components/ValidationInput/ValidationInput";
import { DatePicker } from "reactstrap-date-picker";
import DropdownList from "components/DropdownList/DropdownList";
import GoogleMapComponent from "components/GoogleMaps/GoogleMapComponent";
import addProperty from "api/property/addProperty";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";

import getCustomer from "api/customer/getCustomer";
import generateGrowthSummary from "api/growthTables/generateGrowthSummary";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";
  
  const AddProperty = () => {
    const [customer, setCustomer] = useState(null);
    const [turfTypes, setTurfTypes] = useState(null);
    const [turfTypesNames, setTurfTypesNames] = useState(null);
    const [turfType, setTurfType] = useState("Select...");
    const {token, setToken} = useTokenContext();
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [datePickerEnd, setDatePickerEnd]= useState(new Date(new Date().getFullYear(), 11, 31).toISOString())
    const [datePickerStart, setDatePickerStart] = useState(new Date().toISOString())

    const [address, setAdress]= useState("")
    const [zipCode, setZipCode]= useState("")
    const [area, setArea]= useState("")
    const [lat, setLat]= useState("")
    const [long, setLong]= useState("")
    const [irrigation, setIrrigation] = useState(false)
    const { id } = useParams();
    const [addressValid, setAdressValid]= useState(true)
    const [zipCodeValid, setZipCodeValid]= useState(true)
    const [areaValid, setAreaValid]= useState(true)
    const [generateGrowthSummaryResponse, setGenerateGrowthSummaryResponse] = useState(null)
    const [clippings, setClippings] = useState(1)

    const [addPropertyResponse, setAddPropertyResponse] = useState(null)
    

    function handleDateChangeEnd(value, formattedValue) {
        setDatePickerEnd(value)
      }

      function handleDateChangeStart(value, formattedValue) {
        setDatePickerStart(value)
      }

    function onAddClicked(){
        if(address===""||zipCode===""||area===""||turfType==="Select..."||customer==="Select..."){
            setAlert("Complete all mandatory fields (*)")
        } else {
            if(lat===""||long===""){
                setAlert("Please select the property's location on the map.")
            } else {
                doAddProperty();
            }
        }
    }

    const doGetCustomer = async () => {
        try{
            let currentCustomer = await getCustomer(token, id);
            setCustomer(currentCustomer);
        } catch (error) {
            console.log("Error on Get Customers:", error);
        }
    }

    const doGetListTurfTypes = async () => {
        try{
            let currentTurfTypes = await listTurfTypes(token, 0, 100);
            setTurfTypes(currentTurfTypes);
        } catch (error) {
            console.log("Error on List Turf Types:", error);
        }
    }
    
    const doAddProperty = async () => {
        try{
            let addResponse = await addProperty(token, address, zipCode, turfTypesNames[turfType], [lat, long], parseFloat(area), id, parseInt(new Date(datePickerStart).getTime() / 1000), parseInt(new Date(datePickerEnd).getTime() / 1000), "", irrigation? 1: 2, clippings);
            setAddPropertyResponse(addResponse);
        } catch (error) {
            console.log("Error on Add Property:", error);
        }
    }

    const doGenerateGrowthSummary = async (holeId) => {
        try{
            let generateGrowthSummaryRsp = await generateGrowthSummary(token, holeId)
            setGenerateGrowthSummaryResponse(generateGrowthSummaryRsp)
        } catch (error) {
            console.log("Error on Generate Growth Summary:", error);
        }
    }

    useEffect(() => {
        doGetCustomer();
        doGetListTurfTypes();
    }, [])


    useEffect(() => {
        if(turfTypes){
            setTurfTypesNames(turfTypes["data"].reduce((acc,item) => {
                acc[item["turf_type_name"]] = item["id"]
                return acc
                
            }, {}))
        }
    }, [turfTypes]);

    useEffect(() => {
        if(addPropertyResponse){
            if(addPropertyResponse.success === true){
                  doGenerateGrowthSummary(addPropertyResponse.data.hole.id)   
            } else {
                setAlert(addPropertyResponse.message)
            }
        }
    }, [addPropertyResponse])

    useEffect(() => {
        if(generateGrowthSummaryResponse){
            if(generateGrowthSummaryResponse.success === true){
                const propsToPass = {
                    message: addPropertyResponse.message,
                  };

                  navigate('/admin/property/detail/' + addPropertyResponse.data.property.id , { state: propsToPass });
            }
        }
    }, [generateGrowthSummaryResponse])

    useEffect(() => {
        if(customer && customer.success && customer.data){
            setAdress(customer.data.lawn_customer.delivery_address);
            setZipCode(customer.data.lawn_customer.delivery_zip_code);
        }
    }, [customer])

    return (
      <>
        <PageHeader />
        <Container className={alert ? "mt--8": "mt--7"} fluid>
            <div className="col">
                <Row>
                <Col lg="12" className="mb-2">
                <BreadcrumbNavigation active="New property" past={[{"name":"Customers", "link":"/admin/customers"}, {"name":"Details", "link":"/admin/customer/detail/" + id}]}/>
                </Col>
                </Row>
                <Card className="bg-secondary shadow">
                    <CardHeader className="border-0 d-flex modal-header">
                        <div className="d-flex">
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); navigate(-1)}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                        <h3 className="mb-0">&nbsp;New Property</h3>
                        </div>
                        <div className="d-block">
                            <Button
                                color="primary"
                                disabled={!(addressValid&&zipCodeValid&&areaValid)}
                                onClick={(e) =>{e.preventDefault(); onAddClicked();}}
                                size="sm"
                                className="d-block"
                                >
                                Save changes
                            </Button>
                        </div>
                    </CardHeader>
                    {customer && turfTypesNames && customer.data &&
                    <CardBody>
                        <Row >
                        <Col lg="7">
                        {alert&&
                        <Alert color="danger" text={alert} setter={setAlert}></Alert>}
                        <Form>
                        <h6 className="heading-small text-muted mb-4">
                            Property information
                        </h6>
                        <div className="pl-lg-4">
                        <Row>
                        {/* <Col lg="5">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Property name
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Property name"  value={propertyName} setter={setPropertyName} validationType="FREETEXT" message={"Invalid name"} getValidState={setPropertyNameValid}/>
                            </FormGroup>
                        </Col> */}
                        <Col lg="7" className="mb-3">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-mailing-city"
                            >
                                Customer
                            </label>
                            <label
                                className="form-control-label text-muted"
                            >
                                {customer["data"]["lawn_customer"]["name"]}
                            </label>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Address
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Property address"  value={address} setter={setAdress} validationType="FREETEXT" message={"Invalid address"} getValidState={setAdressValid}/>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Zip Code
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Zip code"  value={zipCode} setter={setZipCode} validationType="NUMERIC" message={"Invalid zip code"} getValidState={setZipCodeValid}/>
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        </Row>
                        </div>
                        <hr className="my-2" />
                        <h6 className="heading-small text-muted mb-4">
                            Lawn information
                        </h6>
                        <div className="pl-lg-4">
                        <Row>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Turf Type
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <DropdownList header={turfType} items={Object.keys(turfTypesNames)} setter={setTurfType}/>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                               Area (sq ft)
                               <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Area"  value={area} setter={setArea} validationType="NUMERIC" message={"Invalid area"} getValidState={setAreaValid}/>
                            </FormGroup>
                        </Col>
                        <Col lg="2">
                        <FormGroup>
                            <div className="d-block" >
                            <label
                                className="form-control-label d-block"
                                htmlFor="customCheckRegister"
                            >Irrigation
                            </label>

                                </div>
                                <label className="custom-toggle"  onClick={(e) => {e.preventDefault(); setIrrigation(!irrigation); console.log(irrigation)}}>
                                <input type="checkbox" checked={irrigation} />
                                <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="YES"></span>
                            </label>
                        </FormGroup>
                        </Col>
                        <Col lg="2">
                            <FormGroup>
                                <div className="d-block" >
                                    <label
                                        className="form-control-label d-block"
                                        htmlFor="customCheckRegister"
                                    >Clippings
                                    </label>
                                </div>
                                <label className="custom-toggle">
                                    <RadioButtonGroup labels={["keep","remove"]} setter={(val) => setClippings(val)}/>                   
                                </label>
                            </FormGroup>
                        </Col>
                        </Row>
                        </div>
                        <hr className="my-2" />
                        
                            <h6 className="heading-small text-muted mb-4">
                                Contract information
                            </h6>
                        <div className="pl-lg-4">
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Start Date
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <DatePicker id      = "example-datepicker-start"
                             dateFormat="MM/DD/YYYY"
                                value   = {datePickerStart} 
                                onChange= {(v,f) => handleDateChangeStart(v, f)}
                                
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                End Date
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <DatePicker id      = "example-datepicker"
                             dateFormat="MM/DD/YYYY"
                                value   = {datePickerEnd} 
                                onChange= {(v,f) => handleDateChangeEnd(v, f)}
                                
                                />
                            </FormGroup>
                        </Col>
                       
                        </Row>
                        </div>
                        </Form>
                        </Col>
                        <Col lg="5">
                            <GoogleMapComponent getLat={setLat} getLong={setLong}  draggable={true} searchAddress={address} searchZipCode={zipCode}/>
                        </Col>
                        </Row>
                    </CardBody>
                    }
                </Card>
            </div>
        </Container>
        </>
    );
  };
  
  export default AddProperty;


    