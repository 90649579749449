
import {useState, useEffect} from "react";

import {
  Row,
  Col,
  FormGroup,
  Button,
} from "reactstrap";
import Alert from "components/Alerts/Alert"

import getCompany from "api/company/getCompany";
import addCompany from "api/company/addCompany";
import DropdownList from "components/DropdownList/DropdownList"
import {STATES} from "assets/texts/states"
import { useTokenContext } from "App";
import ValidationInput from "components/ValidationInput/ValidationInput";

function AddCompany({setSuccess, defaultEmail}){
  const {token, setToken} = useTokenContext();
  
  const [companyName, setCompanyName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [email, setEmail] = useState(defaultEmail);
  const [website, setWebsite] = useState("");
  const [regNo, setRegNo] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyState, setCompanyState] = useState("State");
  const [companyCity, setCompanyCity] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  
  const [nameVisible, setNameVisible] = useState(false);
  const [taxVisible, setTaxVisible] = useState(false);
  const [nameExists, setNameExists] = useState(false);
  const [taxIdExists, setTaxIdExists] = useState(false);

  const [companyNameValid, setCompanyNameValid] = useState(true);
  const [taxIdValid, setTaxIdValid] = useState(true);
  const [regNoValid, setRegNoValid] = useState(true);
  const [emailValid, setemailValid] = useState(true);
  const [websiteValid, setWebsiteValid] = useState(true);
  const [companyAddressValid, setCompanyAddressValid] = useState(true);
  const [companyCityValid, setCompanyCityValid] = useState(true);
  const [companyCountryValid, setCompanyCountryValid] = useState(true);
  const [zipCodeValid, setZipCodeValid] = useState(true);

  const [addCompanyResponse, setAddCompanyResponse] = useState(false);
  const [alert, setAlert] = useState(null);

  function onCompanyNameBlur(){
    doCheckCompanyByName();
  }

  function onCompanyTaxIDBlur(){
        doCheckCompanyByTaxId();    
  }

  const doCheckCompanyByName = async () => {
    const lawnManagementCompanyId = localStorage.getItem("LCMId")
    try {
        let nameEx = await getCompany(token, "", companyName, "", lawnManagementCompanyId);
        setNameExists(nameEx);
    } catch (error) {
        console.log("Error on Check Company By Name:", error);
    }
  }

  function onAddCompanyClick(){    
        if(companyName == ""||taxId===""||email===""||companyAddress===""||companyCity===""||companyCountry===""||companyState==="State"||zipCode===""){
            setAlert("Complete all mandatory fields (*)")
        } else {            
                doAddCompany();
            }
        }    
  

  const doCheckCompanyByTaxId = async () => {
    const lawnManagementCompanyId = localStorage.getItem("LCMId")
    try {
        let taxIDEx = await getCompany(token, taxId, "", "", lawnManagementCompanyId);
        setTaxIdExists(taxIDEx);
    } catch (error) {
        console.log("Error on Check Company By TaxID:", error);
    }
  }

  const doAddCompany = async () => {
    try{
        let response = await addCompany(companyName, taxId, companyAddress,companyCity,companyCountry,companyState,zipCode,email,website,token);
        setAddCompanyResponse(response);
    } catch (error) {
        console.log("Error on Add Provider:", error);
    }
  }

  useEffect(() => {
    if(nameExists){
        if(!taxIdExists || (taxIdExists && taxIdExists.success == false)){
            if(nameExists.success == true){
                setNameVisible(true);
                setCompanyName(nameExists["data"]["company_name"]);
                setTaxId(nameExists["data"]["company_tax_id"]);
                // setTypeField3(taxIdExists["data"]["company_registration_number"])
                setEmail(nameExists["data"]["company_email"])
                setWebsite(nameExists["data"]["company_website"])

                setCompanyAddress(nameExists["data"]["company_address"]);
                setCompanyCity(nameExists["data"]["company_city"])
                setCompanyState(nameExists["data"]["company_state"])
                setCompanyCountry(nameExists["data"]["company_country"])
                setZipCode(nameExists["data"]["company_zip_code"])
            } else {
                setNameVisible(false);
            }
    }
    }
  }, [nameExists]);

  useEffect(() => {
    if(taxIdExists){
        if(!nameExists || (nameExists && nameExists.success == false)){
        if(taxIdExists.success == true){
            setTaxVisible(true);
            setCompanyName(taxIdExists["data"]["company_name"]);
            setTaxId(taxIdExists["data"]["company_tax_id"]);
            // setTypeField3(taxIdExists["data"]["company_registration_number"])
            setEmail(taxIdExists["data"]["company_email"])
            setWebsite(taxIdExists["data"]["company_website"])

            setCompanyAddress(taxIdExists["data"]["company_address"]);
            setCompanyCity(taxIdExists["data"]["company_city"])
            setCompanyState(taxIdExists["data"]["company_state"])
            setCompanyCountry(taxIdExists["data"]["company_country"])
            setZipCode(taxIdExists["data"]["company_zip_code"])
        } else {
            setTaxVisible(false);
        }
    }
    }
  }, [taxIdExists]);

  useEffect(() => {
    if(addCompanyResponse){
        if(addCompanyResponse.success){
            setSuccess(addCompanyResponse);
        } else {
            setAlert(addCompanyResponse.message)
        }
    }

  }, [addCompanyResponse])
  
  return (
    <>
        {alert&&
                <Alert color="danger" text={alert} setter={setAlert}></Alert>
            }
        <div className="pl-lg-2 pr-lg-2">                
                <>
                <div>
                <Row className="pt-lg-4">
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-company-name"
                        >
                            Name
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                         <ValidationInput type="text" placeholder="Company name" value={companyName} setter={setCompanyName} validationType="FREETEXT" message={"Invalid name"} mandatory getValidState={setCompanyNameValid}/>
                    </Col>
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-taxId"
                        >
                            TAX ID
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                        <ValidationInput type="text" placeholder="TAX ID" value={taxId} setter={setTaxId} validationType="FREETEXT" message={"Invalid TAX ID"} mandatory getValidState={setTaxIdValid}/>
                    </Col>
                    </Row>
                    <Row className="pt-lg-2">
                    {/* <Col lg="4">
                        <label
                            className="form-control-label"
                            htmlFor="input-reg-no"
                        >
                            Registration Number
                        </label>
                        <ValidationInput type="text" placeholder="Registration number" value={regNo} setter={setRegNo} validationType="FREETEXT_ZERO" message={"Invalid registration no."} getValidState={setRegNoValid} />
                    </Col> */}
                    
                    <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="input-email-cmp"
                        >
                            Email
                            <span>
                            <small style={{ color: "red", borderColor: "red" }}>
                                &nbsp;*
                            </small>
                            </span>
                        </label>
                        <ValidationInput type="email" placeholder="Email" value={email} setter={setEmail} validationType="EMAIL" message={"Invalid email"} getValidState={setemailValid}/>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-website"
                        >
                            Website
                        </label>
                        <ValidationInput type="text" placeholder="Website" value={website} setter={setWebsite} validationType="FREETEXT_ZERO" message={"Invalid website"} getValidState={setWebsiteValid}/>
                        </FormGroup>
                    </Col>
                    </Row>
                    </div>
                    <hr className="my-1" />
                    {/* Address */}
                    <Row>
                        <Col lg="12">
                        <h6 className="heading-small text-muted mb-2">
                            Billing information
                        </h6>
                        <div>
                            <Row >
                                <Col md="12" lg="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                    >
                                        Address
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Billing Address" value={companyAddress} setter={setCompanyAddress} validationType="FREETEXT" message={"Invalid address"}  getValidState={setCompanyAddressValid}/>
                                </Col>
                                <Col lg="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-city"
                                    >
                                        City
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Billing City" value={companyCity} setter={setCompanyCity} validationType="FREETEXT" message={"Invalid city"} getValidState={setCompanyCityValid}/>
                                </Col>
                            </Row>
                            <Row className="pt-lg-2">
                                <Col lg="5">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-billing-state"
                                    >
                                        State
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                    </label>
                                    <DropdownList header={companyState} items={STATES} setter={setCompanyState} />
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                    >
                                        Zip Code
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Zip Code" value={zipCode} setter={setZipCode} validationType="NUMERIC" message={"Invalid zip code"} getValidState={setZipCodeValid}/>
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                    >
                                        Country
                                        <span>
                                        <small style={{ color: "red", borderColor: "red" }}>
                                            &nbsp;*
                                        </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Billing Country" value={companyCountry} setter={setCompanyCountry} validationType="FREETEXT" message={"Invalid country"} getValidState={setCompanyCountryValid}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        </Col>                   
                    </Row>
                </>                
        </div>
        <div className="pt-3 pl-2">
        <Button 
        color="primary" 
        type="button"
        disabled={!(companyNameValid&&taxIdValid&&regNoValid&&emailValid&&websiteValid&&companyCityValid&&companyCountryValid&&companyAddressValid&&zipCodeValid)}
        onClick={(e) => {e.preventDefault(); onAddCompanyClick();}}>
            Add Company
        </Button>
        </div>
    </>
    );
};

export default AddCompany;
