function InfoBox({text}){

  return (
    <div className="d-flex align-items-start">
        <i className="fa-solid fa-circle-info fa-success"></i>&nbsp;&nbsp;&nbsp;
        <div className="d-block">
        {text && text.map((t,idx) => (
            <div id={"info"+idx} key={idx}>
            <small >
            {t}
                </small>
                </div>
        ))}
        </div>
    </div>
    );
};

export default InfoBox;

