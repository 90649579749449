/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState} from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

// import LandImage from "assets/img/background/land.jpg"
import LandImage from "assets/img/background/our-homepage.png"

import routes from "routes.js";
import ParticleEffect from "components/ParticleEffect/ParticleEffect";
import {initParticlesEngine} from "@tsparticles/react";
import {loadFull} from "tsparticles";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [initParticles, setInit] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    if (initParticles) {
      return;
    }

    initParticlesEngine(async (engine) => {
        await loadFull(engine);
    }).then(() => {
        setInit(true);
    });
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
        <div className="main-content" ref={mainContent} style={{position:"absolute", width:"100%", height:"100%"}}>
          <div className="header bg-gradient-info py-0 py-lg-5" style={{position:"absolute", width:"100%", height:"100%"}}>
            <Container>
              <div className="header-body text-center mb-7">
                <AuthNavbar />              
              </div>
            </Container>
            {initParticles && <ParticleEffect></ParticleEffect>}            
            <Container className="mt-0" >
              <Row className="justify-content-center">
                <Routes>
                  {getRoutes(routes)}
                  <Route path="*" element={<Navigate to="/auth/login" replace />} />
                </Routes>
              </Row>
            </Container>
          </div>           
        </div>
    </>
  );
};

export default Auth;



