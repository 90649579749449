import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import {
    Card,
    Row,
    Col,
    Button,
    CardBody,
    Table,
    UncontrolledTooltip,
  } from "reactstrap";
import Alert from "components/Alerts/Alert";
import getGrowthSummary from "api/growthTables/getGrowthSummary";
import generateGrowthSummary from "api/growthTables/generateGrowthSummary";
import DropdownList from "components/DropdownList/DropdownList";
import listRounds from "api/rounds/listRounds";
import generateRounds from "api/rounds/generateRounds";
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";
import InfoBox from "components/InfoBox/InfoBox";
import addContractDetail from "api/contracts/addContractDetail";
import BuildIcon from '@mui/icons-material/Build';
import listApplications from "api/property/listApplications";
import { QTCHECK } from "assets/texts/states";
import { QTCHECK_color } from "assets/texts/states";
import addExtraRound from "api/rounds/addExtraRound";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import listProducts from "api/products/listProducts";
import { getLCMID } from "api/company/getLCMID";
import YardIcon from '@mui/icons-material/Yard';
import GrassIcon from '@mui/icons-material/Grass';
import PestControlIcon from '@mui/icons-material/PestControl';
  
  function PropertyDetailRoundsPane({holeId, holesNames, selectedHole, setSelectedHole, property, reloadHoles, years, year, setYear, holes, programs,hole}){
    const {token, setToken} = useTokenContext();
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState(null);

    const [roundsResponse, setRoundsResponse] = useState(null);
    const [generateRoundsResponse, setGenerateRoundsResponse] = useState(null);
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])

    const [generateGrowthSummaryResponse, setGenerateGrowthSummaryResponse] = useState(null);
    const [growthSummaryResponse, setGrowthSummaryResponse] = useState(null);
    const [addContractDetResponse, setAddContractDetResponse] = useState(null)
    const [viewRounds, setViewRounds] = useState(1)

    const [turfTypePrograms, setTurfTypePrograms]= useState(null)
    const [turfTypeProgramsNames, setTurfTypeProgramsNames]= useState(null)
    const [selectedProgram, setSelectedProgram] = useState("Select...")
    const [applications, setApplications] = useState(null)
    const [addExtraRoundRsp, setAddExtraRoundRsp] = useState(null)
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [products,setProducts]=useState([])
    const tagIcon = {"fertiliser": <YardIcon />, "weed": <GrassIcon />, "pest": <PestControlIcon />}
    
    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
    }
    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])

    function handleGenerateRounds(){
        if(selectedProgram !== "Select..."){
            doAddContractDetail()
        } else {
            setAlert("Complete all mandatory fields (*).")
            setAlertColor("danger")
        }
    }

    const doGenerateGrowthSummary = async () => {
        try{
            let generateGrowthSummaryRsp = await generateGrowthSummary(token, holeId)
            setGenerateGrowthSummaryResponse(generateGrowthSummaryRsp)
        } catch (error) {
            console.log("Error on Generate Growth Summary:", error);
        }
    }
    const doGetGrowthSummary = async (year) => {
        try{
            let growthSummaryResponse = await getGrowthSummary(token, holeId, year)
            setGrowthSummaryResponse(growthSummaryResponse)
        } catch (error) {
            console.log("Error on Get Growth Summary:", error);
        }
    }

    const doAddExtraRound = async number => {
        try {
            let rsp = await addExtraRound(token, holeId,  Math.floor((new Date()).getTime() / 1000), number, 1)
            setAddExtraRoundRsp(rsp)
        } catch (error) {
            console.log("Error on Add Extra Round:", error);
        }
    }

    useEffect(() => {
        doGetGrowthSummary(year);
    }, [holeId, generateGrowthSummaryResponse])

    useEffect(() => {
        if(growthSummaryResponse && property){
            if(growthSummaryResponse.success == false){
                doGenerateGrowthSummary();
            }
        }
    },[growthSummaryResponse, property])

    useEffect(() => {
        if(programs && hole && programs.data){
            setTurfTypePrograms(programs.data.filter((program) => program.turf_type_id == hole.data.turf_type.id))
        }
    },[programs, hole])

    useEffect(() => {
        if(turfTypePrograms){
            setTurfTypeProgramsNames(turfTypePrograms.reduce((acc, item) => {
                acc[item["program_name"]] = item["id"]
                return acc;
            }, {}))
        }
    },[turfTypePrograms])
    
    function onAddApplicationClicked(roundId){
        navigate("/admin/round/configure/"  + roundId);
    }

    const doListRounds = async () => {
        try{
            let rsp = await listRounds(token, holeId,"")
            setRoundsResponse(rsp)
        } catch (error) {
            console.log("Error on List Rounds:", error);
        }
    }

    useEffect(() => {
        if(roundsResponse && roundsResponse.success && roundsResponse.data && roundsResponse.data.length > 0){
            doListApplications()
        }
    },[roundsResponse])

    const doListApplications = async () => {
        try{
            let rsp = await listApplications(token, "",holeId,"", "Unscheduled")
            setApplications(rsp)
        } catch (error) {
            console.log("Error on List Rounds:", error);
        }
    }

    const doGetProducts = async () => {
        try{
            let rsp = await listProducts(token, "true", lawnManagementCompanyId);
            setProducts(rsp.data);
        } catch (error) {
            console.log("Error on List Products:", error);
        } 
    };  

    useEffect(()=>{
        if(applications && applications.success && applications["data"] && applications["data"].length > 0){
            doGetProducts()
        }
    },[applications])


    const doGenerateRounds = async () => {
        try{
            let rsp = await generateRounds(token, holeId)
            setGenerateRoundsResponse(rsp)
        } catch (error) {
            console.log("Error on Generate Growth Summary:", error);
        }
    }

    const doAddContractDetail = async () => {
        try{
            let rsp = await addContractDetail(token, holeId, property["property"]["id"], turfTypeProgramsNames[selectedProgram]  )
            setAddContractDetResponse(rsp)
        } catch (error) {
            console.log("Error on Add Contract Detail:", error);
        }
    }

    useEffect(() => {
        
        if(holes && holeId && selectedHole){
            doListRounds();
        }
    }, [holeId, generateRoundsResponse, year, holes, addExtraRoundRsp])

    useEffect(() => {
        if(addContractDetResponse){
            if(addContractDetResponse.success){
                doGenerateRounds();
                setAlertColor("success")
                setAlert("Contract details have been updated.")
                reloadHoles()
                
            }else {
              setAlertColor("danger")
              setAlert(addContractDetResponse.message)
            }
          
        }
    },[addContractDetResponse])
    
    useEffect(() => {
        if(hole && hole.success && hole.data && hole.data.contract_detail){
            if(hole.data.contract_detail.program_id){
                setViewRounds(1)
            }
        }  else {
            setViewRounds(2)
        }
    },[hole])

    return (
      <>
        <Card className=" bg-secondary shadow" style={{borderTop:0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <CardBody>
                    {alert&&
                        <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
                    }
                    <div className="d-flex modal-header pt-1 pb-2">
                            <div className="d-block">
                                <div className="d-flex pt-1 pb-2">
                                <h6 className="heading-small text-muted  mr-3">
                                    Rounds
                                </h6>
                                <div>
                                        <i className="fa-regular fa-circle-question pb-2" id="unit"></i>
                                    <UncontrolledTooltip
                                        target="unit"
                                    >
                                
                                Rounds divide the yearly Season Demand evenly for each application, covering nutrient needs until the next round.
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                            <div className="d-block">
                                    <h4>
                                {roundsResponse && roundsResponse["data"] && roundsResponse["data"].length > 0 && roundsResponse["data"][0].program_name}
                                    </h4>
                                </div>
                        </div>
                        {holesNames && <span className="d-flex align-items-center">
                            <h6 className="heading-small text-muted mr-3">
                                Zone
                            </h6>
                            <DropdownList header={selectedHole} items={Object.keys(holesNames)} setter={setSelectedHole}/>
                            
                            <h6 className="heading-small text-muted mr-3 ml-3">
                            Year
                        </h6>
                          {years && year && <DropdownList header={year} items={years} setter={setYear}/>}
                          </span>
                          
                        }
                    </div>
                    
                {viewRounds == 1 ?
                <>
                <div className="pl-lg-3">
                    <div>
                        {applications && applications["data"] &&
                    <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); doAddExtraRound(applications["data"].length+1);}}
                        size="md"
                        className="mb-4"
                        
                        >
                        + Add extra round
                        </Button>
                        }
                    </div>
                <Table className="align-items-center table-light table-sm" responsive>
                  <thead className="thead-dark">
                    <tr> 
                      <th scope="col">No.</th>
                      <th scope="col">Products</th>
                      <th scope="col">Tags</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="justify-content-end text-right"  style={{width:"1vw", overflow:"hidden", textOverflow:"ellipsis"}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {roundsResponse && roundsResponse.success && roundsResponse["data"] && 
                  <>
                    {roundsResponse["data"].length == 0 ?
                    <>
                    </>
                    :
                    <>
                    { applications && applications["data"] && 
                        applications["data"].sort((a, b) => {
                        // Convertim valorile "created_at" în obiecte Date pentru a le compara corect
                        if (a.application.created_at != b.application.created_at){
                            return b.application.created_at-a.application.created_at
                        } else {
                           
                            return a.application.application_date - b.application.application_date
                        }
                    }).slice(startIndex, endIndex).map((application,idx) => (
                        <tr id={application["application"]["id"]} key={idx}>
                        <th>
                        {"Round " + application["round_number"]}
                        </th>

                        {/* making a list of products for that round and show their names (names weren't present in application["products"], 
                        so listProducts was needed - alson order them in this order:fertilsier, weed, pest control - like in the program builder */}
                       <th scope="col">
                            {application["products"] && application["products"].length > 0 ? (
                                <Stack direction="row" spacing={3}>
                                    {application["products"]
                                        .map((product, productIdx) => {
                                            const matchedProducts = products.find(prod => prod.id === product.product_id);
                                            return matchedProducts ? { ...matchedProducts, productIdx } : null;
                                        })
                                        .filter(Boolean) // Remove nulls from the array in case of unmatched products
                                        .sort((a, b) => {
                                            const categoryOrder = { fertiliser: 1, weed_control: 2, pest_control: 3 };
                                            return categoryOrder[a.category] - categoryOrder[b.category];
                                        })
                                        .map(({ name, productIdx }) => (
                                            <Chip label={name} key={productIdx} />
                                        ))
                                    }
                                </Stack>
                            ) : (
                                "-"
                            )}
                        </th>

                        {/* show tags for the products present in that round and order tags like: fertiliser first, then weed, then pest*/}
                        <th scope="col">
                            {application["products"] && application["products"].length > 0 ? (
                                <Stack direction="row" spacing={3}>
                                    {(() => {
                                        const renderedCategories = new Set();
                                        return application["products"]
                                            .map((product) => {
                                                const matchedProducts = products.find(prod => prod.id === product.product_id);
                                                return matchedProducts;
                                            })
                                            .filter(Boolean) // Remove null values (unmatched products)
                                            .sort((a, b) => {
                                                const categoryOrder = { fertiliser: 1, weed_control: 2, pest_control: 3 };
                                                return categoryOrder[a.category] - categoryOrder[b.category];
                                            })
                                            .filter((matchedProducts) => {
                                                if (!renderedCategories.has(matchedProducts.category)) {
                                                    renderedCategories.add(matchedProducts.category);
                                                    return true;
                                                }
                                                
                                                return false;
                                            })
                                            .map((matchedProducts) => (
                                                matchedProducts.category === "fertiliser" ? (
                                                    <Chip label="fertilizer" key="fertiliser" variant="outlined" icon={tagIcon["fertiliser"]} />
                                                ) : matchedProducts.category === "weed_control" ? (
                                                    <Chip label="weed" key="weed_control" variant="outlined" icon={tagIcon["weed"]} />
                                                ) : (
                                                    <Chip label="pest" key="pest_control" variant="outlined" icon={tagIcon["pest"]} />
                                                )
                                            ));
                                    })()}
                                </Stack>
                            ) : (
                                "-"
                            )}
                        </th>

                        <th scope="col" className={"text-"+QTCHECK_color[application["application"]["qt_check"]]}>{QTCHECK[application["application"]["qt_check"]]}</th>
                        <td className="justify-content-end text-center">
                        {application["application"]["status"]["StatusValue"] == "Unscheduled" ?
                        <Button
                            className="text-grey"
                            type="button"
                            size="sm"
                            color=""  
                            iconOnly
                            onClick={() => onAddApplicationClicked(application["application"]["round_id"])}
                            
                        >
                           <BuildIcon/>
                        </Button>

                        :
                        <strong>
                            {application["application"]["status"]["StatusValue"]}
                        </strong>
                        
                        }
                        </td>
                        </tr>

                  ))}
                  </>
                    }
                    </>
                    }
                    </tbody>
                    </Table>
                    </div>
                    {applications && applications.success && applications["data"] && 
                        <div className="mt-3">
                            <PaginationComponent data={applications["data"] } itemsPerPage={constants["ROWS_PER_PAGE"]} getStart={setStartIndex} getEnd={setEndIndex}/>
                        </div>
                    }
                </>
                :
                <>
                <div className="pl-lg-3">
                <Row className="align-items-start">
                {turfTypeProgramsNames ?
                <Col lg="3" className="align-items-center">
                <label
                        className="form-control-label"
                        htmlFor="input-mailing-city"
                    >
                        Select program
                        <span>
                        <small style={{ color: "red", borderColor: "red" }}>
                            &nbsp;*
                        </small>
                        </span>
                    </label>
                        <div className="d-flex">
                        <div className="d-block">
                       <DropdownList header={selectedProgram} items={Object.keys(turfTypeProgramsNames)}  setter={setSelectedProgram}></DropdownList>                    
                        </div>
                        </div>
                    </Col>
                    :
                    <div className="mt-4 mb-4 ml-2">
                    <InfoBox text={["No program registered.", "Go to program builder to add your first program."]}/>
                    </div>
                 }
                
                </Row>
                <Row>
                    {turfTypeProgramsNames &&
                <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); handleGenerateRounds()}}
                        size="md"
                        className="mt-3 ml-3"
                        
                        >
                        Generate rounds
                        </Button>
                    }
                </Row>
                </div>
                </>
                }
                
            </CardBody>
        </Card>
        </>
    );
  };
  
  export default PropertyDetailRoundsPane;


    