import {postRequest, URL_SERVER} from '../requests'


export default async function addProduct2ProgramBuilder(token, program_id, product_id, lawnManagementCompanyId, round_id, rate){
    let data = {
        "program_id": program_id,
        "product_id": product_id,
        "round_id": round_id,
        "lawn_management_company_id": lawnManagementCompanyId,
        "rate": rate,
    }
    try {
        return await postRequest(URL_SERVER + 'product2programBuilder', data, token)
    } catch (error) {
        console.error(error);
    }

}
