import {getRequest, URL_SERVER} from './../requests'


export default async function generateRounds(token, holeId){
    try {
        return await getRequest(URL_SERVER + "rounds/generate/" + holeId , token)
    } catch (error) {
        console.error(error);
    }

}