// GoogleMapComponent.js
import React from 'react';
import {useState, useEffect, setError, useRef} from "react"
import { GoogleMap, LoadScript, Marker, Autocomplete, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { getFormattedTime } from 'utils/utils';
import { Button } from 'reactstrap';

const librariesMap = ['places']

const containerStyle = {
  width:  "100%",
  height: "100%",
};

const defaultCenter = {
  lat: 38.177832,
  lng: -102.3230653
};


const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  mapTypeId: 'hybrid'
  // fullscreenControl: false 
};


function GoogleMapComponent({getLat, getLong, markerSet, draggable, markers, onClickSetStatusDone, onViewApplicationClicked, onViewRoundClicked, searchAddress, searchZipCode}){
const [location, setLocation] = useState(null);
const [markerPosition, setMarkerPosition] = useState(defaultCenter);
const [infoWindowOpen, setInfoWindowOpen] = useState(false);
const [infoWindowPos, setInfoWIndowPos] = useState(null)
const [center, setCenter] = useState(defaultCenter);
const [zoom, setZoom] = useState(5);
var geocoder;

const toggleInfoWindow = (m) => {
  setInfoWindowOpen(!infoWindowOpen);
  setInfoWIndowPos(m)
};

const { isLoaded, loadError } = useLoadScript({
  googleMapsApiKey: 'AIzaSyAuDqmNg1Ui4n8kLjBL1QSeMrzwKHdEm4c',
  libraries: librariesMap
});

  useEffect(() => {
    if(isLoaded){
    if(markers){
      if(markers.length>0){
         //Calculate bounds
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(marker => {
          let pos = {
            lat: parseFloat(marker.gps[0]),
            lng: parseFloat(marker.gps[1]),
          }
            bounds.extend(pos);
        });
        
        var boundsCenter = bounds.getCenter();
        setCenter(boundsCenter)
        setZoom(getZoomLevel(bounds))
      }
    } else {
      if(markerSet){
        setLocation(markerSet);
        setMarkerPosition(markerSet)
      }
      else {
        if(searchZipCode || searchAddress){
          geocoder = new window.google.maps.Geocoder();
          geocoder.geocode( { 'address': searchAddress + " " +searchZipCode}, function(results, status) {
            if (status == 'OK') {
              setLocation(results[0].geometry.location);
              setMarkerPosition(results[0].geometry.location)
              if(getLat){
                getLat(results[0].geometry.location.lat);
                getLong(results[0].geometry.location.lng);
              }
            }
          });
        } 
        else{
          if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                let pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                }
                setLocation(pos);
                setMarkerPosition(pos)
                if(getLat){
                  getLat(pos.lat);
                  getLong(pos.lng);
                }
              },
              function (error) {
                console.log(error.message);
              }
            );
          } else {
            console.log("Geolocation is not supported by this browser.");
          }
      }
    }
  }
    }
  }, [markerSet, markers, searchAddress, searchZipCode, isLoaded]);

  const handleMarkerClick = (event) => {   
    if(!markerSet && event.latLng){
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      setMarkerPosition(newPosition);

      if(getLat){
        getLat(newPosition.lat)
        getLong(newPosition.lng)
      }
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

   function getZoomLevel(bounds) {
       const WORLD_DIM = { height: 256, width: 256 };
       const ZOOM_MAX = 15;

       const ne = bounds.getNorthEast();
       const sw = bounds.getSouthWest();

       const latFraction = (Math.abs(ne.lat() - sw.lat())) / 180;
       const lngDiff = ne.lng() - sw.lng();

       const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

       const latZoom = parseFloat(Math.floor(Math.log(WORLD_DIM.height / latFraction / 256) / Math.log(2)));
       const lngZoom = parseFloat(Math.floor(Math.log(WORLD_DIM.width / lngFraction / 256) / Math.log(2)));

       return Math.min(latZoom, lngZoom, ZOOM_MAX);
   }

  return (
    <>
    {isLoaded &&
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location? location : center}
        zoom={location? 14: zoom}
        onClick={handleMarkerClick}
        options={mapOptions}
      >
      {!markers && <Marker id={"marker-simple"} position={markerPosition}  draggable={draggable} onDragEnd={handleMarkerClick}/>}
      {markers &&
      markers.map((m, idx) => (
        <Marker 
          id={"marker-" + idx} 
          position={{lat: m["gps"][0], lng: m["gps"][1]}}  
          draggable={false} 
          icon={m["icon"]}
          onClick={() => toggleInfoWindow(m)}
          key={idx}
          />
      ))
      }
       {infoWindowOpen && infoWindowPos["type"] === "scheduled" && (
        <InfoWindow
        position={{lat: infoWindowPos["gps"][0], lng: infoWindowPos["gps"][1]}}
          onCloseClick={toggleInfoWindow}
        >
          <div>
            {/* infoWindow */}
            <strong className="mb-0" style={{fontWeight:"bold", marginRight:"10px"}}>{"Customer: " + infoWindowPos["customer_name"]}</strong>
            <h7 className="mb-2 d-block" style={{fontWeight:"bolder"}}>{infoWindowPos["address"]}</h7>
            <h7 className="mb-0 d-block" style={{fontWeight:"bolder"}}>{"Date: " + getFormattedTime(infoWindowPos["date"])}</h7>
            <h7 className="mb-3 d-block" style={{fontWeight:"bolder"}}>{"Assigned worker: " + infoWindowPos["worker"]}</h7>
            <div className='mb-2 d-flex justify-content-around'>
              {new Date().setHours(0, 0, 0, 0) >= new Date(infoWindowPos["date"] * 1000).setHours(0, 0, 0, 0) &&
                <Button className="btn-sm" color="success" onClick={() => onClickSetStatusDone(infoWindowPos["id"], "Done")} >
                  <i className="fa-solid fa-circle-check"></i>
                      &nbsp;Done
                  </Button>
              }
              <Button className="btn-sm" color="secondary" onClick={() => onViewApplicationClicked(infoWindowPos["id"])} >
              <i className="fa-solid fa-eye"></i>
                  &nbsp;View
              </Button>
            </div>
          </div>
        </InfoWindow>
      )}
      {infoWindowOpen && infoWindowPos["type"] === "round" && (
        <InfoWindow
        position={{lat: infoWindowPos["gps"][0], lng: infoWindowPos["gps"][1]}}
          onCloseClick={toggleInfoWindow}
        >
          <div>
            <strong className="mb-0" style={{fontWeight:"bold", marginRight:"10px"}}>{"Customer: " + infoWindowPos["customer_name"]}</strong>
            <h7 className="mb-2 d-block" style={{fontWeight:"bolder"}}>{infoWindowPos["address"]}</h7>
            <h7 className="mb-0 d-block" style={{fontWeight:"bolder"}}>{"Date: " + getFormattedTime(infoWindowPos["date"])}</h7>
            <div className='mb-2 d-flex justify-content-around'>
              <Button className="btn-sm mt-2" color="secondary" onClick={() => onViewRoundClicked(infoWindowPos["id"])} >
              <i className="fa-solid fa-eye"></i>
                  &nbsp;View
              </Button>
            </div>
          </div>
        </InfoWindow>
      )}
            {infoWindowOpen && infoWindowPos["type"] === "completed" && (
        <InfoWindow
        position={{lat: infoWindowPos["gps"][0], lng: infoWindowPos["gps"][1]}}
          onCloseClick={toggleInfoWindow}
        >
          <div>
            <strong className="mb-0" style={{fontWeight:"bold", marginRight:"10px"}}>{"Customer: " + infoWindowPos["customer_name"]}</strong>
            <h7 className="mb-2 d-block" style={{fontWeight:"bolder"}}>{infoWindowPos["address"]}</h7>
            <h7 className="mb-0 d-block" style={{fontWeight:"bolder"}}>{"Date: " + getFormattedTime(infoWindowPos["date"])}</h7>
            <h7 className="mb-3 d-block" style={{fontWeight:"bolder"}}>{"Assigned worker: " + infoWindowPos["worker"]}</h7>
            <div className='mb-2 d-flex justify-content-around'>
              <Button className="btn-sm mt-2" color="secondary" onClick={() => onViewApplicationClicked(infoWindowPos["id"])} >
              <i className="fa-solid fa-eye"></i>
                  &nbsp;View
              </Button>
            </div>
          </div>
        </InfoWindow>
      )}
      </GoogleMap>
    }
    </>
  );
};

export default GoogleMapComponent;
