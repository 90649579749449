import {postRequest, URL_SERVER} from '../requests'


export default async function addEmployee(provider_id, email, firstName, lastName, mobile, token){
    const data = {
        "provider_id": provider_id,
        "email": email,
        "mobile": mobile,
        "create_account": 2
        }
    if(firstName!=""){
        data["first_name"] = firstName;
        data["last_name"] = lastName;
    }
    try {
        return await postRequest(URL_SERVER + 'employee', data, token)
    } catch (error) {
        console.error(error);
    }

}