import {postRequest, URL_SERVER} from './../requests'


export default async function register(username, first_name, last_name, password, legal_type1, legal_version1, legal_type2, legal_version2){
    const data = {
        "email": username,
        "first_name": first_name,
        "last_name": last_name,
        "password": password,
        "legal_type1": legal_type1,
        "legal_version1": legal_version1,
        "legal_type2": legal_type2,
        "legal_version2": legal_version2
        }
    try {
        return await postRequest(URL_SERVER + '/user/admin/register', data)
    } catch (error) {
        console.error(error);
    }
}