import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CardBody,
    } from "reactstrap";
import PageHeader from "components/Headers/PageHeader.js";
import listTurfTypes from "api/property/listTurfTypes";
import Alert from "components/Alerts/Alert";
import ValidationInput from "components/ValidationInput/ValidationInput";
import DropdownList from "components/DropdownList/DropdownList";
import GoogleMapComponent from "components/GoogleMaps/GoogleMapComponent";
import addHole from "api/property/addHole";
import getProperty from "api/property/getProperty";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import { getLCMID } from "api/company/getLCMID";
import generateGrowthSummary from "api/growthTables/generateGrowthSummary";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";
  
  const AddHole = () => {

    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [turfTypes, setTurfTypes] = useState(null);
    const [turfTypesNames, setTurfTypesNames] = useState(null);
    const [turfType, setTurfType] = useState("Select...");
    const { id } = useParams();

    const [property, setProperty] = useState(null);
    const [holeName, setHoleName]= useState("")
    const [area, setArea]= useState("")
    const [lat, setLat]= useState("")
    const [long, setLong]= useState("")
    const [irrigation, setIrrigation] = useState(false)
    const [generateGrowthSummaryResponse, setGenerateGrowthSummaryResponse] = useState(null)

    const [holeNameValid, setHoleNameValid]= useState(true)
    const [areaValid, setAreaValid]= useState(true)

    const [addHoleResponse, setAddHoleResponse] = useState(null)
    const [clippings, setClippings] = useState(1)


    function onAddHoleButtonClicked(){
        if(turfType==="Select..."||holeName===""||area===""){
            setAlert("Complete all mandatory fields (*)")
        } else {
            if(lat===""||long===""){
                setAlert("Please select the property's location on the map.")
            }else {
                doAddHole();
                navigate()
            }
        }
    }

    const doGetListTurfTypes = async () => {
        try{
            let currentTurfTypes = await listTurfTypes(token, 0, 100);
            setTurfTypes(currentTurfTypes);
        } catch (error) {
            console.log("Error on List Turf Types:", error);
        }
    }

    const doGetProperty = async () => {
        try{
            let propResponse = await getProperty(token, id, 0,100);
            setProperty(propResponse);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }
    
    const doAddHole = async () => {
        try{
            let addHoleResponse = await addHole(token, holeName, id, turfTypesNames[turfType],  [lat, long], parseFloat(area), "", irrigation? 1: 2, clippings);
            setAddHoleResponse(addHoleResponse);
        } catch (error) {
            console.log("Error on Add Property:", error);
        }
    }

    const doGenerateGrowthSummary = async (holeId) => {
        try{
            let generateGrowthSummaryRsp = await generateGrowthSummary(token, holeId)
            setGenerateGrowthSummaryResponse(generateGrowthSummaryRsp)
        } catch (error) {
            console.log("Error on Generate Growth Summary:", error);
        }
    }

    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
      }
  
      useEffect(() => {
      if(lawnManagementCompanyId){
        doGetProperty();
        doGetListTurfTypes();
      }
      }, [lawnManagementCompanyId])
  
      useEffect(() => {
          if(id, token){
              getLawnManagementId()
          }
      }, [id,token])

    useEffect(() => {
        if(turfTypes){
            setTurfTypesNames(turfTypes["data"].reduce((acc,item) => {
                acc[item["turf_type_name"]] = item["id"]
                return acc
                
            }, {}))
        }
    }, [turfTypes]);

    useEffect(() => {
        if(addHoleResponse){
            if(addHoleResponse.success === true){
                  doGenerateGrowthSummary(addHoleResponse.data.hole.id)
            } else {
                setAlert(addHoleResponse.message)
            }
        }
    }, [addHoleResponse])

    useEffect(() => {
        if(generateGrowthSummaryResponse){
            if(generateGrowthSummaryResponse.success === true){
                // doGenerateRounds(addHoleResponse.data.hole.id)
                const propsToPass = {
                    message: addHoleResponse.message,
                    pane: 0
                  };
                  navigate('/admin/property/detail/' + id, { state: propsToPass });
            }
        }
    }, [generateGrowthSummaryResponse])    

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className={alert ? "mt--8": "mt--7"} fluid>
            
            <div className="col">
                <Row>
                    <Col lg="12" className="mb-2">
                        {property && property.data &&
                    <BreadcrumbNavigation active="New Zone" past={[{"name":"Customers", "link":"/admin/customers"}, {"name":"Details", "link":"/admin/customer/detail/" + property["data"]["property"]["customer_id"]},{"name": property["data"]["property"]["address"], "link":"/admin/property/detail/" + id}]}/>
                        }
                    </Col>
                </Row>
                <Card className="bg-secondary shadow">

                    <CardHeader className="border-0 d-flex modal-header">
                        <div className="d-flex">
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault();navigate(-1)}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                        {property && <h3 className="mb-0">&nbsp;{"New Zone for Property "+ property["data"]["property"]["address"]}</h3>}
                        </div>
                        <Button
                            color="primary"
                            disabled={!(holeNameValid&&areaValid)}
                            onClick={(e) =>{e.preventDefault(); onAddHoleButtonClicked();}}
                            size="sm"
                            
                            >
                        + Add
                            </Button>
                    </CardHeader>
                    {property && turfTypesNames &&
                    <CardBody>
                        <Row>
                        <Col lg="7">
                        {alert&&
                        <Alert color="danger" text={alert} setter={setAlert}></Alert>
                        }
                        <Form>
                        <h6 className="heading-small text-muted mb-4">
                            Property information
                        </h6>
                        <div className="pl-lg-4">
                        <Row>
                        {/* <Col lg="5">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Property name
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Property name"  value={property["data"]["property"]["property_name"]} disabled={true}/>
                            </FormGroup>
                        </Col> */}
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Customer
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <ValidationInput type="text" placeholder="Customer"  value={property["data"]["customerName"]} disabled={true}/>
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Address
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Property address"  value={property["data"]["property"]["address"]} disabled={true}/>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Zip Code
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Zip code"  value={property["data"]["property"]["zip_code"]} disabled={true}/>
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        </Row>
                        </div>
                        <hr className="my-2" />
                            <h6 className="heading-small text-muted mb-4">
                                Lawn information
                            </h6>
                        <div className="pl-lg-4">
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Zone Name
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <ValidationInput type="text" placeholder="Hole Name"  value={holeName} setter={setHoleName} validationType="LETTERSANDNUMBERS" message={"Invalid name"} getValidState={setHoleNameValid}/> 
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Turf Type
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <DropdownList header={turfType} items={Object.keys(turfTypesNames)} setter={setTurfType}/>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                               Area (sq ft)
                               <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                             <ValidationInput type="text" placeholder="Area"  value={area} setter={setArea} validationType="NUMERIC" message={"Invalid area"} getValidState={setAreaValid}/>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                        <FormGroup>
                            <div className="d-block" >

                            
                            <label
                                className="form-control-label d-block"
                                htmlFor="customCheckRegister"
                            >Irrigation
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                            </label>

                                </div>
                                <label className="custom-toggle"  onClick={(e) => {e.preventDefault(); setIrrigation(!irrigation); console.log(irrigation)}}>
                                <input type="checkbox" checked={irrigation} onChange={() => {}}/>
                                <span className="custom-toggle-slider rounded-circle" data-label-off="NO" data-label-on="YES"></span>
                            </label>
                        </FormGroup>
                        </Col>
                        <Col lg="2">
                        <FormGroup>
                            <div className="d-block" >

                            
                            <label
                                className="form-control-label d-block"
                                htmlFor="customCheckRegister"
                            >Clippings
                            </label>

                                </div>
                                <label className="custom-toggle">
                                <RadioButtonGroup labels={["keep","remove"]} setter={(val) => setClippings(val)}/>                   
                            </label>
                        </FormGroup>
                        </Col>
                        </Row>
                        </div>
                       
                        </Form>
                        </Col>
                        <Col lg="5" style={{mixWidth:"50%", minHeight:"300px"}}>
                        <GoogleMapComponent getLat={setLat} getLong={setLong}  draggable={true} searchAddress={property["data"]["property"]["address"]} searchZipCode={property["data"]["property"]["zip_code"]}/>
                        </Col>
                        </Row>
                    </CardBody>
                    }
                </Card>
            </div>
        </Container>
        </>
    );
  };
  
  export default AddHole;


    