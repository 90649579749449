import {useState, useEffect, createContext, useContext} from "react";
import { Link } from "react-router-dom";
import {BrowserRouter as Router, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import PaginationComponent from "components/Pagination/Pagination";
import { constants } from "assets/constants/constants";

import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Container,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
  } from "reactstrap";
  // core components
  import PageHeader from "components/Headers/PageHeader.js";
  import listProducts from "api/products/listProducts"
  import {convert_timestamp_to_date} from "utils/utils"
  import ModalDialog from "components/ModalDialog/ModalDialog";
  import Alert from "components/Alerts/Alert";
  import { getFormattedTime } from "utils/utils";
  import AddTemplate from "./AddTemplate";
  import ValidationInput from "components/ValidationInput/ValidationInput";
  import { MultiSelect } from "react-multi-select-component";
  import Chip from '@mui/material/Chip';
  import Stack from '@mui/material/Stack';

import { getLCMID } from "api/company/getLCMID";
import listProgramBuilders from "api/programBuilder/listProgramBuilders";
import deleteProduct2ProgramBuilder from "api/products/deleteProduct2ProgramBuilder";
import DropdownList from "components/DropdownList/DropdownList";
import addProduct2ProgramBuilder from "api/products/addProduct2ProgramBuilder";
import deleteProgramBuilder from "api/products/deleteProgramBuilder";
  
  const ProgramBuilder = () => {
    const {token, setToken} = useTokenContext();
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState("success")
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [programBuilders, setProgramBuilders] = useState(null)
    const [startProgramIndex, setStartProgramIndex] = useState(0)
    const [endProgramIndex, setEndProgramIndex] = useState(6)
    const [deleteProgramBuilderRsp, setDeleteProgramBuilderRsp] = useState(null)

    const doGetListProgramBuilders = async () => {
      try{
          let rsp = await listProgramBuilders(token, lawnManagementCompanyId);
          setProgramBuilders(rsp);
      } catch (error) {
          console.log("Error on List Products:", error);
      }
  }

    function onAddTemplateClick(){
      // setAddTemplate(true)
      navigate("/admin/programBuilder/new")
    }

    function onViewProgramBuilder(programBuilderId){
      console.log("/admin/programBuilder/details/" + programBuilderId)
      navigate("/admin/programBuilder/details/" + programBuilderId)
    }


    function onDeleteProgramBuilder(programBuilderId){
      doDeleteProgramBuilder(programBuilderId)
    }

    async function getLawnManagementId () {
      setLawnManagementCompanyId(await getLCMID(token))
    }

    async function doDeleteProgramBuilder(programBuilderId){
      try{
        let rsp = await deleteProgramBuilder(token, programBuilderId);
        setDeleteProgramBuilderRsp(rsp);
      } catch (error) {
          console.log("Error on List Products:", error);
      }
    }

    useEffect(() => {
      if(deleteProgramBuilderRsp){
        if(deleteProgramBuilderRsp.success){
          setAlert(deleteProgramBuilderRsp.message)
          setAlertColor("success")
          doGetListProgramBuilders();
        } else {
          setAlert(deleteProgramBuilderRsp.message)
          setAlertColor("danger")
        }
      }
    },[deleteProgramBuilderRsp])

    useEffect(() => {
    if(lawnManagementCompanyId){
        doGetListProgramBuilders();
    }
    }, [lawnManagementCompanyId])

    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])


    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          {alert&&
            <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
          }

          <Row>

            <div className="col">

              <Card className="shadow mb-4">
                <CardHeader className="border-0 d-flex modal-header">
                  <h3 className="mb-0">Program Builder</h3>
                  <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddTemplateClick();}}
                        size="sm"
                        
                        >
                       + Add new program
                        </Button>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      {/* <th scope="col">Products</th> */}
                      {/* <th style={{width:"5rem"}}></th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {programBuilders && programBuilders["data"] &&programBuilders["data"]&& programBuilders["data"].slice(startProgramIndex, endProgramIndex).map((programBuild, idx) => (
                        <tr id={programBuild["id"]} key={idx}>
                        <th scope="row" style={{maxWidth:"50vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        {programBuild["program_name"]}
                        </th>
                        <th className="text-right">
                          <Button
                          className="btn-icon-only"
                          type="button"
                          size="sm"
                          color="secondary"
                          onClick={() => (onViewProgramBuilder(programBuild["id"]))}
                          >
                          <i className="fas fa-eye" />
                          </Button>
                        <Button
                      
                          color="danger"
                          type="button"
                          size="sm"
                          
                          onClick={() => (onDeleteProgramBuilder(programBuild["id"]))}
                          >
                         <i className="fa-solid fa-trash"></i>
                          </Button>                        
                        </th>
                        </tr>
                  ))}
                    </tbody>
                </Table>
                {programBuilders && programBuilders.success && programBuilders["data"]&& 
                        <div className="mt-3">
                            <PaginationComponent data={programBuilders["data"]} itemsPerPage={6} getStart={setStartProgramIndex} getEnd={setEndProgramIndex}/>
                        </div>
                    }
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
  };
  
  export default ProgramBuilder;


    