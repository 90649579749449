import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    CardBody,
  } from "reactstrap";
  // core components
import PageHeader from "components/Headers/PageHeader.js";
import Alert from "components/Alerts/Alert";
import ValidationInput from "components/ValidationInput/ValidationInput";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import GoogleMapComponent from "components/GoogleMaps/GoogleMapComponent";
import { getLCMID } from "api/company/getLCMID";
import listTurfTypes from "api/property/listTurfTypes";
import DropdownList from "components/DropdownList/DropdownList";
import addProgramBuilder from "api/products/addProgramBuilder";
import generateGrowthSummaryProgram from "api/programBuilder/generateGrowthSummaryProgram";

  const AddTemplate = () => {

    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState(null);
    const [name, setName] = useState(null);
    const [zipCode, setZipCode] = useState(null)

    const [turfTypes, setTurfTypes] = useState(null);
    const [turfTypesNames, setTurfTypesNames] = useState(null);
    const [turfType, setTurfType] = useState("Select...");
    
    const [nameValid, setNameValid] = useState(true)
    const [zipCodeValid, setZipCodeValid] = useState(true)
    const [lat, setLat]= useState("")
    const [long, setLong]= useState("")
    const [addProgramBuilderRsp, setAddProgramBuilderRsp] = useState(null)
    const [generateGrowthSummaryRsp, setGenerateGrowthSummaryRsp] = useState(null)
    const [programId, setProgramId] = useState(null)

    function onClickGenerateProgram(){
        if(turfType==="Select..."||zipCode===""||name===""){
            setAlert("Complete all mandatory fields (*)")
        } else {
            if(lat===""||long===""){
                setAlert("Please select the location on the map.")
            }else {
                doGenerateProgram();
            }
        }
    }

    const doGetListTurfTypes = async () => {
        try{
            let currentTurfTypes = await listTurfTypes(token, 0, 100);
            setTurfTypes(currentTurfTypes);
        } catch (error) {
            console.log("Error on List Turf Types:", error);
        }
    }

    const doGenerateProgram = async () => {
        try {
            let rsp = await addProgramBuilder(token, name, lawnManagementCompanyId, zipCode, turfTypesNames[turfType], lat, long)
            setAddProgramBuilderRsp(rsp)
        } catch (error) {
            console.error("Error on generate program:", error)
        }
    }

    const doGenerateGrowthSummaryProgram = async (programId) => {
        try {
            let rsp = await generateGrowthSummaryProgram(token, programId)
            setGenerateGrowthSummaryRsp(rsp)
        } catch (error) {
            console.error("Error on generate program:", error)
        }
    }


    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
        if(lawnManagementCompanyId){
          doGetListTurfTypes();
        }
        }, [lawnManagementCompanyId])

    useEffect(() => {
        if(token){
            getLawnManagementId()
        }
    }, [token])

    useEffect(() => {
        if(turfTypes){
            setTurfTypesNames(turfTypes["data"].reduce((acc,item) => {
                acc[item["turf_type_name"]] = item["id"]
                return acc
                
            }, {}))
        }
    }, [turfTypes]);

    useEffect(() => {
        if(addProgramBuilderRsp){
            if(addProgramBuilderRsp.success){
                setAlertColor("success")
                setProgramId(addProgramBuilderRsp.data.id)
                doGenerateGrowthSummaryProgram(addProgramBuilderRsp.data.id)
            } else {
                setAlertColor("danger")
            }
            setAlert(addProgramBuilderRsp.message)
        }
    },[addProgramBuilderRsp])

    useEffect(() => {
        if(generateGrowthSummaryRsp){
            if(generateGrowthSummaryRsp.success){
                navigate("/admin/programBuilder/details/" + programId)
            } else {
                setAlertColor("danger")
                setAlert(generateGrowthSummaryRsp.message)
            }
           
        }
    },[generateGrowthSummaryRsp])


    return (
      <>
        <PageHeader />
        <Container className={alert ? "mt--8": "mt--7"} fluid>
            {alert&&
                <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
            }
            <div className="col">
                <Row>
                <Col lg="12" className="mb-2">
                <BreadcrumbNavigation active="New Template" past={[{"name":"ProgramBuilder", "link":"/admin/programBuilder"}]}/>
                </Col>
                </Row>
                <Card className="bg-secondary shadow">
                    <CardHeader className="border-0 d-flex modal-header">
                        <div className="d-flex">
                            <Button
                            color="transparent"
                            onClick={(e) =>{e.preventDefault(); navigate(-1)}}
                            size="sm"
                            >
                                <i className="fa-solid fa-arrow-left"></i>
                            </Button>
                            <h3 className="mb-0">&nbsp;New Program</h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Row >
                        <Col lg="12">
                         <div className="pl-lg-4">
                        <Form>
                        <Row>
                        <Col lg="3" className="mb-3">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-mailing-city"
                            >
                                Program Name
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <ValidationInput type="text" placeholder="Program name"  value={name} setter={setName} validationType="FREETEXT" message={"Invalid name"} getValidState={setNameValid}/>
                            </div>
                        </Col>
                        <Col lg="3" className="mb-3">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-mailing-city"
                            >
                               Zip Code
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <ValidationInput type="text" placeholder="Zip code"  value={zipCode} setter={setZipCode} validationType="NUMERIC" message={"Invalid zip code"} getValidState={setZipCodeValid}/>
                            </div>
                        </Col>
                        <Col lg="3" className="mb-3">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-mailing-city"
                            >
                               Turf Type
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                           { turfTypesNames &&  <DropdownList header={turfType} items={Object.keys(turfTypesNames)} setter={setTurfType}/>}
                            </div>
                        </Col>
                        </Row>
                        </Form>
                        </div>
                        </Col>
                        </Row>
                        <Row  className="px-3">
                        <Col lg="12" style={{minWidth:"50%", minHeight:"40vh"}}>
                            <GoogleMapComponent getLat={setLat} getLong={setLong}  draggable={true}  searchAddress={""} searchZipCode={zipCode}/>
                        </Col>
                        </Row>
                        <Row className="py-4 px-3 justify-content-center">
                        <Button
                                color="primary"
                                disabled={!(nameValid&&zipCodeValid)}
                                onClick={(e) =>{e.preventDefault(); onClickGenerateProgram()}}
                                size="md"
                                className="d-flex "
                                >
                            Generate Program
                            </Button>
                        </Row>
                        </CardBody>
                </Card>
            </div>
        </Container>
        </>
    );
  };
  
  export default AddTemplate;


    