
import { Container } from "reactstrap";

const PageHeader = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-5 pt-5 pt-md-8 pt-sm-7">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
    </>
  );
};

export default PageHeader;
