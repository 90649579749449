import {postRequest, URL_SERVER} from './../requests'


export default async function login(username, password){
    const data = {
        "Username": username,
        "Password": password
        }
    try {
        return await postRequest(URL_SERVER + 'user/login', data)
    } catch (error) {
        console.error(error);
    }
}