
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useNavigate } from "react-router-dom";

function BreadcrumbNavigation({past, active, children}){
    const navigate = useNavigate();
    
  return (
        <>
            {children ?
            <Breadcrumb listTag="div" >
                <div className={"d-flex justify-content-between align-items-center"} style={ {width:"100%"}}>
            {past && past.map((p,idx) => (
                <BreadcrumbItem
                tag="a"
                id={"breadcrumb-" + idx}
                onClick={() => navigate(p["link"])}
                >
                {p["name"]}
            </BreadcrumbItem>

            ))}
        {active &&
        <BreadcrumbItem
            active
            tag="span"
        >
            {active}
        </BreadcrumbItem>
        }
        {children}
        </div>
        </Breadcrumb>   
        :
        <Breadcrumb listTag="div" >
        {past && past.map((p,idx) => (
                <BreadcrumbItem
                    tag="a"
                    id={"breadcrumb-" + idx}
                    onClick={() => navigate(p["link"])}
                    key={idx}
                >
                {p["name"]}
            </BreadcrumbItem>

            ))}
        {active &&
        <BreadcrumbItem
            active
            tag="span"
        >
            {active}
        </BreadcrumbItem>
        }
        </Breadcrumb>   
        
        }
        
        </>
             
    );
};

export default BreadcrumbNavigation;

