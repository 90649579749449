import {getRequest, URL_SERVER} from './../requests'


export default async function getProperty(token, propertyId){
    try {
        return await getRequest(URL_SERVER + "property/" + propertyId, token)
    } catch (error) {
        console.error(error);
    }

}