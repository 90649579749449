
import { useState, useEffect } from "react";

import {
    Input,
    Row,
    Col,
    FormGroup,
    Button,
    Card
} from "reactstrap";
import Alert from "components/Alerts/Alert"

import getCompany from "api/company/getCompany";
import getUserByUsername from "api/users/getUser";
import { addCustomerCompany, addCustomerNatural } from "api/customer/addCustomer";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup"
import DropdownList from "components/DropdownList/DropdownList"
import DropdownListWithSearch from "components/DropdownList/DropdownListWithSearch"
import { STATES } from "assets/texts/states"
import { useTokenContext } from "App";
import ValidationInput from "components/ValidationInput/ValidationInput";
import { getLCMID } from "api/company/getLCMID";

function AddCustomer({ setSuccess }) {
    const { token, setToken } = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [customerType, setCustomerType] = useState(1);


    const [typeField1, setTypeField1] = useState("");
    const [typeField2, setTypeField2] = useState("");
    const [typeField3, setTypeField3] = useState("");
    const [typeField4, setTypeField4] = useState("");
    const [typeField5, setTypeField5] = useState("");

    const [billingAddress, setBillingAddress] = useState("");
    const [billingState, setBillingState] = useState("State");
    const [billingCity, setBillingCity] = useState("");
    const [billingCountry, setBillingCountry] = useState("");
    const [billingZipCode, setBillingZipCode] = useState("");

    const [mailingAddress, setMailingAddress] = useState("");
    const [mailingState, setMailingState] = useState("State");
    const [mailingCity, setMailingCity] = useState("");
    const [mailingCountry, setMailingCountry] = useState("");
    const [mailingZipCode, setMailingZipCode] = useState("");

    const [nameVisible, setNameVisible] = useState(false);
    const [taxVisible, setTaxVisible] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [nameExists, setNameExists] = useState(false);
    const [taxIdExists, setTaxIdExists] = useState(false);
    const [addCustomerResponse, setAddCustomerResponse] = useState(false);

    const [typeField1Valid, setTypeField1Valid] = useState(true);
    const [typeField2Valid, setTypeField2Valid] = useState(true);
    const [typeField3Valid, setTypeField3Valid] = useState(true);
    const [typeField4Valid, setTypeField4Valid] = useState(true);
    const [typeField5Valid, setTypeField5Valid] = useState(true);

    const [billingAddressValid, setBillingAddressValid] = useState(true);
    const [billingCityValid, setBillingCityValid] = useState(true);
    const [billingCountryValid, setBillingCountryValid] = useState(true);
    const [billingZipCodeValid, setBillingZipCodeValid] = useState(true);

    const [mailingAddressValid, setMailingAddressValid] = useState(true);
    const [mailingCityValid, setMailingCityValid] = useState(true);
    const [mailingCountryValid, setMailingCountryValid] = useState(true);
    const [mailingZipCodeValid, setMailingZipCodeValid] = useState(true);
    const [alert, setAlert] = useState(null);

    function onEmailBlur() {
        //check email exists
        doCheckUserByUsername();
    }

    function onCompanyNameBlur() {
        if (typeField1 !== "") {
            doCheckCompanyByName();
        }
    }

    function onCompanyTaxIDBlur() {
        if (typeField2 !== "") {
            doCheckCompanyByTaxId();
        }
    }

    function onAddCustomerClick() {
        if (typeField1 === "" || typeField2 === "" || billingAddress === "" || billingCity === "" || billingCountry === "" || billingState === "State" || billingZipCode === "") {
            setAlert("Complete all mandatory fields (*)")
        } else {
            if (customerType == 2 && (typeField4 === "" || mailingAddress === "" || mailingCountry === "" || mailingCity === "" || mailingState === "State" || mailingZipCode === "")) {
                setAlert("Complete all mandatory fields (*)")
            } else {
                if (customerType == 1 && (typeField3 == "" || typeField4 == "")) {
                    setAlert("Complete all mandatory fields (*)")
                } else {


                    doAddCustomer();
                }
            }
        }
    }

    function onSetCustomerType(type) {
        setCustomerType(type);
        setTypeField1("");
        setTypeField2("");
        setTypeField3("");
        setTypeField4("");
        setTypeField5("");

        setBillingAddress("");
        setBillingCity("");
        setBillingState("State");
        setBillingCountry("");
        setBillingZipCode("");

        setMailingAddress("");
        setMailingCity("");
        setMailingState("State");
        setMailingCountry("");
        setMailingZipCode("")

        setNameVisible(false);
        setTaxVisible(false);

    }

    const doCheckUserByUsername = async () => {
        try {
            let usrEx = await getUserByUsername(token, typeField1);
            setUserExists(usrEx);
        } catch (error) {
            console.log("Error on Check User By UserName:", error);
        }
    }

    const doCheckCompanyByName = async () => {
        try {
            let nameEx = await getCompany(token, "", typeField1, "", lawnManagementCompanyId);
            setNameExists(nameEx);
        } catch (error) {
            console.log("Error on Check Company By Name:", error);
        }
    }

    const doCheckCompanyByTaxId = async () => {
        try {
            let taxIDEx = await getCompany(token, typeField2, "", "", lawnManagementCompanyId);
            setTaxIdExists(taxIDEx);
        } catch (error) {
            console.log("Error on Check Company By TaxID:", error);
        }
    }

    const doAddCustomer = async () => {
        try {
            if (customerType === 2) {
                var exist_company_id = null;
                if (nameExists && nameExists.success == true) {
                    exist_company_id = nameExists["data"]["id"]
                }
                if (taxIdExists && taxIdExists.success == true) {
                    exist_company_id = taxIdExists["data"]["id"];
                }
                if (exist_company_id) {
                    let usrEx = await addCustomerCompany(token, nameExists["data"]["id"], "", "", "", "", "", "", "", "", "", "",
                        billingAddress, billingCity, billingCountry, billingState, billingZipCode, lawnManagementCompanyId);
                    setAddCustomerResponse(usrEx);
                } else {
                    let usrEx = await addCustomerCompany(token, "", typeField1, typeField3, typeField2, mailingAddress, mailingCity, mailingCountry, mailingState, mailingZipCode, typeField4, typeField5,
                        billingAddress, billingCity, billingCountry, billingState, billingZipCode, lawnManagementCompanyId);
                    setAddCustomerResponse(usrEx);
                }
            } else {
                if (userExists && userExists.success == true) {
                    let usrEx = await addCustomerNatural(token, typeField1, typeField2, typeField3, billingAddress, billingCity, billingCountry, billingState, billingZipCode, typeField4, lawnManagementCompanyId);
                    setAddCustomerResponse(usrEx);
                } else {
                    let usrEx = await addCustomerNatural(token, typeField1, typeField2, typeField3, billingAddress, billingCity, billingCountry, billingState, billingZipCode, typeField4, lawnManagementCompanyId);
                    setAddCustomerResponse(usrEx);
                }
            }
        } catch (error) {
            console.log("Error on Add Customer:", error);
        }
    }


    useEffect(() => {
        if (userExists) {
            if (userExists.success == true) {
                setNameVisible(true);
                setTypeField2(userExists["data"]["first_name"]);
                setTypeField3(userExists["data"]["last_name"])
            } else {
                setNameVisible(false);
                setTypeField2("");
                setTypeField3("")

            }
        }
    }, [userExists]);

    useEffect(() => {
        if (nameExists) {
            if (!taxIdExists || (taxIdExists && taxIdExists.success == false)) {
                if (nameExists.success == true) {
                    setNameVisible(true);
                    setTypeField1(nameExists["data"]["company_name"]);
                    setTypeField2(nameExists["data"]["company_tax_id"]);
                    setTypeField3(nameExists["data"]["company_registration_number"])
                    setTypeField4(nameExists["data"]["company_email"])
                    setTypeField5(nameExists["data"]["company_website"])

                    setMailingAddress(nameExists["data"]["company_address"]);
                    setMailingCity(nameExists["data"]["company_city"])
                    setMailingState(nameExists["data"]["company_state"])
                    setMailingCountry(nameExists["data"]["company_country"])
                    setMailingZipCode(nameExists["data"]["company_zip_code"])
                } else {
                    setNameVisible(false);
                    // setTypeField2("");
                    // setTypeField3("");
                    // setTypeField4("");
                    // setTypeField5("");

                    // setBillingAddress("");
                    // setBillingCity("");
                    // setBillingState("State");
                    // setBillingCountry("");

                }
            }
        }
    }, [nameExists]);

    useEffect(() => {
        if (taxIdExists) {
            if (!nameExists || (nameExists && nameExists.success == false)) {
                if (taxIdExists.success == true) {
                    setTaxVisible(true);
                    setTypeField1(taxIdExists["data"]["company_name"]);
                    setTypeField2(taxIdExists["data"]["company_tax_id"]);
                    setTypeField3(taxIdExists["data"]["company_registration_number"])
                    setTypeField4(taxIdExists["data"]["company_email"])
                    setTypeField5(taxIdExists["data"]["company_website"])


                    setMailingAddress(taxIdExists["data"]["company_address"]);
                    setMailingCity(taxIdExists["data"]["company_city"])
                    setMailingState(taxIdExists["data"]["company_state"])
                    setMailingCountry(taxIdExists["data"]["company_country"])
                    setMailingZipCode(taxIdExists["data"]["company_zip_code"])
                } else {
                    setTaxVisible(false);
                    // setTypeField1("");
                    // setTypeField3("");
                    // setTypeField4("");
                    // setTypeField5("");

                    // setBillingAddress("");
                    // setBillingCity("");
                    // setBillingState("State");
                    // setBillingCountry("");
                }
            }
        }
    }, [taxIdExists]);

    useEffect(() => {
        if (addCustomerResponse) {
            if (addCustomerResponse.success) {
                setSuccess(addCustomerResponse.message);
            } else {
                setAlert(addCustomerResponse.message)
            }
        }

    }, [addCustomerResponse])

    async function getLawnManagementId() {
        setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
        if (token) {
            getLawnManagementId()
        }
    }, [token])

    return (
        <>
            {alert &&
                <Alert color="danger" text={alert} setter={setAlert}></Alert>
            }
            <div className="pl-lg-2 pr-lg-2">
                <Row className="">
                    <Col lg="12" className="d-flex">
                        <label
                            className="form-control-label mr-4"
                        >
                            Type
                        </label>
                        <RadioButtonGroup labels={["Home owner", "Company"]} setter={(type) => onSetCustomerType(type)} />
                    </Col>
                </Row>
                {customerType == 2 ?
                    <>
                        <div>
                            <Row className="pt-lg-4">
                                <Col lg="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-company-name"
                                    >
                                        Name
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Company name" value={typeField1} disabled={taxVisible} setter={setTypeField1} validationType="FREETEXT" message={"Invalid name"} mandatory onBlur={onCompanyNameBlur} getValidState={setTypeField1Valid} />
                                </Col>
                                <Col lg="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-taxId"
                                    >
                                        TAX ID
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="TAX ID" value={typeField2} disabled={nameVisible} setter={setTypeField2} validationType="FREETEXT" message={"Invalid TAX ID"} mandatory onBlur={onCompanyTaxIDBlur} getValidState={setTypeField2Valid} />
                                </Col>
                            </Row>
                            <Row className="pt-lg-2">
                                {/* <Col lg="4">
                        <label
                            className="form-control-label"
                            htmlFor="input-reg-no"
                        >
                            Registration Number
                        </label>
                        <ValidationInput type="text" placeholder="Registration number" value={typeField3} disabled={nameVisible||taxVisible} setter={setTypeField3} validationType="FREETEXT_ZERO" message={"Invalid registration no."} getValidState={setTypeField3Valid}/>
                    </Col>
                     */}
                                <Col lg="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email-cmp"
                                    >
                                        Email
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="email" placeholder="Email" value={typeField4} disabled={nameVisible || taxVisible} setter={setTypeField4} validationType="EMAIL" message={"Invalid email"} getValidState={setTypeField4Valid} />
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-website"
                                        >
                                            Website
                                        </label>
                                        <ValidationInput type="text" placeholder="Website" value={typeField5} disabled={nameVisible || taxVisible} setter={setTypeField5} validationType="FREETEXT_ZERO" message={"Invalid website"} getValidState={setTypeField5Valid} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <hr className="my-1" />
                        {/* Billing Address */}
                        <Row>
                            <Col lg="6" md="12">
                                <h6 className="heading-small text-muted mb-2">
                                    Billing information
                                </h6>
                                <Card className="shadow p-3">
                                    <div>
                                        <Row >
                                            <Col md="12">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-address"
                                                >
                                                    Address
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <ValidationInput type="text" placeholder="Billing Address" value={billingAddress} disabled={nameVisible || taxVisible} setter={setBillingAddress} validationType="FREETEXT" message={"Invalid address"} getValidState={setBillingAddressValid} />
                                            </Col>
                                        </Row>
                                        <Row className="pt-lg-2">
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-city"
                                                >
                                                    City
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <ValidationInput type="text" placeholder="Billing City" value={billingCity} disabled={nameVisible || taxVisible} setter={setBillingCity} validationType="FREETEXT" message={"Invalid city"} getValidState={setBillingCityValid} />
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="m-0">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Country
                                                        <span>
                                                            <small style={{ color: "red", borderColor: "red" }}>
                                                                &nbsp;*
                                                            </small>
                                                        </span>
                                                    </label>
                                                    <ValidationInput type="text" placeholder="Billing Country" value={billingCountry} disabled={nameVisible || taxVisible} setter={setBillingCountry} validationType="FREETEXT" message={"Invalid country"} getValidState={setBillingCountryValid} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="pt-lg-2">
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-billing-state"
                                                >
                                                    State
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <DropdownListWithSearch header={billingState} items={STATES} setter={setBillingState} disabled={nameVisible || taxVisible} />
                                            </Col>
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-billing-zip"
                                                >
                                                    Zip Code
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <ValidationInput type="text" placeholder="Billing Zip Code" value={billingZipCode} disabled={nameVisible || taxVisible} setter={setBillingZipCode} validationType="NUMERIC" message={"Invalid zip code"} getValidState={setBillingZipCodeValid} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col>
                                <h6 className="heading-small text-muted mb-2">
                                    Mailing information
                                </h6>
                                <Card className="shadow p-3">
                                    <div>
                                        <Row >
                                            <Col md="12">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-mailcmp-address"
                                                >
                                                    Address
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <ValidationInput type="text" placeholder="Mailing Address" value={mailingAddress} setter={setMailingAddress} validationType="FREETEXT" message={"Invalid address"} getValidState={setMailingAddressValid} />
                                            </Col>
                                        </Row>
                                        <Row className="pt-lg-2">
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-mailcmp-city"
                                                >
                                                    City
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <ValidationInput type="text" placeholder="Mailing City" value={mailingCity} setter={setMailingCity} validationType="FREETEXT" message={"Invalid city"} getValidState={setMailingCityValid} />
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="m-0">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-mailcmp-country"
                                                    >
                                                        Country
                                                        <span>
                                                            <small style={{ color: "red", borderColor: "red" }}>
                                                                &nbsp;*
                                                            </small>
                                                        </span>
                                                    </label>
                                                    <ValidationInput type="text" placeholder="Mailing Country" value={mailingCountry} setter={setMailingCountry} validationType="FREETEXT" message={"Invalid Country"} getValidState={setMailingCountryValid} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="pt-lg-2">
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-mailcmp-billing-state"
                                                >
                                                    State
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <DropdownListWithSearch header={mailingState} items={STATES} setter={setMailingState} />
                                            </Col>
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-mailcmp-zip"
                                                >
                                                    Zip Code
                                                    <span>
                                                        <small style={{ color: "red", borderColor: "red" }}>
                                                            &nbsp;*
                                                        </small>
                                                    </span>
                                                </label>
                                                <ValidationInput type="text" placeholder="Mailing Zip Code" value={mailingZipCode} setter={setMailingZipCode} validationType="NUMERIC" message={"Invalid zip code"} getValidState={setMailingZipCodeValid} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row className="pt-lg-4">
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                        Email
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Email" value={typeField1} onBlur={onEmailBlur} setter={setTypeField1} validationType="EMAIL" message={"Invalid Email"} getValidState={setTypeField1Valid} />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                        Mobile
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Mobile" value={typeField4} setter={setTypeField4} validationType="FREETEXT" message={"Invalid mobile number"} getValidState={setTypeField4Valid} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-first-modal"
                                    >
                                        First Name
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="First name" value={typeField2} setter={setTypeField2} validationType="PERSONNAME" message={"Invalid First Name"} getValidState={setTypeField2Valid} />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-last-modal"
                                    >
                                        Last Name
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Last name" value={typeField3} setter={setTypeField3} validationType="PERSONNAME" message={"Invalid Last Name"} getValidState={setTypeField3Valid} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <h6 className="heading-small text-muted mb-1">
                            Billing information
                        </h6>
                        <div>
                            <Row className="pt-lg-2">
                                <Col md="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-mailing-addr"
                                    >
                                        Address
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Billing Address" value={billingAddress} setter={setBillingAddress} validationType="FREETEXT" message={"Invalid Address"} getValidState={setBillingAddressValid} />
                                </Col>
                                <Col lg="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-mailing-city"
                                    >
                                        City
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <ValidationInput type="text" placeholder="Billing City" value={billingCity} setter={setBillingCity} validationType="FREETEXT" message={"Invalid City"} getValidState={setBillingCityValid} />
                                </Col>
                            </Row>
                            <Row className="pt-lg-2">
                                <Col lg="4">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-mailing-state"
                                    >
                                        State
                                        <span>
                                            <small style={{ color: "red", borderColor: "red" }}>
                                                &nbsp;*
                                            </small>
                                        </span>
                                    </label>
                                    <DropdownListWithSearch header={billingState} items={STATES} setter={setBillingState} />
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-mailing-country"
                                        >
                                            Zip Code
                                            <span>
                                                <small style={{ color: "red", borderColor: "red" }}>
                                                    &nbsp;*
                                                </small>
                                            </span>
                                        </label>
                                        <ValidationInput type="text" placeholder="Billing Zip code" value={billingZipCode} setter={setBillingZipCode} validationType="NUMERIC" message={"Invalid zip code"} getValidState={setBillingZipCodeValid} />
                                    </FormGroup>
                                </Col>
                                <Col lg="5">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-mailing-country"
                                        >
                                            Country
                                            <span>
                                                <small style={{ color: "red", borderColor: "red" }}>
                                                    &nbsp;*
                                                </small>
                                            </span>
                                        </label>
                                        <ValidationInput type="text" placeholder="Billing Country" value={billingCountry} setter={setBillingCountry} validationType="FREETEXT" message={"Invalid Country"} getValidState={setBillingCountryValid} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            </div>
            <div className="pt-2 pl-2">
                <Button
                    color="primary"
                    type="button"
                    disabled={!(typeField1Valid && typeField2Valid && typeField3Valid && typeField4Valid && typeField5Valid && billingAddressValid && billingCityValid && billingCountryValid && billingAddressValid && billingZipCodeValid && mailingZipCodeValid && mailingAddressValid && mailingCountryValid && mailingCityValid)}
                    onClick={(e) => { e.preventDefault(); onAddCustomerClick(); }}>
                    Add customer
                </Button>
            </div>
        </>
    );
};

export default AddCustomer;
