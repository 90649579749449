import {postRequest, URL_SERVER} from '../requests'


export default async function addProduct(token,product_name, price, quantity, type,weightGallon, n_pc,p_pc,k_pc,ca_pc,mg_pc,s_pc,fe_pc,mn_pc,lawn_management_company_id, epaReg, category,active_ingredients, rate, carrier_volume, quantity_type){
    let data = {
        "product_name": product_name,
        "price": parseFloat(price),
        "quantity": parseFloat(quantity),
        "epa_registration_number" : epaReg,
        "type": type,
        "n_pc": parseFloat(n_pc) ,
        "p_pc": parseFloat(p_pc),
        "k_pc": parseFloat(k_pc),
        "lawn_management_company_id" : lawn_management_company_id
    }

    if(category == "weed control" || category == "pest control"){
        data["category"] = category.replace(" ", "_")
        data["active_ingredients"] = active_ingredients
        data["rate"] = rate
        
        
    } else {
        data["ca_pc"]= parseFloat(ca_pc)
        data["mg_pc"]= parseFloat(mg_pc) 
        data["s_pc"]= parseFloat(s_pc)
        data["fe_pc"]= parseFloat(fe_pc)
        data["mn_pc"]= parseFloat(mn_pc)
        data["category"] = category
    }

    if (category=="fertiliser" && type == "liquid"){
        data["weight_per_gallon"] = parseFloat(weightGallon)
    }
    if (type !== "granular"){
        data["carrier_volume"] = carrier_volume
    }

    if (type === "liquid"){
        data["quantity_type"] = quantity_type
    }
    
    try {
        return await postRequest(URL_SERVER + 'product', data, token)
    } catch (error) {
        console.error(error);
    }

}
