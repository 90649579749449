import {postRequest, URL_SERVER} from '../requests'


export default async function addProduct2application(token, application_id, product_id, quantity, carrier_volume){
    let data = {
        "application_id": application_id,
        "product_id": product_id,
        "quantity": parseFloat(quantity),
        "carrier_volume":carrier_volume
    }
    try {
        return await postRequest(URL_SERVER + 'application/product', data, token)
    } catch (error) {
        console.error(error);
    }

}
