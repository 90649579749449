// reactstrap components
import {useState, useEffect} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback
} from "reactstrap";
import ValidationInput from "components/ValidationInput/ValidationInput";

import { useTokenContext } from "App"
import Alert from "components/Alerts/Alert"
import login from "api/login/login"
import { useNavigate } from "react-router-dom";
import { useNotification } from 'components/NotificationContext/NotificationContext';
import { getLCMID } from "api/company/getLCMID";

const Login = () => {
  const { notification, setNotification } = useNotification();
  const {token, setToken} = useTokenContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginProps, setLoginProps] = useState(null);
  const [loginResponse, setLoginResponse] = useState(null);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  const pageAfterLoginRoute = "/admin/index";

  function setUsernameFunction(usr){
    setEmail(usr)
  }
  function setPasswordFunction(pwd){    
    setPassword(pwd)
  }
  function SignInClick() {    
    onSignInClick()
  }
  function RegisterClick(){
    navigate("/auth/register")
  }
  function ForgotPasswordClick(e){
    e.preventDefault();
    navigate("/auth/forgot/password")
  }
  const onSignInClick = async () => {
    try {
      var loginResponse = await login(email, password)
      setLoginResponse(loginResponse)   
    } catch (error) {
      console.error('Login request error:', error);
    }
  }

  useEffect(() => {
    if (loginResponse){

      if (loginResponse.success){
        setLoginProps(loginResponse)
        
      }else{
        setAlert(true)
      }
    }
  },[loginResponse]);

  useEffect(() => {
    if(loginProps != null) {
      setToken(loginProps['data']['token'])

      //save token and expiry to local storage
      localStorage.setItem("expiryDate",loginProps.data.expire_at)
      localStorage.setItem("token",loginProps.data.token)
      getLCMID(loginProps.data.token)
      navigate(pageAfterLoginRoute)
    }
  }, [loginProps]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-2">
                  <strong className="" color="primary">Welcome!</strong>
                </div>
                {notification && 
                  <Alert color="success" text={notification} setter={setNotification}></Alert>
                }
                {!notification &&   
                  <div className="text-center text-muted mb-4">
                    <small>Sign in to continue to QUANTUM TURF</small>
                  </div>
                }
                <div>
                {alert&&
                <Alert color="danger" text="Username/Password incorrect!" setter={setAlert}></Alert>
                }
              </div>   
            <Form role="form">
              <FormGroup className="mb-3">              
                <ValidationInput placeholder="Email" type="email" name="email" validationType="EMAIL" icon={<i className="ni ni-email-83" />} setter={setUsernameFunction} message={"Invalid Email"}/>
                <div>
              </div>
              </FormGroup>
              <FormGroup>
                  <ValidationInput placeholder="Password" type="password" setter={setPasswordFunction} icon={<i className="ni ni-lock-circle-open" />}/>
              </FormGroup>
             
              <div className="text-center">
                <Button className="my-3" color="primary" type="button" onClick={SignInClick} block>
                  Sign in
                </Button>                              
              </div>
              <Row className="mt-2" >
                <Col xs="6">
                  <a href="" onClick={(e) => {ForgotPasswordClick(e)}}>
                    <small>Forgot password?</small>
                  </a>
                </Col> 
              </Row>           
            </Form>
          </CardBody>
        </Card>
        <div className="text-center">
          <div className="text-center text-light mt-2">
              <small>Join our community!</small>
          </div>
          <div className="text-center ">
          <Button className="my-2" color="light" type="button" onClick={RegisterClick} outline style={{backgroundColor:"rgba(24,29,39,0.5)"}}>
            Register
          </Button>    
          </div>            
        </div>

      </Col>
    </>
  );
};

export default Login;
