import {postRequest, URL_SERVER} from './../requests'


export default async function addProperty(token, address, zip_code, turf_id, gps, sq_ft, customer_id, contract_start_date,contract_end_date,
    number_of_applications, irrigation, clippings){
    const data = {
        "address": address,
        "zip_code": zip_code,
        "turf_type_id": turf_id,
        "gps": gps,
        "sq_ft": sq_ft,
        "irrigation":irrigation,
        "clippings": clippings,
        "customer_id": customer_id,
        "contract_start_date": contract_start_date,
        "contract_end_date": contract_end_date,
        }
    try {

        return await postRequest(URL_SERVER + 'property', data, token)
    } catch (error) {
        console.error(error);
    }

}