import {getRequest, URL_SERVER} from './../requests'


export default async function listProducts(token, def, lawnManagementCompanyId, category){
    let query =  "products?default=" + def + "&lawnManagementCompanyId=" + lawnManagementCompanyId 
    if(category){
        query +=  "&category=" + category
    }
    try {
        return await getRequest(URL_SERVER +query,token)
    } catch (error) {
        console.error(error);
    }
}