import {getRequest, URL_SERVER} from '../requests'


export default async function listProviders(token, lawnManagementCompanyId, skip, limit){
    try {
        return await getRequest(URL_SERVER + 'providers/'+lawnManagementCompanyId, token)
    } catch (error) {
        console.error(error);
    }

}