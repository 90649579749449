import {getRequest, URL_SERVER} from './../requests'


export default async function getHole(token, holeId){
    try {
        return await getRequest(URL_SERVER + "hole/" + holeId, token)
    } catch (error) {
        console.error(error);
    }

}