import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import { getFormattedTime } from "utils/utils";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CardBody,
  } from "reactstrap";
  // core components
  import PageHeader from "components/Headers/PageHeader.js";
  import Alert from "components/Alerts/Alert";
import listWorkers from "api/provider/listWorkers";
  import DropdownList from "components/DropdownList/DropdownList";
import getRound from "api/rounds/getRound";
import getHole from "api/property/getHole";
import generateRecomendation from "api/products/generateRecomendation"

import addApplication from "api/rounds/addApplication";
import { DatePicker } from "reactstrap-date-picker";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import getProperty from "api/property/getProperty";
import listNotes from "api/property/listNotes";
import { getLCMID } from "api/company/getLCMID";
import AddApplicationProducts from "./AddApplicationProducts";
import getRoundProgram from "api/rounds/getRoundProgram";
  
  const AddApplication = () => {

    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const { id } = useParams();

    const [round, setRound] = useState(null);

    const [addApplicationResponse, setAddApplicationResponse] = useState(null)
    const [datePicker, setDatePicker]= useState(new Date().toISOString())
    const [currentProductsFertiliser, setCurrentProductsFertiliser] = useState([]);
    const [currentProductsWeed, setCurrentProductsWeed] = useState([]);
    const [currentProductsPest, setCurrentProductsPest] = useState([]);

    const [worker, setWorker] = useState("Select...")
    const [workers, setWorkers] = useState(null);
    const [workersNames, setWorkersNames] = useState(null);
    const [hole, setHole] = useState(null);
    const [property, setProperty] = useState(null);
    const [notes, setNotes] = useState(null);
   
    const [recommandation, setRecommendation] = useState(null)
    const [associatedProgramRound, setAssociatedProgramRound] = useState(null)
    const [fertiliserExistent, setFertilizerExistent] = useState([])
    const [weedExistent, setWeedExistent] = useState([])
    const [pestExistent, setPestExistent] = useState([])


    const doGetRecommendation = async () => {
        try{
            let rsp = await generateRecomendation(token, id);
            setRecommendation(rsp.data);
        } catch (error) {
            console.log("Error get recommendation:", error);
        }
    }    

    function handleDateChange(value, formattedValue) {
        setDatePicker(value)
      }


    function onAddClicked(){
        if(worker === "Select..."){
            setAlert("Assign a worker for this application.")
        } else {
            
            doAddApplication();
        }
       
    }

    const doGetListWorkers = async () => {
        try{
            let rsp = await listWorkers(token, lawnManagementCompanyId);
            setWorkers(rsp);
        } catch (error) {
            console.log("Error on List Workers:", error);
        }
    }


    const doGetRound = async () => {
        try{
            let roundResp = await getRound(token, id);
            setRound(roundResp);
        } catch (error) {
            console.log("Error on Get Round:", error);
        }
    }

    const doGetRoundProducts = async (round_id) => {
        try{
            let roundResp = await getRoundProgram(token, round_id);
            setAssociatedProgramRound(roundResp);
        } catch (error) {
            console.log("Error on Get Round:", error);
        }
    }


    const doGetHole = async () => {
        try{
            let rsp = await getHole(token, round["data"]["hole_id"]);
            setHole(rsp);
        } catch (error) {
            console.log("Error on Get Round:", error);
        }
    }

    const doGetProperty = async () => {
        try{
            let rsp = await getProperty(token, round["data"]["property_id"]);
            setProperty(rsp);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }

    const doGetPropertyNotes = async () => {
        try{
            let rsp = await listNotes(token, round["data"]["property_id"], "", 4);
            setNotes(rsp);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }
    
    const doAddApplication = async () => {
        try{
            let combined = currentProductsFertiliser.concat(currentProductsPest, currentProductsWeed)
            let combined_quantities_int = combined.map((prod) => {return {"product_id": prod.product_id, "quantity": parseFloat(prod.quantity*hole.data.hole.sq_ft/1000)}})
            console.log("COMBINED:", combined)
            let rsp = await addApplication(token, round["data"]["hole_id"], round["data"]["id"], workersNames[worker], parseInt(new Date(datePicker).getTime() / 1000),combined_quantities_int );
            setAddApplicationResponse(rsp);
        } catch (error) {
            console.log("Error on Add Application:", error);
        }
    }

    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
      }
  
      useEffect(() => {
      if(lawnManagementCompanyId){
        doGetRound();
        doGetListWorkers();
      }
      }, [lawnManagementCompanyId])
  
      useEffect(() => {
          if(id, token){
              getLawnManagementId()
          }
      }, [id,token])


    useEffect(() => {
        if(workers && workers.success && workers["data"]!=null){
            setWorkersNames(workers["data"].reduce((acc, item) => {
                let name = item.operator["name"] + " (" + item.associate["email"] + " )"
                acc[name] = item.operator["id"]
                return acc;
            }, {}))
        }
    }, [workers]);

    useEffect(() => {
        if(round){
            if(round.success){
                setDatePicker(new Date(round["data"]["application_date"] * 1000).toISOString())
                doGetHole();
                doGetProperty();
                doGetPropertyNotes();
                doGetRecommendation();
                doGetRoundProducts(round["data"]["program_associated_round_id"]);
            }
        }
    },[round])


        
    useEffect(() => {
        if(addApplicationResponse){
            if(addApplicationResponse.success === true){
                const propsToPass = {
                    message: addApplicationResponse.message,
                    pane: 4
                  };
                  navigate("/admin/property/detail/" + hole["data"]["hole"]["property_id"], { state: propsToPass });
            } else {
                setAlert(addApplicationResponse.message)
            }
        }
    }, [addApplicationResponse])


    useEffect(() => {
        if(associatedProgramRound){
            if(associatedProgramRound.data.products){
                associatedProgramRound.data.products.forEach((product, idx) => {
                    if (product.category === "fertiliser") {
                        setFertilizerExistent(prevState => [...prevState,  {"product": product, "rate": associatedProgramRound.data.rates[idx]}]);
                    } else if (product.category === "weed_control") {
                        setWeedExistent(prevState => [...prevState,  {"product": product, "rate": associatedProgramRound.data.rates[idx]}]);
                    } else if (product.category === "pest_control") {
                        setPestExistent(prevState => [...prevState,  {"product": product, "rate": associatedProgramRound.data.rates[idx]}]);
                    }
                });
            }
        }
    },[associatedProgramRound])


    function navigateBack(){
        if(hole){
        const propsToPass = {
            pane: 3
          };
          navigate(-1, { state: propsToPass });
        }
    }
    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className={alert ? "mt--8": "mt--7"} fluid>
            
            <div className="col">
            {alert&&
                        <Alert color="danger" text={alert} setter={setAlert}></Alert>
                        }
                <Row>
                    <Col lg="12" className="mb-2">
                        {hole && hole.success && hole.data && property && property.data &&
                    <BreadcrumbNavigation active="New Application" past={[{"name":"Customers", "link":"/admin/customers"}, {"name": "Details", "link":"/admin/customer/detail/" + hole["data"]["hole"]["customer_id"]},
                    {"name": property["data"]["property"]["address"], "link":"/admin/property/detail/" + property["data"]["property"]["id"]}]}/>
                        }
                    </Col>
                </Row>
                        
                <Card className="bg-secondary shadow mb-5">

                    <CardHeader className="border-0 d-flex modal-header">
                        <div className="d-flex">
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); navigateBack()}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                        <h3 className="mb-0">Schedule an application</h3>
                        </div>
                        <Button
                            color="primary"
                            onClick={(e) =>{e.preventDefault(); onAddClicked();}}
                            disabled={!(hole)}
                            size="sm"
                            
                            >
                        Schedule application
                            </Button>
                    </CardHeader>
                    <CardBody>
                        <div className="pl-lg-4">
                        <Row className="pb-4">
                        {hole && hole.success &&
                        <>
                            <Col lg="5">
                            <Card className="p-4">
                            <CardHeader className="m-0 p-0 pb-1">
                                <label className="form-control-label text-muted">Property</label>
                                <h5>  {property && property.success && property["data"]["property"]["address"] +" "+ property["data"]["property"]["zip_code"] + " - " + property["data"]["customerName"] + " - " +   hole["data"]["hole"]["hole_name"] }
                                    </h5>
                                </CardHeader>
                            <Row className="mt-2">
                                <Col lg="8">
                                    <label  className="form-control-label text-muted">
                                        Turf Type
                                    </label>
                                    <h5>
                                        {hole["data"]["turf_type"]["turf_type_name"]}
                                    </h5>
                                </Col>
                                <Col  lg="4">
                                    <label  className="form-control-label text-muted">
                                        Area <small>[sq ft]</small>
                                    </label>
                                    <h5>
                                        {hole["data"]["hole"]["sq_ft"]}
                                    </h5>
                                </Col>
                            </Row>
                            </Card>
                            </Col>
                            <Col lg="7">
                            <Card className="p-4">
                            <CardHeader className="m-0 p-0 pb-1">
                                <h4>Recent notes</h4>
                                </CardHeader>
                            <div className="mt-2">
                            {notes && notes.success && notes.data &&
                            
                            notes.data.slice(0,2).map((note,idx) => (
                                <Card id={"note-" + idx} className="p-2 my-1 shadow bg-secondary">
                                    <small>{note.content}</small>
                                </Card>
                            ))
                            
                            }
                            </div>
                            </Card>
                            </Col>
                            </>
                     }
                     </Row>
                     </div>
                    {round && round.success && hole &&
                    <>
                        <hr className="my-1 mt-1" />
                        <div className="pl-lg-4">
                            <h4 className="heading-small text-muted mr-5 my-2 mb-4">
                                Schedule
                            </h4>
                        <Form>
                        <Row className="mt-0 justify-content-start align-items-center">
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Application Date
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <DatePicker id  = "example-datepicker"
                            className=""
                                dateFormat="MM/DD/YYYY"
                                value   = {datePicker} 
                                onChange= {(v,f) => handleDateChange(v, f)}
                                style={{}}
                                
                                />
                                <small className="text-primary font-italic">
                                   {round && round.success &&"Recommended application date: "+ getFormattedTime(round["data"]["application_date"])}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Assigned Worker
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            {workersNames && <DropdownList header={worker} items={Object.keys(workersNames)} setter={setWorker} />}
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                            <AddApplicationProducts recommandation={recommandation} round={round["data"]} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsFertiliser} productsType="fertiliser" title="Fertilization plan" currentProductsParent={fertiliserExistent} area={hole.data.hole.sq_ft}/>
                            </Col>
                            </Row> 
                            <Row className="mt-5">
                            <Col lg="12">
                            <AddApplicationProducts round={round["data"]} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsWeed} productsType="weed_control" title="Weed control plan" currentProductsParent={weedExistent} area={hole.data.hole.sq_ft}/>
                            </Col>
                            </Row>
                            <Row className="mt-5">
                            <Col lg="12">
                            <AddApplicationProducts round={round["data"]} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsPest} productsType="pest_control" title="Pest control plan" currentProductsParent={pestExistent} area={hole.data.hole.sq_ft}/>
                            </Col>
                            </Row>
                            </Form>
                        </div>
                        </>
                    }
                    </CardBody>
                </Card>
            </div>
        </Container>
        </>
    );
  };
  
  export default AddApplication;


    