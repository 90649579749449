import getMyCompanies from "api/me/getMyCompanies";

export async function getLCMID(token){
    let lcmId = localStorage.getItem("LCMId");

    if (lcmId !== null) {
        try {
            let response = await getMyCompanies(token);
            if (response.success && response.data && response.data.companies_roles.length > 0) {
                let verify = response.data.companies_roles.filter((company_role) => company_role.company_id == lcmId)
                if(verify && verify.length >0){
                    return lcmId;
                }
                localStorage.setItem("LCMId", response.data.companies_roles[0].company_id);
                return lcmId;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    try {
        let response = await getMyCompanies(token);
        if (response.success && response.data && response.data.companies_roles.length > 0) {
            lcmId = response.data.companies_roles[0]["company_id"];
            localStorage.setItem("LCMId", lcmId);
            return lcmId;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
  
  }