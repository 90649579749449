import {getRequest, URL_SERVER} from './../requests'


export default async function getRoundProgram(token, roundId){
    try {
        return await getRequest(URL_SERVER + "roundProgram/" + roundId, token)
    } catch (error) {
        console.error(error);
    }

}