import {postRequest, URL_SERVER} from './../requests'


export async function addCustomerCompany(token, company_id, company_name, company_registration_number,
    company_tax_id, company_address, company_city, company_country, company_state, company_zip_code, company_email,
    company_website, delivery_address, delivery_city, delivery_country, delivery_state, delivery_zip_code, lawnManagementCompanyId){
    
    var data = {}
    data["delivery_address"] = delivery_address;
    data["delivery_city"] = delivery_city;
    data["delivery_country"] = delivery_country;
    data["delivery_state"] = delivery_state;
    data["delivery_zip_code"] = delivery_zip_code;
    data["lawn_management_company_id"] = lawnManagementCompanyId;
    if(company_id != ""){
        data["company_id"] = company_id;
    }else {
        data["company_name"] = company_name;
        data["company_registration_number"] = company_registration_number;
        data["company_tax_id"] = company_tax_id;
        data["company_address"] = company_address;
        data["company_city"] = company_city;
        data["company_country"] = company_country;
        data["company_state"] = company_state;
        data["company_zip_code"] = company_zip_code;
        data["company_email"] = company_email;
        data["company_website"] = company_website;
    }
    
    try {

        return await postRequest(URL_SERVER + 'register/customer/company', data, token)
    } catch (error) {
        console.error(error);
    }

}

export async function addCustomerNatural(token, email, first_name, last_name, delivery_address, 
    delivery_city, delivery_country, delivery_state, delivery_zip_code, mobile, lawnManagementCompanyId){
    
    var data = {}
    data["email"] = email;
    data["delivery_address"] = delivery_address;
    data["delivery_city"] = delivery_city;
    data["delivery_country"] = delivery_country;
    data["delivery_state"] = delivery_state;
    data["delivery_zip_code"] = delivery_zip_code;
    data["mobile"] = mobile;
    data["lawn_management_company_id"] = lawnManagementCompanyId;
    data["create_account"] = 2;

    if(first_name!=""){
        data["first_name"] = first_name;
        data["last_name"] = last_name;
    }
    try {

        return await postRequest(URL_SERVER + 'register/customer/natural', data, token)
    } catch (error) {
        console.error(error);
    }

}