import {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { constants } from "assets/constants/constants";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Table,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
  } from "reactstrap";
  // core components
  import Alert from "components/Alerts/Alert"
  import { useTokenContext } from "App"
  import DropdownList from "components/DropdownList/DropdownList"
  import {STATES} from "assets/texts/states"
  import PageHeader from "components/Headers/PageHeader.js";
  import getCustomer from "api/customer/getCustomer"
  import ModalDialog from "components/ModalDialog/ModalDialog"
  import AddContact from "./AddContact";
  import { useNavigate } from 'react-router-dom';
  import { useParams } from 'react-router-dom';

  import {updateCustomer} from "api/customer/updateCustomer"
import ValidationInput from "components/ValidationInput/ValidationInput";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import listProperties from "api/property/listProperties";
import { getFormattedTime } from "utils/utils";
import PaginationComponent from "components/Pagination/Pagination";
import { getLCMID } from "api/company/getLCMID";
import updateCompany from "api/company/updateCompany";
  
  function CustomersDetails(){
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [properties, setProperties] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [modalContactsState, setModalContactsState] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const {token} = useTokenContext();
    const { id } = useParams();
    const [customerId, setCustomerId] = useState(null);

    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [billingState, setBillingState] = useState(null);
    const [country, setCountry] = useState(null);
    const [zipCode, setZipCode] = useState(null);

    const [name, setName] = useState(null)
    const [mobile, setMobile] = useState(null)

    const [emailCompany, setEmailCompany] = useState(null)
    const [websiteCompany, setWebsiteCompany] = useState(null)
    const [addressCompany, setAddressCompany] = useState(null)
    const [cityCompany, setCityCompany] = useState(null)
    const [countryCompany, setCountryCompany] = useState(null)
    const [stateCompany, setStateCompany] = useState(null)
    const [zipCompany, setZipCompany] = useState(null)

    const [addressValid, setAddressValid] = useState(true);
    const [cityValid, setCityValid] = useState(true);
    const [countryValid, setCountryValid] = useState(true);
    const [zipCodeValid, setZipCodeValid] = useState(true);
    const [nameValid, setNameValid] = useState(true)
    const [mobileValid, setMobileValid] = useState(true)

    const [emailCompanyValid, setEmailCompanyValid] = useState(true)
    const [websiteCompanyValid, setWebsiteCompanyValid] = useState(true)
    const [addressCompanyValid, setAddressCompanyValid] = useState(true)
    const [cityCompanyValid, setCityCompanyValid] = useState(true)
    const [countryCompanyValid, setCountryCompanyValid] = useState(true)
    const [zipCompanyValid, setZipCompanyValid] = useState(true)

    const [updateCompanyResponse, setUpdateCompanyResponse] = useState(null)

    const [updateCustomerResponse, setUpdateCustomerResponse] = useState(null);
    const [alert, setAlert] = useState(null);
    const navigate = useNavigate();


    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])
    const location = useLocation();
    const { state } = location;
    const { message } = state || {};

    const doGetListProperties = async () => {
        try{
            let currentproperties = await listProperties(token, null, id, 0, 100);
            setProperties(currentproperties);
        } catch (error) {
            console.log("Error on List properties:", error);
        }
    }

    function onPropertyDetailClick(propertyId){
      navigate('/admin/property/detail/' + propertyId);
    }

    function onAddPropertyClick(){
      navigate('/admin/property/new/' + id);
    }


    useEffect(() => {
      if(message){
          setAlert(message);
          navigate(".", { replace: true });
      }
    }, [message, location])

    const doGetCustomer = async (id) => {
        try{
            let currentCustomer = await getCustomer(token, id, 0, 100);
            setCustomer(currentCustomer);
        } catch (error) {
            console.log("Error on Get Customer:", error);
        }
    }

    const doUpdateCustomer = async (id) => {
        try{
            let resp = await updateCustomer(token, id, address, city, billingState, country, zipCode, mobile, name);
            setUpdateCustomerResponse(resp);
        } catch (error) {
            console.log("Error on Get Customer:", error);
        }
    }

    const doUpdateCompany = async (companyId) => {
        try {
            let rsp = await updateCompany(companyId, addressCompany, cityCompany, countryCompany, stateCompany, zipCompany, emailCompany, websiteCompany, token)
            setUpdateCompanyResponse(rsp)
        } 
        catch (error) {
            console.log("Error on updating company", error)
        }
    }


    function getCustomerName(customer){
        return customer["lawn_customer"]["name"]
    }

    function onAddContactsClick(){
        setModalContactsState(true);
    }

    function onAddContactSuccess(alertMessage){
        document.scrollingElement.scrollTop = 0;
        setModalContactsState(false);
        
        //reload
        doGetCustomer(customerId);
        setAlert(alertMessage);
    }

    function onEditMailingClicked(){
        setEditClicked(true);
        setName(getCustomerName(customer["data"]))
    }

    function onUpdateClick(){
        doUpdateCustomer(customerId);

        if(customer && customer.data.lawn_customer.customer_type == "company"){
            doUpdateCompany(customer.data.associate.id)
        }
    }

    function onCancelEditClicked(){
        setAddress(customer["data"]["lawn_customer"]["delivery_address"])
        setCity(customer["data"]["lawn_customer"]["delivery_city"])
        setBillingState(customer["data"]["lawn_customer"]["delivery_state"])
        setCountry(customer["data"]["lawn_customer"]["delivery_country"])
        setZipCode(customer["data"]["lawn_customer"]["delivery_zip_code"])
        setMobile(customer["data"]["lawn_customer"]["mobile"])
        setName(null)

        setAddressValid(true)
        setCityValid(true)
        setCountryValid(true)
        setZipCodeValid(true)
        setMobileValid(true)
        setNameValid(true)
        setEditClicked(false);

        if(customer.data.lawn_customer.customer_type == "company"){
            setEmailCompany(customer["data"]["associate"]["company_email"])
            setWebsiteCompany(customer["data"]["associate"]["company_website"])
            setAddressCompany(customer["data"]["associate"]["company_address"])
            setCityCompany(customer["data"]["associate"]["company_city"])
            setStateCompany(customer["data"]["associate"]["company_state"])
            setZipCompany(customer["data"]["associate"]["company_zip_code"])
            setCountryCompany(customer["data"]["associate"]["company_country"])
        }
    }

    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
      }

    useEffect(() => {
    if(lawnManagementCompanyId){
        doGetCustomer(id);
        doGetListProperties();
    }
    }, [lawnManagementCompanyId])

    useEffect(() => {
        if(id && token){
            getLawnManagementId()
        }
    }, [id, token])

    useEffect(() => {
        if(customer){
            setCustomerId(customer["data"]["lawn_customer"]["id"])
            setAddress(customer["data"]["lawn_customer"]["delivery_address"])
            setCity(customer["data"]["lawn_customer"]["delivery_city"])
            setBillingState(customer["data"]["lawn_customer"]["delivery_state"])
            setCountry(customer["data"]["lawn_customer"]["delivery_country"])
            setMobile(customer["data"]["lawn_customer"]["mobile"])
            setZipCode(customer["data"]["lawn_customer"]["delivery_zip_code"])

            if(customer.data.lawn_customer.customer_type == "company"){
                setEmailCompany(customer["data"]["associate"]["company_email"])
                setWebsiteCompany(customer["data"]["associate"]["company_website"])
                setAddressCompany(customer["data"]["associate"]["company_address"])
                setCityCompany(customer["data"]["associate"]["company_city"])
                setStateCompany(customer["data"]["associate"]["company_state"])
                setZipCompany(customer["data"]["associate"]["company_zip_code"])
                setCountryCompany(customer["data"]["associate"]["company_country"])
            }
        }
    }, [customer]);
    
    useEffect(() => {
        if(customer && customer.data.lawn_customer.customer_type == "natural" && updateCustomerResponse){
            document.scrollingElement.scrollTop = 0;
            setAlert(updateCustomerResponse.message)
            setEditClicked(false);
            doGetCustomer(customerId);
        }

        if(customer && customer.data.lawn_customer.customer_type == "company" && updateCustomerResponse && updateCompanyResponse){
            document.scrollingElement.scrollTop = 0;
            setAlert(updateCustomerResponse.message)
            setEditClicked(false);
            doGetCustomer(customerId);
        }

    }, [updateCustomerResponse, updateCompanyResponse])

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className={alert ? "mt--8": "mt--7"} fluid>
        {alert&&
            <Alert color="success" text={alert} setter={setAlert}></Alert>
        }
        <Row>
          <Col lg="12" className="mb-2">
          <BreadcrumbNavigation active="Details" past={[{"name":"Customers", "link":"/admin/customers"}]}/>
          </Col>
        </Row>
            <Row>
            <Col xl="6">
                <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <div className="align-items-center">
                        <Button
                        color="transparent"
                        className="mr-2"
                        onClick={(e) =>{e.preventDefault(); navigate(-1)}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                    </div>
                    <div>
                        {customer && customer["data"] && 
                        <>
                        {editClicked && customer["data"]["lawn_customer"]["customer_type"]=="natural" ?
                         <ValidationInput type="text" placeholder="Name" value={name} setter={setName} validationType="FREETEXT" message={"Invalid name"} resetInvalid={editClicked} getValidState={setNameValid}/>
                        :
                        <h3 className="mb-0">{(getCustomerName(customer["data"]))}</h3>
                        }
                        </>
                            
                        }
                    </div>
                    </div>
                    <div className="d-flex text-right">
                    {editClicked ?
                        <div className="d-flex text-right">
                        <Button
                        size="sm"
                        color="primary"
                        disabled={!(addressValid&&cityValid&&countryValid&&zipCodeValid&&nameValid&&mobileValid&&addressCompanyValid&&cityCompanyValid&&zipCompanyValid&&countryCompanyValid&&emailCompanyValid&&websiteCompanyValid)}
                        onClick={(e) =>{e.preventDefault(); onUpdateClick()}}
                        >
                       Update
                        </Button>
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); onCancelEditClicked()}}
                        className="btn-icon-only"
                        >
                        
                        <i className="fa-solid fa-xmark"></i>
                        </Button>
                        </div>
                        :
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); onEditMailingClicked()}}
                        className="btn-icon-only"
                        >
                        <i className="fa-sharp fa-solid fa-pen"></i>
                        </Button>
                        }
                    </div>
                    </div>
                </CardHeader>
                <CardBody>
                {customer && customer["data"] &&
                    <Form>
                    {customer["data"]["lawn_customer"]["customer_type"] === "natural" ?
                    <>
                    <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                        <Col lg="6">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                            >
                                Email
                            </label>
                            <label className="text-muted form-control-label" >
                            {customer["data"]["associate"]["email"]}
                            </label>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                            >
                                Mobile number
                            </label>
                            <ValidationInput type="text" placeholder="Mobile" disabled={!editClicked} value={mobile} setter={setMobile} validationType="FREETEXT" message={"Invalid mobile"} resetInvalid={editClicked} getValidState={setMobileValid}/>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    </>
                    :
                    <>
                    <h6 className="heading-small text-muted mb-2 mr-5 ml-3 mb-3">
                        Company information
                    </h6>
                    <div className="pl-lg-4">

                        <Row className="pb-1">

                        <Col lg="4" md="12">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-taxId"
                            >
                                TAX ID
                            </label>
                            <label className="text-muted form-control-label" >
                            {customer["data"]["associate"]["company_tax_id"]}
                            </label>
                            </div>
                        </Col>
                       
                    
                        {/* <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-reg-no"
                            >
                                Registration Number
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={customer["data"]["associate"]["company_registration_number"]}
                                id="input-reg-no"
                                placeholder="Registration Number"
                                type="text"
                                disabled
                            />
                            </FormGroup>
                        </Col> */}
                        <Col lg="4" md="6">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-email-cmp"
                            >
                                Email
                            </label>
                            <label className="text-muted form-control-label" >
                            <ValidationInput type="text" placeholder="" disabled={!editClicked} value={emailCompany} setter={setEmailCompany} validationType="EMAIL" message={"Invalid email"} resetInvalid={editClicked} getValidState={setEmailCompanyValid}/>
                            </label>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block "
                                htmlFor="input-website"
                            >
                                Website
                            </label>
                            <label className="text-muted form-control-label" >
                            <ValidationInput type="text" placeholder="" disabled={!editClicked} value={websiteCompany} setter={setWebsiteCompany} validationType="FREETEXT" message={"Invalid website"} resetInvalid={editClicked} getValidState={setWebsiteCompanyValid}/>
                            </label>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    <hr className="my-3" />
                        <h6 className="heading-small text-muted mb-2 mr-5 ml-3 mb-3">
                            Mailing information
                        </h6>
                        <div className="pl-lg-4">
                        <Row className="pb-2 pt-3">
                        <Col lg="8">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-address"
                            >
                                Address
                            </label>
                            <ValidationInput type="text" placeholder="" disabled={!editClicked} value={addressCompany} setter={setAddressCompany} validationType="FREETEXT" message={"Invalid address"} resetInvalid={editClicked} getValidState={setAddressCompanyValid}/>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-city"
                            >
                                City
                            </label>
                            <ValidationInput type="text" placeholder="" disabled={!editClicked} value={cityCompany} setter={setCityCompany} validationType="FREETEXT" message={"Invalid city"} resetInvalid={editClicked} getValidState={setCityCompanyValid}/>
                            </div>
                        </Col>
                        </Row>
                        <Row className="">
                        <Col lg="4">
                        <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-billing-state"
                            >
                                State
                            </label>
                            <label className="text-muted form-control-label" >
                            <DropdownList header={stateCompany} items={STATES} setter={setStateCompany} disabled={!editClicked} resetInvalid={editClicked}/>
                            </label>
                            </div>
                        </Col>
                        <Col lg="4">
                        <div className="d-block">
                            <label
                                className="form-control-label d-block"
                                htmlFor="input-billing-state"
                            >
                                Zip Code
                            </label>
                            <label className="text-muted form-control-label" >
                            <ValidationInput type="text" placeholder="" disabled={!editClicked} value={zipCompany} setter={setZipCompany} validationType="NUMERIC" message={"Invalid zip code"} resetInvalid={editClicked} getValidState={setZipCompanyValid}/>
                            </label>
                            </div>
                        </Col>
                        <Col lg="4">
                        <div className="d-block">
                            <label
                                className="form-control-label d-block " 
                                htmlFor="input-country"
                            >
                                Country
                            </label>
                            <label className="text-muted form-control-label">
                            <ValidationInput type="text" placeholder="" disabled={!editClicked} value={countryCompany} setter={setCountryCompany} validationType="NUMERIC" message={"Invalid country"} resetInvalid={editClicked} getValidState={setCountryCompanyValid}/>
                            </label>
                            </div>
                        </Col>
                        
                        </Row>
                        </div>
                    </>
                    }
                    <hr className="my-3" />
                    {/* Address */}
                    <div className="pl-lg-3 d-flex justify-content-between">
                        <h6 className="heading-small text-muted mb-2">
                            Billing information
                        </h6>
                       
                        
                    </div>
                    <div className="pl-lg-4">
                        <Row>
                        <Col lg="8">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-addr"
                            >
                                Address
                            </label>
                            <ValidationInput type="text" placeholder="Billing Address" disabled={!editClicked} value={address} setter={setAddress} validationType="FREETEXT" message={"Invalid address"} resetInvalid={editClicked} getValidState={setAddressValid}/>
                            
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                City
                            </label>
                             <ValidationInput type="text" placeholder="City" disabled={!editClicked} value={city} setter={setCity} validationType="FREETEXT" message={"Invalid city"} resetInvalid={editClicked} getValidState={setCityValid}/>
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-state"
                            >
                                State
                            </label>
                            <DropdownList header={billingState} items={STATES} setter={setBillingState} disabled={!editClicked} resetInvalid={editClicked}/>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-state"
                            >
                                Zip Code
                            </label>
                            <ValidationInput type="text" placeholder="Zip code" disabled={!editClicked} value={zipCode} setter={setZipCode} validationType="NUMERIC" message={"Invalid zip code"} resetInvalid={editClicked} getValidState={setZipCodeValid}/>
                            </FormGroup>
                        </Col>
                        <Col lg="5">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-country"
                            >
                                Country
                            </label>
                             <ValidationInput type="text" placeholder="Country" disabled={!editClicked} value={country} setter={setCountry} validationType="FREETEXT" message={"Invalid country"} resetInvalid={editClicked} getValidState={setCountryValid}/>
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>
                   
                    </Form>
                }
                </CardBody>
                </Card>
            </Col>
            <Col xl="6" className="mt-xl-0 mt-lg-4">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex modal-header">
                    <div className="d-flex align-items-center">
                    <i className="fa-solid fa-seedling text mr-3 fa-lg" />
                    <h3 className="mb-0 text">Properties</h3>
                  </div>
                  <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddPropertyClick();}}
                        size="sm"
                        
                        >
                       + Add
                        </Button>
                </CardHeader>
                <CardBody className="px-0">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Address</th>
                      <th scope="col" className="text-right">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {properties && properties["data"] && properties["data"]["properties"]!==null && properties["data"]["properties"].slice(startIndex, endIndex).map((property, idx) => (
                        <tr id={property["id"]} key={idx}>
                        <th>
                         {property["address"] + " " + property["zip_code"]}
                        </th>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only"
                              role="button"
                              size="sm"
                              color=""  
                              onClick={() => onPropertyDetailClick(property["id"])}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {properties && properties.success && properties["data"] && properties["data"]["properties"]&&
                        <div className="mt-3">
                            <PaginationComponent data={properties["data"]["properties"]} itemsPerPage={3} getStart={setStartIndex} getEnd={setEndIndex}/>
                        </div>
                    }
                </CardBody>
              </Card>
              <div className="pt-4">
                <Card className="card-profile shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                    <Col xs="8">
                        <div className="d-flex align-items-center">
                        <i className="fa-solid fa-address-book  mr-3 fa-lg" />
                        <h3 className="mb-0 ">Contacts</h3>
                    </div>
                    </Col>
                    <Col className="text-right" xs="4">
                        <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddContactsClick();}}
                        size="sm"
                        
                        >
                       + Add
                        </Button>
                    </Col>
                    </Row>
                    <Row>
                        <small></small>
                    </Row>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4 px-0">
                    <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col" className="text-right">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customer && customer["data"] && customer["data"]['Contacts'].map((contact,idx) => (
                            <tr id={contact["id"]} key={idx}>
                            <th scope="row">
                            {contact["user"]["first_name"] +" "+ contact["user"]["last_name"]}
                            </th>
                            <td>
                            {contact["user"]["email"]}
                            </td>
                            <td>
                            {contact["contact"]["mobile"]}
                            </td>
                            <td className="text-right">
                            <Badge color="danger" pill>
                                Inactive
                            </Badge>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </Table>
                </CardBody>
                </Card>
                </div>
          </Col>
            </Row>
           
        </Container>
        {modalContactsState &&
            <ModalDialog 
                status={modalContactsState} 
                setter={setModalContactsState} 
                title={"Add Contact"} 
                content={<AddContact customerId={customer["data"]["lawn_customer"]["id"]} setSuccess={onAddContactSuccess} />}
            />
        }
      </>
    );
  };
  
  export default CustomersDetails;
  